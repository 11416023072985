import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const AccountMenuBackgroundColor = ({theme: {$accountMenuBackgroundColor}}) => `${$accountMenuBackgroundColor}`;
const AccountMenuColor = ({theme: {$accountMenuColor}}) => `${$accountMenuColor}`;
const AccountMenuActiveColor = ({theme: {$accountMenuActiveColor}}) => `${$accountMenuActiveColor}`;
const AccountMenuBorderColor = ({theme: {$accountMenuBorderColor}}) => `${$accountMenuBorderColor}`;
const AccountMenuActiveBackgroundColor = ({theme: {$accountMenuActiveBackgroundColor}}) => `${$accountMenuActiveBackgroundColor}`;

const baseFont = `12px`;
const S = {};

S.FreebetCount= styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #23282e;
    color: #fff;
    text-align: center;
    font-size: 1.1666666667rem!important;
    line-height: 2rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1.6666rem;
`;

S.AccountMenu = styled(List)`
    background: ${AccountMenuBackgroundColor};
    width: 100%;
    font-family: ${FontFamilyMulish};
    font-weight: 400;
`;

S.AccountMenuItem = styled.li`
  height: ${rem('40px', baseFont)};
  display: flex;
`;

S.AccountMenuLink = styled.a`
    color: ${AccountMenuColor};
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 0 0 ${rem('29px', baseFont)};
    border-bottom: 1px solid ${AccountMenuBorderColor};
    font-size: ${rem('13px', baseFont)};
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    flex: 1;
    &.active{
      &:before{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: auto;
        content: '';
        width: 4px;
        background-color: #E4202C;
      }
     }
     
    &.active,
    &:hover{
        background-color: ${AccountMenuActiveBackgroundColor};
        border-right: none;
        text-decoration: none;
        color: ${AccountMenuActiveColor};
        font-family: ${FontFamilyMulish};
        font-weight: 700;
    }
`;

export default S;