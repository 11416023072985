import styled from 'styled-components';
import {rem} from 'polished';
import {Button} from 'global/styles/styles.js';
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;

const baseFont = `12px`;
const S = {};

S.AccountEditProfile = styled.div`
  width: 100%;
  height: 100%;
    padding: 3.3rem 2.5rem 2.5rem;
    padding-left: 30.7692px;
    padding-right: 30.7692px;
        display: flex;
    flex-direction: column;
`;

S.ChangePasswordButton = styled(Button)`
   color: #fff;
    font-weight: 400;
    width: ${rem('100px', baseFont)};
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;;
    white-space: nowrap;
    text-decoration: none;
    background-color: ${({isActive})=>((isActive==true)?'#0E62C4':'#001A5B')};
    text-transform: uppercase;
    font-family: ${FontFamily};
      font-size: ${rem('12px', baseFont)};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom:  ${rem('20px', baseFont)};
      height:  ${rem('30px', baseFont)};
      flex-shrink: 0;
     
   
   &:hover,
    &.active{
  background-color: #0E62C4;
    }
`;


S.AccountEditProfileIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

S.AccountEditProfileTitle = styled.div`
font-size: ${rem('20px', baseFont)};
  font-family: ${FontFamily};
  color: #001A5B;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: ${rem('20px', baseFont)};
  padding-bottom: ${rem('24px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
`;

export default S;