import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import S from 'StyledAccountWallet.js';
import { translation, formatMoney } from 'helpers/utilsHelper.js';
import { formatDate } from 'helpers/datesHelper.js';
import { changeDateByType, changeActiveTab, fetchTransactionsByFilter, setCurrentPage, cancellPayout } from 'accountWalletActions.js';
import ModalOpacity from 'ModalOpacity.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import CalendarIcon from 'calendar_icon.png';

class AccountWallet extends Component {
  constructor(props) {
    super(props);

    this.dateStartRef = React.createRef();
    this.dateEndRef = React.createRef();
    this.state = {
      showConfirmationModal: false,
      currentTransactionAmount: null,
      currentTransactionId: null,
      currentTransactionDate: null,
      statusPayment: undefined,
      shouldStatusPaymentBeVisible: false,
    };
  }

  componentWillUnmount = () => {
    const accountBetHistorySelector = document.querySelector('.account-wallet');
    if (accountBetHistorySelector) {
      accountBetHistorySelector.removeEventListener('click', this.handleOutsideClick, false);
    }
  };

  componentDidMount = async () => {
    const accountBetHistorySelector = document.querySelector('.account-wallet');
    if (accountBetHistorySelector) {
      accountBetHistorySelector.addEventListener('click', this.handleOutsideClick, false);
    }

    const { fetchTransactionsByFilter } = this.props;
    try {
      await fetchTransactionsByFilter();
    } catch (error) {
      console.log('fetchTransactionsByFilter:' + error);
    }
  };

  handleChangeStart = (date) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const startDate = new Date(date);

    changeDateByType('startDate', startDate);
    fetchTransactionsByFilter({ startDate });
  };

  handleChangeEnd = (date) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const endDate = new Date(date);

    changeDateByType('endDate', endDate);
    fetchTransactionsByFilter({ endDate });
  };

  onChangeFilter = (activeTab) => {
    const { changeActiveTab, fetchTransactionsByFilter } = this.props;

    changeActiveTab(activeTab);
    fetchTransactionsByFilter({ activeTab });
  };

  handleOutsideClick = (e) => {
    const datePickerPopperSelector = document.querySelector('.react-datepicker-popper');
    if (datePickerPopperSelector && !datePickerPopperSelector.contains(e.target)) {
      this.dateEndRef?.current?.setOpen(false);
      this.dateStartRef?.current?.setOpen(false);
    }
  };
  handlePayoutCancelModal = (id, amount, date) => {
    this.toggleModal('showConfirmationModal');
    this.setState({
      currentTransactionId: id,
      currentTransactionAmount: amount,
      currentTransactionDate: date,
    });
  };
  toggleModal = (type) => {
    this.setState((prevState) => {
      return { ...prevState, [type]: !prevState[type] };
    });
  };
  getTransactionStatusClass = (statusCode) => {
    const status = statusCode.toUpperCase();
    switch (status) {
      case 'DEC':
        return 'red';
      case 'FLD':
        return 'red';
      case 'SCS':
        return 'green';
      case 'IPR':
        return 'gray';
      default:
        return '';
    }
  };

  parseStatus = (status) => {
    return 'transfer_statusList.' + status;
  };

  cancellPayoutAction = async (transactionId) => {
    const { fetchTransactionsByFilter, cancellPayout } = this.props;

    try {
      this.toggleModal('showConfirmationModal');

      const config = {
        transactionId,
      };
      const payment = await cancellPayout(config);
      if (payment.success == true && payment.responseCode == 1) {
        this.setState({ statusPayment: true, shouldStatusPaymentBeVisible: true });
        fetchTransactionsByFilter({ forceCache: true });
      } else {
        this.setState({ statusPayment: false, shouldStatusPaymentBeVisible: true });
      }
    } catch (error) {
      console.log('cancellPayoutAction:' + error);
      this.setState({ statusPayment: false, shouldStatusPaymentBeVisible: true });
    }
  };

  parseType = (type) => {
    return 'transferTypeList.' + type;
  };

  goPage = async (nextPage) => {
    const { fetchTransactionsByFilter, setCurrentPage } = this.props;

    try {
      await fetchTransactionsByFilter({ nextPage });
      setCurrentPage(nextPage);
    } catch (error) {
      console.log('goPage:' + error);
    }
  };
  render() {
    const { isPending, startDate, endDate, activeTab, data, currentPage, limit, prevData, nextData } = this.props;
    const { shouldStatusPaymentBeVisible, statusPayment, currentTransactionId, currentTransactionAmount, currentTransactionDate, showConfirmationModal } =
      this.state;
    return (
      <S.AccountWallet className="account-wallet">
        <S.WalletTransactionsTitle>{translation('account_wallet_title')}</S.WalletTransactionsTitle>

        <S.WalletFilters>
          <S.WalletFiltersBtns>
            <S.FilterBtn onClick={this.onChangeFilter.bind(this, 'all')} className={classNames({ active: activeTab == 'all' })}>
              {translation('account_wallet_types_all')}
            </S.FilterBtn>
            <S.FilterBtn onClick={this.onChangeFilter.bind(this, 'deposit')} className={classNames({ active: activeTab == 'deposit' })}>
              {translation('account_wallet_types_deposits')}
            </S.FilterBtn>
            <S.FilterBtn onClick={this.onChangeFilter.bind(this, 'payout')} className={classNames({ active: activeTab == 'payout' })}>
              {translation('account_wallet_types_payouts')}
            </S.FilterBtn>
          </S.WalletFiltersBtns>

          <S.WalletFiltersDates>
            <S.DateLabel dangerouslySetInnerHTML={{ __html: translation('account_betHistory_dateRange') }} />

            <S.DateWrapper className="date-wrapper">
              <S.DateIconWrapper>
                <S.DateIcon src={CalendarIcon} />
              </S.DateIconWrapper>

              <Datepicker
                ref={this.dateStartRef}
                selected={startDate}
                selectsStart
                dateFormat="dd-MM-yyyy"
                cb={this.handleChangeStart}
                popperPlacement="bottom-end"
              />
            </S.DateWrapper>

            <S.DateWrapper className="date-wrapper">
              <S.DateIconWrapper>
                <S.DateIcon src={CalendarIcon} />
              </S.DateIconWrapper>

              <Datepicker
                ref={this.dateEndRef}
                minDate={startDate}
                selected={endDate}
                selectsEnd
                dateFormat="dd-MM-yyyy"
                cb={this.handleChangeEnd}
                popperPlacement="bottom-end"
                startDate={startDate}
                endDate={endDate}
              />
            </S.DateWrapper>
          </S.WalletFiltersDates>
        </S.WalletFilters>
        <S.CancelTransactionStatusContainer className={`${shouldStatusPaymentBeVisible ? 'visible' : 'not-visible'} ${statusPayment ? 'success' : 'failure'} `}>
          {statusPayment ? translation('account_wallet_cancel_status_success') : translation('account_wallet_cancel_status_failure')}
        </S.CancelTransactionStatusContainer>

        <S.WalletTransactions>
          {isPending ? (
            <Loader />
          ) : (
            <>
              {/* <S.PayoutCancelStatus success={}></S.PayoutCancelStatus> */}
              <S.WalletTransactionsList>
                <S.FakeTableHead>
                  <div className="wallet-status">{translation('account_wallet_transfers_status')}</div>
                  <div className="wallet-type">{translation('account_wallet_transfers_type')}</div>
                  <div className="wallet-info">{translation('account_wallet_transfers_info')}</div>
                  <div className="wallet-amount">{translation('account_wallet_transfers_amount')}</div>
                  <div className="wallet-id">{translation('common_id')}</div>
                  <div className="wallet-menage">{translation('account_wallet_transfers_manage')}</div>
                </S.FakeTableHead>

                {data?.length ? data.map(({ amount, regDate, type, statusCode, transactionId, referenceId }) => {
                  let isTransactionCancelable = type == 156 && statusCode == 'INI';
                  return (
                    <S.WalletTransactionsListItem className="transaction-list-item" key={transactionId}>
                      <div className={`wallet-status ${this.getTransactionStatusClass(statusCode)}`}>
                        <div className="status-icon"></div>
                      </div>

                      <div className="wallet-type">
                        <span>{translation(this.parseType(type))}</span>
                        <span className="wallet-type-date">
                          {formatDate(regDate, 'dd-MM-yyyy')} {`godz. ${formatDate(regDate, 'HH:mm')}`}
                        </span>
                      </div>

                      <div className="wallet-info">
                        <span>{translation(this.parseStatus(statusCode))}</span>
                      </div>

                      <div className={`wallet-amount ${amount > 0 ? 'green' : 'red'}`}>{`${formatMoney(amount)} zł`}</div>

                      <div className="wallet-id">{transactionId}</div>

                      <div className="wallet-menage">
                        <button
                          onClick={() => this.handlePayoutCancelModal(referenceId, amount, regDate)}
                          disabled={!isTransactionCancelable}
                          className={`${isTransactionCancelable ? 'available' : 'not-available'}`}
                        >
                          {isTransactionCancelable
                            ? translation('ACCOUNT_WALLET_TRANSFERS_MANAGE_CANCEL')
                            : translation('ACCOUNT_WALLET_TRANSFERS_MANAGE_DISABLED')}
                        </button>
                      </div>
                    </S.WalletTransactionsListItem>
                  );
                }) : (
                  <S.NoResult className="no-result">{translation('account_wallet_transfers_notFound')}</S.NoResult>
                )}
              </S.WalletTransactionsList>

              {data?.length > 0 ? <Pagination useNewPagination cb={this.goPage} {...{ currentPage, limit, data, prevData, nextData }} /> : null}
            </>
          )}
        </S.WalletTransactions>
        <ModalOpacity
          isOpen={showConfirmationModal}
          toggleOpen={this.toggleModal.bind(this, 'showConfirmationModal')}
          showCloseIcon={true}
          showHeaderIcon={false}
          headerPadding="30px"
          title="ANULUJ WYPŁATĘ"
          padding={0}
          width="450px"
          minHeightBody="350px"
        >
          <S.PayoutCancelContainer>
            <div>{translation('account_wallet_payoutCancel_info')}</div>
            <div className="transaction-amount">{currentTransactionAmount} ZŁ</div>
            <div className="transaction-date">
              {translation('account_wallet_payoutCancel_date')} {currentTransactionDate && formatDate(this.state.currentTransactionDate, 'dd-MM-yyyy')}{' '}
              {`${currentTransactionDate && formatDate(currentTransactionDate, 'HH:mm')}`}
            </div>
            <button onClick={() => this.cancellPayoutAction(currentTransactionId)}>POTWIERDŹ</button>
          </S.PayoutCancelContainer>
        </ModalOpacity>
      </S.AccountWallet>
    );
  }
}

const mapStateToProps = ({ AccountWallet: { isPending, error, startDate, endDate, activeTab, data, nextData, prevData, currentPage, limit } }, props) => {
  return {
    error,
    isPending,
    startDate,
    endDate,
    activeTab,
    data,
    currentPage,
    limit,
    nextData,
    prevData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
    changeDateByType: bindActionCreators(changeDateByType, dispatch),
    fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
    setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
    cancellPayout: bindActionCreators(cancellPayout, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWallet);
