import styled,{css} from 'styled-components';
import {rem} from 'polished';
import {List} from 'global/styles/styles.js';

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const BetSlipFreebetWrapperBackground = ({theme:{$betSlipFreebetWrapperBackground}}) => `${$betSlipFreebetWrapperBackground}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;

const baseFont = `12px`;
const S = {};

S.FreebetListWrapper = styled.div`
    font-family: ${FontFamily};
    font-weight: 400;
    background-color: ${BetSlipFreebetWrapperBackground};
    display: ${({isVisible})=>{ return (isVisible==true) ? 'block': 'none';}}

`;

S.FreebetList = styled(List)`
    max-height: ${({isSmallDesktop})=>(isSmallDesktop==true? `50px`: `100px`)};
    padding: 0;
    margin: 0;
    line-height: 1;
    & > div:first-of-type{
       &> div{
        &:nth-of-type(3){
          & > div{
          background-color: rgba(228, 32, 44, 1) !important;
          }
        }
       } 
    }
`;

S.FreebetName = styled.span`\
    font-weight: 700;
`;

S.FreebetListItem = styled.li`
    margin: ${rem('7px', baseFont)} 0;
    font-family: ${FontFamilyMulish};
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: ${rem('11px', baseFont)};
    font-weight: 400;
    padding: 0 ${rem('20px', baseFont)};
    & > span{
        margin-right: ${rem('10px', baseFont)}
    }
   ${({isActive}) => {
        if(isActive == true){
            return css`font-family: ${FontFamilyMulish}; font-weight: 700;`;
        }
    }}
 `;

export default S;