import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from "polished";

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.AccountQueueBonus = styled.div`
    width: 100%;
`;

S.BonusListWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: ${rem('20px', baseFont)};
`;

S.BonusList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem('-25px', baseFont)};
`;

S.BonusImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${p => `calc(100% / ${p.ratio})`};
  background: #001A5B;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

S.BonusImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
`;

S.BonusItem = styled.div`
   width: calc(50% - ${rem('25px', baseFont)});
   margin-right: ${rem('25px', baseFont)};
   margin-bottom: ${rem('25px', baseFont)};
   position: relative;
`;

S.BonusHeader = styled.div`
  padding-bottom: ${rem('15px', baseFont)};
`;

S.BonusTitle = styled.div`
  font-size:  ${rem('13px', baseFont)};
  font-family: ${FontFamily};
  text-transform: uppercase;
  font-weight: 400;
`;

S.BonusBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${rem('60px', baseFont)};
  background-color: #F4F7FC;
  border-radius: 0 0 10px 10px;
`;

S.BonusBox =  styled.div`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

S.BonusBoxTitle =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('11px', baseFont)};
  color: #001A5B;
  width: 100%;
  padding: 0 ${rem('5px', baseFont)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-weight: 400;
`;

S.BonusBoxValue =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  color: #001A5B;
    width: 100%;
      padding: 0 ${rem('5px', baseFont)};
       white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
  text-align: center;
`;

S.NoResult = styled.div`
    height: 40px;
    text-align: center;
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
`;

S.ActivateModal = styled.div`
    display: flex;
    flex-direction: column;
    width: ${rem('400px', baseFont)};
    padding:  ${rem('20px', baseFont)} ${rem('30px', baseFont)} ${rem('30px', baseFont)};
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

S.ButtonCancel = styled(Button)`
    color: #fff;
    border: none;
    border-radius: 25px;
    box-shadow: none;
    background: #E4202C;
    margin-right: 1rem;
    height: ${rem('50px', baseFont)};
    font-size: ${rem('15px', baseFont)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${FontFamilyMulish};
    width:  ${rem('100px', baseFont)};
    font-weight: 700;
`;

S.ButtonAccept = styled(S.ButtonCancel)`
  margin-right: 0;
`;

S.Title = styled.div`
    font-size: 1.2307692308rem;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
`;

S.Description = styled.div`
    text-align: center;
    margin-bottom:  ${rem('15px', baseFont)};
    color: #001A5B;
      font-family: ${FontFamilyMulish};
       font-size: ${rem('16px', baseFont)};
`;

S.ProgressCircularWrapper = styled.div`
    width: 50%;
    margin: 0 auto;
    padding: 5px;
`;

S.ModalInfo = styled.div`
    min-width: ${rem('300px', baseFont)};
    position: relative;
    padding: ${rem('30px', baseFont)};
`;

S.InfoTitle = styled.h4`
    color: #001A5B;
    text-transform: uppercase;
    font-size: ${rem('25px', baseFont)};
    padding: 0;
    float: none!important;
    text-align: center;
    margin-bottom: 2rem;
       font-family: ${FontFamilyMulish};
`;

S.InfoDescription = styled.div`
    text-align: center;
     color: #001A5B;
        font-family: ${FontFamilyMulish};
`;

S.ActivateButton = styled(Button)`
  align-self: center;
  width: ${rem('102px', baseFont)};
height: ${rem('28px', baseFont)};
background-color: #E4202C;
border-radius: 14px;
color: #fff;
text-align: center;
font-family: ${FontFamilyMulish};
font-weight: 700;
`;


S.BonusFooter = styled.div`
  text-align: center;
  padding-top: ${rem('12px', baseFont)};
`;

S.BonusRegulationLink = styled.a`
  font-size: ${rem('11px', baseFont)};
  color: #BAC1D0;
  text-decoration: underline;
    font-family: ${FontFamilyMulish};
    cursor: pointer;
    font-weight: 400;
`;

S.CloseButton = styled.img`
position: absolute;
    width: 2.9166666666666665rem;
    height: 2.9166666666666665rem;
    cursor: pointer;
    right: -18px;
    top: -18px;
    z-index: 1;

`;

export default S;