import styled from 'styled-components';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.TopMenu = styled.div`
    z-index: 100;
    height: auto;
    background: ${({theme:{$topMenuBackgroundColor}})=> $topMenuBackgroundColor};
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    float: left;
    width: 100%;
`;

S.MainRow = styled.div`
    display: flex;
    height: 2.67rem;
    justify-content: flex-end;
    background-color: ${({theme:{$topMenuTopBarColor}})=> $topMenuTopBarColor};
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 2rem 0 .70834rem;
    

`;

S.SubmenuRow = styled.div`
    display: flex;
    background-color: ${({theme:{$topMenuSubmenuBarColor}})=> $topMenuSubmenuBarColor};
    height: ${rem('50px', baseFont)};
    padding: 0 ${rem('45px', baseFont)};
    border-bottom: 1px solid #BAC1D0;
    
    ${({theme:{mediumRWD}})=> mediumRWD}{
         padding: 0 ${rem('10px', baseFont)};
    }
    ${({theme:{normalRWD}})=> normalRWD}{
         padding: 0 ${rem('10px', baseFont)};
    }
    ${({theme:{bigRWD}})=> bigRWD}{
     padding: 0 ${rem('10px', baseFont)};
    }
    ${({theme:{largeRWD}})=> largeRWD}{
        padding: 0 ${rem('10px', baseFont)};
    }
`;

S.MenuRow = styled.div`
    display: flex;
    width: 100%;
    padding: 0 ${rem('45px', baseFont)};
    background-color: ${({theme:{$topMenuBackgroundColor}})=> $topMenuBackgroundColor};
    height: ${rem('90px', baseFont)};
    
    ${({theme:{mediumRWD}})=> mediumRWD}{
        height: ${rem('80px', baseFont)};
        padding: 0 ${rem('10px', baseFont)} 0 ${rem('10px', baseFont)};
    }
    ${({theme:{normalRWD}})=> normalRWD}{
        height: ${rem('80px', baseFont)};
        padding: 0 ${rem('10px', baseFont)} 0 ${rem('10px', baseFont)};
    }
    ${({theme:{bigRWD}})=> bigRWD}{
       height: ${rem('80px', baseFont)};
       padding: 0 ${rem('10px', baseFont)} 0 ${rem('10px', baseFont)};
    }
    ${({theme:{largeRWD}})=> largeRWD}{
        padding: 0 ${rem('10px', baseFont)} 0 ${rem('28px', baseFont)};
    }
`;

S.Link = styled.a`
    display: flex;
    margin-right:  ${rem('47px', baseFont)};
    align-items: flex-start;
    align-self: center;
    flex-shrink: 0;
    margin-left: 0;
    max-width: ${rem('170px', baseFont)};
    width: 100%;
    cursor: pointer;
    
    ${({theme:{mediumRWD}})=> mediumRWD}{
        margin-right:  ${rem('19px', baseFont)};
        max-width: ${rem('130px', baseFont)};
    }
    ${({theme:{normalRWD}})=> normalRWD}{
         margin-right:  ${rem('19px', baseFont)};
         max-width: ${rem('130px', baseFont)};
    }
    ${({theme:{bigRWD}})=> bigRWD}{
      margin-right:  ${rem('19px', baseFont)};
      max-width: ${rem('130px', baseFont)};
    }
    ${({theme:{largeRWD}})=> largeRWD}{
       margin-right:  ${rem('23px', baseFont)};
    }
`;

S.Logo = styled.img`
    width: 100%;
    height: auto;
    position: relative;
`;

S.ChatOnline = styled.div`

  width: ${rem('140px', baseFont)};
  height: ${rem('35px', baseFont)};
  display: flex;
  align-self: center;
  cursor: pointer;
`;

S.ChatIconWrapper = styled.i`
    border-radius: ${rem('25px', baseFont)} 0 0 ${rem('25px', baseFont)};
    border: 1px solid #0E62C4;
    background-color: #fff;
     width: ${rem('37px', baseFont)};
     display: flex;
     align-items: center;
`;

S.ChatIcon = styled.img`
          width: ${rem('18px', baseFont)};
          height: ${rem('16px', baseFont)};
          margin-left: ${rem('10px', baseFont)};
`;

S.ChatText = styled.div`
    font-family:  ${({theme:{$fontFamily}})=> $fontFamily};
  border-radius: 0  ${rem('25px', baseFont)} ${rem('25px', baseFont)} 0;
  color: #fff;
  text-transform: uppercase;
  font-size:  ${rem('12px', baseFont)};
  border: 1px solid #0E62C4;
  background-color: #0E62C4;
  flex: 1;
   display: flex;
   align-items: center;
     overflow: hidden;
     text-overflow: ellipsis;
     justify-content: center;
`;

S.Timer = styled.div`
  align-self: center;
  margin-right:  ${rem('30px', baseFont)};
  margin-left: auto;
  color: #001A5B;
  font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  letter-spacing: 0.13px;
  display: flex;
  opacity: 0.5;
  font-weight: 400;
`;

S.CurrentTime =  styled.div`
  padding-right: ${rem('10px', baseFont)};
  position: relative;
  &:after{
    position: absolute;
    content:'';
    right: 0;
    width: 1px;
    top: 2px;
    height: ${rem('15px', baseFont)};
    background-color: #001A5B;
  }
`;

S.CurrentDate =  styled.div`
 margin-left: ${rem('10px', baseFont)};
`;

export default S;