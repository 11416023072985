import styled, {css} from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.LoggedPanel = styled.div`
    display: flex;
    margin-left: auto;
    flex-basis: auto;
    align-items: center;
`;

S.MainBalance = styled.div`
  width: ${rem('127px', baseFont)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({isActive}) => (isActive ? '#09256D' : 'transparent')};
  color: #fff; 
   position: relative;
   align-self: stretch;
   line-height: 1;
    font-size: ${rem('20px', baseFont)};
    font-weight: 400;
    font-style: normal;
    padding: 0 ${rem('10px', baseFont)};
  &:after{
    display: ${({isActive}) => (isActive ? 'block' : 'none')};
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: ${rem('4px', baseFont)};
    background-color: #CE0006;
  }
  
`;

S.MainBalanceAmount = styled.div`
  font-size: ${rem('18px', baseFont)};
  white-space: nowrap;
  margin-bottom: ${rem('10px', baseFont)};
   text-transform: uppercase;
`;

S.MainBalanceLabel = styled.div`
  font-size: ${rem('12px', baseFont)};
  color: #9CAEDA;
  text-transform: uppercase;
`;

S.Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #001A5B;
  line-height: 1;
  padding: ${rem('10px', baseFont)} 0;
  &:hover{
    background-color: #09256D;
  }
`;

S.OptionAmount = styled(S.MainBalanceAmount)`
  color: #fff;
`;

S.OptionLabel = styled(S.MainBalanceLabel)``;

S.SingleValue = styled(S.Option)`
  &:after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: ${rem('4px', baseFont)};
    background-color: #CE0006;
  }
`

S.SingleValueAmount = styled(S.OptionAmount)``;

S.SingleValueLabel = styled(S.OptionLabel)``;

S.BalanceSelect = styled.div`
    min-width: ${rem('127px', baseFont)};
    cursor: pointer;
    align-self: stretch;
    
    & .react-select__menu{
        margin-top: 0;
        border-radius: 0;
        width: 20rem;
        left: -5.5rem;
        
        & .react-select__menu-list{
             padding: 0;
             overflow: visible;
        
            & > div{
                cursor: pointer;
                background-color: #E8ECF4;
                flex-direction: row;
                border-bottom: ${rem('1px', baseFont)} solid #BAC1D0;
                justify-content: space-between;
                padding: 0 ${rem('10px', baseFont)};
                height  3.5rem;
                &:hover{
                  background-color: #fff;
                }
                &:last-of-type{
                  border: 0;
                }
                & > div{
                  color: #001A5B;
                  margin-bottom: 0;
                  &:last-of-type{
                    font-size: 1.2rem;
                    font-weight: bold;
                  }
                }
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        background-color: #001A5B; 
        height: 100%;
        border: none;
        border-radius: 0;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
            & svg{
              & path{
                  fill: #fff !important;
              }
            }
        }

        & .react-select__indicator-separator {
            display: none;
        }
    
        & .react-select__value-container{
        width: 100%;
        height: 100%;
        &--has-value{
          & ${S.SingleValue}{
             /* margin-top: 13px; */
          }
        }
        
            & .react-select__placeholder,
            & .react-select__single-value{
                 font-size: ${rem('18px', baseFont)};
                 font-family:  ${({theme: {$fontFamily}}) => $fontFamily};
                 color: #fff;
                 text-align: center;
            }
                      & .react-select__placeholder{
                      color:   #9CAEDA;
                      }
          
        }
    
    }
`;

S.DepositButton = styled(Button)`
    font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
    transition: .2s all linear;
    background-color: ${({theme:{$topMenuLoggedDepositBtnBgColor}})=> $topMenuLoggedDepositBtnBgColor};
    font-size: ${rem('15px', baseFont)};
    line-height: 1;
    border-radius: ${rem('24px', baseFont)};
    padding: 0;
    width: ${rem('184px', baseFont)};
    height: ${rem('50px', baseFont)};
    text-align: center;
    border: none;
    color: #fff;
    flex-shrink: 0;
    margin-left: ${rem('10px', baseFont)};
    font-weight: 700;
    
    ${({theme:{mediumRWD}})=> mediumRWD}{
     height: ${rem('40px', baseFont)};
     width: ${rem('125px', baseFont)};
    }
    ${({theme:{normalRWD}})=> normalRWD}{
     height: ${rem('40px', baseFont)};
      width: ${rem('125px', baseFont)};
    }
    ${({theme:{bigRWD}})=> bigRWD}{
     height: ${rem('40px', baseFont)};
       width: ${rem('125px', baseFont)};
    }
    ${({theme:{largeRWD}})=> largeRWD}{
      width: ${rem('140px', baseFont)};
    }
    
`;

S.QuickMenuWrapper = styled.div` 
  position: absolute;
  top: 100%;
  padding-top: ${rem('10px', baseFont)};
  width: ${rem('294px', baseFont)};
  right: 0;
 display: none;
`;

S.UserAccountButton = styled(S.DepositButton)`
 background-color: ${({theme:{$topMenuLoggedAccountBtnBgColor}})=> $topMenuLoggedAccountBtnBgColor};
 margin-left: ${rem('10px', baseFont)};
 margin-right: 0;
 position: relative;
 &:hover{
     ${S.QuickMenuWrapper} {
        display: block;
     }
 }
`;

S.QuickMenu = styled.ul`
  list-style: none;
  padding: 0;
  border: 1px solid #BAC1D0;
  border-radius: 3px;
  overflow: hidden;
      z-index: 1;
    position: relative;
`;

S.QuickMenuItem = styled.li`
  height: ${({hasUserName})=>(hasUserName?`${rem('50px', baseFont)}`:`${rem('40px', baseFont)}`)};
  display: flex;
  border-bottom: 1px solid #BAC1D0;
  font-size: ${rem('13px', baseFont)};
  background-color: #fff;
  padding: ${({hasUserName})=>(hasUserName?`0 ${rem('15px', baseFont)} 0 ${rem('20px', baseFont)}`:`0`)};
  &:last-of-type{
    border-bottom: none;
  }
`;

S.QuickMenuLink = styled.div`
  padding: 0 ${rem('15px', baseFont)} 0 ${rem('20px', baseFont)};
  background-color: #E8ECF4;
  color: #001A5B;
  font-weight: ${FontFamilyMulish};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 400;
  &:link,
  &:visited,
  &:hover,
  &:active{
    text-decoration: none;
  }
  
  &:hover{
    background-color: #fff;
  }
`;

S.UserName = styled.div`
  align-self: center;
  color: #001A5B;
  font-weight: ${FontFamilyMulish};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 700;
`;

S.UserNameFirstName =  styled.div`
   margin-bottom: 5px;
`;

S.UserNameLastName =  styled.div``;

S.LogOut = styled.div`
  color: #E4202C;
  align-self: center;
  margin-left: auto;
  font-family: ${FontFamilyMulish};
  flex-shrink: 0;
  font-weight: 400;
`;

S.ResponsibleGamingWrapper = styled.div`
  margin: ${rem('20px', baseFont)} ${rem('40px', baseFont)};
  .responsible-gaming {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .info {
      text-align: center;
    }
    .form-wrapper {
      justify-content: center;
      .body-inner {
        > div {
          flex-basis: 35%;
          margin: 0px auto;
        }
      }
    }
    h2 {
      text-align: center;
    }
  }
`;


export default S;