import React from 'react'
import { connect } from 'react-redux'
import * as styled from 'StyledVirtuals'

const platform = location.hostname.split('.')[0];
const RGS_URL = (platform == "rc" && CLIENT == 'etoto') ? process.env.RGS_URL.replace('rgs', `rgs-rc`) : process.env.RGS_URL;

const Virtuals = ({ isLogged }) => {
  const src = RGS_URL

  return (
    <styled.Iframe id="virtuals-iframe" key={isLogged} src={src} />
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => ({ isLogged })

export default connect(mapStateToProps)(Virtuals)
