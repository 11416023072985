import React, {Component, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {bindActionCreators} from 'redux';
import S from 'StyledTopMenu.js';
import TopMenuList from 'TopMenuList.js';
import SubmenuList from 'SubmenuList.js';
import UnloggedPanel from 'UnloggedPanel.js';
import LoggedPanel from 'LoggedPanel.js';
import store from 'store';

import {changeActiveItem, changeSubmenuActiveItem, changeSubmenuDropdownItem} from 'topMenuActions.js';
import {filterLeftMenuEsportCategories} from 'categoriesActions.js';
import {
    logInUser,
    logOutUser,
    forgotPassword,
    registerUser,
    preregisterUser,
    resetPassword,
    verificationCode,
    loginCustomerByCookie
} from 'authActions.js';
import {getCustomerBettingStatus} from 'customerActions.js';
import {toggleBalance} from 'betSlipActions.js';
import {toggleAccountModal} from 'accountActions.js';
import ChatIcon from 'comment_icon.png';
import LogoIcon from '../../../assets/svg/etoto/etoto_logo_topMenu.png'
import {destroy} from 'redux-form';
import {translation, redirect} from 'utilsHelper.js';
import {getSegmentationCookie} from 'cookiesHelper.js';
import {formatDate} from 'datesHelper.js';
import {pl} from 'date-fns/locale';
import { SBPayments } from 'sb-payments-etoto'
const { PaymentsModal: { ModalProvider } } = SBPayments

const Timer = () => {

    const timeElement = useRef(null);
    const dateElement = useRef(null);

    useEffect(() => {
        timePart();
        datePart();
        const interval = setInterval(timePart, 1000);
        return () => clearInterval(interval);
    }, [])

    const timePart = () => {
        const time = formatDate(new Date(), 'HH:mm:ss', {locale: pl});
        timeElement.current.innerText = time;
    };

    const datePart = () => {
        const date = formatDate(new Date(), 'EEEE, d MMMM', {locale: pl});
        dateElement.current.innerText = date;
    };

    return (
        <S.Timer>
            <S.CurrentTime ref={timeElement}></S.CurrentTime>
            <S.CurrentDate ref={dateElement}></S.CurrentDate>
        </S.Timer>
    )

};

class TopMenu extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };
    state = {hasError: false};


    componentDidMount = () => {
        try {
            const {isLogged, user} = this.props;
            if(isLogged) {
                const {email, login, userId} = user;
                this.setLiveChatUserData(email, login, userId);
            }

            if ('LiveChatWidget' in window) {
                LiveChatWidget.on('visibility_changed', this.onVisibilityChanged);
            }

            this.watchRoute();
            this.selectTopMenuItemsFromPath();

        } catch (error) {
            console.log('topMenuSelection error:', error);
        }
    };

    onVisibilityChanged = (data) => {
        if (data.visibility == 'minimized') {
            LiveChatWidget.call('minimize');
        }
    };

    watchRoute = () => {
        app.router.onRouteChange('topMenuSelection', function () {
            filterEsportCategories();
            handleTopMenuChange();
        });
        const filterEsportCategories = () => {
            const eSportControllers = ['eSport', 'eSportEvent', 'eSportEventList', 'eSportLivescore', 'eSportStatistics'];
            const {filterLeftMenuEsportCategories} = this.props;
            if (eSportControllers.indexOf(app.getCurrentController()) != -1) {
                filterLeftMenuEsportCategories(true);
            } else {
                filterLeftMenuEsportCategories(false);
            }
        };
        const handleTopMenuChange = () => {
            this.selectTopMenuItemsFromPath();
        }
        filterEsportCategories();
    };

    selectTopMenuItemsFromPath = () => {
        const {changeActiveItem, changeSubmenuActiveItem} = this.props;
        const currentRoutePath = app.router.getCurrentRoute();
        const currentController = app.getCurrentController();

        if(/^\/$/.test(currentRoutePath)) {
            changeActiveItem('/');
            changeActiveItem('HOME');
        } else if (/^\/zaklady\-live.*?/.test(currentRoutePath)) {
            changeActiveItem('LIVES');
            switch (currentController) {
                case 'LiveEventList':
                    changeSubmenuActiveItem('OVERVIEW');
                    break;
                case 'LiveMulti':
                    changeSubmenuActiveItem('MULTI');
                    break;
                case 'LiveEvent':
                    changeSubmenuActiveItem('LIVE');
                    break;
                case 'LiveCalendar':
                    changeSubmenuActiveItem('CALENDAR');
                    break;
            }
        } else if (/^\/virtuals.*?/.test(currentRoutePath)) {
            changeActiveItem('VIRTUALS');

        } else if (/^\/gra-w-karty.*?/.test(currentRoutePath)) {
            changeActiveItem('TVBET');

        } else if (/^\/esport.*?/.test(currentRoutePath)) {
            changeActiveItem('ESPORTS');

        } else if (/^\/bonuses.*?/.test(currentRoutePath)) {
            changeActiveItem('BONUSES');

        } else if (/^\/blog.*?/.test(currentRoutePath)) {
            changeActiveItem('BLOG');
        }else if (/^\/etotomania.*?/.test(currentRoutePath)) {
            changeActiveItem('ETOTOMANIA');
        }else if (/^\/pomoc.*?/.test(currentRoutePath)) {
            changeActiveItem('HELP');
        }
    };

    loginOnSubmit = async(postData) => {
        const {logInUser, loginCustomerByCookie} = this.props;
        try {
            await loginCustomerByCookie();
            const {getState} = store;
            const {Auth:{isLogged, user}} = getState();
            let userData;
            if (isLogged){
                userData = user;
            } else {
                userData = await logInUser(postData);
                if(userData && app.config.smarticoIsEnabled && !app.config.smarticoOnlyInit){
                    if(this.props.fromTab === 'missions'){
                        window._smartico.dp('dp:gf')
                    }
                }
            }
            
            return userData;
        } catch (error) {
            throw error;
        }
    };

    preregisterUser = async(postData) => {
        const {preregisterUser} = this.props;
        try {
            await preregisterUser(postData);
        } catch (error) {
        }
    };

    registerOnSubmit = async(postData) => {
        const {registerUser} = this.props;
        try {
            const newUser = await registerUser(postData);
            return newUser;
        } catch (error) {
            throw error;
        }
    };

    forgotPasswordOnSubmit = async(postData) => {
        const {forgotPassword} = this.props;
        try {
            const user = await forgotPassword(postData);
            return user;
        } catch (error) {
            throw error;
        }
    };

    resetPasswordOnSubmit = async(values) => {
        const {resetPassword} = this.props;
        try {
            const result = await resetPassword(values);
            return result;
        } catch (error) {
            throw error;
        }
    };

    verificationCode = async(postData) => {
        const {verificationCode} = this.props;
        try {
            await verificationCode(postData);
        } catch (error) {
            throw error;
        }
    };

    showLiveChat = () => {
        if ('LiveChatWidget' in window) {
            const LIVE_CHAT_VIP_SEGMENTS = [331,332,333];
            const userSegments = getSegmentationCookie();
            
            if (!this.props.isLogged) window.__lc.group = 0;
            
            if (LIVE_CHAT_VIP_SEGMENTS.some(segment => userSegments?.includes(segment))) window.__lc.group = 1;
            else window.__lc.group = 0;

            LiveChatWidget.call('maximize');
        }
    };

    setLiveChatUserData = (email, login, userId) => {
        if ('LiveChatWidget' in window) {

            if(login&&email) {
                LiveChatWidget.call('set_customer_name', login);
                LiveChatWidget.call('set_customer_email', email);
            }

            const params = {
                email,
                login,
                userId
            };
            LiveChatWidget.call('set_session_variables', params);
        }
    };

    createLogoHref = () => {
        return '/'
    }

     onLogoClick = (e) => {
        e.preventDefault();
        redirect(this.createLogoHref());
    }

    rerenderFooter = () => {
        const footerPartialSelector = document.getElementById('customCmsElement');
        if(footerPartialSelector){
            app.partial.CustomCmsElement.initCmsElements([].concat(footerPartialSelector), true);
        }
    };

    componentDidUpdate = (prevProps) => {
        const {isLogged, user} = this.props;
        if (prevProps.isLogged != isLogged) {
            if(isLogged) {
                const {email, login, userId} = user;
                this.setLiveChatUserData(email, login, userId);
            }else{
                this.setLiveChatUserData();
            }

            try{
                this.rerenderFooter();
            }catch(e){}
        }
    }



    render() {

        const {isLogged, logOutUser, user, balanceId, toggleBalance, toggleAccountModal, destroyWizardFormOnModalClose, isOpen, isResetPasswordModalOpen, isLoginModalOpen, isRegisterModalOpen, isActivateAccountModalOpen, customerRegulations, getCustomerBettingStatus, fromTab, ...rest} = this.props;
        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }
        return (
            <S.TopMenu className="top-menu">

                <S.MenuRow className="menu-list">
             
                    <S.Link href={this.createLogoHref()} onClick={(e) => {this.onLogoClick(e)}}  >
                        <S.Logo src={LogoIcon} alt="ETOTO Zakłady Bukmacherskie"/>
                    </S.Link>
           
                    <TopMenuList 
                        isLogged={isLogged}
                        {...rest}
                    />

                    {isLogged ? (
                        <ModalProvider>
                            <LoggedPanel
                                user={user}
                                customerRegulations={customerRegulations}
                                logOut={logOutUser}
                                toggleBalance={toggleBalance}
                                balanceId={balanceId}
                                isAccountOpen={isOpen}
                                toggleUserAccountModal={toggleAccountModal}
                                getCustomerBettingStatus={getCustomerBettingStatus}
                            />
                        </ModalProvider>) : (
                        <UnloggedPanel
                            loginOnSubmit={this.loginOnSubmit}
                            registerOnSubmit={this.registerOnSubmit}
                            preregisterUser={this.preregisterUser}
                            verificationCode={this.verificationCode}
                            forgotPasswordOnSubmit={this.forgotPasswordOnSubmit}
                            resetPasswordOnSubmit={this.resetPasswordOnSubmit}
                            destroyWizardFormOnModalClose={destroyWizardFormOnModalClose}
                            isResetPasswordModalOpen={isResetPasswordModalOpen}
                            isRegisterModalOpen={isRegisterModalOpen}
                            isLoginModalOpen={isLoginModalOpen}
                            isActivateAccountModalOpen={isActivateAccountModalOpen}
                            fromTab={fromTab}
                        />)
                    }

                </S.MenuRow>

                <S.SubmenuRow className="submenu-row">
                    <SubmenuList  {...rest}/>

                    <Timer/>

                    <S.ChatOnline onClick={this.showLiveChat}>
                        <S.ChatIconWrapper>
                            <S.ChatIcon src={ChatIcon}></S.ChatIcon>
                        </S.ChatIconWrapper>
                        <S.ChatText>{translation('topMenu_chatOnline')}</S.ChatText>
                    </S.ChatOnline>
                </S.SubmenuRow>

            </S.TopMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {TopMenu:{activeItem, submenuItem, submenuDropdownItem}, Auth:{isLogged, user}, BetSlip:{balanceId}, Account:{isOpen}, Toggle:{toggleMap}, Customer: {customerRegulations}} = state;
    const toggleResetModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_RESET_PASSWORD_MODAL'});
    const toggleRegisterModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_REGISTER_MODAL'});
    const toggleLoginModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_LOGIN_MODAL'});
    const toggleActivateAccountModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_ACTIVATE_ACCOUNT_MODAL'});

    const isResetPasswordModalOpen = _get(toggleResetModalObject, ['isOpen']);
    const isRegisterModalOpen = _get(toggleRegisterModalObject, ['isOpen']);
    const isLoginModalOpen = _get(toggleLoginModalObject, ['isOpen']);
    const isActivateAccountModalOpen = _get(toggleActivateAccountModalObject, ['isOpen']);
    const fromTab = _get(toggleLoginModalObject, ['fromTab'])

    return {
        activeItem,
        isLogged,
        user,
        balanceId,
        isOpen,
        submenuItem,
        submenuDropdownItem,
        isResetPasswordModalOpen,
        isRegisterModalOpen,
        customerRegulations,
        isLoginModalOpen,
        isActivateAccountModalOpen,
        fromTab
    }
};

const mapDispatchToProps = (dispatch) => {

    const bindedActions = {
        changeActiveItem,
        preregisterUser,
        logOutUser,
        logInUser,
        registerUser,
        forgotPassword,
        toggleBalance,
        toggleAccountModal,
        changeSubmenuActiveItem,
        changeSubmenuDropdownItem,
        resetPassword,
        verificationCode,
        filterLeftMenuEsportCategories,
        getCustomerBettingStatus,
        loginCustomerByCookie
    };

    return {
        ...bindActionCreators(bindedActions, dispatch),
        destroyWizardFormOnModalClose: function () {
            dispatch(destroy('registerWizardForm'));
        },
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

