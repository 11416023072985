import React, {useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import S from 'StyledBetHistoryItemDetails.js';
import {NavLink, withRouter} from 'react-router-dom';
import {translation, decimal, redirect} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {getPathTransaction} from 'transactionsSelectors.js';
import betSlipTypes from 'betSlipTypes.enum.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from "lodash/omit";
import _truncate from "lodash/truncate";
import BackButtonIcon from 'back_btn_icon.svg';
import useToggle from 'UseToggle.js'
import ModalOpacity from 'ModalOpacity.js';
import CashoutForm from 'CashoutForm.js';
import {addOutcome, clearBetslip, changeSlipStake} from 'betSlipActions.js';
import {
    fetchCashoutsMaximumAmount,
    processCashoutAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    refreshBetHistoryList
} from 'accountBetHistoryActions.js';
import BetDetailsFooterLogo from 'bet_details_footer_logo.png';
import AccountShareBetIcon from 'AccountShareBetIcon.js';
import FreebetIcon from 'freebet_icon.svg';
import {createBlocksForTransactions, getPolishTransactionType} from 'helpers/transactionsHelper.js';
import _reject from "lodash/reject";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _each from "lodash/each";
import _every from "lodash/every";
import _reduce from "lodash/reduce";
import _find from "lodash/find";
import _size from "lodash/size";

const BetHistoryItemDetails = (
    {
        transaction,
        clearBetslip,
        addOutcome,
        changeSlipStake,
        activeTab,
        betSlips,
        fetchCashoutsMaximumAmount,
        processCashoutAmount,
        processLiveCurtainForTxId,
        toggleLiveCurtainForTxId,
        refreshBetHistoryList,
        cashouts,
        source = "history",
        history: {goBack}
    }) => {
    const isActiveTabOccupied = betSlips[activeTab].outcomes.length ? true : false;
    console.log(isActiveTabOccupied)
    const [cashoutErrorCode, setCashoutErrorCode] = useState('');
    const [sortedBlocks, setSortedBlocks] = useState({});
    const [taxFactor, setTaxFactor] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [countDownRequired, setCurtainCountdownRequired] = useState(false);
    const [showCashoutModal, toggleCashoutModal] = useToggle(false);
    const [showCopyInfo, toggleCopyInfo] = useToggle(false);
    const [showCashoutErrorModal, toggleCashoutErrorModal] = useToggle(false);
    const [showPlaceBetModal,togglePlaceBetModal] = useToggle(false)
    const {
        transactionId,
        stake,
        possibleReturn,
        confirmedReturn,
        curtainActive,
        regDate,
        slipType,
        isFreebet,
        cashBackReturn,
        statusCode,
        tax,
        taxConfirmed,
        bonus,
        slipCombinations = null,
        totalOddsWithoutSalesTaxFactor
    } = transaction;
    useEffect(() => {
        let blockInfo = prepareBlocks();
        setSortedBlocks(blockInfo);
    }, [transaction]);

    const getTransactionType = useMemo(() => {
        return getPolishTransactionType(transaction);
    }, []);

    const getCombinationApplicableBonusAmount = useMemo(() => {
        let combinationApplicableBonus = 0;
        if (slipCombinations?.combinations) {
            for (let combination of slipCombinations.combinations) {
                if (combination.confirmedReturn) {
                    combinationApplicableBonus = combinationApplicableBonus + combination.bonus;
                }
            }
        }
        return combinationApplicableBonus
    },[]);

    const toggleCashout = async (transactionId) => {
        try {
            const maxAmount = await prepareCashout(transactionId);
            setMaxAmount(maxAmount);
            toggleCashoutModal();
        } catch (errorCode) {
            setCashoutErrorCode(errorCode);
            toggleCashoutErrorModal();
        }
    };

    const prepareCashout = async (slipId) => {
        try {
            const maxAmount = await fetchCashoutsMaximumAmount(slipId);
            if (maxAmount.countDownRequired != undefined) {
                setCurtainCountdownRequired(maxAmount.countDownRequired);
            }
            return maxAmount;
        } catch (error) {
            throw error;
        }
    };

        
    const hasCancelledCombination = () => {
        let isCancelledComb = false;
        const transactionDetails = transaction.transactionDetails;
        isCancelledComb = transactionDetails.every(detail => detail.statusCode === 4);

        return isCancelledComb;
    };

    const processCashout = async (config, abortControllerSignal) => {

        const curtainDelay = async (delayMS) => {
            const p = new Promise((resolve, reject) => {
                delayMS = Number(delayMS);
                const timerId = setTimeout(resolve, delayMS);
                abortControllerSignal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject({message: translation('account_cashoutForm_aborted')});
                });
            });
            return p;
        };

        try {
            let slipId = _get(config, ['slipId']);
            slipId = Number(slipId);

            const hasCashoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);
            if (hasCashoutLiveCurtain && countDownRequired) {
                const curtainData = _omit({...config}, ['slipId', 'type']);
                const delayMS = await processLiveCurtainForTxId(slipId, curtainData);
                await curtainDelay(delayMS);
                const result = await processCashoutAmount(config);
                return result;
            } else {
                const result = await processCashoutAmount(config);
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    const getTransactionDetailsStatus = (statusCode) => {

        let resultText = '';

        switch (statusCode) {
            case 0:
                resultText = 'pending_acceptation';
                break;
            case 1:
                resultText = 'placed';
                break;
            case 2 :
                resultText = 'won';
                break;
            case 3 :
                resultText = 'lost';
                break;
            case 4 :
            case 10:
                resultText = 'cancel';
                break;
            case 5 :
                resultText = 'cashout';
                break;
        }


        return resultText;
    };

    const getTransactionResult = useMemo(() => {

        if (cashBackReturn) {
            return translation('transactionDetails_betSlipCashback');
        }

        const transactionStatus = getTransactionDetailsStatus(statusCode);
        const hasCancelledComb = hasCancelledCombination();
        let translationKey = null;

        if (transactionStatus == 'won') {

            if ([0, 100].indexOf(slipType) != -1) {
                if ((confirmedReturn + bonus) >= stake || (isFreebet && confirmedReturn > 0) || (isFreebet && hasCancelledComb)) {
                    translationKey = translation('transactionDetails_betSlipWon');
                } else {
                    translationKey = translation('transactionDetails_betSlipLost');
                }
            } else {
                if (confirmedReturn > 0 || isFreebet) {
                    translationKey = translation('transactionDetails_betSlipWon');
                } else {
                    translationKey = translation('transactionDetails_betSlipLost');
                }
            }


        } else if (transactionStatus == 'cashout') {
            translationKey = translation('transactionDetails_betSlipCashout');
        } else if (transactionStatus == 'lost') {
            translationKey = translation('transactionDetails_betSlipLost');
        } else if (transactionStatus == 'cancel') {
            translationKey = translation('transactionDetails_betSlipCancel');
        } else {
            translationKey = translation('transactionDetails_betSlipPlaced');
        }

        return translationKey;

    }, [statusCode]);
    const checkCashout = (slipId) => {
        const multiCashoutAvailable = process.env.MULTI_CASHOUT_AVAILABLE && JSON.parse(process.env.MULTI_CASHOUT_AVAILABLE);
        if (multiCashoutAvailable) {
            return cashouts && (slipId in cashouts) && getCashoutAmmountForTxId(slipId) != null;
        } else {
            return (cashouts && cashouts.indexOf(slipId) !== -1);
        }
    };

    const getCashoutAmmountForTxId = (slipId) => {
        return _get(cashouts, [slipId, 'currentCashoutAmount']);
    };

    const checkFactor = (block) => {
        const taxFactorOutcome = _find(block, {eventId: -1});
        // temporary solution (0.88) for old slips migrated from etoto.pl
        taxFactorOutcome ? setTaxFactor(taxFactorOutcome.odds) : setTaxFactor(0.88);
    }

    const prepareBlocks = () => {
        let pureBlocksObject = {}
        let blocks = _get(transaction, 'blocks')
        for (const id in blocks) {
            //get and set factor from data #wspolczynnik 
            checkFactor(blocks[id]);

            if (id != -1) {
                pureBlocksObject[blocks[id].blockName] = blocks[id]
            }
        }
        const sortedData = {};
        Object.keys(pureBlocksObject).sort().forEach(function (key) {
            sortedData[key] = pureBlocksObject[key];
        });
        return sortedData;
    };

    const showCopyInfoAction = () => {
        toggleCopyInfo();
    };

    const isRebetPossible = () => {
        let possibleRebet = false;
        const transactionDetails = transaction.transactionDetails;
        const now = new Date().getTime();

        for (let i = 0; i < transactionDetails.length; i++) {
            if (transactionDetails[i].eventStart >= now && transactionDetails[i].eventId !== -1) {
                possibleRebet = true;
                break;
            }
        }
        return possibleRebet;
    };

    const addRebet = (isActiveTabOccupied) => {
        if(isActiveTabOccupied){
            togglePlaceBetModal()
        }
        app.modal.betslipShare.hide()
        try {

            clearBetslip();
            const transactionDetails = transaction.transactionDetails;
            const now = new Date().getTime();

            for (let i = 0; i < transactionDetails.length; i++) {
                if (transactionDetails[i].eventStart >= now) {
                    const outcome = transactionDetails[i];
                    if (outcome.eventId === -1) {
                        continue;
                    } else {
                        addOutcomeToSlip(outcome);
                    }
                }
            }
            changeSlipStake(transaction.stake);

        } catch (error) {
            console.log({error});
        }
        setTimeout(() => {
            redirect('/');
        }, 1000);
    };

    const addOutcomeToSlip = (singleOutcome) => {
        const {eventId, outcomeId, eventType, gameId, sportId, outcomeName, eventName, gameName, odds} = singleOutcome;

        const outcome = {
            outcomeId: outcomeId,
            outcomeLive: eventType === 2,
            gameId: gameId,
            eventType: eventType,
            eventId: eventId,
            sportId: sportId,
            combinationType: 1,
            outcomeName: outcomeName,
            eventName: eventName,
            gameName: gameName,
            outcomeOdds: odds
        };
        addOutcome(outcome, true)
    };

    const cashoutFormProps = {
        toggleLiveCurtainForTxId,
        refreshBetHistoryList,
        transactionId,
        stake,
        possibleReturn,
        maxAmount,
        toggleCashoutModal,
        toggleCashoutErrorModal,
        prepareCashout,
        curtainActive,
        cachoutOnSubmit: processCashout,
        initialValues: {maximumAmount: maxAmount},
        transactionOdds: decimal(totalOddsWithoutSalesTaxFactor),
    };
    const currencyCode = app.config.mainCurrencyCodeSuffix;
    const customBetDetails = (outcomeName, gameName) => {
      const outcomeNameSplitted = outcomeName.split(' / ');
      const gameNameSplitted = gameName.split(' / ');
      const fullDetails = [];
      for(let i in outcomeNameSplitted){
        const singleDetail = (
          <div key={i}>
            <span className='bold'>{`${outcomeNameSplitted[i]}` }</span>
            <span> {`[${gameNameSplitted[i]}]`}</span>
          </div>
        )
        fullDetails.push(singleDetail)
      }
      return fullDetails;
    }

    const blockCombinations = (transactionItem) => {
        if (transactionItem.slipCombinations) {
            var combinations = [];
            for (var i = 0; i < transactionItem.slipCombinations.combinations.length; i++) {
                var combination = transactionItem.slipCombinations.combinations[i];
                var returnedCombination = (
                    <S.BetHistoryItemDetailsBlockCombinationsItem key={`bet-history-item-block-combinations_${i}`}>
                        <div className="combinations_nr">{i + 1}</div>
                        <div className="combinations_combination">
                            {_map(combination.combinationLettersText.split(''), letter => {
                                const status = getTransactionDetailsStatus(combination.combinationLetterStatus[letter]);
                                return <S.Letter key={`${combination.combinationLettersText}_${letter}`} className={status}>{letter}</S.Letter>
                            })}
                        </div>
                        <div className="combinations_odds">{combination.oddsNoGain}</div>
                        <div className="combinations_possibleWin">{combination.possibleReturn > 0 ?  (combination.possibleReturn + combination.bonus).toFixed(2) : combination.possibleReturn}&nbsp;{currencyCode}</div>
                        <div className="combinations_win">{combination.confirmedReturn > 0 ? (combination.confirmedReturn + combination.bonus).toFixed(2) : combination.confirmedReturn}&nbsp;{currencyCode}</div>
                    </S.BetHistoryItemDetailsBlockCombinationsItem>
                );
                combinations.push(returnedCombination);
            }
            return combinations;
        }
    };

    return (

        <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">

            <ModalOpacity isOpen={showCashoutModal} toggleOpen={toggleCashoutModal} showHeaderIcon={false}
                          title="Cashout" padding={0} showHeader={false}>
                <CashoutForm {...cashoutFormProps}></CashoutForm>
            </ModalOpacity>

            <ModalOpacity isOpen={showCashoutErrorModal} toggleOpen={toggleCashoutErrorModal} showHeaderIcon={false}
                          showHeaderTitle={false} padding="8px">
                <S.ErrorModal>
                    <S.ErrorModalTitle>{translation('account_betHistoryTransactionItem_cashout_error_title')}</S.ErrorModalTitle>
                    <S.ErrorModalMessage>{translation('account_betHistoryTransactionItem_cashout_error_' + cashoutErrorCode)}</S.ErrorModalMessage>
                </S.ErrorModal>
            </ModalOpacity>
            {source ==='share' ? (
                <ModalOpacity isOpen={showPlaceBetModal} toggleOpen={togglePlaceBetModal} showHeaderIcon={false}
                            showHeaderTitle={false} width='30%' showHeader={false} showCloseIcon={false}>
                    <S.PlaceBetModal>
                    <S.PlaceBetModalTitle>{translation('placeSameBetModa_placeSameBetTitle')}</S.PlaceBetModalTitle>
                        <S.PlaceBetModalMsg>{ translation(`placeSameBetModa_placeSameBetText`)}</S.PlaceBetModalMsg>
                        <S.PlaceBetModalButtonsContainer>
                            <S.PlaceSameBetButton confirm onClick={addRebet}>{translation('placeSameBetModal_Yes')}</S.PlaceSameBetButton>
                            <S.PlaceSameBetButton onClick={togglePlaceBetModal}>{translation('placeSameBetModal_No')}</S.PlaceSameBetButton>
                        </S.PlaceBetModalButtonsContainer>
                    </S.PlaceBetModal>
                </ModalOpacity>
                ):null}

            <S.BetHistoryDetailsHeader className="bet-history-item-details-header" >
            {source !="share" ? 
            (<S.BetHistoryDetailsHeaderActions>

                <S.BetHistoryDetailsHeaderGoBack as={NavLink} to={{
                    pathname: '/bet-history',
                    state: {fromTransactionDetails: true}
                }}>
                    <S.BackButtonIcon dangerouslySetInnerHTML={{__html: BackButtonIcon}}/>
                    {translation('account_betHistoryTransactionItem_goBackButton')}
                </S.BetHistoryDetailsHeaderGoBack>

                <S.BetHistoryDetailsTransactionId>
                    {(process.env.ENABLE_FREEBET && isFreebet) &&
                    <S.FreebetIcon dangerouslySetInnerHTML={{__html: FreebetIcon}}/>}
                    {`${translation('account_betHistoryTransactionItem_detailsIdPrefix')} ${transaction.transactionId}`}
                </S.BetHistoryDetailsTransactionId>

            </S.BetHistoryDetailsHeaderActions>):null}
                        

                <S.BetHistoryDetailsHeaderCaption>
                    {translation('account_betHistoryTransactionItem_detailsCaption')}
                </S.BetHistoryDetailsHeaderCaption>
                {process.env.REBET_ALLOWED && source === "share" && (
                    <>
                        {(() => {
                            const hasRebet = isRebetPossible();
                                if (hasRebet) {
                                    return <S.BetHistoryDetailsHeaderRebet onClick={() => isActiveTabOccupied ? togglePlaceBetModal():addRebet(isActiveTabOccupied)}>{translation('shareCoupon_rebet')}<S.CouponIcon/></S.BetHistoryDetailsHeaderRebet>
                                }
                            })()}
                    </>
                )}

                <S.BetHistoryDetailsHeaderColumns>

                    <div className="transaction-event">
                        {translation('account_betHistoryItem_event')}
                    </div>

                    <div className="transaction-date">
                        {translation('account_betHistoryItem_date')}
                    </div>

                    <div className="transaction-type">
                        {translation('account_betHistoryItem_type')}
                    </div>

                    <div className="transaction-odd">
                        {translation('account_betHistoryItem_odd')}
                    </div>

                    <div className="transaction-result">
                        {translation('account_betHistoryItem_result')}
                    </div>

                    <div className="transaction-bet-result">
                        {translation('account_betHistoryItem_betResult')}
                    </div>

                    <div className="transaction-bet-result-icon"></div>

                </S.BetHistoryDetailsHeaderColumns>

            </S.BetHistoryDetailsHeader>

            {_map(sortedBlocks, (blocks) => {

                return (!_isEmpty(blocks) && (

                    <React.Fragment key={blocks[0].eventId}>

                        {Object.keys(transaction.blocks).length !== 1 && (
                            <S.BetHistoryBlockHeader classname={blocks.blockName == "P" ? "banker" : "block"}>
                                {blocks.blockName == "P" ? translation('account_betHistoryTransactionItem_detailsBanker') : `${translation('account_betHistoryTransactionItem_detailsBlock')} ${blocks.blockName}`}
                            </S.BetHistoryBlockHeader>
                        )}

                        <S.BetHistoryItemDetails className="bet-history-item-details">

                            {_map(blocks, ({
                                               eventId,
                                               eventStart,
                                               eventName,
                                               gameName,
                                               outcomeName,
                                               outcomeResult,
                                               odds,
                                               score,
                                               statusCode,
                                               sportId,
                                               categoryName1,
                                               categoryName2,
                                               categoryName3,
                                               gameTypeId
                                           }) => {
                                const statusResult = getTransactionDetailsStatus(statusCode);
                                return (eventId != -1) && (

                                    <S.BetHistoryDetailsBody key={eventId}>

                                        <S.BetHistoryDetailsBodyEvent>

                                            <S.BetHistoryDetailsBodyEventIcon
                                                className={`sport-icon sport-${sportId}`}/>

                                            <S.BetHistoryDetailsBodyEventInfo>

                                                <S.BetHistoryDetailsBodyEventName title={eventName}>
                                                    {_map(String(eventName).split(/\s\-\s/), (participant) => _truncate(participant, {length: 24})).join(' - ')}
                                                </S.BetHistoryDetailsBodyEventName>

                                                <S.BetHistoryDetailsBodyEventCategories>
                                                    <div>{categoryName2}</div>
                                                    <div className="separator">-</div>
                                                    <div>{categoryName3}</div>
                                                </S.BetHistoryDetailsBodyEventCategories>

                                            </S.BetHistoryDetailsBodyEventInfo>

                                        </S.BetHistoryDetailsBodyEvent>

                                        <S.BetHistoryDetailsBodyDate>
                                            <div>{formatDate(eventStart, 'dd-MM-yyyy')}</div>
                                            <div>godz. {formatDate(eventStart, 'HH:mm')}</div>
                                        </S.BetHistoryDetailsBodyDate>

                                        <S.BetHistoryDetailsBodyType>
                                          {(gameTypeId === -9600 || gameTypeId === -9601)  ?
                                            (
                                              <div className="info-wrapper">
                                                {customBetDetails(outcomeName, gameName)}
                                              </div>
                                            )
                                            :
                                            (
                                              <>
                                                <div title={outcomeName}>{outcomeName}</div>
                                                <div title={gameName}>{gameName}</div>
                                              </>
                                            )
                                          }
                                        </S.BetHistoryDetailsBodyType>

                                        <S.BetHistoryDetailsBodyOdd>
                                            {decimal(odds)}
                                        </S.BetHistoryDetailsBodyOdd>

                                        <S.BetHistoryDetailsBodyResult>
                                            {score}
                                        </S.BetHistoryDetailsBodyResult>

                                        <S.BetHistoryDetailsBodyBetResult>

                                            <S.BetHistoryDetailsBodyBetResultScore className={`${(gameTypeId === -9600 || gameTypeId === -9601) ? 'custom-bet' : ''}`}>
                                                {outcomeResult}
                                            </S.BetHistoryDetailsBodyBetResultScore>

                                        </S.BetHistoryDetailsBodyBetResult>

                                        <S.StatusIconWrapper>
                                          <S.StatusIcon className={statusResult}/>
                                        </S.StatusIconWrapper>

                                    </S.BetHistoryDetailsBody>

                                )
                            })}
                        </S.BetHistoryItemDetails>

                    </React.Fragment>
                ));

            })}

            {transaction.slipCombinations && !_isEmpty(transaction.slipCombinations.combinations) && (
                <S.BetHistoryItemDetailsBlockCombinations>
                    <S.BetHistoryItemDetailsBlockCombinationsCaption>
                        {translation('account_betHistoryTransactionItem_combinations')}
                    </S.BetHistoryItemDetailsBlockCombinationsCaption>
                    <S.BetHistoryItemDetailsBlockCombinationsTable>
                        <S.BetHistoryItemDetailsBlockCombinationsHeader>
                            <div
                                className="combinations_nr">{translation('account_betHistoryTransactionItem_combinationsNr')}</div>
                            <div
                                className="combinations_combination">{translation('account_betHistoryTransactionItem_combination')}</div>
                            <div
                                className="combinations_odds">{translation('account_betHistoryTransactionItem_combinationOdds')}</div>
                            <div
                                className="combinations_possibleWin">{translation('account_betHistoryTransactionItem_combinationPossibleWin')}</div>
                            <div
                                className="combinations_win">{translation('account_betHistoryTransactionItem_combinationWin')}</div>
                        </S.BetHistoryItemDetailsBlockCombinationsHeader>
                        <S.BetHistoryItemDetailsBlockCombinationsBody>
                            {blockCombinations(transaction)}
                        </S.BetHistoryItemDetailsBlockCombinationsBody>
                    </S.BetHistoryItemDetailsBlockCombinationsTable>
                </S.BetHistoryItemDetailsBlockCombinations>
            )}

            <S.BetHistoryItemsFooter>

                <S.BetHistoryItemsFooterBox>

                    <S.BetHistoryItemsFooterList>

                        <S.BetHistoryItemsFooterListItem>
                            <div>{translation('account_betHistoryTransactionItem_detailsStatus')}</div>
                            <div>{getTransactionResult}</div>
                        </S.BetHistoryItemsFooterListItem>

                        <S.BetHistoryItemsFooterListItem>
                            <div>{translation('account_betHistoryTransactionItem_detailsType')}</div>
                            <div>{getTransactionType}</div>
                        </S.BetHistoryItemsFooterListItem>

                        <S.BetHistoryItemsFooterListItem>
                            <div>{translation('account_betHistoryTransactionItem_detailsDate')}</div>
                            <div>{formatDate(regDate, 'dd-MM-yyyy')} {formatDate(regDate, 'HH:mm')}</div>
                        </S.BetHistoryItemsFooterListItem>

                        <S.BetHistoryItemsFooterListItem>
                            <div>{translation('account_betHistoryTransactionItem_detailsTaxLabel')}</div>
                            <div>{taxFactor}</div>
                        </S.BetHistoryItemsFooterListItem>

                    </S.BetHistoryItemsFooterList>

                </S.BetHistoryItemsFooterBox>

                <S.BetHistoryItemsFooterBox>

                    <S.BetHistoryItemsFooterLogo src={BetDetailsFooterLogo}/>

                </S.BetHistoryItemsFooterBox>

                <S.BetHistoryItemsFooterBox>

                    <S.BetHistoryItemsFooterList>

                        <S.BetHistoryItemsFooterListItem>
                            <div>{translation('account_betHistoryTransactionItem_detailsAkoLabel')}</div>
                            <div>{decimal(totalOddsWithoutSalesTaxFactor)}</div>
                        </S.BetHistoryItemsFooterListItem>

                        <S.BetHistoryItemsFooterListItem>
                            <div>{translation('account_betHistoryTransactionItem_detailsStakeLabel')}</div>
                            <div>{`${transaction.stake} ${currencyCode}`}</div>
                        </S.BetHistoryItemsFooterListItem>
                
                        {(tax > 0 || taxConfirmed > 0) && (
                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_bettingHistory_betSlipTax')}</div>
                                {(taxConfirmed > 0 && statusCode === 1)
                                ?
                                <div>{decimal(taxConfirmed)}</div>
                                : 
                                <div>{decimal(tax)}</div>
                                }
                            </S.BetHistoryItemsFooterListItem>
                        )}

                    </S.BetHistoryItemsFooterList>

                </S.BetHistoryItemsFooterBox>

                <S.BetHistoryItemsFooterInfo>
                    {![0, 1].includes(transaction.statusCode) && (
                        <S.ConfirmedWin>
                            <S.ConfirmedWinLabel>{translation('account_betHistoryTransactionItem_detailsConfirmedWin')}</S.ConfirmedWinLabel>
                            <S.ConfirmedWinAmmount>{
                            `${(isFreebet && hasCancelledCombination()) ? 0.00 :
                                decimal(transaction.confirmedReturn + bonus)} ${currencyCode}`
                            }</S.ConfirmedWinAmmount>
                        </S.ConfirmedWin>
                    )}

                    {[0, 1].includes(transaction.statusCode) && (
                        <>
                            {([0, 100].indexOf(slipType) != -1)
                                ?
                                <S.PossibleWin>
                                    <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_detailsPossibleWin')}</S.PossibleWinLabel>
                                    <S.PossibleWinAmmount>{`${(transaction.possibleReturn + bonus).toFixed(2)} ${currencyCode}`}</S.PossibleWinAmmount>
                                </S.PossibleWin>
                                :
                                <S.PossibleConfirmedWin>
                                    <S.PossibleWin>
                                        <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_detailsPossibleWin')}</S.PossibleWinLabel>
                                        <S.PossibleWinAmmount>{`${(transaction.possibleReturn + bonus - getCombinationApplicableBonusAmount).toFixed(2)} ${currencyCode}`}</S.PossibleWinAmmount>
                                    </S.PossibleWin>
                                    <S.ConfirmedWin>
                                        <S.ConfirmedWinLabel>{translation('account_betHistoryTransactionItem_detailsConfirmedWin')}</S.ConfirmedWinLabel>
                                        <S.ConfirmedWinAmmount>{`${(transaction.confirmedReturn + getCombinationApplicableBonusAmount).toFixed(2)} ${currencyCode}`}</S.ConfirmedWinAmmount>
                                    </S.ConfirmedWin>
                                </S.PossibleConfirmedWin>
                            }
                        </>
                    )}

                    {(checkCashout(transaction.transactionId)) && (
                        <S.CashoutButton onClick={toggleCashout.bind(null, transaction.transactionId)}>
                            <S.CashoutButtonTxt
                                dangerouslySetInnerHTML={{__html: translation('account_betHistoryTransactionItem_detailsCashout')}}/>
                            <S.CashoutButtonAmmount>
                                {getCashoutAmmountForTxId(transaction.transactionId)}&nbsp;{currencyCode}
                            </S.CashoutButtonAmmount>
                        </S.CashoutButton>
                    )}

                </S.BetHistoryItemsFooterInfo>
                        {app.config.betslipShare ? (
                            <S.BetHistoryDetailsHeaderShareActions>
                                <span className='shareText'>{translation('account_betHistory_shareButton')}</span>
                                <AccountShareBetIcon type="facebook" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon type="twitter" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon showCopyInfo={showCopyInfoAction} type="link" transaction={transaction}></AccountShareBetIcon>
                                {showCopyInfo ? <S.BetHistoryDetailsCopyText>{translation('betSlipPlaceSameSlip_share')}</S.BetHistoryDetailsCopyText>:null}
                            </S.BetHistoryDetailsHeaderShareActions>
                            ):null
                        }
            </S.BetHistoryItemsFooter>

        </S.BetHistoryDetailsWrapper>
    );
};

const mapStateToProps = ({
                            BetSlip:{activeTab,betSlips},
                             AccountFreebet: {freebets: {history: {data: historyFreebets}}},
                             AccountBetHistory: {transactionsList, cashouts},
                         }, {match: {params}}) => {

    transactionsList = createBlocksForTransactions(transactionsList);
    transactionsList = getPathTransaction(transactionsList, params);
    return {
        activeTab,
        betSlips,
        transaction: transactionsList,
        cashouts,
        historyFreebets
    }
};

const mapDispatchToProps = {
    clearBetslip,
    addOutcome,
    changeSlipStake,
    fetchCashoutsMaximumAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    processCashoutAmount,
    refreshBetHistoryList
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetHistoryItemDetails));

