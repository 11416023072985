import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;

const S = {};

S.RegisterWizardForm = styled.div`
`;

export default S;
