import styled from "styled-components";
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const PopularCategoriesHeaderBgColor = ({theme: {$popularCategoriesHeaderBgColor}}) => `${$popularCategoriesHeaderBgColor}`;
const PopularCategoriesItemBgColor = ({theme: {$popularCategoriesItemBgColor}}) => `${$popularCategoriesItemBgColor}`;
const PopularCategoriesItemTextColor = ({theme: {$popularCategoriesItemTextColor}}) => `${$popularCategoriesItemTextColor}`;
const PopularCategoriesSportIconColor = ({theme: {$popularCategoriesSportIconColor}}) => `${$popularCategoriesSportIconColor}`;
const PopularCategoriesEventsCountColor = ({theme: {$popularCategoriesEventsCountColor}}) => `${$popularCategoriesEventsCountColor}`;

const baseFont = `12px`;
const S = {};

S.PopularCategories = styled.div`
  font-family: ${FontFamily};
  border-bottom: 6px solid #001A5B; 
  margin-bottom: ${rem('8px', baseFont)};
  border-radius: 0px 0px 5px 5px;
  margin-bottom: ${rem('14px', baseFont)};
`;

S.PopularCategoriesList = styled(List)`
  position: relative;
`;

S.PopularCategoriesSeo = styled.a`
  display: flex;
  align-items: center;
  pointer-events: none;
  text-decoration:none;   
`;

S.PopularCategoriesListItem = styled.li`
    height: ${rem('37px', baseFont)};
    display: flex;
    align-items: center;
    padding: 0 ${rem('20px', baseFont)};
    background-color: ${PopularCategoriesItemBgColor};
    cursor: pointer;
    border-bottom: 1px solid #BAC1D0;
    color: ${PopularCategoriesItemTextColor};
    font-family: ${FontFamilyMulish};
    font-weight: 400;
    &:last-of-type{
      border-bottom: none;
    }
`;

S.PopularCategoriesHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem('19px', baseFont)};
  height: ${rem('50px', baseFont)};
  background-color: ${PopularCategoriesHeaderBgColor};
  border-radius: 5px 5px 0px 0px;
`;

S.PopularCategoriesHeaderText = styled.div`
  color: #fff;
  font-size: ${rem('15px', baseFont)};
  text-transform: uppercase;
`;

S.SportIcon = styled.i`
  display: flex;
  position: relative;
  flex-shrink: 0;
  margin-right: ${rem('19px', baseFont)};
  line-height: 1;
  width: ${rem('16px', baseFont)};
  
  &:before{
  font-size: ${rem('16px', baseFont)};
     color: ${PopularCategoriesSportIconColor};
  }
`;

S.PopularCategoriesItemName = styled.div`
  font-size: 1.16rem;
  color: #001A5B;
`;

S.EventsCount = styled(S.PopularCategoriesItemName)`
  margin-left:auto;
  color: ${PopularCategoriesEventsCountColor};
`;

export default S;