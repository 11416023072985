import styled from 'styled-components';
import {rem} from 'polished';

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const S = {};
const baseFont = `12px`;

S.AccountSessionTimer = styled.div`
    font-family: ${FontFamilyMulish};
    display: flex;
    color: #001A5B;
    padding: 0;
    margin-top: auto;
    justify-content: center;
        font-size: ${rem('13px', baseFont)};
        line-height: 1;
        margin-bottom: ${rem('20px', baseFont)};
        font-weight: 400;
`;


export default S;