import React, {useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import S from 'StyledBetHistoryItemDetails.js';
import {NavLink, withRouter} from 'react-router-dom';
import {translation, decimal, redirect} from 'utilsHelper.js';
import {formatDateFromUnix} from 'datesHelper.js';
import {getPathTransaction} from 'transactionsSelectors.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from "lodash/omit";
import _truncate from "lodash/truncate";
import BackButtonIcon from 'back_btn_icon.svg';
import {addOutcome, clearBetslip, changeSlipStake} from 'betSlipActions.js';
import {
    fetchCashoutsMaximumAmount,
    processCashoutAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    refreshBetHistoryList
} from 'accountBetHistoryActions.js';
import BetDetailsFooterLogo from 'bet_details_footer_logo.png';
import {createBlocksForTransactions} from 'helpers/transactionsHelper.js';
import _reject from "lodash/reject";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _each from "lodash/each";
import _every from "lodash/every";
import _reduce from "lodash/reduce";

const VirtualBetHistoryItemDetails = (
    {
        transaction,
        currencyCode,
        history: {goBack}
    }) => {

    const [sortedBlocks, setSortedBlocks] = useState({});
    const {transactionId, stake, possibleReturn, confirmedReturn, regDate, slipType, transactionType, transactionDetails, statusCode, tax, bonus, totalOdds, salesTaxOdds} = transaction;

    useEffect(() => {
        let blockInfo = prepareBlocks();
        setSortedBlocks(blockInfo);
    }, [transaction]);

    const getTransactionType = useMemo(() => {

        let slipTypeValue = slipType;
        if (slipTypeValue == null) {
            slipTypeValue = translation('betSlip_integrationType_' + Number(transactionType));
            return slipTypeValue;
        }

        let bankerCount = 0;
        let allCount = transactionDetails.length;
        const blocksCount = [];

        if ([0, 100].indexOf(slipType) == -1) {
            for (let i = 0; i < transactionDetails.length; i++) {
                if (transactionDetails[i].blockId != 0 && transactionDetails[i].blockId != null) {
                    allCount--;
                    if (blocksCount.indexOf(transactionDetails[i].blockId)== -1) {
                        blocksCount.push(transactionDetails[i].blockId);
                    }
                }
                if (transactionDetails[i].banker && transactionDetails[i].remoteId != -1) {
                    bankerCount++;
                }
            }
        }

        const betSlipType = translation('betSlip_type_combination');
        let typeName = slipType;
        let multi = '';
        if (typeName == 1) {
            typeName = translation('betSlip_type_SIMPLE');
        } else if (typeName == 2) {
            typeName = translation('betSlip_type_SYSTEM');
        } else {
            multi = `${translation('betSlip_type_MULTI')} `;
        }

        slipTypeValue = multi + betSlipType.replace('{0}', typeName).replace('{1}', allCount + blocksCount.length - bankerCount);

        return slipTypeValue;
    }, []);


    const getTransactionDetailsStatus = (statusCode) => {

        let resultText = '';

        switch (statusCode) {
            case 1:
                resultText = 'placed';
                break;
            case 2:
                resultText = 'lost';
                break;
            case 3:
            case 4: // in RGS etoto virtuals statusCode 3 is automatically switched to 4
                resultText = 'won';
                break;
            case 5 :
                resultText = 'cancel';
                break;
            case 6:
                resultText = 'pending_acceptation';
                break;
            case 7:
                resultText = 'rejected';
                break;
        }


        return resultText;
    };

    const getTransactionStatus = (statusCode) => {

        let resultText = '';

        switch (statusCode) {
            case 1:
                resultText = 'lost';
                break;
            case 2:
                resultText = 'cancel';
                break;
            case 3:
                resultText = 'won';
                break;
        }

        return resultText;
    };

    const getTransactionResult = useMemo(() => {

        const transactionStatus = getTransactionDetailsStatus(statusCode);
        let translationKey = null;

        if (transactionStatus == 'won') {
            translationKey = translation('transactionDetails_betSlipWon');
        } else if (transactionStatus == 'cashout') {
            translationKey = translation('transactionDetails_betSlipCashout');
        } else if (transactionStatus == 'lost') {
            translationKey = translation('transactionDetails_betSlipLost');
        } else if (transactionStatus == 'cancel') {
            translationKey = translation('transactionDetails_betSlipCancel');
        } else  {
            translationKey = translation('transactionDetails_betSlipPlaced');
        }

        return translationKey;

    }, [statusCode]);

    const sumOdds = (transaction) => {

        let sum = 1;
        if (transaction && transaction.transactionDetails) {

            for (var i = 0; i < transaction.transactionDetails.length; i++) {
                if (transaction.transactionDetails[i].outcomeId != -1) {
                    sum *= parseFloat(transaction.transactionDetails[i].odds);
                }
            }

        }
        return sum.toFixed(2);
    };

    const prepareBlocks = () => {
        let pureBlocksObject = {}
        let blocks = _get(transaction, 'blocks')
        for (const id in blocks) {    
            if(id != -1){
                pureBlocksObject[blocks[id].blockName] = blocks[id]
            }
        }
        const sortedData = {};
        Object.keys(pureBlocksObject).sort().forEach(function(key) {
            sortedData[key] = pureBlocksObject[key];
        });
        return sortedData;
    };

    const transactionOdds = useMemo(()=>{
        return sumOdds(transaction);
    }, [])

    const blockCombinations = (transactionItem) => {
        if (transactionItem.slipCombinations) {
            var combinations = [];
            for (var i = 0; i < transactionItem.slipCombinations.combinations.length; i++) {
                var combination = transactionItem.slipCombinations.combinations[i];
                var returnedCombination = (
                    <S.BetHistoryItemDetailsBlockCombinationsItem key={`bet-history-item-block-combinations_${i}`}>
                        <div className="combinations_nr">{i+1}</div>
                        <div className="combinations_combination">{_map(combination.combinationLettersText.split(''), letter=><S.Letter key={letter}>{letter}</S.Letter>)}</div>
                        <div className="combinations_odds">{combination.odds}</div>
                        <div className="combinations_stake">{combination.stake}&nbsp;{currencyCode}</div>
                        <div className="combinations_bonus">{combination.bonus??''}</div>
                        <div className="combinations_possibleWin">{combination.possibleReturn}&nbsp;{currencyCode}</div>
                    </S.BetHistoryItemDetailsBlockCombinationsItem>
                );
                combinations.push(returnedCombination);
            }
            return combinations;
        }
    };

    return (

            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">

                <S.BetHistoryDetailsHeader className="bet-history-item-details-header">

                    <S.BetHistoryDetailsHeaderActions>

                        <S.BetHistoryDetailsHeaderGoBack as={NavLink} to={{pathname: '/virtuals-bet-history', state: {fromTransactionVirtualDetails: true, bettingActiveTab: 2}}}>
                            <S.BackButtonIcon dangerouslySetInnerHTML={{__html: BackButtonIcon}}/>
                            {translation('account_betHistoryTransactionItem_goBackButton')}
                        </S.BetHistoryDetailsHeaderGoBack>

                       <S.BetHistoryDetailsTransactionId>
                           {`${translation('account_betHistoryTransactionItem_detailsIdPrefix')} ${transaction.transactionId}`}
                       </S.BetHistoryDetailsTransactionId>

                    </S.BetHistoryDetailsHeaderActions>

                    <S.BetHistoryDetailsHeaderCaption>
                        {translation('account_betHistoryTransactionItem_detailsCaption')}
                    </S.BetHistoryDetailsHeaderCaption>

                    <S.BetHistoryDetailsHeaderColumns>

                        <div className="transaction-event">
                            {translation('account_betHistoryItem_event')}
                        </div>

                        <div className="transaction-date">
                            {translation('account_betHistoryItem_date')}
                        </div>

                        <div className="transaction-type">
                            {translation('account_betHistoryItem_type')}
                        </div>

                        <div className="transaction-odd">
                            {translation('account_betHistoryItem_odd')}
                        </div>

                        <div className="transaction-result">
                            {translation('account_betHistoryItem_result')}
                        </div>

                        <div className="transaction-bet-result">
                            {translation('account_betHistoryItem_betResult')}
                        </div>

                        <div className="transaction-bet-result-icon"></div>

                    </S.BetHistoryDetailsHeaderColumns>

                </S.BetHistoryDetailsHeader>

                {_map(sortedBlocks, (blocks) => {
                    return (!_isEmpty(blocks) && (

                        <React.Fragment key={blocks[0].remoteId}>

                            {Object.keys(transaction.blocks).length !== 1 && (
                                <S.BetHistoryBlockHeader classname={blocks.blockName == "P"? "banker" : "block"}>
                                    {blocks.blockName == "P"? translation('account_betHistoryTransactionItem_detailsBanker') : `${translation('account_betHistoryTransactionItem_detailsBlock')} ${blocks.blockName}`}
                                </S.BetHistoryBlockHeader>
                            )}

                            <S.BetHistoryItemDetails className="bet-history-item-details">
    
                                {_map(blocks, ({ remoteId, eventStart, eventName, gameName, outcomeName, outcomeResult, odds, score, statusCode, sportId, categoryName1, categoryName2, categoryName3 }) => {

                                    const statusResult = getTransactionStatus(statusCode);
                                    return (remoteId != -1) && (
                                         
                                        <S.BetHistoryDetailsBody key={remoteId}>

                                            <S.BetHistoryDetailsBodyEvent>

                                                <S.BetHistoryDetailsBodyEventIcon className={`sport-icon sport-${sportId}`}/>

                                                <S.BetHistoryDetailsBodyEventInfo>

                                                    <S.BetHistoryDetailsBodyEventName title={eventName}>
                                                        {_map(String(eventName).split(/\s\-\s/), (participant)=>_truncate(participant, {length: 24})).join(' - ')}
                                                    </S.BetHistoryDetailsBodyEventName>

                                                    <S.BetHistoryDetailsBodyEventCategories>
                                                        <div>{categoryName2}</div>
                                                        <div className="separator">-</div>
                                                        <div>{categoryName3}</div>
                                                    </S.BetHistoryDetailsBodyEventCategories>

                                                </S.BetHistoryDetailsBodyEventInfo>

                                            </S.BetHistoryDetailsBodyEvent>

                                            <S.BetHistoryDetailsBodyDate>
                                                <div>{formatDateFromUnix(eventStart, 'dd-MM-yyyy')}</div>
                                                <div>godz. {formatDateFromUnix(eventStart, 'HH:mm')}</div>
                                            </S.BetHistoryDetailsBodyDate>

                                            <S.BetHistoryDetailsBodyType>
                                                <div title={outcomeName}>{outcomeName}</div>
                                                <div title={gameName}>{gameName}</div>
                                            </S.BetHistoryDetailsBodyType>

                                            <S.BetHistoryDetailsBodyOdd>
                                                {decimal(odds)}
                                            </S.BetHistoryDetailsBodyOdd>

                                            <S.BetHistoryDetailsBodyResult>
                                                    {score}
                                            </S.BetHistoryDetailsBodyResult>

                                            <S.BetHistoryDetailsBodyBetResult>

                                                <S.BetHistoryDetailsBodyBetResultScore>
                                                    {outcomeResult}
                                                </S.BetHistoryDetailsBodyBetResultScore>

                                            </S.BetHistoryDetailsBodyBetResult>

                                            <S.StatusIcon className={statusResult}/>
    
                                        </S.BetHistoryDetailsBody>
                                        
                                    )     
                                })}
                            </S.BetHistoryItemDetails>

                        </React.Fragment>
                    ));
    
                })}

                {transaction.slipCombinations && (
                    <S.BetHistoryItemDetailsBlockCombinations>
                        <S.BetHistoryItemDetailsBlockCombinationsCaption>
                            {translation('account_betHistoryTransactionItem_combinations')}
                        </S.BetHistoryItemDetailsBlockCombinationsCaption>
                        <S.BetHistoryItemDetailsBlockCombinationsTable>
                            <S.BetHistoryItemDetailsBlockCombinationsHeader>
                                <div className="combinations_nr">{translation('account_betHistoryTransactionItem_combinationsNr')}</div>
                                <div className="combinations_combination">{translation('account_betHistoryTransactionItem_combination')}</div>
                                <div className="combinations_odds">{translation('account_betHistoryTransactionItem_combinationOdds')}</div>
                                <div className="combinations_stake">{translation('account_betHistoryTransactionItem_combinationStake')}</div>
                                <div className="combinations_bonus">{translation('account_betHistoryTransactionItem_combinationBonus')}</div>
                                <div className="combinations_possibleWin">{translation('account_betHistoryTransactionItem_combinationPossibleWin')}</div>
                            </S.BetHistoryItemDetailsBlockCombinationsHeader>
                            <S.BetHistoryItemDetailsBlockCombinationsBody>
                                {blockCombinations(transaction)}
                            </S.BetHistoryItemDetailsBlockCombinationsBody>
                        </S.BetHistoryItemDetailsBlockCombinationsTable>
                    </S.BetHistoryItemDetailsBlockCombinations>
                )}

                <S.BetHistoryItemsFooter>

                    <S.BetHistoryItemsFooterBox>

                        <S.BetHistoryItemsFooterList>

                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_betHistoryTransactionItem_detailsStatus')}</div>
                                <div>{getTransactionResult}</div>
                            </S.BetHistoryItemsFooterListItem>

                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_betHistoryTransactionItem_detailsType')}</div>
                                <div>{getTransactionType}</div>
                            </S.BetHistoryItemsFooterListItem>

                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_betHistoryTransactionItem_detailsDate')}</div>
                                <div>{formatDateFromUnix(regDate, 'dd-MM-yyyy')} {formatDateFromUnix(regDate, 'HH:mm')}</div>
                            </S.BetHistoryItemsFooterListItem>

                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_betHistoryTransactionItem_detailsTaxLabel')}</div>
                                <div>{salesTaxOdds}</div>
                            </S.BetHistoryItemsFooterListItem>

                        </S.BetHistoryItemsFooterList>

                    </S.BetHistoryItemsFooterBox>

                    <S.BetHistoryItemsFooterBox>

                            <S.BetHistoryItemsFooterLogo src={BetDetailsFooterLogo}/>

                    </S.BetHistoryItemsFooterBox>

                    <S.BetHistoryItemsFooterBox>

                        <S.BetHistoryItemsFooterList>

                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_betHistoryTransactionItem_detailsAkoLabel')}</div>
                                <div>{totalOdds}</div>
                            </S.BetHistoryItemsFooterListItem>

                            <S.BetHistoryItemsFooterListItem>
                                <div>{translation('account_betHistoryTransactionItem_detailsStakeLabel')}</div>
                                <div>{`${stake} ${currencyCode}`}</div>
                            </S.BetHistoryItemsFooterListItem>

                            {(tax > 0) && (
                                <S.BetHistoryItemsFooterListItem>
                                    <div>{translation('account_bettingHistory_betSlipTax')}</div>
                                    <div>{decimal(tax)}</div>
                                </S.BetHistoryItemsFooterListItem>
                            )}

                        </S.BetHistoryItemsFooterList>

                    </S.BetHistoryItemsFooterBox>

                    <S.BetHistoryItemsFooterInfo>

                        {![0, 1].includes(transaction.statusCode) && (
                            <S.ConfirmedWin>
                                <S.ConfirmedWinLabel>{translation('account_betHistoryTransactionItem_detailsConfirmedWin')}</S.ConfirmedWinLabel>
                                <S.ConfirmedWinAmmount>{`${transaction.confirmedReturn.toFixed(2)} ${currencyCode}`}</S.ConfirmedWinAmmount>
                            </S.ConfirmedWin>
                        )}

                        {[0, 1].includes(transaction.statusCode) && (
                            <S.PossibleWin>
                                <S.PossibleWinLabel>{translation('account_betHistoryTransactionItem_detailsPossibleWin')}</S.PossibleWinLabel>
                                <S.PossibleWinAmmount>{`${transaction.possibleReturn.min} ${currencyCode}`}</S.PossibleWinAmmount>
                            </S.PossibleWin>
                        )}

                    </S.BetHistoryItemsFooterInfo>

                </S.BetHistoryItemsFooter>

            </S.BetHistoryDetailsWrapper>
    );
};

const mapStateToProps = ({AccountFreebet:{freebets:{history:{data:historyFreebets}}}, AccountBetHistory: {cashouts, virtualBetHistory}, Auth: {user: {currencyCode}}}, {match: {params}}) => {

    virtualBetHistory = Object.values(virtualBetHistory);
    virtualBetHistory = createBlocksForTransactions(virtualBetHistory);
    virtualBetHistory = getPathTransaction(virtualBetHistory, params);

    return {
        transaction: virtualBetHistory,
        cashouts,
        currencyCode,
        historyFreebets
    }
};

const mapDispatchToProps = {
    clearBetslip,
    addOutcome,
    changeSlipStake,
    fetchCashoutsMaximumAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    processCashoutAmount,
    refreshBetHistoryList
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VirtualBetHistoryItemDetails));