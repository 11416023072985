import React from 'react';
import S from 'StyledChangePasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'utilsHelper.js';
import _get from 'lodash/get';
import CloseIcon from 'account_close_btn.png';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.CHANGE_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    placeholder,
    meta: {touched, error, warning, submitFailed, dirty}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Label>{label}</S.Label>
                <S.Input {...input} type={type} required="required" placeholder={placeholder} dirty={dirty}/>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ChangePasswordForm = ({changePasswordOnSubmit, handleSubmit, toggleOpen, userId, submitting, error, submitSucceeded}) => {

    const onFormSubmit = async(values) => {

        try {
            const params = {
                oldValue: _get(values, 'oldPassword'),
                newValue: _get(values, 'newPassword'),
                customerId: userId
            };

            await changePasswordOnSubmit(params);
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ChangePasswordForm method="post" onSubmit={handleSubmit(onFormSubmit)} noValidate>

            <S.Header>
                <S.Title>
                    {translation('account_editProfileForm_changePassword_formHeader')}
                </S.Title>
                <S.CloseButton src={CloseIcon} onClick={toggleOpen}/>
            </S.Header>

            <S.Body>

            {submitSucceeded && <S.SubmissionSuccess>{translation('account_changePasswordForm_submissionSuccess')}</S.SubmissionSuccess>}
            {error && <S.SubmissionError>{error}</S.SubmissionError>}

            <Field
                name="oldPassword"
                type="password"
                component={renderField}
                placeholder={translation('account_changePasswordForm_typePassword')}
                label={translation('account_changePasswordForm_oldPassword')}
            />
            <Field
                name="newPassword"
                type="password"
                component={renderField}
                placeholder={translation('account_changePasswordForm_typePassword')}
                label={translation('account_changePasswordForm_newPassword')}
            />
            <Field
                name="confirmPassword"
                type="password"
                component={renderField}
                placeholder={translation('account_changePasswordForm_typePassword')}
                label={translation('account_changePasswordForm_confirmNewPassword')}
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn type="submit" disabled={submitting}>
                {translation('account_changePasswordForm_changePassword')}
            </S.SubmitBtn>

            </S.Body>

        </S.ChangePasswordForm>
    );
}

export default reduxForm({
    form: 'changePasswordForm',
    validate
})(ChangePasswordForm)
