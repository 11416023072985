import styled, {css} from 'styled-components';
import {List, Link} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.SubList = styled(List)`
    position: absolute;
    top: 100%;
    left: -25px;
    width: 153px;
    background-color: #4D555E; 
    border-left: 1px solid #4D555E;
    border-right: 1px solid #4D555E;
    border-bottom: 1px solid #4D555E;
    display: none;
    z-index: 1;
`;

S.SubListItem = styled.li`
    height: 39px;
    border-bottom: 1px solid #626973;
    &:last-of-type{
       border-bottom: none;
    }
    
   ${({isActive}) => {
    if(isActive==true){
        return `
               ${S.SubListLink}{
                 background-color: #626973;
                 color: #fff;
                 }
               `;
    }
}}

`;

S.SubListLink = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 23px;
    padding-right: 23px;
    color: #BCC0C5;
    font-size: 14px;
    text-transform: none;
    
    &.is-active,
    &:hover{
      background-color: #626973;
      color: #fff;
    }
    
    & > span{
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

S.SubmenuList = styled(List)`
    height: 100%;
    margin-right: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
`;

S.SubmenuLink = styled(Link)`
   font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
    width: 100%;
    font-size: 1.2rem;
    padding: 0;
    color: ${({theme:{$topMenuSubmenuLinkColor}})=> $topMenuSubmenuLinkColor};
    display:inline-block;
    position: relative;
    cursor: pointer;
    font-weight: 400;
    
    &:before{
    display: block;
    content: attr(title);
      font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-weight: 700;
    }
    
    &:link,
    &:visited,
    &:hover,
    &:active{
       text-decoration: none;
    }
    
    &:hover{
        font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
        font-weight: 700;
    }
`;


S.SubmenuListItem = styled.li`
    display: inline-block;
     font-size: 0;
    margin-right: ${rem('30px', baseFont)};
    &:last-of-type{
     margin-right: 0;
     &>a{
         color:#E4202C;
     }
    }
    
    ${({isActive}) => {
        if(isActive==true){
            return css`
                & ${S.SubmenuLink}{
                      font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
                      font-weight: 700;
                }
            `;
        }
        
    }}
`;

export default S;