import React from 'react';
import S from 'StyledForgotPasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'utilsHelper.js';
import CloseIcon from 'account_close_btn.png';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.FORGOT_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, submitFailed, dirty}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" placeholder={label} dirty={dirty}/>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ForgotPasswordForm = ({forgotPasswordOnSubmit, handleSubmit, toggleOpen, submitting, error, submitSucceeded}) => {

    const onFormSubmit = async(values) => {
        try {
            await forgotPasswordOnSubmit(values);
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ForgotPasswordForm method="post" onSubmit={handleSubmit(onFormSubmit)} noValidate>

            <S.Header>
                <S.Title>
                    {translation('forgotPasswordForm_title')}
                </S.Title>
                <S.CloseButton src={CloseIcon} onClick={toggleOpen}/>
            </S.Header>

            <S.Body>

                <S.Info>{translation('forgotPasswordForm_text1')}</S.Info>

                {submitSucceeded && <S.SubmissionSuccess className="submission-success">{translation('forgotPasswordForm_submissionSuccess')}</S.SubmissionSuccess>}
                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Field
                    name="email"
                    type="text"
                    component={renderField}
                    label={translation('forgotPasswordForm_email')}
                />

                <S.SubmitBtn type="submit" disabled={submitting}>
                    {translation('forgotPasswordForm_recoverPassword')}
                </S.SubmitBtn>

                <S.LoginFormReturn onClick={toggleOpen}>
                    &laquo;&nbsp;{translation('forgotPasswordForm_loginFormReturn')}
                </S.LoginFormReturn>

            </S.Body>

        </S.ForgotPasswordForm>
    );
};

export default reduxForm({
    form: 'forgotPassword',
    validate
})(ForgotPasswordForm)