import validation from 'sb-validate-js/validate.js';
import {translation} from 'utilsHelper.js';
import {substractYears, formatDate} from 'datesHelper.js'
import {validateDO, isPeselAdult, isPeselValid} from 'validateHelper.js';
import _range from 'lodash/range';
import _repeat from 'lodash/repeat';
import _reduce from 'lodash/reduce';
import _size from 'lodash/size';

validation.validators.isAdult = function (value, {message, latest}) {
    const adult = value <= latest;
    return !adult ? message() : null;
};

validation.validators.isPeselAdult = function (value, {message}) {
    const size = value && _size(value);
    if (size == 11) {
        const adult = isPeselAdult(value);
        return !adult ? message() : null;
    }
    return null;
};

validation.validators.isPeselValid = function (value, {message}) {
    const size = value && _size(value);
    if (size == 11) {
        const valid = isPeselValid(value);
        return !valid ? message() : null;
    }
    return null;
};

let prohibitedPhoneNumbers = _reduce(_range(1, 10), (initialArray, nextElem) => {
    const number = _repeat(nextElem, 9);
    return [...initialArray, number];
}, []);
prohibitedPhoneNumbers = [...prohibitedPhoneNumbers, ...['123456789', '987654321', '123123123']];

export default {
    REGISTER_STEP1: {
        clientNumber: {
            presence: {
                message: () => translation('account_registerForm_clientNumberEmpty'),
            },
            length: {
                minimum: 12,
                maximum: 12,
                tooShort: () => translation('account_registerForm_clientNumberTooShort'),
                tooLong: () => translation('account_registerForm_clientNumberTooLong'),
            },
            numericality: {
                onlyInteger: true,
                message: () => translation('account_registerForm_clientNumberIntegerOnly')
            }
        },
        pinCode: {
            presence: {
                message: () => translation('account_registerForm_pinCodeEmpty'),
            },
            length: {
                minimum: 4,
                maximum: 4,
                tooShort: () => translation('account_registerForm_clientNumberTooShort'),
                tooLong: () => translation('account_registerForm_clientNumberTooLong')
            },
            numericality: {
                onlyInteger: true,
                message: () => translation('account_registerForm_pinCodeIntegerOnly')
            }
        },
        password: {
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_registerForm_passwordTooShort'),
                tooLong: () => translation('account_registerForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=[^\s]*?[A-Z])(?=[^\s]*?[a-z])(?=[^\s]*?[0-9])[^\s]*$/,
                message: () => translation('account_registerForm_wrongPasswordPattern'),
            },
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('account_registerForm_passwordsNotEqual'),
            }
        },
        email: {
            presence: {
                message: () => translation('account_registerForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_registerForm_wrongEmailPattern'),
            },
            length: {
                minimum: 5,
                tooShort: () => translation('account_registerForm_emailTooShort'),
            }
        },
        mobile: {
            presence: {
                message: () => translation('account_registerForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_registerForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_registerForm_phoneTooShort'),
                tooLong: () => translation('account_registerForm_phoneTooLong'),
            },
            exclusion: {
                within: prohibitedPhoneNumbers,
                message: () => translation('account_registerForm_prohibitedPhoneNumber'),
            },
            numericality: {
                onlyInteger: true,
                notInteger: () => translation('account_registerForm_onlyInteger'),
            }
        },
        rodoClause: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_adult'),
            },
            presence: {
                message: () => translation('account_registerForm_adult'),
            },
        }
    },
    REGISTER_STEP2: {
        firstName: {
            presence: {
                message: () => translation('account_registerForm_firstNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_firstNameTooShort'),
            }
        },
        lastName: {
            presence: {
                message: () => translation('account_registerForm_lastNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_lastNameTooShort'),
            }
        },
        dateOfBirth: {
            isAdult: {
                latest: substractYears(new Date, 18),
                message: () => translation('account_registerForm_notAdult')
            }
        },
        personalIdentifier: {
            isPeselValid:{
                message: () => translation('account_registerForm_peselNotValid')
            },
            isPeselAdult: {
                message: () => translation('account_registerForm_notAdult')
            },
            presence: {
                message: () => translation('account_registerForm_peselEmpty'),
            },
            length: {
                minimum: 11,
                maximum: 11,
                tooShort: () => translation('account_registerForm_peselTooShort'),
                tooLong: () => translation('account_registerForm_peselTooLong'),
            }
        },
        verificationCode: {
            presence: {
                message: () => translation('account_registerForm_smsCodeEmpty'),
            },
            length: {
                minimum: 4,
                maximum: 4,
                tooShort: () => translation('account_registerForm_smsCodeTooShort'),
                tooLong: () => translation('account_registerForm_smsCodeTooLong'),
            }
        },
        giifClause: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_giifClause'),
            },
            presence: {
                message: () => translation('account_registerForm_giifClause'),
            }
        }
    },
    LOGIN: {
        login: {
            presence: {
                message: () => translation('loginModal_loginEmpty'),
            }
        },
        password: {
            presence: {
                message: () => translation('loginModal_passwordEmpty'),
            }
        }
    },
    FORGOT_PASSWORD: {
        email: {
            presence: {
                message: () => translation('account_forgotPasswordForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_forgotPasswordForm_wrongEmailPattern'),
            },
        }
    },
    CASHOUT: {},
    CHANGE_PASSWORD: {
        oldPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_oldPasswordEmpty'),
            },
        },
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    RESET_PASSWORD: {
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    EDIT_ACCOUNT: {
        id:{
            presence: {
                message: () => translation('account_editProfileForm_noIdNumber'),
                allowEmpty: false
            },
            format: {
                pattern: /^[a-zA-Z]{3}[0-9]{6}$/,
                message: () => translation('account_editProfileForm_idNumber_incorrect'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        passport:{
            presence: {
                message: () => translation('account_editProfileForm_noPassportNumber'),
                allowEmpty: false
            },
            format: {
                pattern: /^[a-zA-Z]{2}[0-9]{7}$/,
                message: () => translation('account_editProfileForm_passportNumber_incorrect'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        driving_license:{
            presence: {
                message: () => translation('account_editProfileForm_noDrivingLicenseNumber'),
                allowEmpty: false
            }, 
            format: {
                pattern: /^[0-9]*$/,
                message: () => translation('account_editProfileForm_dlNumber_incorrect'),
            },
        },
        mobileNumber: {
            presence: {
                message: () => translation('account_editProfileForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_editProfileForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        city: {
            presence: {
                message: () => translation('account_editProfileForm_cityEmpty'),
            },
            length: {
                minimum: 2,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
            format: {
                pattern: /^[a-zA-ZąĄęĘóÓćĆłŁńŃśŚżŻźŹ-\s]*$/,
                message: () => translation('account_editProfileForm_wrongCityPattern'),
            },
        },
        address: {

            presence: {
                message: () => translation('account_editProfileForm_adressEmpty'),
            },
            format: {
                pattern: /^[a-zA-Z0-9ąĄęĘóÓćĆłŁńŃśŚżŻźŹ-\s]*$/,
                message: () => translation('account_editProfileForm_onlyAlfanumeric'),
            },
            length: {
                minimum: 2,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
        },
        postalCode: {
            presence: {
                message: () => translation('account_editProfileForm_postalCodeEmpty'),
            },
            format: {
                pattern: /^[0-9]{2}-[0-9]{3}$/,
                message: () => translation('account_editProfileForm_wrongPostalPattern'),
            },
        },
        pesel: {
            presence: {
                message: () => translation('account_editProfileForm_peselEmpty'),
            },
            format: {
                message: () => translation('account_editProfileForm_wrongPeselPattern'),
            },
        },
        nationality: {
            presence: {
                message: () => translation('account_editProfileForm_nationalityNotSelected'),
            },
        },
        bankAccountNumber: {
            presence: {
                message: () => translation('account_editProfileForm_bankAccountEmpty'),
            },
            format: {
                pattern: /^[0-9\s]{26}$/,
                message: () => translation('account_editProfileForm_wrongbankAccountPattern'),
            },
            length: {
                minimum: 26,
                maximum: 26,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        loginName: {
            presence: {
                message: () => translation('account_editProfileForm_loginNameEmpty'),
            },
            format: {
                pattern: /^[a-zA-Z0-9@.\s]*$/,
                message: () => translation('account_editProfileForm_loginNamePattern'),
            },
            length: {
                minimum: 3,
                maximum: 40,
                tooShort: () => translation('account_editProfileForm_loginNameTooShort'),
                tooLong: () => translation('account_editProfileForm_loginNameTooLong'),
            },
        },
    },
};

