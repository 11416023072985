import styled from 'styled-components';
import {List, Link} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.MenuList = styled(List)`
    height: 100%;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    margin-right: .83rem;
    padding-left: 0;
    font-family: ${({theme:{$fontFamily}})=> $fontFamily};
`;

S.MenuLink = styled(Link)`
    height: 100%;
    line-height: 1;
    color: ${({theme:{$topMenuLinkColor}})=> $topMenuLinkColor};
    width: 100%;
    font-size: ${rem('20px', baseFont)};
    font-weight: 400;
    font-style: normal;
    padding: 0 ${rem('25px', baseFont)} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    &:link,
    &:visited,
    &:hover,
    &:active{
     text-decoration: none;
    }
    
    ${({theme:{mediumRWD}})=> mediumRWD}{
       font-size: ${rem('16px', baseFont)};
           padding: 0 ${rem('15px', baseFont)} 0;
    }
    ${({theme:{normalRWD}})=> normalRWD}{
           font-size: ${rem('16px', baseFont)};
         padding: 0 ${rem('15px', baseFont)} 0;
    }
    ${({theme:{bigRWD}})=> bigRWD}{
           font-size: ${rem('16px', baseFont)};
      padding: 0 ${rem('15px', baseFont)} 0;
    }
    ${({theme:{largeRWD}})=> largeRWD}{
               font-size: ${rem('19px', baseFont)};
         padding: 0 ${rem('25px', baseFont)} 0;
    }
    
    
`;

S.MenuListItem = styled.li`
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: .2s all linear;
    text-align: center;
    padding: 0;
    height: 100%;
    font-style: italic;
    position: relative;
    flex-direction: column;
    &:after {
        display: none;
    }
    &:hover{
     & ${S.MenuLink}
        {
            color: ${({theme:{$topMenuActiveLinkColor}})=> $topMenuActiveLinkColor};
        }
    }
    &.is-active {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: ${rem('4px', baseFont)};
            background-color: ${({theme:{$topMenuListItemActiveColor}})=> $topMenuListItemActiveColor};
            display: block;
        }
         & ${S.MenuLink}
        {
            color: ${({theme:{$topMenuActiveLinkColor}})=> $topMenuActiveLinkColor};
        }
       
    }
`;

S.SubMenuList = styled(List)``;

S.SubMenuListItem = styled.li``;

S.SubMenuLink = styled.a``;

S.MenuNewSign = styled.span`
    background-color: #E4202C;
    color: #fff;
    position: absolute;
    top: 1.2rem;
    border-radius: 3px;
    text-transform: uppercase;
    font-style: normal;
    right: ${rem('25px', baseFont)};
    left:auto;
    padding: 0 3px;

    ${({theme:{mediumRWD}})=> mediumRWD}{
        right: ${rem('15px', baseFont)} 
     }
     ${({theme:{normalRWD}})=> normalRWD}{
        right: ${rem('15px', baseFont)} 
     }
     ${({theme:{bigRWD}})=> bigRWD}{
        right: ${rem('15px', baseFont)} 
     }
     ${({theme:{largeRWD}})=> largeRWD}{
        right: ${rem('25px', baseFont)} 
     }

`

S.MenuButton = styled.button `
    height: 100%;
    line-height: 1;
    color: ${({theme:{$topMenuLinkColor}})=> $topMenuLinkColor};
    width: 100%;
    font-size: ${rem('20px', baseFont)};
    font-weight: 400;
    font-style: normal;
    padding: 0 ${rem('25px', baseFont)} 0;
    display: flex;
    border: 0;
    justify-content: center;
    align-items: center;
    background-color: ${({theme:{$topMenuBackgroundColor}})=> $topMenuBackgroundColor};
    text-transform: uppercase;
    &:hover{
        color: ${({theme:{$topMenuActiveLinkColor}})=> $topMenuActiveLinkColor};
    }
    

    ${({theme:{mediumRWD}})=> mediumRWD}{
       font-size: ${rem('16px', baseFont)};
           padding: 0 ${rem('15px', baseFont)} 0;
    }
    ${({theme:{normalRWD}})=> normalRWD}{
           font-size: ${rem('16px', baseFont)};
         padding: 0 ${rem('15px', baseFont)} 0;
    }
    ${({theme:{bigRWD}})=> bigRWD}{
           font-size: ${rem('16px', baseFont)};
      padding: 0 ${rem('15px', baseFont)} 0;
    }
    ${({theme:{largeRWD}})=> largeRWD}{
               font-size: ${rem('19px', baseFont)};
         padding: 0 ${rem('25px', baseFont)} 0;
    }
    
`

export default S;