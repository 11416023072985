import styled from 'styled-components';
import {rem} from 'polished';
import StyledDatepicker from 'StyledDatepicker.js';
import {Button} from 'global/styles/styles.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const AccountBetHistoryTitleColor = ({theme: {$accountBetHistoryTitleColor}}) => `${$accountBetHistoryTitleColor}`;
const AccountBetHistoryTitleBorderColor = ({theme: {$accountBetHistoryTitleBorderColor}}) => `${$accountBetHistoryTitleBorderColor}`;
const FwMedium = ({theme: {$fwMedium}}) => `${$fwMedium}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const AccountBetHistoryHeaderBackgroundColor = ({theme: {$accountBetHistoryHeaderBackgroundColor}}) => `${$accountBetHistoryHeaderBackgroundColor}`;
const AccountBetHistoryFilterButtonBackgroundColor = ({theme: {$accountBetHistoryFilterButtonBackgroundColor}}) => `${$accountBetHistoryFilterButtonBackgroundColor}`;
const AccountBetHistoryFilterButtonColor = ({theme: {$accountBetHistoryFilterButtonColor}}) => `${$accountBetHistoryFilterButtonColor}`;
const AccountBetHistoryFilterButtonActiveBackgroundColor = ({theme: {$accountBetHistoryFilterButtonActiveBackgroundColor}}) => `${$accountBetHistoryFilterButtonActiveBackgroundColor}`;

const AccountDateBorderColor = ({theme: {$accountDateBorderColor}}) => `${$accountDateBorderColor}`;
const AccountDateIconBackgroundColor = ({theme: {$accountDateIconBackgroundColor}}) => `${$accountDateIconBackgroundColor}`;
const AccountDateInputColor = ({theme: {$accountDateInputColor}}) => `${$accountDateInputColor}`;
const AccountDateLabelColor = ({theme: {$accountDateLabelColor}}) => `${$accountDateLabelColor}`;
const AccountBettingTabBackground = ({theme: {$accountBetHistoryTabBackgroundColor}}) => `${$accountBetHistoryTabBackgroundColor}`;
const AccountBettingTabBackgroundActive = ({theme: {$accountBetHistoryTabBackgroundColorActive}}) => `${$accountBetHistoryTabBackgroundColorActive}`;
const AccountBettingTabColor = ({theme: {$accountBetHistoryTabColor}}) => `${$accountBetHistoryTabColor}`;
const AccountBettingTabColorActive = ({theme: {$accountBetHistoryTabColorActive}}) => `${$accountBetHistoryTabColorActive}`;

const baseFont = `12px`;
const S = {};

S.AccountBetHistory = styled.div`
    width: 100%;
    padding: ${rem('30px', baseFont)};
`;

S.AccountBetHistoryTitle = styled.div`
  font-size: ${rem('20px', baseFont)};
  text-transform: uppercase;
  color: ${AccountBetHistoryTitleColor};
  padding-bottom: ${rem('30px', baseFont)};
  margin-bottom: ${rem('20px', baseFont)};
  border-bottom: 1px solid ${AccountBetHistoryTitleBorderColor};
  font-family: ${FontFamily};
  font-weight: ${FwMedium};
`;

S.TabsWrapper = styled.ul`
    margin-bottom: ${rem('20px', baseFont)};
    border-bottom: 3px solid ${AccountBettingTabColor};
    display: flex;
    padding: 0;
    list-style-type: none;
`;

S.Tab = styled.li`
    flex: 1;
    width: 150px;
    height: 30px;
    background: ${AccountBettingTabBackground};
    color: ${AccountBettingTabColor};
    border-radius: 4px 4px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
    text-align: center;
    cursor: pointer;

    &.active-tab {
        background: ${AccountBettingTabBackgroundActive};
        color: ${AccountBettingTabColorActive};
    }
`;

S.BetHistoryFilters = styled.div`
    display: flex;
    height: ${rem('30px', baseFont)};
        margin-bottom: ${rem('20px', baseFont)};
`;

S.BetHistoryFiltersDates = styled.div`
    display: flex;
    margin-left: auto;
    & > div{
        margin-right: ${rem('10px', baseFont)};
        &:first-of-type{}
        &:last-of-type{
             margin-right: 0;
         }
    }
`;

S.DateWrapper = styled.div`
    width: ${rem('90px', baseFont)};
    height: ${rem('30px', baseFont)};
    display: flex;
    position: relative;
    
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: 100%;
       overflow: hidden;
       border-right: 1px solid ${AccountDateBorderColor};
       border-top: 1px solid ${AccountDateBorderColor};
       border-bottom: 1px solid ${AccountDateBorderColor};
       border-radius: 0 4px 4px 0;
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: ${rem('11px', baseFont)}
        font-weight: ${FwRegular};
        width: 100%;
        color: ${AccountDateInputColor};
        padding-left: ${rem('9px', baseFont)};
       
    }
`;


S.DateLabel = styled.div`
    display: flex;
    font-weight: ${FwRegular};
    color: ${AccountDateLabelColor};
    width: ${rem('45px', baseFont)};
    justify-content: center;
    align-items: center;
    font-size: ${rem('12px', baseFont)};
    text-align: right;
    text-transform: uppercase;
`;

S.DateIcon = styled.img``;

S.DateIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${AccountDateIconBackgroundColor};
  width: ${rem('25px', baseFont)};
  border-radius: 4px 0  0 4px;
  flex-shrink: 0;
`;

S.BetHistoryFiltersBtns = styled.div`
    display: flex;
    & > button{
        margin-right: ${rem('10px', baseFont)};
        &:last-of-type{
            margin-right: 0;
        }
    }
`;

S.FilterBtn = styled(Button)`
    margin-top: 0;
    background-color: ${AccountBetHistoryFilterButtonBackgroundColor};
    color: ${AccountBetHistoryFilterButtonColor};
    font-weight: ${FwRegular};
    width: ${rem('100px', baseFont)};
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 ${rem('5px', baseFont)};
    
    &:hover,
    &.active{
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: ${AccountBetHistoryFilterButtonActiveBackgroundColor};
    }
`;

S.BonusListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;


S.BonusList = styled.div``;


S.BetHistoryTransactionsList = styled.div`
    margin-top: 10px;
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.BetHistoryTransactionsListItem = styled.div`
display: flex;
&>div {
	padding: 1.2rem .67rem;
	border: none;
	font-size: 1rem;
	color: #fff;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	color: #000;
	font-size: 1rem;
	padding: 1.2rem .67rem;
	border-bottom: .08rem solid #fff;
	border-right: .08rem solid #fff;
	&.wallet-status {
		width: 13.2995706264%;
		padding-left: 2.7523945833%;
		display: flex;
		align-items: center;
		&.red{
		    & .status-icon{
		        background-image: url(/images/svg/status_red.svg);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(/images/svg/status_green.svg);
		    }
		}
		
		& .status-icon{
		    margin-left: 1.17rem;
            width: 1.3819275738rem;
            height: 1.3819275738rem;
            background-color: transparent;
            background-position: 0 0;
            background-size: cover;
		}
	}
	&.wallet-type {
		width: 20.1805570847%;
	}
	&.wallet-info {
		width: 20.1805570847%;
	}
	&.wallet-amount {
		width: 15.6005724981%;
		&.green{}
		&.red{}
	}
	&.wallet-date {
		width: 16.9767697897%;
		display: flex;
		justify-content: space-between;
	}
	&.wallet-id {
		flex: 1;
	}
}
`;

S.BetHistoryTransactions = styled.div`
`;

S.FakeTableHead = styled.div`
    background-color: ${AccountBetHistoryHeaderBackgroundColor};
    display: flex;
    height: ${rem('30px', baseFont)};
    border-radius: 4px 4px 0 0;
    font-family: ${FontFamily};
    align-items: center;
    
    & > div{
        border: none;
        font-size:  ${rem('13px', baseFont)};
        color: #fff;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: ${rem('10px', baseFont)};

           &.transaction-status{
               flex: 1 1 10%;
               border-right: none;
               padding-left: ${rem('20px', baseFont)};
           }
        
        &.transaction-odd{
                flex: 1 1 7%;
        }
        
        &.transaction-date-cashout,
        &.transaction-date{
              flex: 1 1 15%;
         }
         &.transaction-stake-cashout,
         &.transaction-stake{
               flex: 1 1 15%;
           }
         &.transaction-result{
                  flex: 1 1 15%;
           }
           &.transaction-typ{
              flex: 1 1 20%;
           }
           &.transaction-cashout{
               flex: 1 1 18%;
                display: flex;
                align-items: center;
                padding-right: 0;
                justify-content: center;
           }
    }
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
`;

S.BtnReset = styled(Button)`
    margin-top: .465rem;
    height: 2.4rem;
    background-color: #2d9ef5;
    font-size: 1rem;
    font-weight: 700;
    vertical-align: middle;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    border-radius: 4px;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
        
    & > span{
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
    }
`;

S.BtnRefresh = styled(S.BtnReset)`
    margin-left: 10px;
`;

export default S;