/* global app */
import React, { useEffect } from 'react'

const AccountDeposit = ({ toggleAccountModal }) => {
  // redirects and closes account modal #107929
  useEffect(()=> {
    toggleAccountModal()
    app.router.redirect('/wplata')
  },[])

  return(
    <></>
  )
}

export default AccountDeposit
