import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import _findIndex from 'lodash/findIndex';
import _each from 'lodash/each';
import _size from 'lodash/size';
import {USER_SIGNIN, USER_LOGOUT, USER_FROM_COOKIE, UPDATE_BALANCE, UPDATE_USER_BALANCE, USER_LOAD_DATA, BRANDS_DATA, TOGGLE_SESSION_EXTENSION_MODAL} from 'authActions.js';
import { processCustomerDepositData } from 'helpers/customerDataHelper.js';

let initialState = {
    isPending: false,
    isLogged: false,
    error: null,
    user: null,
    brandData: null,
    isOpenSessionExtensionModal: null
};

export default typeToReducer({
    [UPDATE_BALANCE]: (state, {payload: {balance}}) => {
        const user = update(state.user, {balance: {$set: balance}});
        return {...state, user};
    },
    [USER_LOGOUT]: (state, action) => {
        return {...state, isLogged: false, user: null, isOpenSessionExtensionModal: null}
    },
    [USER_SIGNIN]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {user}}) => {
            return {...state, isLogged: true, isPending: false, user};
        }
    },
    [USER_LOAD_DATA]: (state, {payload: {user}}) => {
        if(process.env.DEPOSIT_COUNTER_COOKIE_ENABLED && JSON.parse(process.env.DEPOSIT_COUNTER_COOKIE_ENABLED) && user.depositInfo) {
          processCustomerDepositData(user);
        }
        return {...state, isLogged: true, user};
    },
    [USER_FROM_COOKIE]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {user}}) => {
            return {...state, isLogged: true, isPending: false, user};
        }
    },
    [UPDATE_USER_BALANCE]: (state, {payload:{data:{newBalance, bonusBalanceList = [], creditBonusBalance}}}) => {
        let user = update(state.user, {balance: {$set: newBalance}});

        if (_size(bonusBalanceList) && ('bonusBalanceList' in user) && _size(user['bonusBalanceList'])) {
            _each(bonusBalanceList, ({wagerBonusId, amount}) => {
                wagerBonusId = Number(wagerBonusId);
                const indexOfItem = _findIndex(user.bonusBalanceList, {wagerBonusId});
                if (indexOfItem != -1) {
                    user = update(user, {bonusBalanceList: {[indexOfItem]: {amount: {$set: amount}}}})
                }
            });

        }

        if(creditBonusBalance){
          user = update(user, {creditBonusBalance: {$set: creditBonusBalance}});
        }
        return {...state, user}
    },
    [BRANDS_DATA] : {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {brandData}}) => {
            return {...state, isPending: false, brandData};
        }
    },
    [TOGGLE_SESSION_EXTENSION_MODAL]: (state, {payload: {isOpenSessionExtensionModal}}) => {
        return {...state, isOpenSessionExtensionModal};
    },
}, initialState);


