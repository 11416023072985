import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {rem} from 'polished';

const FontFamily = ({theme:{$fontFamily}}) => `${$fontFamily}`;
const baseFont = `12px`;
const S = {};

S.LeftMenu = styled.div`
    float: left;
    width: 100%;
`;

S.DropdownMenuWrapper = styled.div`
    position: relative;
    float: left;
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    
    & ${StyledLoader.LoaderWrapper}{}
`;

S.Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem('19px', baseFont)};
  height: ${rem('50px', baseFont)};
  background-color: #001A5B;
  border-radius: 5px 5px 0px 0px;
`;

S.HeaderText = styled.div`
  color: #fff;
  font-size: ${rem('15px', baseFont)};
  font-family: ${FontFamily};
`;

S.LeftMenuButton = styled.button`
padding: 0;
border: 0;
`

export default S;