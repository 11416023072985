import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation, formatMoney} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import S from 'StyledAccountHistoryBonus.js';
import Pagination from 'Pagination.js';
import {
    setCurrentPage,
    fetchCreditBonusListByFilter,
    fetchCreditBonusListPending,
    fetchCreditBonusListFulfilled,
    fetchCreditBonusListFailure
} from 'accountBonusActions.js';

class AccountHistoricalBonus extends Component {

    componentDidMount = async() => {
        const {fetchCreditBonusListPending, fetchCreditBonusListByFilter, fetchCreditBonusListFailure, fetchCreditBonusListFulfilled, startDate, endDate} = this.props;

        fetchCreditBonusListPending();

        try {
            const data = await fetchCreditBonusListByFilter({status: 'HISTORY', startDate, endDate});
            fetchCreditBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountHistoricalBonus:', error);
            fetchCreditBonusListFailure(error)
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchCreditBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage,
            status
        };

        try {
            await fetchCreditBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    render() {

        const {isPending, error, bonus, currencyCode, limit} = this.props;
        const historyBonus = _get(bonus, 'history');
        const historyBonusData = _get(historyBonus, 'data');

        return (
            <S.AccountHistoryBonus className="account-history-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                            <S.BonusListWrapper>
                                {_size(historyBonusData) ?
                                    (
                                        <>
                                        <S.BonusList>
                                            {_map(historyBonusData, (bonus)=>{

                                                const {offerId, offerName, nameKey, assignedBonusAmount, validTo, activationTime, offerPlayerInfo={}} = bonus;
                                                const {imageUrl, rulesPageUrl} = offerPlayerInfo;
                                                const bonusItem = (

                                                    <S.BonusItem key={offerId}>

                                                        <S.BonusHeader>
                                                            <S.BonusTitle data-translation={nameKey}>
                                                                {translation(nameKey)}
                                                            </S.BonusTitle>
                                                        </S.BonusHeader>

                                                        <S.BonusImageWrapper ratio={2}>
                                                            {imageUrl && <S.BonusImage src={`${imageUrl}`}/>}
                                                        </S.BonusImageWrapper>

                                                        <S.BonusBody>

                                                            <S.BonusBox>
                                                                <S.BonusBoxTitle>
                                                                    {translation('account_bonus_activationTime')}
                                                                </S.BonusBoxTitle>
                                                                <S.BonusBoxValue>
                                                                    {formatDate(activationTime, 'yyyy-MM-dd HH:mm')}
                                                                </S.BonusBoxValue>
                                                            </S.BonusBox>

                                                            <S.BonusBox>
                                                                <S.BonusBoxTitle>
                                                                    {translation('account_bonus_amount')}
                                                                </S.BonusBoxTitle>
                                                                <S.BonusBoxValue>
                                                                    {formatMoney(assignedBonusAmount)}&nbsp;{currencyCode}
                                                                </S.BonusBoxValue>
                                                            </S.BonusBox>

                                                            <S.BonusBox>
                                                                <S.BonusBoxTitle>
                                                                    {translation('account_bonus_validToDate')}
                                                                </S.BonusBoxTitle>
                                                                <S.BonusBoxValue>
                                                                    {formatDate(validTo, 'yyyy-MM-dd HH:mm')}
                                                                </S.BonusBoxValue>
                                                            </S.BonusBox>

                                                        </S.BonusBody>

                                                        <S.BonusFooter>
                                                            <S.BonusRegulationLink href={(rulesPageUrl?rulesPageUrl:'/')} target="_blank">
                                                                {translation('account_bonus_regulationLink')}
                                                            </S.BonusRegulationLink>
                                                        </S.BonusFooter>

                                                    </S.BonusItem>
                                                );
                                                return bonusItem;
                                            })}
                                        </S.BonusList>

                                        {_size(historyBonusData) && (
                                                    <Pagination currentPage={_get(historyBonus, 'currentPage')}
                                                                limit={limit}
                                                                data={historyBonusData}
                                                                cb={this.goPage.bind(this, 'history')}/>
                                        )}
                                    </>
                                    )
                                    :
                                    <S.NoResult>
                                        {translation('account_bonus_notFound')}
                                    </S.NoResult>
                                }

                            </S.BonusListWrapper>

                        </>
                    )
                }

            </S.AccountHistoryBonus>
        );
    }
}


const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchCreditBonusListByFilter: bindActionCreators(fetchCreditBonusListByFilter, dispatch),
        fetchCreditBonusListPending: bindActionCreators(fetchCreditBonusListPending, dispatch),
        fetchCreditBonusListFulfilled: bindActionCreators(fetchCreditBonusListFulfilled, dispatch),
        fetchCreditBonusListFailure: bindActionCreators(fetchCreditBonusListFailure, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHistoricalBonus);
