import {createSelector} from 'reselect';
import _filter from 'lodash/filter';

const categoriesByTime = state => state.Categories.categoriesByTime;
const time = state => state.Categories.time;
const categories = state => state.Categories.categories;
const favouriteLeagues = state => state.Favourites.favouriteLeagues;
const favouriteLeaguesSelector = createSelector(
    categories,
    favouriteLeagues,
    categoriesByTime,
    time,
    (categories, favouriteLeagues, categoriesByTime, time) => {
        const categoriesToFilter = time ? categoriesByTime[time] : categories;
        return _filter(categoriesToFilter, ({categoryId}) => {
            return [].concat(favouriteLeagues).indexOf(categoryId) != -1;
        });
    }
)

export {favouriteLeaguesSelector}