import styled from 'styled-components'
import { rem } from "polished"

const FontFamily = ({ theme: { $fontFamily } }) => `${$fontFamily}`
const FwMedium = ({ theme: { $fwMedium } }) => `${$fwMedium}`;

const AccountWithdrawTitleColor = ({ theme: { $accountWalletTitleColor } }) => `${$accountWalletTitleColor}`
const AccountWithdrawTitleBorderColor = ({ theme: { $accountWalletTitleBorderColor } }) => `${$accountWalletTitleBorderColor}`

const baseFont = `12px`
const S = {}

S.AccountWithdraw = styled.div`
    width: 100%;
    padding: ${rem('30px', baseFont)};
`

S.WithdrawTitle = styled.div`
  font-size: ${rem('20px', baseFont)};
  text-transform: uppercase;
  padding-bottom: ${rem('30px', baseFont)};
  margin-bottom: ${rem('20px', baseFont)};
  font-family: ${FontFamily};
  font-weight: ${FwMedium};
  border-bottom: 1px solid ${AccountWithdrawTitleBorderColor};
  color: ${AccountWithdrawTitleColor};
`

export default S
