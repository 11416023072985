import styled from "styled-components";
import {Button, Input,Form} from 'global/styles/styles.js';
import Loader from "Loader.js";
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const baseFont = `12px`;
const S = {};

S.QuickSearchWrapper = styled.div`
    position: relative;
    height: ${rem('40px', baseFont)};
    display: flex;
    width: 100%;
    margin: 0 auto ${rem('20px', baseFont)};
`;

S.QuickSearch = styled(Form)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    align-self: center; 
`;

S.QuickSearchInput = styled(Input)`
    font-family: ${FontFamily};
    font-weight: 400;
    color: #C0C3CA;
    border: 1px solid #BAC1D0; 
    border-radius: ${rem('20px', baseFont)};
    padding: 0 ${rem('30px', baseFont)} 0 ${rem('50px', baseFont)};
    height: 100%;
    flex-grow: 1;
    font-size:  ${rem('13px', baseFont)};
    width: 100%;
    &::placeholder {
        color: #C0C3CA;
        text-transform: uppercase;
    }
     &:-webkit-autofill {
                background-color: #fff;
                -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                }
     }
`;

S.SubmitButton = styled(Button)`
    background: #fff url(/images/search_icon.png) no-repeat 0 50%;
    width: 17px;
    height: 17px;
    padding: 0;
    position: absolute;
    left: ${rem('20px', baseFont)};
    top: 0;
    bottom: 0;
    margin: auto 0;
    & svg{
      display: none;
    }
`;

S.ClearResults = styled(Button)`
    background-image: url(/images/svg/close_yellow.svg);
    background-repeat: no-repeat;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 10px;
    background-color: transparent;
`;

S.ResultsBox = styled.ul`
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    top: 42px;
    background: #fff;
    list-style: none;
    padding: 0rem;
    z-index: 1;
    ${props => props.center &&`
       text-align: center;
    `}
`;

S.ResultItem = styled.li`
    padding: 0.5rem 0 0.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
        background: #e3f4ff;
    }

    .highlight {
        background-color: transparent;
        font-weight: bold;
    }
`;

S.Loader = styled(Loader)`
    position: absolute;
    right: 5px;
`;

export default S;
