import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _size from 'lodash/size';
import {fetchCategories, selectLeagueById, deselectLeagueById, changeSportId, fetchPopularCategories,toggleSelectedLeague, toggleSelectedLeaguesForSports, selectThirdLevelCategory} from 'categoriesActions.js';
import {dropdownMenuSelector} from 'categoriesSelector.js';
import {favouriteLeaguesSelector} from 'favouriteLeaguesSelector.js';
import {toggleFavouriteForLeague, getFavouriteLeagues} from 'favouritesActions.js';
import {translation, redirect} from 'utilsHelper.js';
import CmsPage from 'CmsPage.js';
import DropdownMenu from 'DropdownMenu.js';
import PopularCategories from 'PopularCategories.js';
import QuickSearch from "QuickSearch.js";
import S from 'StyledLeftMenu.js';;


class LeftMenu extends Component {

    static propTypes = {};

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidMount = () => {
        const {dispatch} = this.props;
        try {
           const { fetchCategories, categories} = this.props;
           if(!_size(categories)){
               fetchCategories();
           }

           if (location.pathname && /\/zaklady-bukmacherskie\/.+(?:,.+)*\/.+(?:,.+)*\/.+(?:,.+)*\/\d+(,\d+)*\/.*/u.test(location.pathname)) {
            let categoriesFromPath = location.pathname.replace(/\/zaklady-bukmacherskie\/.+(?:,.+)*\/.+(?:,.+)*\/.+(?:,.+)*\/(\d+(,\d+)*)\/.*/u, '$1');

            categoriesFromPath = String(categoriesFromPath).split(',');

            const favouriteLeaguesGroupBySport = _reduce(categoriesFromPath, (initialArray, leagueId) => {

                leagueId = Number(leagueId);
                const category = _find(categories, {categoryId: leagueId});
                const sportId = _get(category, 'sportId');

                const leaguesIdsMappedToSportExist = _find(initialArray, {sportId});
                if (leaguesIdsMappedToSportExist) {
                    const leaguesFromSport = _get(leaguesIdsMappedToSportExist, 'leagues');
                    leaguesFromSport.push(leagueId);
                } else {
                    initialArray.push({sportId, leagues: [].concat(leagueId)})
                }
                return initialArray;
            }, []);

            dispatch(toggleSelectedLeaguesForSports(favouriteLeaguesGroupBySport));

            console.log('dispatch toggleSelectedLeaguesForSports');

            try {
                Promise.all([
                    waitUntilComponentRendered(app.component.EventsList),
                    waitUntilComponentRendered(app.component.SportCategoryFilter)
                ]).then(([eventListComponent, sportCategoryFilterComponent]) => {

                    const waitUntilRequestFullfilled = (component) => {
                        var d = $.Deferred();
                        var isRendered = () => {
                            if (!component.dataLoaded) {
                                setTimeout(isRendered, 100);
                            } else {
                                d.resolve(component);
                            }
                        };
                        isRendered();
                        return d.promise();
                    };

                    waitUntilRequestFullfilled(app.component.EventsList).then(() => {
                        console.log('component method loaded');
                        this.dispatchCustomEvent(eventListComponent, sportCategoryFilterComponent);
                    })

                });

            } catch (e) {
                console.log(e.message);
            }
        }
        } catch (error) {
            console.log('fetchCategories error:', error);
        }

    };

    /**
    * Function to handle smartico iframe. If the user is logged in, it should return a smartico widget, otherwise it should open the login module.
    * @param {isLogged} - boolean if user is logged in
    */
         handleSmarticoIframe = (isLogged) => {
            const {dispatch} = this.props;
            if(isLogged && app.config.smarticoIsEnabled){
                window._smartico.dp('dp:gf');
            } else {
                dispatch({ type: 'TOGGLE_BY_KEY', payload: { key: 'TOGGLE_OPEN_LOGIN_MODAL', isOpen: true, fromTab: 'missions' } })
            }
        }

    render() {
        const {isPending, categories, fetchPopularCategories, popularCategories, isPopularCategoriesPending, ...rest} = this.props;
        const {toggleFavouriteForLeague, favouriteLeagues, isLogged, orgCategories, sportsOrder, outrights, getFavouriteLeagues} = rest;
        const popularCategoriesProps = {
            fetchPopularCategories,
            popularCategories,
            isPopularCategoriesPending
        };
        const favouriteLeaguesProps = {
            favouriteLeagues,
            getFavouriteLeagues,
            orgCategories,
            outrights,
            sportsOrder,
            toggleFavouriteForLeague,
            toggleSelectedLeague,
            toggleSelectedLeaguesForSports,
        };
        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }
        if (isPending) {
            return <div>Loading ...</div>
        }


        return (
            <S.LeftMenu className="left-menu">

                <QuickSearch/>
            
            {app.config.smarticoIsEnabled && !app.config.smarticoOnlyInit  ? 
                <S.LeftMenuButton onClick={() => this.handleSmarticoIframe(isLogged)}>
                    <CmsPage name="left-banner-place" isPlaces={true} />
                </S.LeftMenuButton> 
                :
                <CmsPage name="left-banner-place" isPlaces={true} />
            }
       
                <PopularCategories {...popularCategoriesProps}/>

                <S.Header>
                    <S.HeaderText>{translation('all_sports_header')}</S.HeaderText>
                </S.Header>
                {categories.length && <DropdownMenu config={categories} {...rest} />}

            </S.LeftMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Auth:{isLogged}, Categories:{thirdLevelSelectedLeague, isPending, error, selectedLeagues, prevSortId, categories:orgCategories, isEsport, time, categoriesByTime, selectedLeaguesBySport, sportsOrder, outrights}, PopularCategories:{popularCategories, isPending:isPopularCategoriesPending}} = state;
    const categories = dropdownMenuSelector(state, props);
    const favouriteLeagues = favouriteLeaguesSelector(state, props);

    return {
        categories,
        categoriesByTime,
        error,
        favouriteLeagues,
        isEsport,
        isLogged,
        isPending,
        isPopularCategoriesPending,
        orgCategories,
        outrights,
        popularCategories,
        prevSortId,
        selectedLeagues,
        selectedLeaguesBySport,
        sportsOrder,
        time,
        thirdLevelSelectedLeague
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectLeagueById: bindActionCreators(selectLeagueById, dispatch),
        deselectLeagueById: bindActionCreators(deselectLeagueById, dispatch),
        changeSportId: bindActionCreators(changeSportId, dispatch),
        fetchCategories: bindActionCreators(fetchCategories, dispatch),
        fetchPopularCategories: bindActionCreators(fetchPopularCategories, dispatch),
        toggleSelectedLeague: bindActionCreators(toggleSelectedLeague, dispatch),
        toggleSelectedLeaguesForSports: bindActionCreators(toggleSelectedLeaguesForSports, dispatch),
        selectThirdLevelCategory: bindActionCreators(selectThirdLevelCategory, dispatch),
        toggleFavouriteForLeague: (categoryId, status, e) => {
            e.stopPropagation();
            dispatch(toggleFavouriteForLeague(categoryId, status));
        },
        // getFavouriteLeagues: bindActionCreators(getFavouriteLeagues, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
