import React from 'react';
import Modal from 'Modal.js';
import S from 'StyledModal.js';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';

const duration = 300;
const ModalWithOpacityStyles = styled(Modal)`
  &.modal-opacity-enter {
    opacity: 0.01;
  }
  &.modal-opacity-enter-active {
   transition: opacity ${duration}ms;
   opacity: 1;
  }
  &.modal-opacity-exit {
    opacity: 1;
  }
  &.modal-opacity-exit-active {
    opacity: 0.01;
    transition: opacity ${duration}ms ease-in;
  }
  & ${S.ModalBody}{
    padding: 0;
    height: 100%;
  }
  & ${S.ModalHeader}{
    padding: 0;
    margin: 0;
  }
  & ${S.ModalWrapper}{
    padding: 0;
    border-radius: 0.42rem;
    margin: 5rem;
    height: 90vh;
    background-color: #fff;
    min-width: 1024px;
    overflow: visible;
    width: 100%;
    max-width: 1200px;
  }
`;

const AccountModal = ({isOpen, toggleOpen, children}) => {
    return (
        <>
        <CSSTransition in={isOpen}
                       className="modal-transition"
                       classNames="modal-transition"
                       unmountOnExit
                       timeout={duration}>

            <ModalWithOpacityStyles
                showHeaderIcon={false}
                showCloseIcon={true}
                showHeaderTitle={false}
                open={isOpen}
                onClose={toggleOpen}>
                {children}
            </ModalWithOpacityStyles>

        </CSSTransition>
        </>
    );
};

export default AccountModal;