import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$registerFormSubmitBtnBgColor}}) => `${$registerFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$registerFormSubmitBtnColor}}) => `${$registerFormSubmitBtnColor}`;

const baseFont = `12px`;

const S = {};

S.RegisterWizardFormStep2 = styled(Form)` 
    position: relative;
    width: ${rem('580px', baseFont)};
    font-family: "Roboto Condensed", sans-serif;
    padding: 0 0 ${rem('20px', baseFont)};
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;


S.Row = styled.div`
    width: 100%;
`;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 1.4rem;
    left: 1.8rem;
    color: #787d83!important;
    font-size: 1.33rem;
    font-weight: 400;
`;

S.FakeChbox = styled.div`
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    margin-right: .83rem;
    cursor: pointer;
    margin-top: 2px;
    flex-shrink: 0;
    pointer-events: none;
    z-index: 3;
    background-color: #FCFCFC;
    position: relative;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    font-weight: bold;
    color: #787d83;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: #262626;
    font-size:  ${rem('14px', baseFont)};
    padding: 2.1rem 1.75rem;
    background-color: #FCFCFC;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    display: block;
    width: 100%;
    height: ${rem('54px', baseFont)};
    &:valid{
       & + ${S.Label}{
         margin-top: ${rem('-14px', baseFont)};
         font-size:  ${rem('12px', baseFont)};
       }
    }
    &:-webkit-autofill {
                background-color: #FCFCFC;
                -webkit-box-shadow: 0 0 0rem 83.33rem #FCFCFC inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #FCFCFC inset;
                }
     }
     
    &.has-error{
        border: .08rem solid red;
    }
    &.is-valid{
        border: .08rem solid #63dd7d;
    }
`;

S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('44px', baseFont)};
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: ${rem('125px', baseFont)};
    margin-bottom: 2.18rem;
    margin-top: .69rem;
    color: ${SubmitBtnColor};
    border: 2px solid #CE0006;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: ${rem('14px', baseFont)};
    cursor: pointer;
    &:hover{
        color: #fff;
        background-color: #CE0006;
    }
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
    margin-left: ${rem('15px', baseFont)};
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #bebebe;
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 2rem;
    cursor: pointer;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.SubmissionSuccess = styled(S.SubmissionError)`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
`;

S.Datepicker = styled.div`
    box-shadow: none;
    color: #262626;
    font-size: 1.1666666666666667rem !important;
    padding: 2.1rem 1.75rem;
    background-color: #FCFCFC;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    display: block;
    width: 100%;
    height: 100%;
    font-weight: 400 !important;
`;

S.Select = styled.div`
    cursor: pointer;
    align-items: stretch;
    display: block;

    & .react-select__menu{
        margin-top: 0;
        z-index: 5;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
            &--is-multi {
                .react-select__option {
                    color: #777D85;
                    &--is-focused {
                       background-color: #E8E8E8;
                    }
                    &--is-selected {
                        background-color: inherit;
                        font-weight: 600;
                        color: #262626;
                        
                        &.react-select__option {
                            &--is-focused {
                                background-color: #E8E8E8;
                            }
                        }
                    }
                }                
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 4.5rem;
        border: 1px solid #E8E8E8;
        align-items: flex-start;
        background-color: #FCFCFC;
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
        
        & .react-select__clear-indicator{
            display: none;
        }
        
        & .react-select__indicator-separator{
            display: none;
        }
        
        &--is-focused {
            border-color: #E8E8E8;
        }
    
        & .react-select__value-container{
             margin-top: ${rem('16px', baseFont)};
             margin-left: ${rem('11px', baseFont)};
             margin-right: ${rem('15px', baseFont)};
            & .react-select__single-value{
                color: #262626;
                font-size: 1.1666666666666667rem;
            }
            &--is-multi {
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                display: initial;
            }
        }
    
    }

`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
   
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
       
    }
    
    &.with-datepicker{
    
        ${S.Label}{
             margin-top: ${rem('-14px', baseFont)};
             font-size:  ${rem('12px', baseFont)};
             z-index:1;
            
            
            }
    
    & .react-datepicker-wrapper{
  width: 100%;
}

& .react-datepicker-popper{
    z-index: 3;
}
 
        & .react-datepicker__input-container{
            height: 4.5rem;
        border: 1px solid #E8E8E8;
        }
    
    }
    &.with-select{
            ${S.Label}{
             margin-top: ${rem('-14px', baseFont)};
             font-size:  ${rem('12px', baseFont)};
             z-index:1;
            }
      }
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
      ${S.Label}{
            flex: 1;
            font-size: 1.17rem;
            font-weight: 400;
            padding-top: 0;
            color: #787d83;
            display: flex;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
            
                            align-items: flex-end;
            
            & > span,
            & a{
              color: #CE0006;
            }
            
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 1px;
            height: 14px;
            width: auto;
         }
    }
`;



S.Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${rem('35px', baseFont)};
  height:  ${rem('80px', baseFont)};
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 ${rem('9px', baseFont)} ${rem('87px', baseFont)};
`;

S.Title = styled.h2`
   margin: 0;
   color: #262626;
    font-size: ${rem('24px', baseFont)}
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;

S.Body = styled.div`
 padding: 0 ${rem('66px', baseFont)};
`;

S.Footer = styled.div``;


S.HelpBtn = styled.div`
    line-height: 1;
    color: #777D85;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${rem('20px', baseFont)};
`;

S.HelpIcon = styled.div`
    cursor: pointer;
    width: ${rem('19px', baseFont)};
    height: ${rem('19px', baseFont)};
    background-color: #CBD0D6;
    border-radius: 50%;
    display: flex;
    color: #fff;
    margin-right: ${rem('10px', baseFont)};
    justify-content: center;
    align-items: center;
    
    &:before{
     background-color: transparent;
    }
`;

S.PrevStepBtn = styled(Button)`
    display:flex;
    background-color: transparent;
    color: #777D85;
    align-items: center;
    margin-left: ${rem('10px', baseFont)};
`;


S.PrevIcon = styled.div`
    justify-content: center;
   align-items: center;
      cursor: pointer;
    width: ${rem('19px', baseFont)};
    height: ${rem('19px', baseFont)};
    background-color: #CBD0D6;
     margin-right: ${rem('10px', baseFont)};
      border-radius: 50%;
    display: flex;
    color: #fff;
    line-height: 1;
`;


export default S;
