import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadPersonalData, updatePersonalData} from 'customerActions.js';
import {changePassword} from 'authActions.js';
import S from 'StyledAccountEditProfile.js';
import ChangePasswordForm from 'ChangePasswordForm.js';
import ModalOpacity from 'ModalOpacity.js';
import _find from "lodash/find";
import _get from "lodash/get";
import {translation} from 'utilsHelper.js';

class AccountEditProfile extends Component {

    state = {
        showChangePasswordModal: false
    };

    toggleChangePasswordModal = (isOpen) => {
        this.setState((prevState) => {
            return {...prevState, showChangePasswordModal: isOpen}
        });
    };

    showChangePasswordModal = () => {
        const {dispatch} = this.props;
        dispatch({type: 'TOGGLE_BY_KEY', payload: {key:'TOGGLE_OPEN_CHANGE_PASSWORD_MODAL', isOpen: true}});
    };

    hideChangePasswordModal = () => {
        const {dispatch} = this.props;
        this.toggleChangePasswordModal(false);
        dispatch({type: 'TOGGLE_BY_KEY', payload: {key: 'TOGGLE_OPEN_CHANGE_PASSWORD_MODAL', isOpen: false}});
    };

    changePasswordOnSubmit = async(values) => {
        const {changePassword} = this.props;

        try {
            const result = await changePassword(values);
            return result;
        } catch (error) {
            throw error;
        }
    };

    componentDidUpdate = (prevProps) => {
        if ((this.props.isChangePasswordModalOpen != prevProps.isChangePasswordModalOpen) && this.props.isChangePasswordModalOpen) {
            this.toggleChangePasswordModal(true);
        }
    };

    render() {
        const {userData,  userId} = this.props;
        const {showChangePasswordModal} = this.state;

        return (
            <S.AccountEditProfile>

                <ModalOpacity isOpen={showChangePasswordModal}
                              toggleOpen={this.hideChangePasswordModal}
                              title={translation('account_editProfileForm_changePassword_formHeader')}
                              showHeader={false}
                              padding={0}>

                    <ChangePasswordForm changePasswordOnSubmit={this.changePasswordOnSubmit} toggleOpen={this.hideChangePasswordModal} userId={userId}/>

                </ModalOpacity>

                <S.AccountEditProfileTitle>
                    {translation('account_editProfile_title')}
                </S.AccountEditProfileTitle>

                <S.AccountEditProfileIframe src={`${process.env.REGISTRATION_URL}/profil`} frameBorder="0"/>

            </S.AccountEditProfile>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Customer:{userData, error, isPending}, Auth:{user:{userId}}, Toggle:{toggleMap}} = state;
    const toggleChangePasswordModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_CHANGE_PASSWORD_MODAL'});
    const isChangePasswordModalOpen = _get(toggleChangePasswordModalObject, ['isOpen']);

    return {
        isChangePasswordModalOpen,
        userData,
        error,
        isPending,
        userId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadPersonalData: bindActionCreators(loadPersonalData, dispatch),
        updatePersonalData: bindActionCreators(updatePersonalData, dispatch),
        changePassword: bindActionCreators(changePassword, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditProfile);
