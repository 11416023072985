import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.UnloggedPanel = styled.div`
    display: flex;
    margin-left: auto;
    font-family:  ${({theme:{$fontFamily}})=> $fontFamily};
    align-items: center;
`;

S.Button = styled.a`
    font-family:  ${({theme:{$fontFamilyMulish}})=> $fontFamilyMulish};
    transition: .2s all linear;
    color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    font-size: ${rem('15px', baseFont)};
    font-weight: 700;
    line-height: 1;
    border-radius: ${rem('24px', baseFont)};
    padding: 0;
    width: ${rem('184px', baseFont)};
    text-align: center;
    border: none;
    background: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    &:link,
    &:visited,
    &:hover,
    &:active{
      text-decoration: none;
      color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    }
    
    // login
    &:nth-of-type(1){
       padding: 0;
       margin-right: ${rem('10px', baseFont)};
       background-color: ${({theme:{$topMenuUnloggedLoginBtnBgColor}})=> $topMenuUnloggedLoginBtnBgColor};
       
    ${({theme:{mediumRWD}})=> mediumRWD}{
      margin-right: ${rem('10px', baseFont)};
    }
    ${({theme:{normalRWD}})=> normalRWD}{
        margin-right: ${rem('10px', baseFont)};
    }
    ${({theme:{bigRWD}})=> bigRWD}{
        margin-right: ${rem('10px', baseFont)};
    }
    ${({theme:{largeRWD}})=> largeRWD}{
         margin-right: ${rem('20px', baseFont)};
    }
       
    }
    &:nth-of-type(2){
      background-color: ${({theme:{$topMenuUnloggedRegisterBtnBgColor}})=> $topMenuUnloggedRegisterBtnBgColor};
    }
    
    ${({theme:{mediumRWD}})=> mediumRWD}{
       width: ${rem('125px', baseFont)};
    }
    ${({theme:{normalRWD}})=> normalRWD}{
        width: ${rem('125px', baseFont)};
    }
    ${({theme:{bigRWD}})=> bigRWD}{
        width: ${rem('125px', baseFont)};
    }
    ${({theme:{largeRWD}})=> largeRWD}{
        width: ${rem('140px', baseFont)};
    }
    
`;

S.ButtonsWrapper = styled.div`
    display: flex;
    height:  ${rem('50px', baseFont)};
`;

S.ConfirmationModal = styled.div`
   display: flex;
   flex-direction: column;
   padding: ${rem('40px', baseFont)};
`;

S.ConfirmationMsg = styled.div`
   font-size: ${rem('20px', baseFont)};
`;

S.ConfirmationCloseBtn = styled(Button)`
    background-color: #CE0006;
    height: ${rem('44px', baseFont)};
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: ${rem('125px', baseFont)};
    margin-bottom: 0;
    margin-top: 1.5rem;
    color: #fff;
    cursor: pointer;
    border: 2px solid #CE0006;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

S.CloseButton = styled(Button)`
    position: absolute;
    right: -40px;
    top: -40px;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;
    transform: none;
    line-height: 1;
    margin-right: -3rem;
    margin-top: -3rem;
    border-radius: 50%;
    bottom: 0;
    margin: .83rem 0;
    background: #fff url(/images/svg/header_close_black.svg) no-repeat 50% 50%;
    background-size: 1.47rem 1.47rem;
    cursor: pointer;
`;

export default S;