import styled from 'styled-components';
import {rem} from 'polished';
import {Form, Input, Button} from 'global/styles/styles.js';

const SubmitBtnBgColor = ({theme:{$loginFormSubmitBtnBgColor}}) => `${$loginFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$loginFormSubmitBtnColor}}) => `${$loginFormSubmitBtnColor}`;
const FontFamilyMulish  = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;

const baseFont = `12px`;
const S = {};

S.LoginForm = styled(Form)`
    width: ${rem('520px', baseFont)};
    font-family: ${FontFamilyMulish};
`;

S.Row = styled.div``;


S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: ${rem('10px', baseFont)};
`;

S.Input = styled(Input)`
font-weight: 400;
    box-shadow: none;
    color: #001A5B;
    font-size:  ${rem('15px', baseFont)};
    padding: 2.1rem 1.75rem;
    background-color: #fff;
    border: none;
     border-bottom: 2px solid #BAC1D0;
    border-radius: 0;
    display: block;
    width: 100%;
    height: ${rem('54px', baseFont)};
    text-align: center;
    &::placeholder{
      color: rgba(0, 26, 91, 0.5);
    }
    
    ${({dirty})=>{
     if(dirty==true){
         return `
               color: #001A5B;
                border-bottom: 2px solid #016CD9;
         `;
     }    
    }}

     &:-webkit-autofill {
                color: #001A5B;
                border-bottom: 2px solid #016CD9;
                background-color: #fff;
                -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                }
     }
    
`;

S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('50px', baseFont)};
    font-size: ${rem('15px', baseFont)};
    font-weight: 700;
    padding: 0;
    width: ${rem('206px', baseFont)};
    margin-bottom: 0;
    margin-top: ${rem('44px', baseFont)};
    color: ${SubmitBtnColor};
    cursor: pointer;
    border: none;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

S.Error = styled.div`
    color: red;
    margin-top: 5px;
    margin-left: 0;
`;

S.RememberMe = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.RememberMeText = styled.div`
  font-size: ${rem('16px', baseFont)};
  color: #000;
`;

S.RememberMeChbox = styled(Input)`
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  &:checked + ${S.RememberMeFakeChbox}{
    &:before{
     position: absolute;
     content: '\\002714';
     margin-left: 2px;
     color: #000;
     font-size: ${rem('14px', baseFont)};
    }
  }
`;

S.RememberMeFakeChbox = styled.div`
  position: relative;
  margin-right: ${rem('8px', baseFont)};
  width: ${rem('22px', baseFont)};
  height: ${rem('22px', baseFont)};
  border: 2px solid #ECEFF1;
  border-radius: 3px;
  cursor: pointer;
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 600;
    font-size: ${rem('13px', baseFont)};
    line-height: 1;
    color: #001A5B;
    display: block;
    text-align: right;
    border: none;
    padding: 0;
    width: 100%;
    cursor: pointer;
    text-decoration: underline;
    margin-top: ${rem('42px', baseFont)};
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('10px', baseFont)};
`;


S.Body = styled.div`
      padding: ${rem('20px', baseFont)} ${rem('50px', baseFont)} ${rem('50px', baseFont)};;
`;

S.Header = styled.div`
position: relative;
  display: flex;
  margin: 0 ;
  height:  ${rem('128px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
  padding: 0 ;
  justify-content: center;
  align-items: center;
  background-color: #F4F7FC;
  border-radius: 5px 5px 0 0;
`;

S.Title = styled.div`
 margin: 0;
   color: #001A5B;
    font-size: ${rem('30px', baseFont)}
    padding: 0;
    font-weight: 600;
    text-align: center;
    font-family: ${FontFamily};
    text-transform: uppercase;
`;

S.CloseButton = styled.img`
    position: absolute;
    width: 2.9166666666666665rem;
    height: 2.9166666666666665rem;
    cursor: pointer;
    right: -18px;
    top: -18px;
    z-index: 1;
`;

S.Footer = styled.div``;

export default S;