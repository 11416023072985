import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from "polished";

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.AccountHistoryBonus = styled.div`
    width: 100%;
`;

S.BonusListWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: ${rem('20px', baseFont)};
`;

S.BonusImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${p => `calc(100% / ${p.ratio})`};
  background: #001A5B;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

S.BonusImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
`;

S.BonusList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem('-25px', baseFont)};
`;

S.BonusItem = styled.div`
   width: calc(50% - ${rem('25px', baseFont)});
   margin-right: ${rem('25px', baseFont)};
   margin-bottom: ${rem('25px', baseFont)};
   position: relative;
`;

S.BonusHeader = styled.div`
  padding-bottom: ${rem('15px', baseFont)};
`;

S.BonusTitle = styled.div`
  font-size:  ${rem('13px', baseFont)};
  font-family: ${FontFamily};
  font-weight: 400;
`;

S.BonusBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${rem('60px', baseFont)};
  background-color: #F4F7FC;
  border-radius: 0 0 10px 10px;
`;

S.BonusBox =  styled.div`
  width: calc(100% / 3);
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

S.BonusBoxTitle =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('11px', baseFont)};
  color: #001A5B;
  width: 100%;
  padding: 0 ${rem('5px', baseFont)};
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
 text-align: center;
  font-weight: 400;
`;

S.BonusBoxValue =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  color: #001A5B;
    width: 100%;
      padding: 0 ${rem('5px', baseFont)};
       white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
  text-align: center;
  font-weight: 700;
`;

S.NoResult = styled.div`
    height: 40px;
    text-align: center;
`;


S.NoResult = styled.div`
    height: 40px;
    text-align: center;
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
`;

S.ActivateModal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: -30px;
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

S.ButtonCancel = styled(Button)`
    color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    padding: 6px 12px;
    background: #00a651;
    margin-right: 1rem;
    margin-top: 1rem;
`;

S.ButtonAccept = styled(S.ButtonCancel)``;

S.Title = styled.div`
    font-size: 1.2307692308rem;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
`;

S.Description = styled.div`
    text-align: center;
`;

S.ProgressCircularWrapper = styled.div`
    width: 50%;
    margin: 0 auto;
    padding: 5px;
`;

S.BonusFooter = styled.div`
  text-align: center;
  padding-top: ${rem('12px', baseFont)};
`;

S.BonusRegulationLink = styled.a`
  font-size: ${rem('11px', baseFont)};
  color: #BAC1D0;
  text-decoration: underline;
   font-family: ${FontFamilyMulish};
   font-weight: 400;
`;

export default S;