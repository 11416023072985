import React, { useMemo } from 'react'
import { translation } from 'helpers/utilsHelper.js'
import Virtuals from 'sb-virtuals'
import LayoutVfec from '../layouts/LayoutVfec'

const Vfec = () => {
  const vfec = useMemo(() => ({
    url: process.env.VIRTUALS_VFEC_URL,
    groups: [
      {
        header: translation('virtuals_vfec_matches'),
        groups: [
          {
            header: translation('virtuals_vfec_matches_result'),
            outcomes: ['1', 'X', '2'],
            accessor: 1,
          },
          {
            header: translation('virtuals_vfec_matches_doubleChance'),
            outcomes: ['1/X', '1/2', 'X/2'],
            accessor: 4,
          },
          {
            header: translation('virtuals_vfec_matches_underOver'),
            outcomes: [translation('virtuals_global_underOver')],
            argument: 2.5,
            accessor: 8,
          },
        ],
      },
      {
        header: translation('virtuals_vfec_matchDay'),
        day: -1,
        groups: [
          {
            header: translation('virtuals_vfec_matchDay_groupWinner'),
            outcomes: [],
            accessor: -2794,
          },
          {
            header: translation('virtuals_vfec_matchDay_groupTop2ExactOrder'),
            outcomes: [],
            accessor: -2796,
          },
        ],
      },
      {
        header: translation('virtuals_vfec_season'),
        day: -1,
        groups: [
          {
            header: translation('virtuals_vfec_season_winner'),
            outcomes: [],
            accessor: -2793,
          },
          {
            header: translation('virtuals_vfec_season_top2ExactOrder'),
            outcomes: [],
            accessor: -2798,
          },
          {
            header: translation('virtuals_vfec_season_toReachFinal'),
            outcomes: [],
            accessor: -2797,
          },
          {
            header: translation('virtuals_vfec_season_qualifyToPlayOffs'),
            outcomes: [],
            accessor: -2795,
          },
        ],
      },
    ],
  }), [])

  return (
    <Virtuals config={{ vfec }}>
      {setup => (
        <LayoutVfec {...setup} />
      )}
    </Virtuals>
  )
}

export default Vfec
