import React from 'react';
import { translation } from 'helpers/utilsHelper.js';
import { connect } from 'react-redux';
import { fetchBestsellersOutcomes } from 'bestsellersActions.js';
import S from './StyledBestsellersOutcomes';

const BestsellersOutcomes = ({fetchBestsellersOutcomes}) => {

    const fetchOutcomes = () => {
        fetchBestsellersOutcomes();
    };

    return (
        <S.Button onClick={ fetchOutcomes }>{ translation('get_bestsellers_outcomes') }</S.Button>
    );
};

const mapDispatchToProps = {
    fetchBestsellersOutcomes,
};

export default connect(null, mapDispatchToProps)(BestsellersOutcomes);