import React, {useState, useMemo}  from 'react';
import {useDispatch} from 'react-redux';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _reduce from 'lodash/reduce';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
import S from 'StyledBetHistoryTransactionItem.js';
import {formatDateFromUnix} from 'datesHelper.js';
import {translation, decimal} from 'utilsHelper.js'
import {getTransactionResultStatusText} from 'helpers/transactionsHelper.js'
import SlipDetailsArrow from 'arrow_slip_details.png';
import {withRouter} from 'react-router-dom';

const VirtualBetHistoryItem = ({
    item,
    currencyCode,
    history
    }) => {

    const {
        confirmedReturn,
        regDate,
        salesTaxOdds,
        slipType,
        stake,
        statusCode,
        statusCode_name,
        tax,
        totalOdds,
        transactionDetails,
        transactionId,
        __slipType_name
    } = item;

    const dispatch = useDispatch();

    const getTransactionType = useMemo(() => {
        
        let typeName = slipType;

        switch (typeName) {
            case 1:
                typeName = translation('virtualBetSlip_type_SIMPLE');
                break;
            case 2:
                typeName = translation('virtualBetSlip_type_SYSTEM');
                break;
            case 3:
                typeName = translation('virtualBetSlip_type_MULTISYSTEM');
                break;
            default:
                typeName = translation('virtualBetSlip_type_SIMPLE');
                break;
        }

        return typeName;
    }, []);


    const getTransactionStatusClass = (statusCode, stake, confirmedReturn, pretty) => {

        var resultText = '';

        if (statusCode == 2) {

            if([0,100].indexOf(slipType)!=-1){
             resultText = getTransactionResultStatusText(app.config.betIsWonIfConfirmedReturnIsGtZero,stake,confirmedReturn)
        
            } else {

                const transactionsWithoutTax = _reject(transactionDetails, {outcomeId: -1});
                const bankers = _filter(transactionsWithoutTax, {banker: true});
                const lostBankers = _some(bankers, {statusCode: 3});

                if (!lostBankers) {
                    let hasWonCombination = false;
                    
                    const combinationsWon = _filter(transactionsWithoutTax, {statusCode: 2});
                    const combinationWonNotInBlock = _some(combinationsWon, ({blockId}) => (blockId > 0));
                    if (combinationWonNotInBlock) {
                        hasWonCombination = true;
                    } else {
                        const combinationWonInBlock = _filter(combinationsWon, ({blockId}) => (blockId < 0));
                        _each(combinationWonInBlock, ({blockId}) => {
                            const allCombinationInBlock = _filter(transactionsWithoutTax, {blockId});
                            const allCombinationWonInBlock = _every(allCombinationInBlock, {statusCode: 2});
                            if(allCombinationWonInBlock){
                                hasWonCombination = true;
                                return false;
                            }
                        });
                    }
                    

                    if (hasWonCombination) {
                        resultText = 'won';
                    } else {
                        resultText = 'lost';
                    }

                }else{
                    resultText = 'lost';
                }
            }

        } else {

            switch (statusCode) {
                case 1:
                    resultText = 'placed';
                    break;
                case 2:
                    resultText = 'lost';
                    break;
                case 3:
                case 4: // in RGS etoto virtuals statusCode 3 is automatically switched to 4
                    resultText = 'won';
                    break;
                case 5:
                    resultText = 'cancel';
                    break;
                case 6:
                    resultText = 'pending_acceptation';
                    break;
                case 7:
                    resultText = 'rejected';
                    break;
            }
        }

        if (pretty && resultText) {
            resultText = resultText.substring(0, 1).toUpperCase() + resultText.substring(1, resultText.length);
        }

        return resultText;
    };

    const goToTransactionDetails = (transactionId) => {
        if (transactionDetails) {
            history.push(`/virtuals-bet-history/${transactionId}`);
        }
    }

    const transactionStatusClass = getTransactionStatusClass(statusCode, stake, confirmedReturn, false);
    const transactionStatus = transactionStatusClass;

    const getTransactionResult = useMemo(()=>{

        let ammount = null;
        let translationKey = null;
        let isWon = false;
        if (transactionStatus == 'won') {
            isWon = true;
            ammount = decimal(confirmedReturn);
            translationKey = translation('transactionDetails_betSlipWon');
        } else if (transactionStatus == 'cashout') {
            isWon = true;
            ammount = decimal(confirmedReturn);
            translationKey = translation('transactionDetails_betSlipCashout');
        } else if (transactionStatus == 'lost') {
            ammount = decimal(0);
            translationKey = translation('transactionDetails_betSlipLost');
        } else if (transactionStatus == 'cancel') {
            ammount = decimal(confirmedReturn);
            translationKey = translation('transactionDetails_betSlipCancel');
        } else if (transactionStatus == 'cashback') {
            ammount = decimal(cashBackReturn);
            translationKey = translation('transactionDetails_betSlipCashback');
        }else {
            return null;
        }

        return (
            <>
                <S.TransactionTitle>{translationKey}</S.TransactionTitle>
                <S.TransactionAmmount isWon={isWon}>{ammount} {currencyCode}</S.TransactionAmmount>
            </>
        )

    }, []);

    return (
        <S.BetHistoryItemWrapper className="bet-history-item-wrapper">

            <S.BetHistoryItem className="bet-history-item" onClick={goToTransactionDetails.bind(null, transactionId)}>

                <div className={`transaction-status ${transactionStatusClass}`}></div>

                <div className="transaction-type">

                    <span>{getTransactionType}</span>

                    {(typeof bonusId != 'undefined') && (
                        <span>
                            {translation('account_betHistoryTransactionItem_transactionFromBonusBalance')}
                        </span>
                    )}

                </div>

                <div className={`transaction-amount`}>
                    {decimal(stake)}&nbsp;{currencyCode}
                </div>

                <div className="transaction-odd">{totalOdds}</div>

                <div className="transaction-result">
                    {getTransactionResult}
                </div>

                <div className={`transaction-date`}>
                    <span>{formatDateFromUnix(regDate, 'dd-MM-yyyy')}</span>
                    <span>godz. {formatDateFromUnix(regDate, 'HH:mm:ss')}</span>

                    <S.SlipDetailsArrow src={SlipDetailsArrow}/>
                </div>

            </S.BetHistoryItem>

        </S.BetHistoryItemWrapper>
    );
};

export default withRouter(VirtualBetHistoryItem);
