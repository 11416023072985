import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FwBold = ({theme:{$fwBold}}) => `${$fwBold}`;
const FontFamilyMulish = ({theme:{$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.SelfExclusion = styled.div`
    font-family: ${FontFamily};
    width: 100%;
    padding: 0;
    font-weight: ${FwRegular};
`;

S.SelfExclusionBoxArea = styled.div`
    display: flex;
    flex-direction: column;
`;

S.SelfExclusionBoxWrapper = styled.div`
  &:nth-of-type(1){
    background-color: #fff;
  }
`;

S.SelfExclusionBox = styled.div`
    width: ${rem('750px', baseFont)};
    display: flex;
    flex-direction: column;
    padding: ${rem('16px', baseFont)} 0;
`;

S.Title = styled.span`
    font-size: ${rem('16px', baseFont)};
    font-weight:  ${FwSemibold};
    margin-bottom:  ${rem('16px', baseFont)};
`;

S.RowWrapper = styled.div`
  display: grid;
  grid-gap: ${rem('15px', baseFont)};
  grid-template-columns: 1fr 1fr;
  margin-top: ${rem('15px', baseFont)};
  width: 80%;
`;

S.Button = styled(Button)`
  border-radius: ${rem('7px'), baseFont};
  padding: ${rem('10px', baseFont)} 0;
  background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnBgColor}})=> $accountResponsibleGameSelfExclusionBtnBgColor};
  font-size: ${rem('12px', baseFont)};
  font-family: ${FontFamilyMulish};
  text-align: center;
  font-weight: 700;
  color: ${({theme:{$accountResponsibleGameSelfExclusionBtnColor}})=> $accountResponsibleGameSelfExclusionBtnColor};;

  &:disabled {
    color: ${({theme:{$accountResponsibleGameSelfExclusionBtnDisabled}})=> $accountResponsibleGameSelfExclusionBtnDisabled};
  }
`;

S.Error = styled.span`
    font-family: ${FontFamilyMulish};
    color: #D32F2F;
    font-size: ${rem('12px', baseFont)};
    margin: ${rem('10px', baseFont)} 0 0;
    font-weight: 600;
`;

S.SelfExclusionValidTo = styled(S.Error)``;

S.Description = styled.div`
  font-family: ${FontFamilyMulish};
  font-size: ${rem('12px', baseFont)};
  font-weight: 600;
`;


S.DialogBtnOk = styled(Button)`
  border-radius: ${rem('10px', baseFont)};
  width: 100%;
  padding: ${rem('18px', baseFont)};
  background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnBgColor}})=> $accountResponsibleGameSelfExclusionBtnBgColor};
  font-size: ${rem('14px', baseFont)};
  font-family: ${FontFamily};
  font-weight: ${FwBold};
  color: ${({theme:{$accountResponsibleGameSelfExclusionBtnOkColor}})=> $accountResponsibleGameSelfExclusionBtnOkColor};
  
  &:focus {
          outline: 0;
          outline-color: transparent;
          outline-style: none;
          border-bottom-color: #0E62C4;
      }
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
  margin-bottom: ${rem('10px', baseFont)}; 
  background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelBgColor}})=> $accountResponsibleGameSelfExclusionBtnCancelBgColor};
  font-weight: ${FwBold};
  color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelColor}})=> $accountResponsibleGameSelfExclusionBtnCancelColor};
`;

S.DialogText =styled.div`
  font-size: ${rem('17px', baseFont)};
  font-weight:  ${FwSemibold};
  color: ${({theme:{$accountResponsibleGameSelfExclusionDialogText}})=> $accountResponsibleGameSelfExclusionDialogText};
  text-align: center;
`;

export default S;