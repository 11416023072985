import styled, {css,keyframes} from 'styled-components';
import {rem} from 'polished';
import {Button, Input} from 'global/styles/styles.js';
import StyledLoader from 'StyledLoader.js'

const BetSlipFormGroupBgColor = ({theme:{$betSlipFormGroupBgColor}}) => `${$betSlipFormGroupBgColor}`;
const BetSlipFormStakeWrapperBackground = ({theme:{$betSlipFormStakeWrapperBackground}}) => `${$betSlipFormStakeWrapperBackground}`;
const BetSlipPlaceBetButtonBgColor = ({theme:{$betSlipPlaceBetButtonBgColor}}) => `${$betSlipPlaceBetButtonBgColor}`;
const BetSlipPlaceBetButtonColor = ({theme:{$betSlipPlaceBetButtonColor}}) => `${$betSlipPlaceBetButtonColor}`;
const BetSlipButtonBorderColor = ({theme:{$betSlipButtonBorderColor}}) => `${$betSlipButtonBorderColor}`;
const BetSlipButtonHoverColor = ({theme:{$betSlipButtonHoverColor}}) => `${$betSlipButtonHoverColor}`;
const BetSlipFormInputColor = ({theme:{$betSlipFormInputColor}}) => `${$betSlipFormInputColor}`;
const BetSlipFormLabelColor = ({theme:{$betSlipFormLabelColor}}) => `${$betSlipFormLabelColor}`;
const BetSlipPlaceBetSuccessBackground = ({theme:{$betSlipPlaceBetSuccessBackground}}) => `${$betSlipPlaceBetSuccessBackground}`;
const BetSlipPlaceBetSuccessColor = ({theme:{$betSlipPlaceBetSuccessColor}}) => `${$betSlipPlaceBetSuccessColor}`;
const BetSlipButtonsWrapperBackground = ({theme:{$betSlipButtonsWrapperBackground}}) => `${$betSlipButtonsWrapperBackground}`;
const BetSlipCheckboxWrapperBackground = ({theme:{$betSlipCheckboxWrapperBackground}}) => `${$betSlipCheckboxWrapperBackground}`;
const BetSlipFakeChboxBackground = ({theme:{$betSlipFakeChboxBackground}}) => `${$betSlipFakeChboxBackground}`;
const BetSlipChboxInputBorderColor = ({theme:{$betSlipChboxInputBorderColor}}) => `${$betSlipChboxInputBorderColor}`;
const BetSlipChboxLabelColor = ({theme:{$betSlipChboxLabelColor}}) => `${$betSlipChboxLabelColor}`;
const BetSlipTypesTabsAnchor = ({theme:{$betSlipTypesTabsAnchor}}) => `${$betSlipTypesTabsAnchor}`;
const BetSlipHeaderBackground = ({theme:{$betSlipHeaderBackground}}) => `${$betSlipHeaderBackground}`;
const BetSlipTotalBonusBackground = ({theme:{$betSlipTotalBonusBackground}}) => `${$betSlipTotalBonusBackground}`;
const BetSlipTotalBonusBorder = ({theme:{$betSlipTotalBonusBorder}}) => `${$betSlipTotalBonusBorder}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FwMedium = ({theme: {$fwMedium}}) => `${$fwMedium}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

const blinkAnimation = keyframes`
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 0.9;
  }
`;
S.SeeCouponButton=styled.div`
  display: flex;
  flex-grow: 1;
  text-decoration:underline;
  &:hover{
    cursor:pointer;
  }
`
S.BetSlipWrapper = styled.div`
    position: relative;
    &.is-sticked{
        position: fixed;
        top: 0;
        z-index: 10001;
    }
`;

S.LockBetSlip = styled(Button)`
    padding: 0;
    width: ${rem('16px', baseFont)};
    margin-left:  ${rem('16px', baseFont)};
    margin-right: ${rem('14px', baseFont)};
    background-color: transparent;
    line-height: 1;
    align-self: center;
    & svg{
      width: 100%;
      & path{
        fill: ${({isSticked}) => (isSticked == true) ? `#A1A1A1 !important;` : css`${BetSlipTypesTabsAnchor}`} 
      }
    }
`;

S.Info = styled.div`
   ${({status}) => {
    if (status == 'error') {
        return `
            color: #fff;
             background-color: red;
           `;
    } else if (status == 'success') {
        return `
             color: #fff;
             background-color: green;
           `;
    }
}};

position: relative;
margin: ${rem('10px', baseFont)} 0;
padding:  ${rem('5px', baseFont)};
flex-shrink: 0;
flex-grow: 1;
border-radius: 4px;
min-height: ${rem('30px', baseFont)};
font-size: ${rem('10px', baseFont)};
font-weight: 400;
font-family: ${FontFamily};
color: #fff;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: ${rem('3px', baseFont)};
&:last-of-type{
    margin-bottom: 0;
}
`;

S.InfoMessage = styled.div`
  padding-right: ${rem('10px', baseFont)};
`;

S.InfoCloseIcon = styled.div`
  cursor: pointer;
    font-size: ${rem('30px', baseFont)};
    line-height: 1;
    position: absolute;
    right: ${rem('10px', baseFont)};
    top: 0;
`;

S.BetSlipHeader = styled.div`
    width: 100%;
    padding: 0 ${rem('20px', baseFont)};
    background-color: ${BetSlipHeaderBackground};
    display: flex;
    cursor: pointer;
    height: ${rem('50px', baseFont)};
    align-items: center;
    border-radius: 5px 5px 0 0;
    
`;

S.BetSlipHeaderCaption = styled.div`
  font-family: ${FontFamily};
  font-weight: ${FwMedium};
  font-size: ${rem('15px', baseFont)};
  color: #fff;
  text-transform: uppercase;
  margin-right: ${rem('7px', baseFont)};
`;

S.BetSlipBody = styled.div`
    overflow: hidden;
    position: relative;
    max-height: 1000px;
    transition-property: max-height;
    transition-duration: .5s;
    ${({isHidden}) => {
        return isHidden == true && 'max-height: 0';
    }}
`;

S.BetSlipContainer = styled.div`
    right: 500px;
    width: 100%;
    z-index: 1000000;
    min-height: 100px;
    background-color: #fff;
    padding: 0;
    border-radius: 0 0 5px 5px;
`;

S.Outcome = styled.div`
   padding: 5px 0;
   border-bottom: 1px solid grey;
   display: flex;
`;

S.PrevOdds = styled.span`
    position: relative;
    margin-left: 30px;
    &.up,
    &.down{
         padding-right: 25px;
         &:before{
              right: 5px;
              top: 4px;
              content: '';
              width: 0;
              height: 0;
              position: absolute;
              animation-direction: normal;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: ${blinkAnimation};
              animation-timing-function: ease;
          }
    }
    &.up{
        color: green;
        &:before{
            border-color: green transparent;
	        border-style: solid;
	        border-width: 0px 8px 8px 8px;
        }
    }
    &.down{
        color: red;
        &:before{
            border-color: red transparent;
	        border-style: solid;
	        border-width: 8px 8px 0px 8px;
        }
     }
`;

S.Error = styled.div`
padding:  ${rem('5px', baseFont)};
flex-shrink: 0;
flex-grow: 1;
 background-color: #E4202C;
border-radius: 4px;
height: ${rem('30px', baseFont)};
font-size: ${rem('10px', baseFont)};
font-weight: 400;
font-family: ${FontFamily};
color: #fff;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: ${rem('3px', baseFont)};
&:last-of-type{
    margin-bottom: 0;
}
`;


S.BetSlipOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
    
`;

S.ArrowIcon = styled.i`
    margin-left: auto;
    margin-right: 0.5rem;
`;

S.ClearBetSlip = styled(Button)`
    background-color: transparent;
    height: ${rem('45px', baseFont)};
    padding: 0  ${rem('20px', baseFont)};
    border-radius: 3px;
    border: 2px solid ${BetSlipButtonBorderColor};
    text-transform: uppercase;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;
    &:hover{
         background-color: ${BetSlipButtonHoverColor};
         color: #fff;
    }
`;

S.PlaceBet = styled(S.ClearBetSlip)`
    background-color: ${BetSlipPlaceBetButtonBgColor};
    color: ${BetSlipPlaceBetButtonColor};
    height: ${rem('40px', baseFont)};
    border-radius: 20px;
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    width: 100%;
    border: none;
    font-weight: 700;
`;

S.BetSlipLogin = styled(S.PlaceBet)``;

S.BetInShop = styled(S.ClearBetSlip)`
  background-color: ${BetSlipButtonHoverColor};
  color: #fff;
`;

S.AcceptTraderChanges = styled(S.ClearBetSlip)`
         background-color: ${BetSlipButtonHoverColor};
         color: #fff;
         margin-right: ${rem('20px', baseFont)};
         flex:1 ;
`;

S.RejectTraderChanges = styled(S.ClearBetSlip)`
         background-color: ${BetSlipButtonHoverColor};
         color: #fff;
         flex: 1;
`;

S.ButtonsWrapper = styled.div`
    background-color: ${BetSlipButtonsWrapperBackground};
    padding: ${rem('18px', baseFont)} ${rem('20px', baseFont)} ${rem('18px', baseFont)};
    display: flex;
    &.trader-changes{
      position: relative;
      top: -2px;
    }
    &.place-bet{
      padding-bottom:0;
    }
`;

S.WinningTaxBox = styled.div`
    color: ${BetSlipChboxLabelColor};
    border: 1px solid #0000003D;
    border-radius: 3px;
    padding: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
`;

S.FormGroupWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${rem('15px', baseFont)} ${rem('20px', baseFont)};
    background-color: ${BetSlipFormStakeWrapperBackground};
    align-items: center;
    &.total-stake-wrapper{
      padding-top: 0;
    }
`;

S.InputWrapper = styled.div`
    display: flex;
    color: ${BetSlipFormInputColor};
    height:  ${rem('30px', baseFont)};
`;

S.Input = styled(Input)`
    font-size:  ${rem('15px', baseFont)} ;
    background-color: #fff;
    border: none;
    padding:0;
    width:  ${rem('90px', baseFont)};
    color: ${BetSlipFormInputColor};
    font-family: ${FontFamily};
    font-weight: 500;
    height: 100%;
    text-align: center;
    
    &[type="number"]{
      -moz-appearance:textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
       margin: 0;
    }
`;

S.Label =  styled.label`
    flex-shrink: 0;
    font-size: ${rem('14px', baseFont)};
    margin-bottom: 0;
    color: ${BetSlipFormLabelColor};
    align-self: center;
    font-family: ${FontFamily};
    font-weight: 500;
    text-transform: uppercase;
    width: 30%;
`;

S.Currency = styled.div`
    font-size: 1.3333rem;
    display: flex;
    align-items: center;
`;

S.EmptySlip = styled.div`
    padding: ${rem('10px', baseFont)};
`;

S.PlaceBetSuccess = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${rem('8px', baseFont)};
    padding:  ${rem('5px', baseFont)};
`;

S.PlaceAgainBtn = styled(Button)`
    background-color: transparent;
    height: ${rem('45px', baseFont)};
    padding: 0  ${rem('20px', baseFont)};
    border-radius: 3px;
    border: 2px solid ${BetSlipButtonBorderColor};
    text-transform: uppercase;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;
`;

S.ClearBetSlipBtn = styled(S.PlaceAgainBtn)``;

S.PlaceBetSuccessTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${BetSlipPlaceBetSuccessBackground};
    color: ${BetSlipPlaceBetSuccessColor};
    border-radius: 3px;
    min-height: ${rem('125px', baseFont)};
    margin-bottom: ${rem('6px', baseFont)};
    padding: ${rem('17px', baseFont)};
`;

S.PlaceBetSuccessBottom = styled.div`
    display: grid;
    grid-gap: 0 ${rem('3px', baseFont)};
    grid-template-columns: repeat(2, 1fr);
`;

S.PlaceBetIcon = styled.div`
    width: ${rem('47px', baseFont)};
    height: ${rem('47px', baseFont)};
    margin-bottom: ${rem('8px', baseFont)};
`;

S.PlaceBetMessage = styled.div`
    font-weight: bold;
    font-size: ${rem('14px', baseFont)};
    text-align: center;
`;

S.CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${rem('8px', baseFont)};
    background-color: ${BetSlipCheckboxWrapperBackground};
`;

S.CheckboxGroup = styled.div``;

S.LabelCheckbox = styled.label`
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
`;

S.InputCheckbox = styled(Input)`
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;

    &:checked + ${S.FakeCheckbox} {
        &:before {
            display: block;
        }
    }
`;

S.FakeCheckbox = styled.span`
    cursor: pointer;
    background-color: ${BetSlipFakeChboxBackground};
    margin-right: ${rem('15px', baseFont)};
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid ${BetSlipChboxInputBorderColor};
    &:before {
        content: "\\002714";
        position: absolute;
        display: none;
        left: 0.30rem;
        top: 0.05rem;
    }
`;

S.CheckboxText = styled.span`
    font-size: ${rem('12px', baseFont)};
    color: ${BetSlipChboxLabelColor};
    font-weight: normal;
    line-height: 1;
`;

S.TaxFactor = styled.div`
  color: #fff;
  font-family: ${FontFamilyMulish};
  font-size: ${rem('10px', baseFont)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  background-color: #02153F;
  height: ${rem('29px', baseFont)};
  font-weight: 400;
`;

S.FormGroup = styled.div`
    padding: ${rem('5px', baseFont)} ${rem('20px', baseFont)};
    display: flex;
    justify-content: space-between;
    background-color: ${BetSlipFormGroupBgColor};
    align-items: center;
    
    &.possible-win,
    &.total-bonus {
      & > ${S.FormGroupText}{
        &:first-of-type{
            color: #fff;
        }
        &:last-of-type{
          font-size: ${rem('16px', baseFont)};
          color: #fff;
        }
      }
    }
    &.total-bonus{
      & > ${S.FormGroupText}{
        &:last-of-type{
          font-size: ${rem('12px', baseFont)};
        }
      }
    }
    &.multi-betSlip {
      flex-wrap: wrap;
      & > ${S.FormGroupText}{
        &:nth-child(1) {
          width: 100%;
          color: ${BetSlipHeaderBackground};
          font-size: ${rem('12px', baseFont)};
          font-family: ${FontFamilyMulish};
          font-weight: 600;
        }
        &:not(:nth-child(1)) {
          color: #fff;
          font-size: ${rem('12px', baseFont)};
          font-family: ${FontFamilyMulish};
        }
      }
    }
    
    & > ${S.FormGroupText}{
      &:first-of-type{
        font-family: ${FontFamilyMulish};
        font-size: ${rem('11px', baseFont)};
        font-weight: 400;
      }
      &:last-of-type{
        font-family: ${FontFamily};
        font-size: ${rem('12px', baseFont)};
      }
    }
`;

S.FormGroupText =  styled.div`
  font-size: ${rem('14px', baseFont)};
  color: rgba(255, 255, 255, 0.5);
`;

S.UnexpectedError = styled.div`
   padding: ${rem('15px', baseFont)};
   border-radius: 3px;
   background-color: red;
   margin: ${rem('10px', baseFont)} 0 0;
   display: flex;
   align-items: center;
`;

S.UnexpectedErrorIcon = styled.div`
    flex-shrink: 0;
    font-size: ${rem('20px', baseFont)};
    margin-right: ${rem('10px', baseFont)};
`;

S.UnexpectedErrorMessage = styled.div``;

S.ErrorContainer = styled.div`
    padding: ${rem('10px', baseFont)} ${rem('20px', baseFont)};
    background-color: #F4F7FC;
    display: flex;
    flex-direction: column;
`;

S.RemoveBetSlip = styled(Button)`
    padding: 0;
    background-color: transparent;
    line-height: 1;
    width: ${rem('14px', baseFont)};
    & svg{
      width: 100%;
    }
`;

S.RemoveBetSlipLink = styled.div`
  pointer-events: ${({disabled})=> ( (disabled==true) ? 'none': 'auto')};
  display: flex;
  justify-content: center;
  padding:  ${rem('10px', baseFont)} 0 0;
  font-family: ${FontFamilyMulish};
  font-size: ${rem('11px', baseFont)};
  color: rgba(255,255,255,0.5);
  background-color: #001A5B;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
`;

S.StakeControl = styled.div`
    font-family: ${FontFamilyMulish};
    width: ${rem('30px', baseFont)};
    height: ${rem('30px', baseFont)};
    background-color: ${({disabled})=>(disabled==true)?`grey`:`#E4202C`};
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem('16px', baseFont)};
    pointer-events: ${({disabled})=>(disabled==true)?`none`:`auto`};
    font-weight: 700;
    // because not all stake changes disable the stake control by redrawing component
    &[disabled] {
      background-color: grey;
      pointer-events: none;
    }
    &:first-of-type{
      border-radius: 3px 0 0 3px;
    }
    &:last-of-type{
     border-radius: 0 3px 3px 0;
    }
 
`;

S.AcceptOddsChangeSelect = styled.div`
    padding: ${rem('18px', baseFont)}  ${rem('20px', baseFont)};
    width: 100%;
    cursor: pointer;
    align-items: stretch;
    background-color: #001A5B;
    
    & .react-select__menu{
        margin-top: 0;
        left: ${rem('20px', baseFont)};
        right: ${rem('20px', baseFont)};
        width: auto;
        border: 1px solid #1C3C8E;
        top: 44px;
        
        & .react-select__menu-list{
             padding: 0;
            
        
            &  .react-select__option{
            border-bottom: 1px solid #1C3C8E;
            text-transform: uppercase;
            display: flex;
            align-items: center;
                cursor: pointer;
                height:  ${rem('25px', baseFont)};
                background-color: #001A5B;
                 font-size:  ${rem('10px', baseFont)};
                 color: #fff;
                
                 &:last-of-type{
                 border-bottom: none;
                 }
                 
                &--is-focused,
                &--is-selected{
                   background-color: #0E62C4;
                }
            }
        }
    }
    
    & .react-select__control{
        cursor: pointer;
        box-shadow: none;
        min-height: 0;
        background-color: #09256D;
        height: ${rem('25px', baseFont)};
        border-radius: 3px;
        border: 1px solid #1C3C8E;
        &:hover{
          border: 1px solid #1C3C8E;
        }
        
        & .react-select__dropdown-indicator{
            padding: 3px;
            background: transparent url('/images/select_arrow_white.png') no-repeat center;
            width:  ${rem('8px', baseFont)};
            height:  ${rem('4px', baseFont)};
            margin-right: ${rem('12px', baseFont)};
            
            & svg{
              display: none;
            }
        }

        & .react-select__indicator-separator {
            display: none;
        }
    
        & .react-select__value-container{
              padding-left: ${rem('12px', baseFont)};
        
            & .react-select__single-value{
                 font-size: ${rem('10px', baseFont)};
                 font-weight: 400;
                 font-family: ${FontFamily};
                 color: #fff;
                 padding-left: 0;
                 margin-left: 0;
                 text-transform: uppercase;
            }
        }
    
    }
`;

S.PlaceBetSuccess = styled.div`
  padding: ${rem('10px', baseFont)} ${rem('20px', baseFont)};
  background-color: #F4F7FC;
  display: flex;
  flex-direction:column;
  align-items: center;
  background-color: #2E8300;
  border: 1px solid #C0C3CA;
  border-radius: 4px;
  font-size: ${rem('10px', baseFont)};
  font-weight: 400;
  font-family: ${FontFamily};
  color: #fff;
  text-transform: uppercase;
`;

S.PredefiniedStakes = styled.div`
  display: flex;
  min-height: ${rem('25px', baseFont)};
  background-color: #09256D;
  padding: 0 ${rem('20px', baseFont)} ${rem('15px', baseFont)};
`;

S.Stake = styled(Button)`
  height: ${rem('25px', baseFont)};
  flex-grow: 1;
  width: ${rem('55px', baseFont)};
  margin-right: ${rem('5px', baseFont)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({isActive})=>(isActive==true)?`#09256D`:`#fff`};
  font-size: ${rem('12px', baseFont)};
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  background-color: ${({isActive})=>(isActive==true)?`#fff`:`transparent`};
  padding: 0;
  pointer-events: ${({isActive, disabled})=>(disabled==true)?`none`:`auto`};
  
  &:hover{
    background-color: #fff;
    color: #09256D;
  }
  
  &:last-of-type{
    margin-right: 0;
  }

`;

//total bonus element
S.TotalBonusWrapper = styled.div`
  background-color: ${BetSlipFormGroupBgColor};
`;
S.TotalBonusAdditionalInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${rem('20px', baseFont)};
  padding: ${rem('8px', baseFont)} ${rem('3px', baseFont)} ${rem('8px', baseFont)} ${rem('8px', baseFont)};
  background-color: ${BetSlipTotalBonusBackground};
  border: 1px solid ${BetSlipTotalBonusBorder};
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.5);
  font-size: ${rem('9px', baseFont)};
  font-weight: normal;
`;
S.TotalBonusAdditionalInfoText = styled.div`
  width: 80%;
  font-weight: ${FwRegular};
  font-family: ${FontFamilyMulish};
`;
S.TotalBonusAdditionalInfoIcon = styled.div`
  width: 40px;
  height: 20px;
  background-image: url(/images/svg/akoplus_icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;


export default S;