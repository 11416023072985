import React, {memo, useEffect, useMemo} from 'react';
import Switch from 'react-switch';
import S from 'StyledFreebetList.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _size from 'lodash/size';
import { Scrollbars } from 'react-custom-scrollbars';
import useMediaQuery from 'react-use-media-query-hook';
import {translation} from 'utilsHelper';

const FreebetList = (props) => {

    const {fetchFreebetByStatus, toggleFreebet, validFreebets, freebet, currencyCode, slipType, hasApprovalButtonsVisibled, checkFreetbetConditionValidity} = props;

    const isSmallDesktop = useMediaQuery('(max-width: 1440px)');

    const didMount = async() => {
        try {
            const config = {
                status: 'ACTIVE'
            };
            await fetchFreebetByStatus(config);
            checkFreetbetConditionValidity();
        } catch (error) {
            console.log('freebet:', error)
        }
    };

    useEffect(() => {
            didMount();
    }, []);

    const toggleActiveFreebet = (offerFreebetId) => {
        const item = _find(validFreebets, {offerFreebetId});
        toggleFreebet(item);
    };

    const isVisible = useMemo(() => {
        return (slipType == 'ACCUMULATOR' && _size(validFreebets) > 0);
    }, [slipType, validFreebets]);

    return (
        <S.FreebetListWrapper className="freebet-list-wrapper" isVisible={isVisible}>

            <S.FreebetList className="freebet-list-wrapper" isSmallDesktop={isSmallDesktop}>

                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                    style={{width: '100%'}}
                    autoHeight
                    autoHeightMax={(isSmallDesktop ? 50 : 100)}>

                    {_map(validFreebets, ({freebetStake, offerFreebetId, offerName, nameKey}) => {
                        const selectedFreebetId = _get(freebet, ['offerFreebetId']);
                        return (
                            <S.FreebetListItem key={offerFreebetId} isActive={offerFreebetId == selectedFreebetId}>
                                <span>
                                    <S.FreebetName data-translation={nameKey}>{translation(nameKey)} :</S.FreebetName> {freebetStake} {currencyCode}</span>&nbsp;&nbsp;
                                <Switch height={20}
                                        width={40}
                                        disabled={hasApprovalButtonsVisibled}
                                        onChange={toggleActiveFreebet.bind(null, offerFreebetId)}
                                        checked={offerFreebetId == selectedFreebetId}/>
                            </S.FreebetListItem>
                        )
                    })}

                </Scrollbars>

            </S.FreebetList>

        </S.FreebetListWrapper>
    );
};

export default memo(FreebetList);
