import React, { useMemo } from 'react'
import { translation } from 'helpers/utilsHelper.js'
import { useDispatch } from 'react-redux'
import { addOutcome } from 'betSlipActions.js'
import Virtuals, { MethodProvider } from 'sb-virtuals'
import Layout from '../layouts/LayoutVti'

const Vti = () => {
  const vti = useMemo(() => ({
    url: process.env.VIRTUALS_VTI_URL,
    groups: [
      {
        header: translation('virtuals_vti_game'),
        groups: [
          {
            accessor: 681,
          },
          {
            accessor: 259,
          },
          {
            accessor: 685,
          },
          {
            accessor: 620,
          },
          {
            accessor: 262,
          }
        ],
      },
      {
        header: translation('virtuals_vti_set'),
        groups: [
          {
            accessor: 221,
          },
          {
            accessor: 624,
          },
          {
            accessor: 258
          },
          {
            accessor: 2455
          }
        ]
      },
      {
        header: translation('virtuals_vti_match'),
        groups: [
          {
            accessor: 202,
          },
          {
            accessor: 246
          }
        ]
      }
    ],
  }), [])

  const dispatch = useDispatch()

  const _addOutcome = (outcome) => {
    outcome['virtualSport'] = 'VTI';
    dispatch(addOutcome(outcome, true))
  }

  return (
    <MethodProvider method={_addOutcome}>
      <Virtuals config={{ vti }}>
        {setup => (
          <Layout {...setup} />
        )}
      </Virtuals>
    </MethodProvider>
  )
}

export default Vti
