import React, {Component} from 'react';
import {connect} from 'react-redux';
import S from 'StyledAccountSessionTimer.js';
import _map from 'lodash/map';
import {translation} from 'utilsHelper.js';

class AccountSessionTimer extends Component {

    parseSessionTime = () => {
        const {sessionTime, sessionStart} = this.props;

        const sessionTimeDiff = Math.floor( (sessionTime - sessionStart) / 1000);
        const hoursSession = Math.floor(sessionTimeDiff / 3600);
        const minutesSession = Math.floor((sessionTimeDiff - (hoursSession * 3600)) / 60);
        const secondsSession = sessionTimeDiff - (hoursSession * 3600) - (minutesSession * 60);
        const timePartsSession = _map([hoursSession, minutesSession, secondsSession], (part) => ('0' + part).substr(-2));
        return timePartsSession
    };
    
    render() {
        const sessionTimeParts = this.parseSessionTime();
        return (
            <S.AccountSessionTimer>
                {`${translation("account_loggedSince")} ${sessionTimeParts[0]}:${sessionTimeParts[1]}:${sessionTimeParts[2]}`}
            </S.AccountSessionTimer>
        );
    }
}

const mapStateToProps = ({Account: {sessionTime}, Auth: {user: {sessionStart, sessionTime:prevSessionTime}}}) => {
    return {
        sessionTime,
        sessionStart,
        prevSessionTime
    }
};

export default connect(mapStateToProps)(AccountSessionTimer);
