/* global app */
import React, { useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { loadCustomerData } from 'customerActions.js'
import { SBPayments } from 'sb-payments-etoto'
import { translation } from 'helpers/utilsHelper.js';
import S from 'StyledAccountWithdraw.js'

const { Withdraw, useSession } = SBPayments

const AccountWithdraw = ({ isLogged, balance, currencyCode, loadCustomerData, status }) => {
  const session = useSession()
  const shouldWithdrawButtonBeDisabled = status == 'TEMPORARY_ACCOUNT';
  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase(), [app.config.lang, app.config.mainCountryCode])

  const onSuccess = useCallback(() => loadCustomerData({
    keepSessionAlive: false,
    updateBalance: true
  }), [loadCustomerData])

  return (
    <S.AccountWithdraw>
      <S.WithdrawTitle>{translation('account_withdraw_title')}</S.WithdrawTitle>
      {isLogged && (
        <Withdraw
          lang={lang}
          session={session}
          balance={balance}
          currencyCode={currencyCode}
          onSuccess={onSuccess}
          shouldWithdrawButtonBeDisabled={shouldWithdrawButtonBeDisabled}
        />
      )}
    </S.AccountWithdraw>
  )
}

const mapStateToProps = ({ Auth: { isLogged, user: { balance, currencyCode, status } } }) => {
  return {
    isLogged,
    balance,
    currencyCode,
    status,
  }
}

export default connect(mapStateToProps, { loadCustomerData })(AccountWithdraw)
