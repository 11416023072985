const topMenuUserIconSVG = () => {
    return {
        __html: `
 <svg width="100%" height="100%" viewBox="0 0 49 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">                
 <path id="account-circle" d="M24.26,41.726c-6.075,0 -11.419,-3.102 -14.551,-7.811c0.063,-4.82 9.713,-7.472 14.551,-7.472c4.837,0 14.487,2.652 14.55,7.472c-3.132,4.709 -8.476,7.811 -14.55,7.811Zm0,-34.448c4.017,0 7.277,3.26 7.277,7.278c0,4.022 -3.26,7.278 -7.277,7.278c-4.018,0 -7.278,-3.256 -7.278,-7.278c0,-4.018 3.26,-7.278 7.278,-7.278Zm0,-7.278c-13.399,0 -24.26,10.859 -24.26,24.259c0,13.399 10.861,24.26 24.26,24.26c13.398,0 24.259,-10.861 24.259,-24.26c0,-13.4 -10.861,-24.259 -24.259,-24.259Z" style="fill:#fff;fill-rule:nonzero;"></path>
 </svg>`
    };
};

const modalUserIconSVG = () => {
    return {
        __html: `
<svg width="100%" height="100%" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                    <path d="M8.825,15.197c-2.156,0 -4.063,-1.104 -5.175,-2.777c0.026,-1.717 3.45,-2.656 5.175,-2.656c1.718,0 5.15,0.939 5.175,2.656c-1.112,1.673 -3.019,2.777 -5.175,2.777m0,-12.247c1.432,0 2.588,1.155 2.588,2.587c0,1.432 -1.156,2.588 -2.588,2.588c-1.432,0 -2.587,-1.156 -2.587,-2.588c0,-1.432 1.155,-2.587 2.587,-2.587m0,-2.588c-4.76,0 -8.625,3.864 -8.625,8.625c0,4.761 3.865,8.625 8.625,8.625c4.761,0 8.625,-3.864 8.625,-8.625c0,-4.761 -3.864,-8.625 -8.625,-8.625" style="fill:currentColor;fill-rule:nonzero;"></path>
                </svg>`
    };
};

const searchIconSVG = () => {
    return {
        __html: `
    <svg width="100%" height="100%" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g><path d="M6.5,11c-2.49,0 -4.5,-2.01 -4.5,-4.5c0,-2.49 2.01,-4.5 4.5,-4.5c2.489,0 4.5,2.01 4.5,4.5c0,2.49 -2.011,4.5 -4.5,4.5m6,0l-0.79,0l-0.28,-0.27c0.98,-1.14 1.57,-2.62 1.57,-4.23c0,-3.59 -2.91,-6.5 -6.5,-6.5c-3.59,0 -6.5,2.91 -6.5,6.5c0,3.59 2.91,6.5 6.5,6.5c1.609,0 3.09,-0.59 4.23,-1.57l0.27,0.281l0,0.789l5,4.99l1.49,-1.49l-4.99,-5Z" style="fill:#fff;fill-rule:nonzero;"></path></g></svg>`
    };
};




export {topMenuUserIconSVG, modalUserIconSVG, searchIconSVG};
