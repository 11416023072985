import styled from 'styled-components';
import {rem} from 'polished';
import {Form, Input, Button} from 'global/styles/styles.js';

const SubmitBtnBgColor = ({theme:{$loginFormSubmitBtnBgColor}}) => `${$loginFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$loginFormSubmitBtnColor}}) => `${$loginFormSubmitBtnColor}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;

const baseFont = `12px`;
const S = {};

S.ChangePasswordForm = styled(Form)`
  width: ${rem('520px', baseFont)};
   font-family: ${FontFamilyMulish};
`;

S.Row = styled.div``;

S.Label = styled.label`
    color: #001A5B;
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    margin-bottom: 0;
    font-weight: 600;
`;

S.InputWrapper = styled.div`
    margin-bottom: ${rem('15px', baseFont)};
`;

S.Input = styled(Input)`
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #BAC1D0;
    color: #001A5B;
    font-size: ${rem('15px', baseFont)};
    padding: ${rem('25px', baseFont)} 0;
    background-color: #fff;
    display: block;
    width: 100%;
    height: 34px;
    font-weight: 600;
 
      &::placeholder{
        color: rgba(0, 26, 91, 0.5);
      font-style: italic;
      }
        
    ${({dirty})=>{
        if(dirty==true){
            return `         
                    color: #001A5B;
                    border-bottom: 2px solid #0069D6;
             `;
        }
    }};

     &:-webkit-autofill {
                color: #001A5B;
                border-bottom: 2px solid #0069D6;
                background-color: #fff;
                -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                }
     }
`;

S.SubmitBtn = styled(Button)`
    border-radius: 20px;
    background-color: ${SubmitBtnBgColor};
    height: ${rem('40px', baseFont)};
    line-height: 5rem;
    font-size: ${rem('13px', baseFont)};
    padding: 0;
    width: ${rem('240px', baseFont)};
    margin-bottom: ${rem('15px', baseFont)};
    margin-top: ${rem('45px', baseFont)};
    margin-left: auto;
    margin-right: auto;
    color: ${SubmitBtnColor};
    cursor: pointer;
    font-family: ${FontFamilyMulish};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
`;

S.Error = styled.div`
    color: red;
    margin: 5px 0;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('10px', baseFont)};
`;

S.SubmissionSuccess = styled(S.SubmissionError)`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
`;

S.SuccessModal = styled.div`
    margin-top: -44px;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.1538461538rem;
    letter-spacing: .2px;
    font-weight: 400;
`;


S.Body = styled.div`
      padding: ${rem('20px', baseFont)} ${rem('50px', baseFont)} ${rem('50px', baseFont)};;
`;

S.Header = styled.div`
position: relative;
  display: flex;
  margin: 0 ;
  height:  ${rem('128px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
  padding: 0 ;
  justify-content: center;
  align-items: center;
  background-color: #F4F7FC;
  border-radius: 5px 5px 0 0;
`;

S.Title = styled.div`
 margin: 0;
   color: #001A5B;
    font-size: ${rem('30px', baseFont)}
    padding: 0;
    font-weight: 600;
    text-align: center;
    font-family: ${FontFamily};
    text-transform: uppercase;
`;

S.CloseButton = styled.img`
    position: absolute;
    width: 2.9166666666666665rem;
    height: 2.9166666666666665rem;
    cursor: pointer;
    right: -18px;
    top: -18px;
    z-index: 1;
`;


export default S;