import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.DropdownMenuList = styled(List)`
    float:left;
    width: 100%;
    border-bottom: 4px solid #001A5B;
    border-radius: 0px 0px 5px 5px;
`;

S.DropdownSubmenuList = styled(S.DropdownMenuList)`
    float:left;
    width: 100%;
    border-radius: 0;
     border-bottom: none;
     
`;

S.Title = styled.span`
    overflow: hidden;
    flex: 1;
    padding-right: 10px;
    font-size: 1.16rem;
`;

S.Icon = styled.div`
    flex-shrink: 0;
    margin-right: ${rem('19px', baseFont)};
    color: ${({theme:{$categoriesSportIconColor}}) => $categoriesSportIconColor};
    width:  ${rem('16px', baseFont)};
    &:before{
        font-size: 1.3333333333333333rem;
    }
`;

S.Arrow = styled.div`
    height: 100%;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    transition: transform 0.5s;
    margin-right: 5px;
    &:before {
        content: '';
        width:  1.2rem;
        height: .87rem;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: #001A5B;
         mask-size:  1.2rem .87rem;
         -webkit-mask-size:  1.2rem .87rem;
         mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
         -webkit-mask: url(/images/svg/chevron_right_grey.svg) no-repeat 50% 50%;
 }
`;

S.EventsCount = styled.span`
    width: 16.6666666667%;
    display: inline-block;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${({theme:{$categoriesEventsCount}}) => $categoriesEventsCount};
    color: #fff;
    text-align: center;
    font-size: .9rem!important;
    border-radius: 3px;
    padding: 3px 0;
    flex-shrink: 0;
`;

S.DropdownMenuItemInner = styled.a`
    font-family: ${FontFamilyMulish};
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${rem('40px', baseFont)}
    border-bottom: .08rem solid ${({theme:{$categoriesDropdownItemBorderBottom}}) => $categoriesDropdownItemBorderBottom};;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
    font-size: 1.1rem!important;
    margin-top: 0;
    background: 0 0;
    background-color: ${({theme:{$categoriesDropdownItemBgColor}}) => $categoriesDropdownItemBgColor};
    padding: 1rem;
    padding-left: ${rem('20px', baseFont)};
    color: ${({theme:{$categoriesDropdownItemColor}}) => $categoriesDropdownItemColor};
    font-weight: 400;
    &:active, &:visited, &:focus, &:hover {
        text-decoration: none;
    }
    &:hover {
        background-color: ${({theme:{$categoriesDropdownItemBgColorHover}}) => $categoriesDropdownItemBgColorHover};
    }   
`;

S.DropdownMenuItem = styled.li`
    &[data-level="1"].is-active {
        &>${S.DropdownMenuItemInner}{
        
         & ${S.Title}
            {
             
                color: ${({theme:{$categoriesDropdownItemActiveColor}}) => $categoriesDropdownItemActiveColor};
            }
            
              & ${S.EventsCount}{
                    color: ${({theme:{$categoriesActiveEventsCountColor}}) => $categoriesActiveEventsCountColor};
                    background-color: ${({theme:{$categoriesActiveEventsCountBgColor}}) => $categoriesActiveEventsCountBgColor};
              }
        }
    }
    
    &[data-level="2"]{
      &>${S.DropdownMenuItemInner}{
            background-color: ${({theme:{$categoriesDropdownItemLvl2BgColor}}) => $categoriesDropdownItemLvl2BgColor};
        }
    }

    &[data-level="2"].is-active {
        &>${S.DropdownMenuItemInner}
        {
            background-color: ${({theme:{$categoriesDropdownItemLvl2ActiveBgColor}}) => $categoriesDropdownItemLvl2ActiveBgColor};
            & ${S.Title}
            {
              font-family: ${FontFamilyMulish};
               color: ${({theme:{$categoriesDropdownItemLvl2ActiveTitleColor}}) => $categoriesDropdownItemLvl2ActiveTitleColor};
               font-weight: 700;
               font-size: 1.15rem;
            }
            & ${S.Arrow}
            {
                transform: rotate(90deg);
                &:before {
                   background-color: ${({theme:{$categoriesDropdownItemLvl2ActiveArrowColor}}) => $categoriesDropdownItemLvl2ActiveArrowColor};
                }
            }
        }
    }
    
    &[data-level="3"]{
        &>${S.DropdownMenuItemInner}{
  
            background-color: ${({theme:{$categoriesDropdownItemLvl3BgColor}}) => $categoriesDropdownItemLvl3BgColor};
            
               ${S.Title}{
               font-size: 1.1rem;
               }
    
        }
    }
    
       &[data-level="3"].is-active {
              &>${S.DropdownMenuItemInner}{
                       position: relative;
                         
                         &:before{
                         position: absolute;
                         content: '';
                         left: 0;
                         top: 0;
                         bottom: 0;
                         width: 4px;
                         background-color: #E4202C;
                        }
                        
                        ${S.Title}{
                          
                             font-family: ${FontFamilyMulish};
                             font-weight: 700;
                        }
              }
       }
`;

S.FakeChbox = styled.div`
    width: ${rem('16px', baseFont)};
    height:  ${rem('16px', baseFont)};
    border: 1px solid ${({theme:{$categoriesLvl3CheckboxBorderColor}}) => $categoriesLvl3CheckboxBorderColor};
    background-color: ${({theme:{$categoriesLvl3CheckboxBgColor}}) => $categoriesLvl3CheckboxBgColor};
    border-radius: 4px;
    margin-right: 10px;
    &.is-active {
        border-color: ${({theme:{$categoriesLvl3CheckboxActiveBorderColor}}) => $categoriesLvl3CheckboxActiveBorderColor};
        background-color:  ${({theme:{$categoriesLvl3CheckboxActiveBgColor}}) => $categoriesLvl3CheckboxActiveBgColor};
        &:after {
            content: "";
            display: block;
            height: 100%;
            pointer-events: none;
            background: transparent url('/images/tick.png') no-repeat center;
        }
        
        & + ${S.Title}{
            font-family: ${FontFamilyMulish};
            font-weight: 700;
        }
}
`;

export default S;