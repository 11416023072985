import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import AccountSessionTimer from 'AccountSessionTimer.js';
import AccountMenu from 'AccountMenu.js';
import AccountRoute from 'AccountRoute.js';
import S from 'StyledAccount.js';
import _size from 'lodash/size';
import {fetchFreebetByStatus} from 'freebetActions.js';
import {toggleAccountModal, loadVeryficationStatus} from 'accountActions.js';
import AccountCloseBtn from 'account_close_btn.png';
import { Scrollbars } from 'react-custom-scrollbars';

class Account extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false, freebetCount: 0};

    componentDidMount = async() => {
        const {fetchFreebetByStatus, loadVeryficationStatus} = this.props;
        try {
            loadVeryficationStatus();
            const config = {
                status: 'ACTIVE'
            };
            const {active} = await fetchFreebetByStatus(config);
            this.setState((prevState) => {
                return {...prevState, freebetCount: _size(active)};
            })

        } catch (error) {
            console.log('fetchFreebetByStatus:' + error);
        }
    };

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(toggleAccountModal(false));
    };

    render() {
        const {user:{login, userId, currencyCode, email, firstName, lastName}, accountData, toggleAccountModal, dispatch} = this.props;
        const {hasError} = this.state;
        return (
            <S.AccountWrapper className="account-wrapper">

                    <S.AccountCloseBtn src={AccountCloseBtn} onClick={toggleAccountModal.bind(this, false)}></S.AccountCloseBtn>

                    <S.AccountBottom className="account-bottom">

                        <S.AccountSidebar className="account-sidebar">

                            <S.UserInfo>
                                <S.UserName>{firstName} {lastName}</S.UserName>
                                <S.UserEmail>{email}</S.UserEmail>
                            </S.UserInfo>

                            <AccountMenu freebetCount={this.state.freebetCount}/>

                            <AccountSessionTimer/>

                        </S.AccountSidebar>

                        <S.AccountBody className="account-body">
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <AccountRoute toggleAccountModal={toggleAccountModal.bind(this, false)} accountData={accountData}/>
                            </Scrollbars>
                        </S.AccountBody>

                    </S.AccountBottom>

            </S.AccountWrapper>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Account: {accountData}} = state;
    return {
        accountData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFreebetByStatus: bindActionCreators(fetchFreebetByStatus, dispatch),
        loadVeryficationStatus: bindActionCreators(loadVeryficationStatus, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account));
