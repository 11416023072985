import styled from 'styled-components';
import {rem} from 'polished';

const FontFamilyMulish = ({theme:{$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;

const S = {};

S.LimitsTable = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2em auto;
    background: ${({theme:{$accountResponsibleGameTableBgColor}})=> $accountResponsibleGameTableBgColor};
    padding: 20px 30px;
`;

S.FlexTable = styled.div`
    font-family: ${FontFamilyMulish};
    display: flex;
    flex-flow: row wrap;
    font-size: ${rem('12px', baseFont)};
    color: ${({theme:{$accountResponsibleGameTableColor}})=> $accountResponsibleGameTableColor};
    font-weight: 600;
  
    &.header {
        font-family: ${FontFamilyMulish};
        font-weight: 700;
    }
    & .row {
        flex-direction: column;
        font-family: ${FontFamilyMulish};
        font-weight: 600;
    }
    .first {
        display: flex;
        flex-basis: 30%;
        align-items: center;
    }
`;

S.FlexRow = styled.div`
    width: 100%;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

S.Header = styled.div`
    padding: 6px 2px;
    flex-basis: 17.5%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${FontFamilyMulish};
    font-weight: 700;
`;

S.Cell = styled.div`
    padding: 6px 2px;
    flex-basis: 17.5%;
    overflow: hidden;
    text-overflow: ellipsis;
    .text {
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .tooltip-content {
            max-width: 250px;
            border: 1px solid #BAC1D0 !important;
            &::before{
                border-bottom-color: #BAC1D0 !important;
            }
            &.show{
                opacity: 1;
            }
            span {
                max-width: 200px;
                white-space: normal;
                font-size: ${rem('10px', baseFont)};
                color: ${({theme:{$accountResponsibleGameTooltipContentColor}})=> $accountResponsibleGameTooltipContentColor};
            }
        }
`;
S.HelpIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 ${rem('5px', baseFont)};
    border-radius: 50%;
    width: ${rem('14px', baseFont)};
    height: ${rem('14px', baseFont)};
    padding: 0 0 ${rem('2px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameTableHelpBgColor}})=> $accountResponsibleGameTableHelpBgColor};
    color: #fff;
    
    &:before{
     background-color: transparent;
    }
`;

export default S;