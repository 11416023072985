import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import AccountBonusRoute from 'AccountBonusRoute.js';
import S from 'StyledAccountBonus.js';
import {translation} from 'utilsHelper.js';
import {changeDateByType, changeActiveTab, fetchCreditBonusListByFilter} from 'accountBonusActions.js';
import CalendarIcon from 'calendar_icon.png';
import Datepicker from 'Datepicker.js';

class AccountBonus extends Component {

    constructor(props) {
        super(props);
        this.dateStartRef = React.createRef();
        this.dateEndRef = React.createRef();
    }

    componentDidMount = async () => {
        const accountBonusSelector = document.querySelector('.account-bonus');
        if (accountBonusSelector) {
            accountBonusSelector.addEventListener('click', this.handleOutsideClick, false);
        }
    };

    componentWillUnmount = () => {
        const accountBonusSelector = document.querySelector('.account-bonus');
        if (accountBonusSelector) {
            accountBonusSelector.removeEventListener('click', this.handleOutsideClick, false);
        }
    };

    handleOutsideClick = (e) => {
        const datePickerPopperSelector = document.querySelector('.react-datepicker-popper');
        if (datePickerPopperSelector && !datePickerPopperSelector.contains(e.target)) {
            this.dateEndRef?.current?.setOpen(false);
            this.dateStartRef?.current?.setOpen(false);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, fetchCreditBonusListByFilter, activeTab} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };

        changeDateByType('startDate', startDate);

        if(activeTab == 'historical'){
            params['status'] = 'HISTORY'
        }else if(activeTab == 'current'){
            params['status'] = 'ACTIVE';
        }else{
            params['status'] = 'QUEUE';
        }

        fetchCreditBonusListByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, fetchCreditBonusListByFilter, activeTab} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };

        changeDateByType('endDate', endDate);

        if(activeTab == 'historical'){
            params['status'] = 'HISTORY'
        }else if(activeTab == 'current'){
            params['status'] = 'ACTIVE';
        }else{
            params['status'] = 'QUEUE';
        }

        fetchCreditBonusListByFilter(params);
    };

    render() {

        const {match, changeActiveTab, startDate, endDate} = this.props;
        return (
            <S.AccountBonus className="account-bonus">

                <S.AccountBonusTitle>
                    {translation('account_bonus_title')}
                </S.AccountBonusTitle>

                <S.BonusFilters className="bonus-filters">

                    <S.BonusFiltersBtns className="bonus-btns-wrapper">

                        <S.FilterBtn as={NavLink}
                                     to={`${match.path}/current-bonus`}
                                     exact
                                     activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'current')}
                                     data-test='account_bonus_active'>
                            {translation('account_bonus_active')}
                        </S.FilterBtn>
                        <S.FilterBtn as={NavLink}
                                     to={`${match.path}/queue-bonus`}
                                     exact
                                     activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'queue-bonus')}
                            data-test='account_bonus_queue'>
                            {translation('account_bonus_queue')}
                        </S.FilterBtn>
                        <S.FilterBtn as={NavLink}
                                     to={`${match.path}/historical-bonus`}
                                     exact
                                     activeClassName="active"
                                     onClick={changeActiveTab.bind(null, 'historical')}
                            data-test='account_bonus_history'>
                            {translation('account_bonus_history')}
                        </S.FilterBtn>
                    </S.BonusFiltersBtns>

                    <S.FiltersDates className="bet-history-filters-dates">

                        <S.DateLabel dangerouslySetInnerHTML={{__html: translation('account_betHistory_dateRange')}}/>

                        <S.DateWrapper className="date-wrapper">

                            <S.DateIconWrapper>
                                <S.DateIcon src={CalendarIcon}/>
                            </S.DateIconWrapper>

                            <Datepicker
                                ref={this.dateStartRef}
                                selected={startDate}
                                selectsStart
                                dateFormat="dd-MM-yyyy"
                                cb={this.handleChangeStart}
                                popperPlacement="bottom-end"
                            />
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">

                            <S.DateIconWrapper>
                                <S.DateIcon src={CalendarIcon}/>
                            </S.DateIconWrapper>

                            <Datepicker
                                ref={this.dateEndRef}
                                minDate={startDate}
                                selected={endDate}
                                selectsEnd
                                dateFormat="dd-MM-yyyy"
                                cb={this.handleChangeEnd}
                                popperPlacement="bottom-end"
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </S.DateWrapper>

                    </S.FiltersDates>

                </S.BonusFilters>

                <AccountBonusRoute {...this.props}/>

            </S.AccountBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{activeTab,startDate, endDate, limit}}) => {
    return {
        activeTab,
        startDate,
        endDate,
        limit
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        fetchCreditBonusListByFilter: bindActionCreators(fetchCreditBonusListByFilter, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBonus);

