import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import AccountBetHistory from 'AccountBetHistory.js';
import AccountEditProfile from 'AccountEditProfile.js';
import AccountWallet from 'AccountWallet.js';
import AccountCashback from 'AccountCashback.js';
import AccountFreebet from 'AccountFreebet.js';
import AccountBonus from 'AccountBonus.js';
import AccountDeposit from 'AccountDeposit.js';
import AccountWithdraw from 'AccountWithdraw.js';
import ResponsibleGame from 'AccountResponsibleGame.js';
import AccountBetHistoryItemDetails from 'BetHistoryItemDetails.js';
import AccountVirtualBetHistoryItemDetails from 'VirtualBetHistoryItemDetails.js';

const AccountRoute = (props) => {
    const { accountData, toggleAccountModal } = props;
    return (
        <Switch>
            <Route exact path="/edit-profile" render={(props) => <AccountEditProfile {...props} accountData={accountData}/>}/>
            <Route path="/wallet"  render={(props) => <AccountWallet {...props}/>}/>
            <Route path="/bet-history/:transactionId" render={(props) => <AccountBetHistoryItemDetails {...props}/>}/>
            <Route path="/virtuals-bet-history/:transactionId" render={(props) => <AccountVirtualBetHistoryItemDetails {...props}/>}/>
            <Route path="/virtuals-bet-history/" render={(props) => <AccountBetHistory {...props}/>}/>
            <Route path="/bet-history" render={(props) => <AccountBetHistory {...props}/>}/>
            <Route path="/cashback" render={(props) => <AccountCashback {...props}/>}/>
            <Route path="/freebets" render={(props) => <AccountFreebet {...props}/>}/>
            <Route path="/bonus" render={(props) => <AccountBonus {...props}/>}/>
            {/* redirects and closes account modal */}
            <Route path="/wplata" render={(props) => <AccountDeposit {...props} toggleAccountModal={toggleAccountModal}/>}/>
            <Route path="/withdraw" render={(props) => <AccountWithdraw {...props}/>}/>
            <Route path="/responsible-game" render={(props) => <ResponsibleGame {...props}/>}/>
            <Redirect to="/edit-profile"/>
        </Switch>
    )
};

export default AccountRoute;