/* global app */
import React, { useMemo } from 'react'
import { SBPayments } from 'sb-payments-etoto'

const { Summary } = SBPayments

const PaymentSummary = () => {
  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase(), [app.config.lang, app.config.mainCountryCode])

  const handleSpikeClearStyles = () => {
    if($('body').hasClass('modal-open')){
      $('body').removeClass('modal-open')
    }
  }

  return (
    <div onClick={handleSpikeClearStyles}>
        <Summary lang={lang} />
    </div>
  )
}

export default PaymentSummary
