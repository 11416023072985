import styled, {css, keyframes}  from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';

const BetSlipOutcomeOddsColor = ({theme:{$betSlipOutcomeOddsColor}}) => `${$betSlipOutcomeOddsColor}`;
const BetSlipOutcomeOddsBgColor = ({theme:{$betSlipOutcomeOddsBgColor}}) => `${$betSlipOutcomeOddsBgColor}`;
const BetSlipOutcomeEventNameColor = ({theme:{$betSlipOutcomeEventNameColor}}) => `${$betSlipOutcomeEventNameColor}`;
const BetSlipOutcomeRemoveIconColor = ({theme:{$betSlipOutcomeRemoveIconColor}}) => `${$betSlipOutcomeRemoveIconColor}`;
const BetSlipOutcomeGameNameColor = ({theme:{$betSlipOutcomeGameNameColor}}) => `${$betSlipOutcomeGameNameColor}`;
const BetSlipOutcomeNameColor = ({theme:{$betSlipOutcomeNameColor}}) => `${$betSlipOutcomeNameColor}`;
const BetSlipOutcomeBorderColor = ({theme:{$betSlipOutcomeBorderColor}}) => `${$betSlipOutcomeBorderColor}`;
const BetSlipOutcomeBgColor = ({theme:{$betSlipOutcomeBgColor}}) => `${$betSlipOutcomeBgColor}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;

const baseFont = `12px`;

const blinkAnimation = keyframes`
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 0.9;
  }
`;

const S = {};

S.Outcome = styled.div`
   padding: 0;
   display: flex;
   min-height: ${rem('70px', baseFont)};
   background-color: ${BetSlipOutcomeBgColor};
   position:relative;
   &:after{
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid ${BetSlipOutcomeBorderColor};
    bottom: 0;
    width: auto;
   };
   
   &.no-active{
     background-color: #fff;
    opacity: 0.5;
  };
   
   &.unavailable{
   };
   
   &.oddsChanged{
    &.up{
    }
    &.down{
    }
   }
`;

S.BetSlipAccumulator = styled.div`
    max-height: ${({isSmallDesktop})=>(isSmallDesktop==true? `200px`: `250px`)};
    &.has-scrollbar{
        ${S.Outcome}{
            &:after{
              right: 7px;
            }
        }
    }
`;

S.OutcomeBody = styled.div`
    display: flex;
    flex: 1;
    padding: ${rem('10px', baseFont)} ${rem('18px', baseFont)}
`;

S.OutcomeLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

S.OutcomeRightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${rem('50px', baseFont)};
    align-items: flex-end;
`;

S.EventName =  styled.div`
    color: ${BetSlipOutcomeEventNameColor};
    font-size: ${rem('12px', baseFont)};
    margin-bottom: ${rem('4px', baseFont)};
    font-family: ${FontFamilyMulish};
    ${({theme: { lineClamp }}) => lineClamp(2)};
    display: flex;
    font-weight: 400;
`;

S.LiveIndicator = styled.div`
    align-self: center;
    background-color: #CE0006;
    color: #fff;
    height: ${rem('15px', baseFont)};
    text-transform: uppercase;
    flex-shrink: 0;
    margin-left:  ${rem('5px', baseFont)};
    padding: 0 ${rem('5px', baseFont)};
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-weight: bold;
 `;

S.GameName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeGameNameColor};
    font-size: ${rem('10px', baseFont)};
    margin-bottom: ${rem('6px', baseFont)};
    ${({theme: { lineClamp }}) => lineClamp(1)};
`;

S.OutcomeName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeNameColor};
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    margin-bottom: ${rem('4px', baseFont)};
    font-weight: 700;
`;

S.OutcomeOdds = styled.div`
    color: ${BetSlipOutcomeOddsColor};
    font-size: ${rem('12px', baseFont)};
    width: ${rem('50px', baseFont)};
    height: ${rem('22px', baseFont)};
    border-radius: 3px;
    background-color: ${BetSlipOutcomeOddsBgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: ${FontFamily};
`;

S.PrevOdds = styled.span`
    font-size: ${rem('12px', baseFont)};
    position: relative;
    &.up,
    &.down{
         padding-left: 25px;
         &:before{
              left: 5px;
              top: 4px;
              content: '';
              width: 0;
              height: 0;
              position: absolute;
              animation-direction: normal;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: ${blinkAnimation};
              animation-timing-function: ease;
          }
    }
    &.up{
        color: green;
        &:before{
            border-color: green transparent;
	        border-style: solid;
	        border-width: 0px 7px 7px 7px;
        }
    }
    &.down{
        color: red;
        &:before{
            border-color: red transparent;
	        border-style: solid;
	        border-width: 7px 7px 0px 7px;
        }
     }
`;

S.RemoveOutcome = styled(Button)`
    padding: 0;
    background-color: transparent;
    flex-shrink: 0;
    width:  ${rem('9px', baseFont)};
    height:  ${rem('9px', baseFont)};
    line-height: 1;
`;

S.UnavailableOutcome = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
`;

S.RemoveUnavailableOutcome = styled(S.RemoveOutcome)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: ${rem('30px', baseFont)};
  height: ${rem('30px', baseFont)};
  
`;

export default S;