import React from 'react';
import S from 'StyledResetPasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation, redirect} from 'utilsHelper.js';
import _get from 'lodash/get';
import CloseIcon from 'account_close_btn.png';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.RESET_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, submitFailed, dirty}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" placeholder={label} dirty={dirty}/>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ResetPasswordForm = ({ resetPasswordOnSubmit, handleSubmit, onResetPasswordModalClose, submitting, error, submitSucceeded}) => {

    const onFormSubmit = async(values) => {

        try {
            const customerId = location.pathname && location.pathname.replace(/\/set\-new\-password\/(\d+)\/([^\/]+)(?:\/.+)?/, '$1');
            const verificationCode = location.pathname && location.pathname.replace(/\/set\-new\-password\/(\d+)\/([^\/]+)(?:\/.+)?/, '$2');
            const params = {
                password: _get(values, 'newPassword'),
                verificationCode,
                customerId
            };

            await resetPasswordOnSubmit(params);
            //hard redirect to main page to reload url
            window.history.pushState({}, 'redirectHome', '/');
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ResetPasswordForm method="post" onSubmit={handleSubmit(onFormSubmit)} autocomplete="off" noValidate>

            <S.Header>
                <S.Title>
                    {translation('account_unloggedPanel_header_newPassword')}
                </S.Title>
                <S.CloseButton src={CloseIcon} onClick={onResetPasswordModalClose}/>
            </S.Header>

            <S.Body>
                {submitSucceeded && (
                    <S.SubmissionSuccess className="submission-success">
                        {translation('setNewPassword_success')}
                    </S.SubmissionSuccess>
                )}

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Field
                    name="newPassword"
                    type="password"
                    component={renderField}
                    label={translation('newPassword')}
                />
                <Field
                    name="confirmPassword"
                    type="password"
                    component={renderField}
                    label={translation('confirmNewPassword')}
                />

                <S.SubmitBtn type="submit" disabled={submitting || submitSucceeded}>
                    {translation('setNewPassword')}
                </S.SubmitBtn>

            </S.Body>

        </S.ResetPasswordForm>
    );
};

export default reduxForm({
    form: 'resetPasswordForm',
    validate
})(ResetPasswordForm);
