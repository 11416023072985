import styled from 'styled-components';
import {rem} from 'polished';
import {Button} from 'global/styles/styles.js';
import StyledDatepicker from 'StyledDatepicker.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;

const AccountDateBorderColor = ({theme: {$accountDateBorderColor}}) => `${$accountDateBorderColor}`;
const AccountDateIconBackgroundColor = ({theme: {$accountDateIconBackgroundColor}}) => `${$accountDateIconBackgroundColor}`;
const AccountDateInputColor = ({theme: {$accountDateInputColor}}) => `${$accountDateInputColor}`;
const AccountDateLabelColor = ({theme: {$accountDateLabelColor}}) => `${$accountDateLabelColor}`;

const baseFont = `12px`;
const S = {};

S.AccountBonus = styled.div`
    width: 100%;
    padding: 3.3rem 2.5rem 0;
    padding-left: 30.7692px;
    padding-right: 30.7692px;
`;

S.AccountBonusTitle = styled.div`
  font-size: ${rem('20px', baseFont)};
  font-family: ${FontFamily};
  color: #001A5B;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: ${rem('20px', baseFont)};
  padding-bottom: ${rem('24px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
`;

S.BonusFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;

S.BonusFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;

S.BonusFiltersBtns = styled.div`
    display: flex;
    height:  ${rem('30px', baseFont)};
    margin-bottom:  ${rem('15px', baseFont)};
    & > a{
        margin-right:  ${rem('10px', baseFont)};

        &:link {
            text-decoration: none;
        }
        &:last-of-type{
            margin-right: 0;
        }
    }
`;

S.FilterBtn = styled(Button)`
    color: #fff;
    font-weight: 400;
    width: ${rem('100px', baseFont)};
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;;
    white-space: nowrap;
    text-decoration: none;
    background-color: #001A5B;
    text-transform: uppercase;
    font-family: ${FontFamily};
      font-size: ${rem('12px', baseFont)};
      display: flex;
      justify-content: center;
      align-items: center;
   
   &:hover,
    &.active{
  background-color: #0E62C4;
    }
`;

S.Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
`;

S.Head = styled.thead`
`;

S.Body = styled.tbody``;

S.Row = styled.tr`
    border-top: 0;
    
    &:before,
    &:after{
     display: none !important;
    }
    
    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
        background-color: #f3f3f3;
        color: #000;
        border-right: .08rem solid #fff;
        border-top: .08rem solid #fff;
      
        & > td{
            text-align: center;
        }
    }
`;

S.Td = styled.td`
    padding: 0 1.25rem;
    line-height: 4rem!important;
    font-size: 1rem;
    background-color: #f3f3f3;
    color: #000;
    border-right: .08rem solid #fff;
    border-top: .08rem solid #fff;
    vertical-align: top;
`;

S.Th = styled.th`
    background-color: #eaeaea;
    color: #a3a3a3;
    padding: .4rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    border-right: .08rem solid #fff;
    line-height: 1.42857143;
    vertical-align: bottom;
    border-top: 0;
`;

S.BonuskList = styled.div``;


S.FiltersDates = styled.div`
    display: flex;
    margin-left: auto;
    & > div{
        margin-right: ${rem('10px', baseFont)};
        &:first-of-type{}
        &:last-of-type{
             margin-right: 0;
         }
    }
`;

S.DateWrapper = styled.div`
    width: ${rem('90px', baseFont)};
    height: ${rem('30px', baseFont)};
    display: flex;
    position: relative;
    
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: 100%;
       overflow: hidden;
       border-right: 1px solid ${AccountDateBorderColor};
       border-top: 1px solid ${AccountDateBorderColor};
       border-bottom: 1px solid ${AccountDateBorderColor};
       border-radius: 0 4px 4px 0;
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: ${rem('11px', baseFont)}
        font-weight: ${FwRegular};
        width: 100%;
        color: ${AccountDateInputColor};
        padding-left: ${rem('9px', baseFont)};
       
    }
`;


S.DateLabel = styled.div`
    display: flex;
    font-weight: ${FwRegular};
    color: ${AccountDateLabelColor};
    width: ${rem('45px', baseFont)};
    justify-content: center;
    align-items: flex-start;
    font-size: ${rem('12px', baseFont)};
    text-align: right;
    text-transform: uppercase;
`;

S.DateIcon = styled.img``;

S.DateIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${AccountDateIconBackgroundColor};
  width: ${rem('25px', baseFont)};
  border-radius: 4px 0  0 4px;
  flex-shrink: 0;
`;


export default S;