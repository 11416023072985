import React from 'react'
import { Vti } from 'sb-virtuals'
import * as styled from '../StyledVirtual'
import Outcome from '../utils/Outcome'

const { Event, Game } = Vti

const Layout = ({ iframeUrl, iframeHeight, tabsList, events, groups, styles, ...rest }) => (
  <styled.Wrapper>
    <styled.Iframe src={iframeUrl} height={iframeHeight} width={800} />

    {tabsList ? (
      <styled.GamesList>
        {tabsList.map((item) => (
          <styled.GamesItem key={item.name}>
            <styled.GamesItemButton {...item}>
              {item.name}
            </styled.GamesItemButton>
          </styled.GamesItem>
        ))}
      </styled.GamesList>
    ) : null}

    <styled.EventsWrap>
      {events.map(event => (
        <Event {...event} key={event.eventId}>
          {({ groups }) => groups.map(({ gameName, games }) => (
            <styled.GroupTi key={gameName}>
              <styled.EventsDataInfo>
                <styled.EventIcon>
                  <use href={`#sport-${event.sportId}`}></use>
                </styled.EventIcon>
                <styled.GroupNameTi>{gameName}</styled.GroupNameTi>
              </styled.EventsDataInfo>
              <styled.OutcomesListTi>
                {games.map(game => (
                  <Game {...game} key={game.gameId}>
                    {({ outcomes }) => (
                      outcomes.map(outcome => (
                        <styled.OutcomesItemTi key={outcome.outcomeId}>
                          <Outcome isWide virtualSport="VTI" {...outcome} outcomeLive='true' />
                        </styled.OutcomesItemTi>
                      ))
                    )}
                  </Game>
                ))}
              </styled.OutcomesListTi>
            </styled.GroupTi>
          ))}
        </Event>
      ))}
    </styled.EventsWrap>
  </styled.Wrapper>
)

export default Layout
