import styled,{css} from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';
import StyledLoader from 'StyledLoader.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.CashoutForm = styled(Form)`
    width: ${rem('615px', baseFont)};
    padding: 0;
    position: relative;
    
    & #customerAcceptedAmountChange{
        margin: 0 4px 0 0;
    }
`;

S.Row = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`;

S.InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: ${rem('30px', baseFont)};
    color: ${({theme:{$accountProfileBetHistoryCashoutHeaderColor}})=> $accountProfileBetHistoryCashoutHeaderColor};
    background-color: ${({theme:{$accountProfileBetHistoryCashoutHeaderBgColor}})=> $accountProfileBetHistoryCashoutHeaderBgColor};
    height: ${rem('128px', baseFont)};
    font-family: ${FontFamily};
    text-transform: uppercase;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #BAC1D0;
    font-weight: 500;
`;

S.AccountCloseBtn = styled.img`
      position: absolute;
      width: ${rem('35px', baseFont)};
      height: ${rem('35px', baseFont)};
      cursor: pointer;
      right: -18px;
      top: -18px;
      z-index: 1;
`;

S.IconCashout = styled.div`
      width: ${rem('29px', baseFont)};
      height: ${rem('29px', baseFont)};
      margin-right: ${rem('13px', baseFont)};
      line-height: 1;
`;

S.Label = styled.label`
    color: ${({theme:{$accountProfileBetHistoryCashoutLabelColor}})=> $accountProfileBetHistoryCashoutLabelColor};
    font-size:  ${rem('16px', baseFont)};
    font-family: ${FontFamilyMulish};
    margin-bottom: 0;
    & span{
        &.asterisk{
        color: #D13836;
         align-self: flex-start;
        }
    }
`;

S.InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

S.CashoutAmmount = styled.div`
    color: #098E09;
    font-size: ${rem('40px', baseFont)};
    font-family: ${FontFamily};
    font-weight: 500;
    text-transform: uppercase;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border: none;
    color: #098E09;
    font-size: ${rem('40px', baseFont)};
    padding: 0;
    background-color: transparent;
    display: block;
    font-family: ${FontFamily};
    font-weight: 500;
    text-align: center;
    
    &:disabled {
        border: none;
        background-color: #fff;
    }
`;

S.SubmitBtn = styled(Button)`
    border-radius: 25px;
    background-color: #E4202C;
    height:  ${rem('50px', baseFont)};
    line-height: 1;
    font-size:  ${rem('15px', baseFont)};
    padding: 0;
    width:  ${rem('200px', baseFont)};
    color: #fff;
    cursor: pointer;
    font-family: ${FontFamilyMulish};
    font-weight: 700;
    
    ${({disabled})=> {
        return (disabled == true) && `
                background: #eee;
                opacity: 1;
                color: #676767
        `;
    }}
`;

S.Error = styled.div`
    color: red;
    margin: 2px 0 0;
    position: absolute;
    width: 100%;
    text-align: center;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.Stake = styled.div`
    color: ${({theme:{$accountProfileBetHistoryCashoutLabelColor}})=> $accountProfileBetHistoryCashoutLabelColor};
    font-size:  ${rem('16px', baseFont)};
    font-family: ${FontFamilyMulish};
    margin-bottom:  ${rem('5px', baseFont)};
`;

S.PossibleReturn = styled(S.Stake)`
  margin-bottom: 0;
`;

S.Button = styled(S.SubmitBtn)`
  width: ${rem('120px', baseFont)};
`;

S.ConclusionMsg = styled.div`
    width: 100%;
    text-align: center;
    border-radius: 0;
    position: relative;
    font-weight: 600;
    border: none;
    color: #001A5B;
    font-size: ${rem('20px', baseFont)};
    font-family: ${FontFamilyMulish};
`;

S.ConclusionTaxInfo = styled(S.ConclusionMsg)``;

S.TaxInfo = styled.div`
  color: #098E09;
   font-size: ${rem('16px', baseFont)};
      font-family: ${FontFamilyMulish};
          font-weight: 600;
          width: ${rem('350px', baseFont)};
          text-align: center;
`;

S.Overlay = styled.div`
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
`;

S.Odds = styled.div`
    color: ${({theme:{$accountProfileBetHistoryCashoutLabelColor}})=> $accountProfileBetHistoryCashoutLabelColor};
    font-size:  ${rem('16px', baseFont)};
    font-family: ${FontFamilyMulish};
    margin-bottom:  ${rem('5px', baseFont)};
`;

export default S;