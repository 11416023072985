import React, {useState} from 'react';
import S from 'StyledAccountResponsibleGame.js';
import ResponsibleGamingForm from 'ResponsibleGamingForm.js';
import AccountResponsibleGameSelfExclusion from 'AccountResponsibleGameSelfExclusion.js';
import {translation} from 'helpers/utilsHelper.js';
import ResponsibleLimitsTable from 'ResponsibleLimitsTable.js'

const tabs = [
    { label: 'account_responsibleGame_responsibleGameTab', value: 1 },
    { label: 'account_responsibleGame_limitsTab', value: 2 },
  ]

const AccountResponsibleGame = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].value)

    return (
        <S.AccountResponsibleGame>
            <S.Header>
                <S.Title>{translation('account_responsibleGame_title')}</S.Title>
            </S.Header>

            <S.AccountResponsibleGameTabs>
                <S.TabsWrapper>
                    {tabs.map(({ label, value }, index) => {
                        return (
                            <S.Tab key={index} className={`tab ${value === activeTab ? 'active-tab' : ''}`} onClick={() => {setActiveTab(value)}}>{translation(label)}</S.Tab>
                        )
                    })}
                </S.TabsWrapper>
            </S.AccountResponsibleGameTabs>

            {
            activeTab === 1 ? <AccountResponsibleGameSelfExclusion/>
            : 
            activeTab === 2 ?
                <>
                    <ResponsibleLimitsTable/>
                    <ResponsibleGamingForm accountView/> 
                </>
            : null 
            }

        </S.AccountResponsibleGame>
    );
}

export default AccountResponsibleGame;
