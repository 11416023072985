import React, { useMemo } from 'react'
import { translation } from 'helpers/utilsHelper.js'
import { useDispatch } from 'react-redux'
import { addOutcome } from 'betSlipActions.js'
import Virtuals, { MethodProvider } from 'sb-virtuals'
import Layout from '../layouts/LayoutMatch'

const Vbl = () => {
  const vbl = useMemo(() => ({
    url: process.env.VIRTUALS_VBL_URL,
    groups: [
      {
        header: translation('virtuals_vbl_winner'),
        groups: [
          {
            header: translation('virtuals_vbl_winner'),
            outcomes: ['1', '2'],
            accessor: -2527,
          },
          {
            header: translation('virtuals_vbl_halfTimeResult'),
            outcomes: ['1', 'X', '2'],
            accessor: 3,
          },
          {
            header: translation('virtuals_vbl_highestScoringQuarter'),
            outcomes: ['Q1', 'Q2', 'Q3', 'Q4', translation('virtuals_global_equal')],
            accessor: 175,
          },
        ],
      },
      {
        header: translation('virtuals_vbl_totalPoints'),
        groups: [
          {
            header: translation('virtuals_vbl_totalPoints_fullTime'),
            groups: [
              {
                header: translation('virtuals_vbl_totalPoints_fullTime'),
                outcomes: [translation('virtuals_global_underOver')],
                accessor: -2966,
              },
            ],
          },
          {
            header: translation('virtuals_vbl_totalPoints_halfTime'),
            groups: [
              {
                header: translation('virtuals_vbl_totalPoints_halfTime'),
                outcomes: [translation('virtuals_global_underOver')],
                accessor: -2541,
              },
            ],
          },
        ],
      },
      {
        header: translation('virtuals_vbl_winningMargin'),
        groups: [
          {
            header: translation('virtuals_vbl_winningMargin_fullTime'),
            groups: [
              {
                header: '',
                outcomes: [{ main: '1', sub: ['11+', '6-10', '1-5'] }, { main: '2', sub: ['1-5', '6-10', '11+'] }],
                accessor: -341,
              },
            ],
          },
          {
            header: translation('virtuals_vbl_winningMargin_halfTime'),
            groups: [
              {
                header: '',
                outcomes: ['11+', '6-10', '1-5','Draw','1-5', '6-10', '11+'],
                accessor: -2823,
              },
            ],
          },
        ],
      },
      {
        header: translation('virtuals_vbl_pointsSpread'),
        groups: [
          {
            header: translation('virtuals_vbl_pointsSpread_fullTime'),
            groups: [
              {
                header: translation('virtuals_vbl_pointsSpread_fullTime'),
                outcomes: ['1', '2'],
                accessor: -2950,
              },
            ],
          },
          {
            header: translation('virtuals_vbl_pointsSpread_halfTime'),
            groups: [
              {
                header: translation('virtuals_vbl_pointsSpread_halfTime'),
                outcomes: ['1', '2'],
                accessor: -6008,
              },
            ],
          },
        ],
      },
      {
        header: translation('virtuals_vbl_raceToXPoints'),
        groups: [
          {
            header: translation('virtuals_vbl_raceToXPoints'),
            outcomes: ['1', '2'],
            accessor: -2987,
          },
        ],
      },
      {
        header: translation('virtuals_vbl_teamTotalPoints'),
        groups: [
          {
            header: translation('virtuals_vbl_teamTotalPoints_teamOne'),
            outcomes: [translation('virtuals_global_underOver')],
            accessor: 182,
          },
          {
            header: translation('virtuals_vbl_teamTotalPoints_teamTwo'),
            outcomes: [translation('virtuals_global_underOver')],
            accessor: 183,
          },
        ],
      },
    ],
  }), [])

  const dispatch = useDispatch()

  const _addOutcome = (outcome) => dispatch(addOutcome(outcome, true))

  return (
    <MethodProvider method={_addOutcome}>
      <Virtuals config={{ vbl }}>
        {setup => (
          <Layout {...setup} />
        )}
      </Virtuals>
    </MethodProvider>
  )
}

export default Vbl
