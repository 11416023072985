import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {Button} from 'global/styles/styles.js';

const BetSlipTypesTabsBackground = ({theme:{$betSlipTypesTabsBackground}}) => `${$betSlipTypesTabsBackground}`;
const BetSlipTypesTabsTabActiveBgColor = ({theme:{$betSlipTypesTabsTabActiveBgColor}}) => `${$betSlipTypesTabsTabActiveBgColor}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

S.BetSlipTypesTabs = styled.div`
    display: flex;
    height: ${rem('40px', baseFont)};
    background-color: ${BetSlipTypesTabsBackground};
    line-height: 1;
`;

S.BetSlipTypeTab = styled(Button)`
    padding: 0;
    font-size: ${rem('13px', baseFont)};
    width: 50%;
    background-color: ${({isActive}) => (isActive == true) ? css`${BetSlipTypesTabsTabActiveBgColor}`: `transparent`};
    font-weight: ${FwRegular};
    font-family: ${FontFamily};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    &.full-row {
      width: 100%;
    }
    &:after{
      bottom: 0;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: auto;
      height: 2px;
      background-color: #E4202C;
      display: ${({isActive}) => (isActive == true) ? `block`: `none`};
    }
`;


export default S;