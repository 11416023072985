import React from 'react'
import * as styled from '../StyledVirtual'

const Switcher = ({ onClick, isDisabled, text }) => (
  <styled.Switcher onClick={onClick} isDisabled={isDisabled}>
    {text}
  </styled.Switcher>
)

export default Switcher
