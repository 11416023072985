import React, {forwardRef} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import S from 'StyledDatepicker.js';
import {pl} from 'date-fns/locale';

const Datepicker = ({cb, ...rest}, _ref) => {

    const handleChange = (date) => {
        cb(date);
    };

    const daysPL = ['nie.', 'pon.', 'wt.', 'śr.', 'czw.', 'pt.', 'sob.'];

    const monthPL = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];

    registerLocale('pl',  {localize: {day: n => daysPL[n], month: n => monthPL[n]}, formatLong:{}} );

    return (
        <S.Datepicker as={DatePicker} locale="pl" {...rest} onChange={handleChange}  ref={_ref}></S.Datepicker>
    );
};

export default forwardRef(Datepicker);
