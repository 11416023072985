import { addOutcome, clearBetslip } from '../betSlipActions/betSlipActions';
import _each from 'lodash/each';

export const FETCH_BESTSELLERS_OUTCOMES = "FETCH_BESTSELLERS_OUTCOMES";

const fetchBestsellersOutcomesFailure = (error) => {
    return {
        type: `${FETCH_BESTSELLERS_OUTCOMES}_FAILURE`,
        payload: {error},
    };
};

const fetchBestsellersOutcomesSuccess = (bestsellersOutcomes) => {
    return {
        type: `${FETCH_BESTSELLERS_OUTCOMES}_SUCCESS`,
        payload: {bestsellersOutcomes},
    };
};

const fetchBestsellersOutcomesPending = () => {
    return {
        type: `${FETCH_BESTSELLERS_OUTCOMES}_PENDING`,
    };
};

const fetchBestsellersOutcomes = () => {
    return async(dispatch, getState, {BettingApi}) => {
        dispatch(fetchBestsellersOutcomesPending());
        try {
            let {data} = await BettingApi.getBestsellersOutcomes();

            data = data || [];
            dispatch(fetchBestsellersOutcomesSuccess(data));
            dispatch(addBestsellersOutcomesToBetSlip(data));
            return data;
        } catch (e) {
            dispatch(fetchBestsellersOutcomesFailure(e));
            throw e;
        }
    }
};

const addBestsellersOutcomesToBetSlip = (data) => {
    return (dispatch, getState) => {
        dispatch(clearBetslip());
        _each(data, (outcome) => {
            outcome['outcomeOdds'] = outcome['odds'];
            outcome['eventType'] = 1;
            dispatch(addOutcome(outcome, true));
        });
    }
};

export {
    fetchBestsellersOutcomes
};
