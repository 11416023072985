import styled,{css, keyframes}  from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';

const BetSlipOutcomeOddsColor = ({theme:{$betSlipOutcomeOddsColor}}) => `${$betSlipOutcomeOddsColor}`;
const BetSlipOutcomeEventNameColor = ({theme:{$betSlipOutcomeEventNameColor}}) => `${$betSlipOutcomeEventNameColor}`;
const BetSlipOutcomeGameNameColor = ({theme:{$betSlipOutcomeGameNameColor}}) => `${$betSlipOutcomeGameNameColor}`;
const BetSlipOutcomeNameColor = ({theme:{$betSlipOutcomeNameColor}}) => `${$betSlipOutcomeNameColor}`;
const BetSlipOutcomeBorderColor = ({theme:{$betSlipOutcomeBorderColor}}) => `${$betSlipOutcomeBorderColor}`;
const BetSlipCombinationTypesSelectBackground = ({theme:{$betSlipCombinationTypesSelectBackground}}) => `${$betSlipCombinationTypesSelectBackground}`;
const BetSlipCombinationTypesSelectArrowColor = ({theme:{$betSlipCombinationTypesSelectArrowColor}}) => `${$betSlipCombinationTypesSelectArrowColor}`;
const BetSlipCombinationTypesSelectTextColor = ({theme:{$betSlipCombinationTypesSelectTextColor}}) => `${$betSlipCombinationTypesSelectTextColor}`;
const BetSlipSelectBackground = ({theme:{$betSlipSelectBackground}}) => `${$betSlipSelectBackground}`;
const BetSlipSelectArrowColor = ({theme:{$betSlipSelectArrowColor}}) => `${$betSlipSelectArrowColor}`;
const BetSlipSelectTextColor = ({theme:{$betSlipSelectTextColor}}) => `${$betSlipSelectTextColor}`;
const BetSlipBlockSelectBackground = ({theme:{$betSlipBlockSelectBackground}}) => `${$betSlipBlockSelectBackground}`;
const BetSlipBlockSelectArrowColor = ({theme:{$betSlipBlockSelectArrowColor}}) => `${$betSlipBlockSelectArrowColor}`;
const BetSlipBlockSelectTextColor = ({theme:{$betSlipBlockSelectTextColor}}) => `${$betSlipBlockSelectTextColor}`;
const BetSlipBlockSelectBorderColor = ({theme:{$betSlipBlockSelectBorderColor}}) => `${$betSlipBlockSelectBorderColor}`;
const BetSlipBlockHeaderTextColor = ({theme:{$betSlipBlockHeaderTextColor}}) => `${$betSlipBlockHeaderTextColor}`;
const BetSlipBlockHeaderBackground = ({theme:{$betSlipBlockHeaderBackground}}) => `${$betSlipBlockHeaderBackground}`;
const BetSlipBlockBodyBorderColor = ({theme:{$betSlipBlockBodyBorderColor}}) => `${$betSlipBlockBodyBorderColor}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const BetSlipOutcomeBgColor = ({theme:{$betSlipOutcomeBgColor}}) => `${$betSlipOutcomeBgColor}`;
const BetSlipOutcomeOddsBgColor = ({theme:{$betSlipOutcomeOddsBgColor}}) => `${$betSlipOutcomeOddsBgColor}`;

const baseFont = `12px`;
const blinkAnimation = keyframes`
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 0.9;
  }
`;

const S = {};


S.CombinationTypesSelect = styled.select`
    padding: ${rem('18px', baseFont)} ${rem('20px', baseFont)}
    width: 100%;
    cursor: pointer;
    align-items: stretch;
    background-color: #F4F7FC;
    
    & .react-select__menu{
        margin-top: 0;
        left: ${rem('20px', baseFont)};
        right: ${rem('20px', baseFont)};
        width: auto;
        border: 1px solid #C0C3CA;
        top: 44px;
        
        & .react-select__menu-list{
             padding: 0;
            
            &  .react-select__option{
            border-bottom: 1px solid #C0C3CA;
            text-transform: uppercase;
            display: flex;
            align-items: center;
                cursor: pointer;
                height:  ${rem('25px', baseFont)};
                background-color: #fff;
                 font-size:  ${rem('10px', baseFont)};
                 color: #001A5B;
                
                 &:last-of-type{
                 border-bottom: none;
                 }
                 
                &--is-focused,
                &--is-selected{
                  background-color: #F4F7FC;
                }
            }
        }
    }
    
    & .react-select__control{
        cursor: pointer;
        box-shadow: none;
        min-height: 0;
        background-color: #fff;
        height: ${rem('25px', baseFont)};
        border-radius: 3px;
        border: 1px solid #C0C3CA;
        &:hover{
          border: 1px solid #C0C3CA;
        }
        
        & .react-select__dropdown-indicator{
            padding: 3px;
            background: transparent url('/images/select_arrow_grey.png') no-repeat center;
            width:  ${rem('8px', baseFont)};
            height:  ${rem('4px', baseFont)};
            margin-right: ${rem('12px', baseFont)};
            
            & svg{
              display: none;
            }
        }

        & .react-select__indicator-separator {
            display: none;
        }
    
        & .react-select__value-container{
              padding-left: ${rem('12px', baseFont)};
        
            & .react-select__single-value{
                 font-size: ${rem('10px', baseFont)};
                 font-weight: 400;
                 font-family: ${FontFamily};
                 color: #001A5B;
                 padding-left: 0;
                 margin-left: 0;
                 text-transform: uppercase;
            }
        }
    
    }
`;

S.Select = styled.select`
        align-self: center;
        flex-shrink: 0;
        margin-right: ${rem('7px', baseFont)};
        width: ${rem('15px', baseFont)};
        height:  ${rem('15px', baseFont)};
        
                 & .react-select__menu{
                  margin: 0;
                 top: 19px;
                  border-radius: 0 0 3px 3px;
                  & .react-select__menu-list{
                    padding: 0;
                  }
                 
                  & .react-select__option{
                  display: flex;
                  justify-content: center;
                    padding: 0;
                    font-size: ${rem('10px', baseFont)};
                    color: #09256D;
                    font-weight: 400;
                    font-family: ${FontFamily};
                    height:  ${rem('15px', baseFont)};
                    border-bottom: 1px solid #DEEBFF;
                    &:last-of-type{
                      border-bottom: none;
                    }
                    &--is-selected{
                      background-color: #DEEBFF;
                    }
                    
                  }
                 
                 }

           & .react-select__control{
            line-height: 1;
            background-color: transparent;
            min-height: ${rem('15px', baseFont)};
            border: none;
            border: 1px solid #001A5B;
            border-radius: 3px;
            
            & .react-select__value-container{
            padding: 0;
            
                & .react-select__single-value{
                    font-size: ${rem('10px', baseFont)};
                    color: #09256D;
                    font-weight: 400;
                    font-family: ${FontFamily};
                    transform: translate(-50%, -50%);
                    max-width: none;
                    left: 50%;
                    margin: 0;
                }
            }
            
            & .react-select__indicators{
                display: none;
            }
        }
`;

S.Outcome = styled.div`
   padding: 0;
   display: flex;
   min-height: ${rem('70px', baseFont)};
   background-color: ${BetSlipOutcomeBgColor};
   flex-direction: column;
   position: relative;
    
    &:after{
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
     border-bottom: 1px solid ${BetSlipOutcomeBorderColor};
    bottom: 0;
    width: auto;
   };
   
   &.no-active{
    background-color: #fff;
    opacity: 0.5;
  };
   
   &.unavailable{
     background-color: #fff;
     opacity: 0.5;
   };
   
   &.oddsChanged{
    &.up{
       //border: 2px dashed green;
    }
    &.down{
        //border: 2px dashed red;
        
    }
   }
   
   & input{
    margin-right: 5px !important;
   }
`;


S.BetSlipSystem = styled.div`
     max-height: ${({isSmallDesktop})=>(isSmallDesktop==true? `200px`: `250px`)};
    &.has-scrollbar{
        ${S.Outcome}{
            &:after{
              right: 7px;
            }
        }
    }
`;

S.OutcomeHeader = styled.div`
  background-color: #001A5B;
  border-radius: 5px 5px 0 0;
  display: flex;
  font-family: ${FontFamily};
  color: #fff;
  justify-content: space-between;
  height: ${rem('20px', baseFont)};
  padding: 0 ${rem('10px', baseFont)};
  margin: ${rem('10px', baseFont)} ${rem('20px', baseFont)} 0;
  font-size: ${rem('11px', baseFont)};
  align-items: center;
  text-transform: uppercase;
`;

S.OutcomeBody = styled.div`
    display: flex;
    flex: 1;
    padding: ${rem('10px', baseFont)} ${rem('18px', baseFont)};
    position: relative;
`;

S.OutcomeLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

S.OutcomeRightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${rem('50px', baseFont)};
    align-items: flex-end;
`;

S.EventName =  styled.div`
    color: ${BetSlipOutcomeEventNameColor};
    font-size: ${rem('12px', baseFont)};
    margin-bottom: ${rem('4px', baseFont)};
    font-family: ${FontFamilyMulish};
    font-weight: 400;
    
    ${({theme: { lineClamp }}) => lineClamp(2)};
        display: flex;
`;

S.LiveIndicator = styled.div`
    align-self: center;
    background-color: #CE0006;
    color: #fff;
    height: ${rem('15px', baseFont)};
    text-transform: uppercase;
    flex-shrink: 0;
    margin-left:  ${rem('5px', baseFont)};
    padding: 0 ${rem('5px', baseFont)};
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-weight: bold;
 `;

S.GameName =  styled.div`
     display: flex;
    color: ${BetSlipOutcomeGameNameColor};
    font-size: ${rem('10px', baseFont)};
    margin-bottom: ${rem('6px', baseFont)};
    ${({theme: { lineClamp }}) => lineClamp(1)};
`;

S.OutcomeName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeNameColor};
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    margin-bottom: ${rem('4px', baseFont)};
    align-items: center;
    font-weight: 700;
`;

S.OutcomeOdds = styled.div`
    color: ${BetSlipOutcomeOddsColor};
    font-size: ${rem('12px', baseFont)};
    width: ${rem('50px', baseFont)};
    height: ${rem('22px', baseFont)};
    border-radius: 3px;
    background-color: ${BetSlipOutcomeOddsBgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: ${FontFamily};
`;

S.CreateBlockBtn = styled(Button)`
 justify-content: center;
    background-color: #000;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
        margin-top: 10px;
        width: 120px;
`;


S.AccumulatorBtn = styled(Button)`
   justify-content: center;
   width: 120px;
    margin-top: ${rem('10px', baseFont)};
    margin-bottom: ${rem('10px', baseFont)};
    background-color: #000;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: ${rem('8px', baseFont)};
    ${({isActive})=>{
        if(isActive==true){
            return `&:after{
                content: "✓";
                color: #fff;
                margin-left: 10px;
            }`;
        }
    }}
`;

S.PrevOdds = styled.span`
    position: relative;
    margin-left: auto;
    &.up,
    &.down{
         padding-left: 25px;
         &:before{
              left: 5px;
              top: 4px;
              content: '';
              width: 0;
              height: 0;
              position: absolute;
              animation-direction: normal;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: ${blinkAnimation};
              animation-timing-function: ease;
          }
    }
    &.up{
        color: green;
        &:before{
            border-color: green transparent;
	        border-style: solid;
	        border-width: 0px 7px 7px 7px;
        }
    }
    &.down{
        color: red;
        &:before{
            border-color: red transparent;
	        border-style: solid;
	        border-width: 7px 7px 0px 7px;
        }
     }
`;

S.Block = styled.div`
    border: 1px solid ${BetSlipBlockBodyBorderColor};
    box-sizing: border-box;
    background-color: #F4F7FC;
`;

S.BlockHeader = styled(S.OutcomeHeader)``;

S.BlockBody = styled.div`

    & ${S.OutcomeHeader}{
       margin-top: ${rem('5px', baseFont)};
    }
    & ${S.Outcome}{
        border: none;
    }
    & ${S.Select}{
    }
`;

S.RemoveOutcome = styled(Button)`
    padding: 0;
    background-color: transparent;
    flex-shrink: 0;
    width:  ${rem('9px', baseFont)};
    height:  ${rem('9px', baseFont)};
    line-height: 1;
`;


S.Banker = styled(Button)`
    padding: 0;
    background-color: transparent;
    width: 14px;
    flex-shrink: 0;
    ${({isActive})=>{
        if(isActive==true){
            return `
                svg {
                    circle{
                        fill: #88d726 !important;
                    }
                }
            `;
        }
    }}
`;

S.BlockName = styled.div``;

S.BlockOdds = styled.div``;

S.AddAccumulator = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem('20px', baseFont)}  ${rem('10px', baseFont)};
      background-color: #F4F7FC;
`;

S.AddAccumulatorCheckbox = styled.div`
  background-color: #fff;
  margin-right: ${rem('9px', baseFont)};
  width: ${rem('16px', baseFont)};
  height: ${rem('16px', baseFont)};
  border: 1px solid #C0C3CA;
  border-radius: 4px;
  position: relative;
  pointer-events: ${({disabled})=>(disabled==true?`none`:`auto`)};
  cursor: pointer;
  &:after{
    display: ${({isActive})=>(isActive==true?`block`:`none`)};
    content: '\\002714';
    position: absolute;
        left: 3px;
    top: -2px;
  }
`;

S.AddAccumulatorLabel = styled.div`
  font-family: ${FontFamilyMulish};
  font-size: ${rem('11px', baseFont)};
  color: #09256D;
  font-weight: 700;
`;

S.GroupName = styled.div``;

S.Odds = styled.div``;

S.UnavailableOutcome = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
`;

S.RemoveUnavailableOutcome = styled(S.RemoveOutcome)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: ${rem('30px', baseFont)};
  height: ${rem('30px', baseFont)};
  
`;

export default S;