import React, { useMemo } from 'react'
import { translation } from 'helpers/utilsHelper.js'
import Virtuals from 'sb-virtuals'
import LayoutVflm from '../layouts/LayoutVflm'

const Vflm = () => {
  const vflm = useMemo(() => ({
    url: process.env.VIRTUALS_VFLM_URL,
    groups: [
      {
        header: translation('virtuals_vflm_matches'),
        groups: [
          {
            header: translation('virtuals_vflm_matches_result'),
            outcomes: ['1', 'X', '2'],
            accessor: 1,
          },
          {
            header: translation('virtuals_vflm_matches_doubleChance'),
            outcomes: ['1/X', '1/2', 'X/2'],
            accessor: 4,
          },
          {
            header: translation('virtuals_vflm_matches_underOver'),
            outcomes: [translation('virtuals_global_underOver')],
            accessor: 8,
            argument:1.5
          },
        ],
      },
      {
        header: translation('virtuals_vflm_matchDay'),
        day: -1,
        groups: [
          {
            header: translation('virtuals_vflm_matchDay_homeTeamsTotal'),
            outcomes: [],
            accessor: -1001,
          },
          {
            header: translation('virtuals_vflm_matchDay_awayTeamsTotal'),
            outcomes: [],
            accessor: -1002,
          },
          {
            header: translation('virtuals_vflm_matchDay_total'),
            outcomes: [],
            accessor: -1003,
          },
          {
            header: translation('virtuals_vflm_matchDay_mostGoalsHomeOrAwayTeams'),
            outcomes: [],
            accessor: -1004,
          },
          {
            header: translation('virtuals_vflm_matchDay_totalHomeTeamWins'),
            outcomes: [],
            accessor: -1005,
          },
          {
            header: translation('virtuals_vflm_matchDay_totalDraws'),
            outcomes: [],
            accessor: -1006,
          },
          {
            header: translation('virtuals_vflm_matchDay_totalAwayTeamsWins'),
            outcomes: [],
            accessor: -1007,
          },
        ],
      },
      {
        header: translation('virtuals_vflm_season'),
        day: -1,
        groups: [
          {
            header: translation('virtuals_vflm_season_winner'),
            outcomes: [],
            accessor: -2793,
          },
          {
            header: translation('virtuals_vflm_season_top5Finish'),
            outcomes: [],
            accessor: -1008,
          },
          {
            header: translation('virtuals_vflm_season_bottom3Finish'),
            outcomes: [],
            accessor: -1009,
          },
          {
            header: translation('virtuals_vflm_season_headToHead'),
            outcomes: [],
            accessor: -1010,
          },
        ],
      },
    ],
  }), [])

  return (
    <Virtuals config={{ vflm }}>
      {setup => (
        <LayoutVflm {...setup} />
      )}
    </Virtuals>
  )
}

export default Vflm
