import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwMedium = ({theme: {$fwMedium}}) => `${$fwMedium}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const duration = 300;
const baseFont = `12px`;
const S = {};

S.BetHistoryItemDetails = styled.div`
  &.slide-transition-enter {
      max-height: 0px;
      margin-bottom: 3.75rem;
      overflow: hidden;
      transition: max-height ${duration}ms;
  }
  
  &.slide-transition-enter-active {
       max-height: 10000px;
  }
  
  &.slide-transition-exit-active {
       max-height: 0;
  }
`;

S.BetHistoryDetailsWrapper = styled.div`
   width: 100%;
   padding: 2.5rem;
`;

S.BetHistoryDetailsHeader = styled.div`
    display: flex;
    position:relative;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    color: #000;
`;

S.BetHistoryDetailsHeaderActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${rem('30px', baseFont)};
    margin-bottom: ${rem('20px', baseFont)};
    border-bottom: 1px solid #BAC1D0;
`;

S.BetHistoryDetailsHeaderGoBack = styled.div`
    display: flex;
    width: ${rem('175px', baseFont)};
    height: ${rem('30px', baseFont)};
    color: #fff;
    font-size:  ${rem('12px', baseFont)};
    text-transform: uppercase;
    padding: 0  ${rem('14px', baseFont)} 0 ${rem('38px', baseFont)};
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: ${FontFamily};
    font-weight: ${FwRegular};
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsBackButtonBgColor}})=> $accountProfileBetHistoryTransactionDetailsBackButtonBgColor};
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;

    &:link,
    &:visited,
    &:hover,
    &:active{
        text-decoration: none;
    }
`;

S.BackButtonIcon = styled.div`
     position: absolute;
     width:  ${rem('10px', baseFont)};
     height:  ${rem('10px', baseFont)};
     left: ${rem('10px', baseFont)};
`;

S.FreebetIcon = styled.div`
 width:  ${rem('20px', baseFont)};
     height:  ${rem('20px', baseFont)};
     margin-right: ${rem('10px', baseFont)};
`;

S.BetHistoryDetailsTransactionId = styled.div`
  font-family: ${FontFamily};
  font-weight: ${FwMedium};
  color: ${({theme:{$accountBetHistoryTransactionItemId}})=> $accountBetHistoryTransactionItemId};
  font-size:  ${rem('14px', baseFont)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

S.BetHistoryDetailsHeaderTitle = styled.div`
    display: flex;
    padding-bottom: 2px;
    font-size: 1.83rem;
    font-weight: 600;
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderTitle}})=> $accountProfileBetHistoryTransactionDetailsHeaderTitle};
`;

S.BetHistoryDetailsHeaderShareActions = styled.div`
    position:absolute;
    top: ${rem('140px', baseFont)};
    left:5px;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right:15px;
    & .shareText{
      font-size: 1.08rem;
      margin-right:10px;
      text-transform:uppercase;
      line-height: 32px;
    }
    & .shareIcon {
        display: flex;
        width: 32px;
        height: 32px;
        margin-right: 0.67rem;
        background-size: covere;
    }
`;
S.PlaceBetModal= styled.div`
width:100%;`
S.PlaceBetModalTitle=styled.h4`
    font-size: 1.2rem;
    font-weight:700;
    text-align: center;
    margin-bottom: 1.42rem;
`
S.PlaceBetModalMsg = styled.p`
    text-align: center;
    color: #061957;
    margin: 0;
    font-size: 1.2rem;
`;
S.PlaceBetModalButtonsContainer=styled.div`
display:flex;
justify-content:space-between;
`
S.BetHistoryDetailsCopyText= styled.div`
display:flex;
justify-content:center;
align-items:center;
`
S.PlaceSameBetButton=styled.div`
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin: 0.625rem;
    padding:10px;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: ${props=>props.confirm? "#0E62C4" : "#ccc"}
`
S.CouponIcon = styled.div`
margin-left:7px;
  background: url(/images/svg/ikonka-kupon.svg) no-repeat;
  height:19px;
  width:16px ;
`
S.BetHistoryDetailsHeaderRebet = styled.div`
    position:absolute ;
    right: 0;
    display: flex;
    justify-content:center ;
    align-items:center;
    padding:10px 10px;
    border-radius:15px;
    color:#fff;
    font-size:.8rem;
    background-color: #0E62C4;
    font-weight:700;
    text-transform:uppercase;
    cursor: pointer;
`;

S.BetHistoryDetailsHeaderShareFb = styled.div`
    background: url(/images/svg/fb.svg) no-repeat;
`;

S.BetHistoryDetailsHeaderShareTwitter = styled.div`
    background: url(/images/svg/twitterShare.svg) no-repeat;
`;

S.BetHistoryDetailsHeaderShareLink = styled.div`
    background: url(/images/svg/share.svg) no-repeat;
    &:last-child {
        margin-right: 0;
    }
`;

S.InfoContainer = styled.div`
    display: ${({inView})=>(inView == true ? 'flex': 'none')};
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    padding-left: 1.67rem;
    background-color: ${({theme:{$accountProfileBetHistoryShareLinkInfoSuccessBackground}})=> $accountProfileBetHistoryShareLinkInfoSuccessBackground};
    color: ${({theme:{$accountProfileBetHistoryShareLinkInfoSuccessColor}})=> $accountProfileBetHistoryShareLinkInfoSuccessColor};

    & .close {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1.67rem;
        background: url(/images/svg/ic_expand_close.svg) no-repeat;
        background-size: contain;
    }
`;

S.BetHistoryDetailsHeaderInfo = styled.div`
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 1.75rem;
    font-size: 1.17rem;
    font-weight: 400;
    line-height: 1; 
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsMainColor}})=> $accountProfileBetHistoryTransactionDetailsMainColor};
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBgInfo}})=> $accountProfileBetHistoryTransactionDetailsHeaderBgInfo};
`;

S.BetHistoryBlockHeader = styled.div`
    height: ${rem('30px', baseFont)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0 0.83rem;
    font-weight: 600; 
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBlockBg}})=> $accountProfileBetHistoryTransactionDetailsHeaderBlockBg};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBlockTxt}})=> $accountProfileBetHistoryTransactionDetailsHeaderBlockTxt};
`;

S.BetHistoryDetailsHeaderInfoSliptype = styled.div`
    font-weight: 600;
`;

S.BetHistoryDetailsHeaderInfoDate = styled.div`
    margin-left: 2.67rem;
`;

S.BetHistoryDetailsHeaderInfoId = styled.div`
    margin-left: auto;
`;

S.BetHistoryDetailsBody = styled.div`
    width: 100%;
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsMainColor}})=> $accountProfileBetHistoryTransactionDetailsMainColor};
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsBgColor}})=> $accountProfileBetHistoryTransactionDetailsBgColor};
    display: flex;
    min-height: ${rem('60px', baseFont)};
    border-bottom: 1px solid ${({theme:{$accountProfileBetHistoryTransactionDetailsBorderColor}})=> $accountProfileBetHistoryTransactionDetailsBorderColor};
`;


S.BetHistoryDetailsBodyEventCategories = styled.div`
  display: flex;
  font-size: ${rem('11px', baseFont)};
  color: rgba(9, 37, 109, 0.5);
  font-family: ${FontFamilyMulish};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  
  & > div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  & .separator{
    padding: 0  ${rem('5px', baseFont)};
  }
`;

S.BetHistoryDetailsBodyEventInfo = styled.div`
  flex:1 ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - ${rem('60px', baseFont)});
  height: ${rem('60px', baseFont)};
`;

S.BetHistoryDetailsBodyEvent = styled.div`
  display: flex;
  flex: 1 1 40%;
  max-width: 40%;
  padding-right: ${rem('10px', baseFont)};
`;

S.BetHistoryDetailsBodyEventName = styled.div`
  font-family: ${FontFamilyMulish};
  font-size: ${rem('13px', baseFont)};
    white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
`;

S.BetHistoryDetailsBodyEventIcon = styled.i`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${rem('60px', baseFont)};
    font-size: ${rem('20px', baseFont)};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsIcon}}) => $accountProfileBetHistoryTransactionDetailsIcon};
    flex-shrink: 0;
    height: ${rem('60px', baseFont)};
`;

S.BetHistoryDetailsBodyDate = styled.div`
    padding-right: ${rem('10px', baseFont)};
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 9%;
      max-width: 9%;
    font-family: ${FontFamilyMulish};
    font-size: ${rem('11px', baseFont)};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsDateColor}}) => $accountProfileBetHistoryTransactionDetailsDateColor};
    font-weight: 400;
    height: ${rem('60px', baseFont)};
`;

S.BetHistoryDetailsBodyType = styled.div`
    padding-right: ${rem('10px', baseFont)};
  flex: 1 1 15%;
    max-width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({theme:{$accountProfileBetHistoryTransactionDetailsTypeColor}}) => $accountProfileBetHistoryTransactionDetailsTypeColor};
  min-height: ${rem('60px', baseFont)};
 
  & > div{
      &:nth-of-type(1){
         font-size: ${rem('13px', baseFont)};
         font-family: ${FontFamilyMulish};
         font-weight: 700;
      }
      &:nth-of-type(2){
        font-size: ${rem('11px', baseFont)};
        font-family: ${FontFamilyMulish};
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
      }
      &.info-wrapper{
        font-weight: 400;
        padding: 1rem 0;
        & .bold{
          font-weight: 700;
        }
        & > div{
          line-height: 1.25rem;
          margin-bottom: 0.25rem;
        }
      }
  }
`;

S.BetHistoryDetailsBodyOdd = styled.div`
    padding-right: ${rem('10px', baseFont)};
    flex: 1 1 7%;
      max-width: 7%;
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsOddColor}}) => $accountProfileBetHistoryTransactionDetailsOddColor};
  align-items: center;     
  display: flex;
  font-weight: 700;
  height: ${rem('60px', baseFont)};
`;

S.BetHistoryDetailsBodyBetResult = styled(S.BetHistoryDetailsBodyOdd) `
    padding-right: ${rem('10px', baseFont)};
    flex: 1 1 12%;
    max-width: 12%;
    min-height: ${rem('60px', baseFont)};
   
`;

S.BetHistoryDetailsBodyResult = styled(S.BetHistoryDetailsBodyOdd)`
    padding-right: ${rem('10px', baseFont)};
    flex: 1 1 13%;
    max-width: 13%;
    display: flex;
    height: ${rem('60px', baseFont)};
`;

S.BetHistoryDetailsBodyBetResultScore = styled.div`
   text-align: left;
   &.custom-bet{
    height: inherit;
   }
`;

S.BetHistoryDetailsBodyInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    margin-bottom: 1.17rem;
    border-bottom: 2px solid ${({theme:{$accountProfileBetHistoryTransactionHeaderBorder}})=> $accountProfileBetHistoryTransactionHeaderBorder};

    & span {
        font-size: 1rem;
        font-weight: 600;
        color: ${({theme:{$accountProfileBetHistoryTransactionDetailsMainColor}})=> $accountProfileBetHistoryTransactionDetailsMainColor};
    }

    & .details-category {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;

        & .separator {
            display: block;
            margin: 0 0.42rem;
            width: 0.5rem;
            height: 0.5rem;
            background-image: url(/images/svg/arrow-right-separator.svg);
            background-repeat: no-repeat;
        }
    }
`;

S.BetHistoryDetailsBodyTransactionData = styled.div`
    display: flex;
    height: 9.17rem;
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsBg}})=> $accountProfileBetHistoryTransactionDetailsBg};
    border-radius: 0.25rem;
`;

S.BetHistoryDetailsBodyTransactionDataIcon = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 1.33rem;
    width: 10%;

    & > div {
        font-size: 1.83rem;
    }
`;

S.BetHistoryDetailsBodyTransactionDataTypes = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 75%;

    & .details-bet {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        & .event-name  {
            padding: 1.25rem 0 1.17rem 0;
            font-size: 1.33rem;
            font-weight: 600;
        }
        & .game-name  {
            padding-bottom: 0.5rem;
            font-size: 0.92rem;
            line-height: 1;
        }
        & .outcome-name {
            font-size: 1.08rem;
        }
    }
`;

S.BetHistoryDetailsBodyTransactionDataResults = styled.div`
    display: flex;
    flex-direction: column;
    width: 15%;

    & > div {
        display: flex;
        justify-content: flex-end;
        padding-right: 1.33rem;

        &.details-result {
            padding: 1.33rem 1.5rem 2.75rem 0;
            font-size: 16px;
            font-weight: 600;
        }
    }
    & .details-odds {
            display: flex;
            align-items: center;
            justify-content: center;
        }
`;

S.StatusIconWrapper = styled.div`
    height: ${rem('60px', baseFont)};
    width: 4%;
    flex: 1 1 4%;
    display: flex;
`;

S.StatusIcon = styled.div`
        height: ${rem('22px', baseFont)};
        flex: 1;
        background: none;
        flex-shrink: 0;
        align-self: center;

         &.pending_acceptation {
            background: transparent url(/images/icon_pending.png) no-repeat center;
              }
    
              &.won {
              background: transparent url(/images/icon_won.png) no-repeat center;
              }
    
              &.cashout {
                    background: transparent url(/images/icon_cashout.png) no-repeat center;
              }
    
              &.cancel {
               background: transparent url(/images/icon_cancel.png) no-repeat center;
              }
    
              &.cashback {
                    background: transparent url(/images/icon_cashback.png) no-repeat center;
              }
    
              &.lost {
               background: transparent url(/images/icon_lost.png) no-repeat center;
              }
    
              &.placed {
               background: transparent url(/images/icon_pending.png) no-repeat center;
              }
`;

S.BetHistoryItemsFooter = styled.div`
    position:relative ;
    display: flex;
    height: ${rem('130px', baseFont)};
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterBg}})=> $accountProfileBetHistoryTransactionDetailsFooterBg};
    border-radius: 0 0 4px 4px;
    position: relative;
    padding: 0 ${rem('25px', baseFont)};
`;

S.BetHistoryItemsFooterBox = styled.div`
      flex: 1 1 33%;
      &:nth-of-type(1){
        align-items: center;
        display: flex;
        & ul{
            margin-top: 0;
        }
      }
      
`;

S.BetHistoryItemsFooterList = styled(List)`
      margin-top: ${rem('16px', baseFont)};
      list-style: none;
      padding: 0;
      width: 100%;
`;

S.BetHistoryItemsFooterListItem = styled.li`
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom:  ${rem('10px', baseFont)};
      &:last-of-type{
          margin-bottom: 0;
      }
      
      & > div{
         flex: 1 1 50%;
         &:nth-of-type(1){
            font-family: ${FontFamilyMulish};
            font-size: ${rem('11px', baseFont)};
            text-align: left;
            font-weight: 400;
         }
         &:nth-of-type(2){
             text-transform: uppercase;
             font-size: ${rem('12px', baseFont)};
             font-family: ${FontFamily};
             text-align: right;
             font-weight: ${FwRegular};
             
         }
      }
`;

S.BetHistoryItemsFooterInfo = styled.div`
  position: absolute;
  right: 0;
  top: ${rem('100px', baseFont)};
  width: ${rem('237px', baseFont)};
  padding: ${rem('14px', baseFont)} ${rem('24px', baseFont)};
  background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterInfoBg}})=> $accountProfileBetHistoryTransactionDetailsFooterInfoBg};
`;

S.ConfirmedWin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
`;

S.ConfirmedWinLabel = styled.div`
  font-family: ${FontFamily};
  font-size: ${rem('12px', baseFont)};
  text-transform: uppercase;
  font-weight: ${FwRegular};
`;

S.ConfirmedWinAmmount = styled.div`
  font-family: ${FontFamily};
  font-size: ${rem('22px', baseFont)};
  text-transform: uppercase;
  font-weight: ${FwSemibold};
`;

S.PossibleWin = styled(S.ConfirmedWin)``;

S.PossibleWinLabel = styled(S.ConfirmedWinLabel)``;

S.PossibleWinAmmount = styled(S.ConfirmedWinAmmount)``;

S.PossibleConfirmedWin = styled.div`
  ${S.PossibleWinLabel} {
    font-size: ${rem('10px', baseFont)};
  }

  ${S.PossibleWinAmmount} {
    font-size: ${rem('18px', baseFont)};
  }
`;

S.CashoutButton = styled(Button)`
   background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterCashoutButtongBg}})=> $accountProfileBetHistoryTransactionDetailsFooterCashoutButtongBg};
   border-radius: 4px;
   height: ${rem('45px', baseFont)};
   width: 100%;
   color: #fff;
   font-weight: ${FwSemibold};
   font-family: ${FontFamily};
   justify-content: space-between;
   align-items: center;
   text-transform: uppercase;
   display: flex;
   padding: 0 ${rem('10px', baseFont)};
   margin-top: ${rem('10px', baseFont)};
`;

S.CashoutButtonTxt = styled.span`
    font-size: ${rem('11px', baseFont)};
    text-align: left;
`;

S.CashoutButtonAmmount = styled.span`
    font-size: ${rem('18px', baseFont)};
`;

S.ErrorModal = styled.div`
     width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.BetHistoryDetailsHeaderColumns = styled.div`
    background-color: ${({theme:{$accountBetHistoryTransactionItemHeaderBackgroundColor}}) => $accountBetHistoryTransactionItemHeaderBackgroundColor};
    display: flex;
    height: ${rem('30px', baseFont)};
    border-radius: 4px 4px 0 0;
    font-family: ${FontFamily};
    align-items: center;
    
    & > div{
        border: none;
        font-size:  ${rem('13px', baseFont)};
        color: #fff;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: ${rem('10px', baseFont)};

         &.transaction-event{
               flex: 1 1 40%;
               border-right: none;
               padding-left: ${rem('20px', baseFont)};
        }
        
        &.transaction-odd{
                flex: 1 1 7%;
        }
        
        &.transaction-date{
              flex: 1 1 9%;
        }
        
        &.transaction-bet-result{
                  flex: 1 1 12%;
        }
        
        &.transaction-bet-result-icon{
            flex: 1 1 4%;
        }
        
           &.transaction-result{
                  flex: 1 1 13%;
        }
           
        &.transaction-type{
              flex: 1 1 15%;
        }
    }
`;

S.BetHistoryItemsFooterLogo = styled.img`
    margin:  ${rem('25px', baseFont)} auto 0;
`;

S.BetHistoryItemDetailsBlockCombinations = styled.div`
`;

S.BetHistoryItemDetailsBlockCombinationsCaption = styled.div`
    height: ${rem('53px', baseFont)};
    align-items: center;
    display: flex;
    font-family: ${FontFamily};
    font-weight: 500;
    font-size: ${rem('13px', baseFont)};
    text-transform: uppercase;
    color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsCaption}}) => $accountBetHistoryItemDetailsBlockCombinationsCaption};  
`;

S.BetHistoryDetailsHeaderCaption = styled(S.BetHistoryItemDetailsBlockCombinationsCaption)``;

S.BetHistoryItemDetailsBlockCombinationsTable = styled.div``;

S.BetHistoryItemDetailsBlockCombinationsHeader = styled.div`
  background-color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsHeaderBgColor}}) => $accountBetHistoryItemDetailsBlockCombinationsHeaderBgColor};  
  height: ${rem('30px', baseFont)};
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  & > div{
      font-size: ${rem('13px', baseFont)};
      font-family: ${FontFamily};
      font-weight: 500;
      text-transform: uppercase;
      &.combinations_nr{
        padding-left: ${rem('15px', baseFont)};
        flex: 1 1 7%;
      }
      &.combinations_combination{
       flex: 1 1 30%;
      }
      &.combinations_odds{
         flex: 1 1 17%;
      }
      &.combinations_stake{
         flex: 1 1 17%;
      }
      &.combinations_possibleWin{
          flex: 1 1 15%;
      }
      &.combinations_win{
        flex: 1 1 14%;
      }
  }
`;

S.BetHistoryItemDetailsBlockCombinationsBody = styled.div``;

S.BetHistoryItemDetailsBlockCombinationsItem = styled.div`
  background-color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsItemBgColor}}) => $accountBetHistoryItemDetailsBlockCombinationsItemBgColor}; 
  color: ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsItemColor}}) => $accountBetHistoryItemDetailsBlockCombinationsItemColor}; 
  border-bottom: 1px solid  ${({theme:{$accountBetHistoryItemDetailsBlockCombinationsItemBorderColor}}) => $accountBetHistoryItemDetailsBlockCombinationsItemBorderColor}; 
  height: ${rem('40px', baseFont)};
  display: flex;
  align-items: center;
  
  
  &:last-of-type{
    border-bottom: none;
  }
  
  & > div{
      font-family: ${FontFamilyMulish};
      font-size: ${rem('13px', baseFont)};
      padding-right:  ${rem('10px', baseFont)};
      font-weight: 700;
      &.combinations_nr{
        flex: 1 1 7%;
        padding-left: ${rem('15px', baseFont)};
      }
      &.combinations_combination{
       flex: 1 1 30%;
       display: flex;
       line-height: 1;
      }
      &.combinations_odds{
         flex: 1 1 17%;
      }
      &.combinations_stake{
         flex: 1 1 17%;
      }
      &.combinations_possibleWin{
          flex: 1 1 15%;
      }
      &.combinations_win{
        flex: 1 1 14%;
      }
  }
`;

S.Letter = styled.div`
  width: ${rem('20px', baseFont)};
  height: ${rem('20px', baseFont)};
  line-height: ${rem('20px', baseFont)};
  background-color: #02B253;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  border-radius: 50%;
  margin-right:  ${rem('7px', baseFont)};
  font-weight: 700;
  &:last-of-type{
    margin-right: 0;
  }
  &.lost {
      background-color: #EC3839;
  }
`;


export default S;