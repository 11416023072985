export default {
    HOME: {
        SPORT_OFFER: {
            href: '/',
            className: 'sport-offer',
            translationKey: 'submenu_sport_offer'
        },
        PROMOTIONS: {
            href: '/promocje',
            className: 'promotions',
            translationKey: 'submenu_promotions'
        },
        STATISTICS: {
            href: 'https://statystyki.etoto.pl/',
            className: 'statistics',
            translationKey: 'submenu_statistics',
            openInNewTab: true
        },
        HELP: {
            href: '/pomoc',
            className: 'help',
            translationKey: 'submenu_help'
        },
        BLOG: {
            href: 'https://blog.etoto.pl/',
            className: 'blog',
            translationKey: 'submenu_blog',
            openInNewTab: true
        },
        MOBILE_APP: {
            href: '/aplikacja-mobilna',
            className: 'mobile-app',
            translationKey: 'submenu_mobile_app'
        },
    },
    LIVES: {
        OVERVIEW: {
            href: '/zaklady-live',
            className: 'overview',
            translationKey: 'submenu_lives_overview',
        },
        LIVE: {
            href: '/zaklady-live/event',
            className: 'live-event',
            translationKey: 'submenu_lives_liveEvent',
        },
        MULTI: {
            href: '/zaklady-live/multi',
            className: 'multi',
            translationKey: 'submenu_lives_multi',
        },
        CALENDAR: {
            href: '/zaklady-live/calendar',
            className: 'calendar',
            translationKey: 'submenu_lives_calendar',
        }
        // TV_STREAMS: {
        //     href: '/zaklady-live',
        //     className: 'tv-streams',
        //     translationKey: 'submenu_lives_tv_streams',
        // },
        // GAMES_RESULTS: {
        //     href: '/zaklady-live',
        //     className: 'games-results',
        //     translationKey: 'submenu_lives_games_results',
        // }
    }
};