import React, { useMemo, useEffect } from 'react'
import { AnimateSharedLayout } from 'framer-motion'
import { useSelector, useDispatch, Provider } from 'react-redux'
import { addOutcome, startIntervalFetchOddsByOutcomeId, stopIntervalFetchOddsByOutcomeId } from 'betSlipActions.js'
import usePrevious from 'UsePrevious.js'
import store from '../../../store'
import { createSelector } from 'reselect'

import * as styled from 'StyledVirtual'
import _get from "lodash/get";
import _find from "lodash/find";
import _filter from "lodash/filter";

const getOutcomes = ({ BetSlip: { activeTab, betSlips } }) => betSlips[activeTab].outcomes

const getIsActive = (outcomeId) => createSelector(
  [getOutcomes],
  outcomes => outcomes
    .map(({ outcomeId }) => outcomeId)
    .includes(outcomeId)
)

const STATUS_AVAILABLE = 100
const STATUS_LIVE_AVAILABLE = 210
const IS_WON = 1
const IS_LOST = 0

const Outcome = ({ isWide = false, gameStatus = 100, virtualSport = null, outcomeName, outcomeOdds, outcomeId, status, result, ...outcome }) => {

  const onClick = () => dispatch(addOutcome({ outcomeName, outcomeId, outcomeOdds, status, result, virtualSport, ...outcome }, true))

    const prevOutcomeOdds = usePrevious(outcomeOdds);

    useEffect(() => {
        if (prevOutcomeOdds && prevOutcomeOdds != outcomeOdds) {
            const {BetSlip: {activeTab, betSlips}} = store.getState();
            const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
            const outcome = _find(outcomesOnTab, {outcomeId});
            if (outcome) {
                let liveOutcomes = _filter(outcomesOnTab, (o) => (o.outcomeLive == 'true' || o?.virtualSport == 'VTI'));
                if(liveOutcomes.length){
                    dispatch(stopIntervalFetchOddsByOutcomeId());
                    dispatch(startIntervalFetchOddsByOutcomeId(liveOutcomes));
                }

            }
        }
    }, [outcomeOdds])

  const getIsOutcomeActive = useMemo(() => getIsActive(outcomeId), [outcomeId])

  const isActive = useSelector(getIsOutcomeActive)

  const oldOdds = usePrevious(outcomeOdds)

  const isRising = useMemo(() => outcomeOdds > oldOdds, [outcomeOdds, oldOdds])

  const isDropping = useMemo(() => outcomeOdds < oldOdds, [outcomeOdds, oldOdds])

  const isLost = useMemo(() => result === IS_LOST, [result])

  const isWon = useMemo(() => result === IS_WON, [result])

  const isAvailable = useMemo(() => (gameStatus === STATUS_AVAILABLE || gameStatus === STATUS_LIVE_AVAILABLE) && status === STATUS_AVAILABLE, [gameStatus, status])

  const dispatch = useDispatch()

  return (
    <AnimateSharedLayout>
      <styled.OutcomesOutcomeNameContainer>
        {isWide ? (
          <styled.OutcomesOutcomeName>
            {outcomeName}
          </styled.OutcomesOutcomeName>
        ) : null}
      </styled.OutcomesOutcomeNameContainer>
      <styled.OutcomesOutcomeContainer>
        <styled.OutcomesOutcome
          isWide={isWide}
          onClick={onClick}
          isActive={isActive}
          isLost={isLost}
          isWon={isWon}
          isAvailable={isAvailable}
        >
          {outcomeOdds.toFixed(2)}
          {isRising ? (<styled.OutcomesHigh layoutId='caret' />) : null}
          {isDropping ? (<styled.OutcomesLow layoutId='caret' />) : null}
        </styled.OutcomesOutcome>
      </styled.OutcomesOutcomeContainer>
    </AnimateSharedLayout>
  )
}

export default (props) => (
  <Provider store={store}>
    <Outcome {...props} />
  </Provider>
)
