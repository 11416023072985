import React, {PureComponent} from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import S from 'StyledDropdownMenu.js';
import classNames from 'classnames';
import {findCategoryItemByIdLevel, getUrlPreparedString} from 'utilsHelper';
import _map from "lodash/map";
import _filter from "lodash/filter";

class DropdownMenu extends PureComponent {

    static propTypes = {};

    constructor(props) {
        super(props);

        const menuItems = [];
        props.config.forEach((item) => {
            if (item.children) {
                const id = item.categoryId;
                const isOpen = false;
                menuItems.push({id, isOpen});
            }
        });
        this.state = {menuItems};
    }

    toggleDropdown = (e, id) => {
        e.preventDefault();

        const indexOfItem = this.findIndexOfItem(id);
        const item = _head(this.state.menuItems.slice(indexOfItem, indexOfItem + 1));

        this.updateCategoryItem(indexOfItem, item);
    }

    updateCategoryItem = (indexOfItem, item) => {
        this.setState((prevState) => {

            const itemsBeforeIndex = prevState.menuItems.slice(0, indexOfItem);
            const itemsAfterIndex = prevState.menuItems.slice(indexOfItem + 1);
            const nextState = {
                ...prevState,
                menuItems: [...itemsBeforeIndex, {...item, isOpen: !item.isOpen}, ...itemsAfterIndex]
            };
            return nextState;
        })
    }

    addCategoryItem = (id, isOpen = false) => {
        this.setState((prevState) => {
            return {...prevState, menuItems: [...prevState.menuItems, {id, isOpen}]}
        });
    }

    findIndexOfItem = (searchId) => {
        return this.state.menuItems.findIndex(({id}) => id == searchId);
    }

    findItemByCategoryId = (searchId) => {
        return this.state.menuItems.find(({id}) => id == searchId);
    }

    getMenuItem = (menuItem) => {

        const title = menuItem.categoryName;
        const categoryId = menuItem.categoryId;
        const sportId = menuItem.sportId;
        const parentCategory = menuItem.parentCategory;
        const level = menuItem.level;
        const eventsCount = menuItem.eventsCount;
        const categoryUrl = getUrlPreparedString(menuItem.categoryName).toLowerCase();
        // categoryIdArray created from list of custom subpage
        const SPORT_MENU_IDS = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 15, 16, 17, 19, 20, 21, 22, 26, 29, 31, 41, 43, 44, 1860];
        let categoryHref;
        let isInSportMenu = false;
        

        if (menuItem.children && menuItem.children.length > 0) {

            const {isOpen} = this.findItemByCategoryId(categoryId);
            const itemClass = classNames({'is-active': isOpen, 'has-submenu': true, 'dropdown-menu-item': true});
            for(let id of SPORT_MENU_IDS){
                if(id === categoryId &&  !_.isEmpty(categoryUrl)){
                    categoryHref = `/zaklady-bukmacherskie/${categoryUrl}/`;
                    isInSportMenu = true;
                }
            }
            return (
                <S.DropdownMenuItem key={categoryId} className={itemClass} data-level={level} data-parent-id={parentCategory}>

                            <S.DropdownMenuItemInner {... !isInSportMenu && {as: "div"} } className="dropdown-menu-item-inner" {... categoryUrl && {href: categoryHref} } onClick={(e) => {
                            this.toggleDropdown(e, categoryId);
                            }}>
                                {level == 1 && <S.Icon className={`sport-icon sport-${sportId}`}></S.Icon>}
    
                                <S.Title className="title">{title}</S.Title>
    
                                {level == 2 && <S.Arrow className="arrow"></S.Arrow>}
    
                            </S.DropdownMenuItemInner>
                    {isOpen && <DropdownMenu {...this.props} config={menuItem.children} submenu={true}/>}
                </S.DropdownMenuItem>
            );
        } else {

            const sportName = _get(findCategoryItemByIdLevel(categoryId, 1, this.props.orgCategories), 'categoryName');
            const isChecked = this.isChecked(categoryId);
            const chboxClass = classNames({'is-active': isChecked, 'fake-chbox': true});

            const itemClass = classNames({'is-active': (isChecked|| (this.props.thirdLevelSelectedLeague == categoryId)), 'dropdown-menu-item': true});
            const params = [sportName, sportId, categoryId];
            const preparedSportName = getUrlPreparedString(sportName).toLowerCase();

            return (
                <S.DropdownMenuItem key={categoryId} className={itemClass} data-level={level} data-parent-id={parentCategory}>
                    <S.DropdownMenuItemInner className="dropdown-menu-item-inner" href={this.createSeoHrefThirdLevel(...params)} onClick={(e) => {
                        e.preventDefault();
                    }}>

                        <S.FakeChbox className={chboxClass} onClick={(e) => {
                            this.handleCheckbox(e, ...params);
                        }}></S.FakeChbox>

                        <S.Title className="title" onClick={(e)=>{
                            this.props.selectThirdLevelCategory(categoryId);
                            this.redirectToEventList(...params);
                        }}>{title}</S.Title>

                        <S.Arrow className="arrow" onClick={(e)=>{
                            this.props.selectThirdLevelCategory(categoryId);
                            this.redirectToEventList(...params);
                        }}></S.Arrow>

                    </S.DropdownMenuItemInner>
                </S.DropdownMenuItem>
            );
        }
    };

    isChecked = (leagueId) => {
        const {selectedLeagues} = this.props;
        return (selectedLeagues.indexOf(leagueId) != -1);
    };

    checkIfIsEsport = (sportId) => {
        const items = this.props.orgCategories;
        const level1Items = _filter(items, {treatAsSport:-100, level:1});
        let filteredItems = [];
        if (level1Items.length) {
            for (let i = 0; i < items.length; i++) {
                for (let j = 0; j < level1Items.length; j++) {
                    if (items[i].sportId == level1Items[j].sportId) {
                        filteredItems.push(items[i].sportId);
                    }
                }
            }
            return filteredItems.includes(sportId) ? true : false;
        } else {
            return false;
        }
    }

    createEventParams = (sportName, sportId, leagueId) => {
        const {orgCategories} = this.props;
        const normalizeURL = (name) => getUrlPreparedString(name).toLowerCase();
        const findCategoryNameByLevel = (categoryId, level) => {
            return _get(findCategoryItemByIdLevel(categoryId, level, orgCategories), 'categoryName');
        };

        const leaguesArr = String(leagueId).split(',');
        let countryName = _map(leaguesArr, (id) => findCategoryNameByLevel(id, 2));
        let leagueName = _map(leaguesArr, (id) => findCategoryNameByLevel(id, 3));
        countryName = _map([...new Set(countryName)], normalizeURL);
        leagueName = _map([...new Set(leagueName)], normalizeURL);
        sportName = _map([].concat(sportName), normalizeURL);

        const params = {
            selectedSports: sportName,
            filterSportId: sportId,
            categories: leagueId,
            selectedCountries: countryName,
            selectedLeagues: leagueName
        };

        return params;
    }

    createSeoHrefThirdLevel = (sportName, sportId, leagueId) => {
        const params = this.createEventParams(sportName, sportId, leagueId);
        const isEsport = this.checkIfIsEsport(params.filterSportId);
        if (isEsport) {
            return `/esport/${params.selectedSports}/${params.selectedCountries}/${params.selectedLeagues}/${params.categories}`
        } else {
            return `/zaklady-bukmacherskie/${params.selectedSports}/${params.selectedCountries}/${params.selectedLeagues}/${params.categories}`
        }
    }

    redirectToEventList = (sportName, sportId, leagueId) => {
        const params = this.createEventParams(sportName, sportId, leagueId);
        const isEsport = this.checkIfIsEsport(params.filterSportId);
        if (isEsport) {
            app.router.redirect('/esport/:selectedSports/:selectedCountries/:selectedLeagues/:categories', params);
        } else {
            app.router.redirect('/zaklady-bukmacherskie/:selectedSports/:selectedCountries/:selectedLeagues/:categories', params);
        }
    };

    handleCheckbox = (e, sportName, sportId, leagueId) => {
        e.preventDefault();

        let {selectedLeagues, ...rest} = this.props;
        let tempLeagues = [];
        const {prevSortId, changeSportId, selectLeagueById, deselectLeagueById} = rest;
        const target = e.target;


        const div = target.closest('div');
        if (div) {
            if (!div.classList.contains('is-active')) {
                if (sportId != prevSortId) {
                    changeSportId(sportId);
                    selectedLeagues = [];
                }
                if (selectedLeagues.indexOf(leagueId) == -1) {
                    selectLeagueById(leagueId);
                    tempLeagues = [...selectedLeagues, leagueId];
                }
            } else {
                deselectLeagueById(leagueId);
                tempLeagues = selectedLeagues.filter(id => id != leagueId);
            }

            if (!tempLeagues.length) {
                app.router.redirect('/sports/events');
            } else {
                const leaguesIds = tempLeagues.join(',');
                this.redirectToEventList(sportName, sportId, leaguesIds);
            }
        }
    };

    render() {

        const {config} = this.props;
        const options = [];
        config.map((item) => {
            options.push(this.getMenuItem(item));
        });

        if (this.props.submenu && this.props.submenu === true)
            return <S.DropdownSubmenuList className="dropdown-submenu">{options}</S.DropdownSubmenuList>;

        return (
            <S.DropdownMenuList className="dropdown-menu">
                {options}
            </S.DropdownMenuList>
        );
    }
}

export default DropdownMenu;
