import React, {useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import _reduce from 'lodash/reduce';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
import S from 'StyledBetHistoryTransactionItem.js';
import useToggle from 'UseToggle.js'
import ModalOpacity from 'ModalOpacity.js';
import {formatDate} from 'datesHelper.js';
import {translation, decimal} from 'utilsHelper.js'
import betSlipTypes from 'betSlipTypes.enum.js';
import CashoutForm from 'CashoutForm.js';
import {clearBetslip, addOutcome} from 'betSlipActions.js';
import SlipDetailsArrow from 'arrow_slip_details.png';
import {withRouter} from 'react-router-dom';
import FreebetIcon from 'freebet_icon.svg';
import {getPolishTransactionType,getTransactionResultStatusText} from 'helpers/transactionsHelper.js';
import { sl } from 'date-fns/locale';

const BetHistoryTransactionItem = ({
                                       transaction,
                                       transactionsList,
                                       currencyCode,
                                       index,
                                       cashouts,
                                       historyFreebets,
                                       prepareCashout,
                                       processCashout,
                                       refreshBetHistoryList,
                                       toggleAccountModal,
                                       toggleLiveCurtainForTxId,
                                       history
                                   }) => {

    const {
        transactionId,
        regDate,
        stake,
        possibleReturn,
        confirmedReturn,
        bonus = 0,
        statusCode,
        slipType,
        curtainActive = false,
        maxAmount = '',
        transactionDetails,
        transactionType,
        cashBackId,
        cashBackReturn,
        tax,
        totalOddsWithoutSalesTaxFactor,
        slipCombinations = null,
        isFreebet,
    } = transaction;

    const [showCashoutModal, toggleCashoutModal] = useToggle(false);
    const [showCashoutErrorModal, toggleCashoutErrorModal] = useToggle(false);
    const [cashoutErrorCode, setCashoutErrorCode] = useState('');
    const dispatch = useDispatch();

    const minMigratedSlipId = 1000000000;

    const getTransactionType = useMemo(() => {
        return getPolishTransactionType(transaction);
    }, []);

    const isRebetPossible = (index) => {
        let possibleRebet = false;
        const now = new Date().getTime();
        for (let i = 0; i < transactionsList[index].transactionDetails.length; i++) {
            if (transactionsList[index].transactionDetails[i].eventStart >= now) {
                possibleRebet = true;
                break;
            }
        }
        return possibleRebet;
    };

    const hasCancelledCombination = () => {
        const transactionDetails = transaction.transactionDetails;
        const isCancelledComb = transactionDetails?.every(detail => detail.statusCode === 4) ?? false;

        return isCancelledComb;
    };

    const addRebet = (index) => {
        try {

            dispatch(clearBetslip());
            const now = new Date().getTime();
            dispatch({type: 'CHANGE_SLIP_STAKE', payload: {stake: _get(transactionsList[index], 'stake')}});

            for (let i = 0; i < transactionsList[index].transactionDetails.length; i++) {
                if (transactionsList[index].transactionDetails[i].eventStart >= now) {
                    const outcome = transactionsList[index].transactionDetails[i];
                    if (outcome.outcomeId != -1) {
                        outcome['outcomeOdds'] = outcome['odds'];
                        outcome['eventType'] = outcome['live'] ? 2 : 1;
                        dispatch(addOutcome(outcome, true));
                    }
                }
            }
            toggleAccountModal(false);

        } catch (error) {
            console.log({error});
        }
    };

    const getTransactionStatusClass = (statusCode, stake, confirmedReturn, pretty, cashBackReturn, isFreebet) => {

        if (cashBackReturn) {
            return 'cashback';
        }

        let resultText = '';
        const hasCancelledComb = hasCancelledCombination();

        if (statusCode == 2) {

            if ([0, 100].indexOf(slipType) != -1) {
                if ((confirmedReturn + bonus) >= stake || (isFreebet && confirmedReturn > 0) || (isFreebet && hasCancelledComb)) {
                    resultText = 'won';
                } else {
                    resultText = 'lost';
                }
            } else {
                if (confirmedReturn > 0) {
                    resultText = 'won';
                } else {
                    resultText = 'lost';
                }
            }

        } else {

            switch (statusCode) {
                case 0:
                    resultText = 'pending_acceptation';
                    break;
                case 1:
                    resultText = 'placed';
                    break;
                case 2 :
                    resultText = 'won';
                    break;
                case 3 :
                    resultText = 'lost';
                    break;
                case 4 :
                case 10 :
                    resultText = 'cancel';
                    break;
                case 5 :
                    resultText = 'cashout';
                    break;
            }
        }

        if (pretty && resultText) {
            resultText = resultText.substring(0, 1).toUpperCase() + resultText.substring(1, resultText.length);
        }

        return resultText;
    };

    const toggleCashout = async (transactionId) => {
        try {
            const maxAmount = await prepareCashout(transactionId);
            toggleCashoutModal();
        } catch (errorCode) {
            setCashoutErrorCode(errorCode);
            toggleCashoutErrorModal();
        }
    };

    const openCashoutModal = (transactionId, e) => {
        e.stopPropagation();
        toggleCashout(transactionId);
    };

    const checkCashout = (slipId) => {
        const multiCashoutAvailable = process.env.MULTI_CASHOUT_AVAILABLE && JSON.parse(process.env.MULTI_CASHOUT_AVAILABLE);
        if (multiCashoutAvailable) {
            return cashouts && (slipId in cashouts) && getCashoutAmmountForTxId(slipId) != null;
        } else {
            return (cashouts && cashouts.indexOf(slipId) !== -1);
        }
    };

    const getCashoutAmmountForTxId = (slipId) => {
        return _get(cashouts, [slipId, 'currentCashoutAmount']);
    };

    const checkFreebet = (slipId) => {
        const historyFreebetList = _reduce(historyFreebets, (initialArray, {betSlipId}) => {
            return [...initialArray, betSlipId];
        }, []);
        return (historyFreebetList && historyFreebetList.indexOf(slipId) !== -1);
    };

    const parseSlipType = (slipType) => {
        return betSlipTypes.getByType(slipType).name;
    };

    const goToTransactionDetails = (transactionId) => {
        if (transactionDetails) {
            history.push(`/bet-history/${transactionId}`);
        }
    }

    const transactionStatusClass = getTransactionStatusClass(statusCode, stake, confirmedReturn, false, cashBackReturn, isFreebet);
    const transactionStatus = transactionStatusClass;
    const cashoutFormProps = {
        toggleLiveCurtainForTxId,
        curtainActive,
        refreshBetHistoryList,
        transactionId,
        stake,
        possibleReturn,
        maxAmount,
        toggleCashoutModal,
        toggleCashoutErrorModal,
        prepareCashout,
        currencyCode,
        cachoutOnSubmit: processCashout,
        initialValues: {maximumAmount: maxAmount},
        transactionOdds: decimal(totalOddsWithoutSalesTaxFactor),
    };

    const getCombinationApplicableBonusAmount = useMemo(() => {
        let combinationApplicableBonus = 0;
        if (slipCombinations?.combinations) {
            for (let combination of slipCombinations.combinations) {
                if (combination.confirmedReturn) {
                    combinationApplicableBonus = combinationApplicableBonus + combination.bonus;
                }
            }
        }
        return combinationApplicableBonus
    },[]);

    const getTransactionResult = useMemo(() => {

        let ammount = null;
        let translationKey = null;
        let isWon = false;
        let isCombinationWon = false;
        if (transactionStatus == 'won') {
            isWon = true;
            ammount = (isFreebet && hasCancelledCombination()) ? decimal(0) : (confirmedReturn > 0 ? decimal(confirmedReturn + bonus) : confirmedReturn);
            translationKey = translation('transactionDetails_betSlipWon');
        } else if ( transactionStatus == 'placed' && (confirmedReturn !== 0 || null)) {
            isCombinationWon = true;
            ammount = confirmedReturn > 0 ? decimal(confirmedReturn + getCombinationApplicableBonusAmount) : confirmedReturn;
            translationKey = translation('transactionDetails_CombinationWon');
        } else if (transactionStatus == 'cashout') {
            isWon = true;
            ammount = decimal(confirmedReturn);
            translationKey = translation('transactionDetails_betSlipCashout');
        } else if (transactionStatus == 'lost') {
            ammount = decimal(0);
            translationKey = translation('transactionDetails_betSlipLost');
        } else if (transactionStatus == 'cancel') {
            ammount = decimal(confirmedReturn);
            translationKey = translation('transactionDetails_betSlipCancel');
        } else if (transactionStatus == 'cashback') {
            ammount = decimal(cashBackReturn);
            translationKey = translation('transactionDetails_betSlipCashback');
        } else {
            return null;
        }

        return (
            <>
                <S.TransactionTitle>{translationKey}</S.TransactionTitle>
                <S.TransactionAmmount isWon={isWon} isCombinationWon={isCombinationWon}>{ammount} {currencyCode}</S.TransactionAmmount>
            </>
        )

    }, []);

    return (
        <S.BetHistoryItemWrapper className="bet-history-item-wrapper">

            <ModalOpacity isOpen={showCashoutModal} toggleOpen={toggleCashoutModal} showHeaderIcon={false}
                          title="Cashout" padding={0} showHeader={false}>
                <CashoutForm {...cashoutFormProps}></CashoutForm>
            </ModalOpacity>

            <ModalOpacity isOpen={showCashoutErrorModal} toggleOpen={toggleCashoutErrorModal} showHeaderIcon={false}
                          showHeaderTitle={false} padding="8px">
                <S.ErrorModal>
                    <S.ErrorModalTitle>{translation('account_betHistoryTransactionItem_cashout_error_title')}</S.ErrorModalTitle>
                    <S.ErrorModalMessage>{translation('account_betHistoryTransactionItem_cashout_error_' + cashoutErrorCode)}</S.ErrorModalMessage>
                </S.ErrorModal>
            </ModalOpacity>

            <S.BetHistoryItem className="bet-history-item" onClick={goToTransactionDetails.bind(null, transactionId)}>

                <div className={`transaction-status ${transactionStatusClass}`}></div>

                <div className="transaction-type">

                    <span>{getTransactionType}</span>

                    {(typeof bonusId != 'undefined') && (
                        <span>
                            {translation('account_betHistoryTransactionItem_transactionFromBonusBalance')}
                        </span>
                    )}

                </div>

                <div className={`transaction-amount${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                    {decimal(stake)}&nbsp;{currencyCode}
                    {(process.env.ENABLE_FREEBET && isFreebet) &&
                    <S.FreebetIcon dangerouslySetInnerHTML={{__html: FreebetIcon}}/>}
                </div>

                <div className="transaction-odd">{decimal(totalOddsWithoutSalesTaxFactor)}</div>

                <div className="transaction-result">
                    {getTransactionResult}
                </div>

                {process.env.CASHOUT && (
                    <div className="transaction-cashout">
                        {(checkCashout(transactionId) && !isFreebet) && (
                            <button onClick={openCashoutModal.bind(null, transactionId)}>
                                {getCashoutAmmountForTxId(transactionId)}&nbsp;{currencyCode}
                            </button>
                        )}
                    </div>
                )}

                <div className={`transaction-date${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                    <span>{formatDate(regDate, 'dd-MM-yyyy')}</span>
                    <span>godz. {formatDate(regDate, 'HH:mm:ss')}</span>

                    <S.SlipDetailsArrow src={SlipDetailsArrow}/>
                </div>

            </S.BetHistoryItem>

        </S.BetHistoryItemWrapper>
    );
};

export default withRouter(BetHistoryTransactionItem);
