import styled from 'styled-components';
const S = {};

S.Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 1.3333rem;
    color: ${({theme:{$getBestsellersOutcomesButtonColor}}) => $getBestsellersOutcomesButtonColor};;
    background-color: ${({theme:{$getBestsellersOutcomesBackground}}) => $getBestsellersOutcomesBackground};;
    height: 4.5rem;
    border: none;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    margin: 1rem 0;
`;

export default S;