import React from 'react'
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom'
import { translation } from 'helpers/utilsHelper.js'
import Vflm from './sports/Vflm'
import Vfec from './sports/Vfec'
import Vbl from './sports/Vbl'
import Vti from './sports/Vti'
import * as styled from './StyledVirtual'

const Virtual = () => (
  <Router>
    <>
      <styled.Nav>
        <styled.NavList>
          <styled.NavItem>
            <styled.NavLink exact to='/'>{translation('virtuals_sport_VFLM')}</styled.NavLink>
          </styled.NavItem>
          <styled.NavItem>
            <styled.NavLink to='/vbl'>{translation('virtuals_sport_VBL')}</styled.NavLink>
          </styled.NavItem>
          {process.env.VIRTUALS_VFEC_ENABLED === 'true' ? (
            <styled.NavItem>
              <styled.NavLink to='/vfec'>{translation('virtuals_sport_VFEC')}</styled.NavLink>
            </styled.NavItem>
          ) : null}
          {process.env.VIRTUALS_VTI_ENABLED === 'true' ? (
            <styled.NavItem>
              <styled.NavLink to='/vti'>{translation('virtuals_sport_VTI')}</styled.NavLink>
            </styled.NavItem>
          ) : null}
        </styled.NavList>
      </styled.Nav>
      <Switch>
        <Route exact path='/' component={Vflm} />
        <Route path='/vbl' component={Vbl} />
        {process.env.VIRTUALS_VFEC_ENABLED === 'true' ? (<Route path='/vfec' component={Vfec} />) : null}
        {process.env.VIRTUALS_VTI_ENABLED === 'true' ? (<Route path='/vti' component={Vti} />) : null}
      </Switch>
    </>
  </Router>
)

export default Virtual
