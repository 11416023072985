import styled from 'styled-components';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.AccountActivate = styled.div`
    width: 100%;
    padding: ${rem('30px', baseFont)};
    font-size: ${rem('26px', baseFont)};
    color: #54C341;
    text-transform: uppercase;
`;

S.Error = styled(S.AccountActivate)`
    color: red;
`;

export default S;