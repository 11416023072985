import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountQueueBonus.js';
import {translation, formatMoney, redirect} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import ModalOpacity from 'ModalOpacity.js';
import Pagination from 'Pagination.js';

import {loadCustomerData} from 'customerActions.js';
import {
    setCurrentPage,
    fetchCreditBonusListByFilter,
    fetchCreditBonusListPending,
    fetchCreditBonusListFulfilled,
    fetchCreditBonusListFailure,
    manageCreditBonus
} from 'accountBonusActions.js';
import CloseIcon from 'account_close_btn.png';

class AccountQueueBonus extends Component {

    state = {
        selectedOfferId: null,
        showConfirmationModal: false,
        showInfoModal: false,
        modalTitle: '',
        modalDescription: '',
        infoModalTitle: '',
        infoModalDescription: '',
        status: ''
    };

    setSelectedOfferId = (selectedOfferId) => {
        this.setState((prevState) => {
            return {...prevState, selectedOfferId}
        });
    };

    setStatus = (status) => {
        this.setState((prevState) => {
            return {...prevState, status}
        });
    };

    setInfoModalTextElements = (infoModalTitle, infoModalDescription) => {
        this.setState((prevState) => {
            return {...prevState, infoModalTitle, infoModalDescription};
        });
    };

    setConfirmModalTextElements = (action) => {
        let modalTitle = '';
        let modalDescription = '';
        switch (action) {
            case 'activate':
                modalTitle = translation('account_bonus_activationTitle');
                modalDescription = translation('account_bonus_confirmActivate');
                break;
            case 'cancel':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmCancel');
                break;
            case 'reject':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmReject');
                break;
        }

        this.setState((prevState) => {
            return {...prevState, modalTitle, modalDescription};
        });
    };

    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    renderBonusActionConfirmation = (offerId, status) => {
        this.setStatus(status);
        this.setConfirmModalTextElements(status);
        this.setSelectedOfferId(offerId);
        this.toggleModal('showConfirmationModal');
    };

    componentDidMount = async() => {
        const {fetchCreditBonusListPending, fetchCreditBonusListByFilter, fetchCreditBonusListFailure, fetchCreditBonusListFulfilled} = this.props;

        fetchCreditBonusListPending();

        try {
            const queueBonusPromise = fetchCreditBonusListByFilter({status: 'QUEUE'});
            const result = await Promise.all([queueBonusPromise]);
            fetchCreditBonusListFulfilled(result);
        } catch (error) {
            console.log('AccountCurrentBonus:', error);
            fetchCreditBonusListFailure(error)
        }
    };

    bonusActionAccept = async(offerId, action) => {
        const {manageCreditBonus, loadCustomerData, fetchCreditBonusListByFilter, fetchCreditBonusListFulfilled} = this.props;

        try {
            const result = await manageCreditBonus(action, offerId);
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');

            this.setInfoModalTextElements('success', '');
            loadCustomerData();
            const queueBonusPromise = fetchCreditBonusListByFilter({status: 'QUEUE'});
            const bonusInQueue = await Promise.all([queueBonusPromise]);
            fetchCreditBonusListFulfilled(bonusInQueue);
        } catch (error) {
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');

            this.setInfoModalTextElements('error', translation(error));
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchCreditBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage: offset,
            status
        };

        try {
            await fetchCreditBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    render() {
        const {isPending, bonus, currencyCode, limit} = this.props;
        const {showInfoModal, showConfirmationModal, modalTitle, modalDescription, infoModalTitle, infoModalDescription, status, selectedOfferId} = this.state;

        const queueBonus = _get(bonus, 'queue');
        const queueBonusData = _get(queueBonus, 'data');

        return (
            <S.AccountQueueBonus>

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <ModalOpacity isOpen={showConfirmationModal}
                                      showHeaderIcon={false}
                                      padding={0}
                                      title={modalTitle}
                                      toggleOpen={this.toggleModal.bind(this, 'showConfirmationModal')}>

                            <S.ActivateModal>

                                <S.Description>{modalDescription}</S.Description>

                                <S.BtnsWrapper>
                                    <S.ButtonCancel onClick={this.toggleModal.bind(this, 'showConfirmationModal')}>
                                        {translation('common_cancel')}
                                    </S.ButtonCancel>
                                    <S.ButtonAccept onClick={this.bonusActionAccept.bind(this, selectedOfferId, status)}>
                                        {translation('common_accept')}
                                    </S.ButtonAccept>
                                </S.BtnsWrapper>

                            </S.ActivateModal>

                        </ModalOpacity>

                        <ModalOpacity isOpen={showInfoModal}
                                      showHeader={false}
                                      padding={0}
                                      toggleOpen={this.toggleModal.bind(this, 'showInfoModal')}>

                            <S.ModalInfo>
                                <S.CloseButton src={CloseIcon} onClick={this.toggleModal.bind(this, 'showInfoModal')}/>
                                <S.InfoTitle>{infoModalTitle}</S.InfoTitle>
                                {infoModalDescription && <S.InfoDescription>{infoModalDescription}</S.InfoDescription>}
                            </S.ModalInfo>

                        </ModalOpacity>

                            <S.BonusListWrapper>
                                {_size(queueBonusData) ?
                                    (
                                        <>
                                        <S.BonusList>
                                            {_map(queueBonusData, (bonus)=>{

                                                const {offerId, offerName, nameKey, bonusValue, validTo, offerQueueId, offerPlayerInfo = {}} = bonus;
                                                const {imageUrl, rulesPageUrl} = offerPlayerInfo;
                                                const bonusItem = (

                                                    <S.BonusItem key={offerId}>

                                                        <S.BonusHeader>
                                                            <S.BonusTitle data-translation={nameKey}>
                                                                {translation(nameKey)}
                                                            </S.BonusTitle>
                                                        </S.BonusHeader>

                                                        <S.BonusImageWrapper ratio={2}>
                                                            {imageUrl && <S.BonusImage src={`${imageUrl}`}/>}
                                                        </S.BonusImageWrapper>

                                                        <S.BonusBody>

                                                            <S.BonusBox>
                                                                <S.BonusBoxTitle>
                                                                    {translation('account_bonus_amount')}
                                                                </S.BonusBoxTitle>
                                                                <S.BonusBoxValue>
                                                                    {formatMoney(bonusValue)}&nbsp;{currencyCode}
                                                                </S.BonusBoxValue>
                                                            </S.BonusBox>

                                                            <S.BonusBox>
                                                                <S.BonusBoxTitle>
                                                                    {translation('account_bonus_validToDate')}
                                                                </S.BonusBoxTitle>
                                                                <S.BonusBoxValue>
                                                                    {formatDate(validTo, 'yyyy-MM-dd HH:mm')}
                                                                </S.BonusBoxValue>
                                                            </S.BonusBox>

                                                            <S.BonusBox>

                                                                <S.ActivateButton onClick={this.renderBonusActionConfirmation.bind(this, offerQueueId, 'activate')}>
                                                                    {translation('account_bonus_use')}
                                                                </S.ActivateButton>

                                                            </S.BonusBox>

                                                        </S.BonusBody>

                                                        <S.BonusFooter>
                                                            <S.BonusRegulationLink href={(rulesPageUrl?rulesPageUrl:'/')} target="_blank">
                                                                {translation('account_bonus_regulationLink')}
                                                            </S.BonusRegulationLink>
                                                        </S.BonusFooter>

                                                    </S.BonusItem>
                                                );
                                                return bonusItem;
                                            })}
                                        </S.BonusList>
                                        {_size(queueBonusData) && (
                                            <Pagination currentPage={_get(queueBonus, 'currentPage')}
                                                        limit={limit}
                                                        data={queueBonusData}
                                                        cb={this.goPage.bind(this, 'queue')}/>
                                        )}
                                        </>
                                    )
                                    :
                                    <S.NoResult>
                                        {translation('account_bonus_notFound')}
                                    </S.NoResult>
                                }

                            </S.BonusListWrapper>


                        </>
                    )
                }

            </S.AccountQueueBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchCreditBonusListByFilter: bindActionCreators(fetchCreditBonusListByFilter, dispatch),
        fetchCreditBonusListPending: bindActionCreators(fetchCreditBonusListPending, dispatch),
        fetchCreditBonusListFulfilled: bindActionCreators(fetchCreditBonusListFulfilled, dispatch),
        fetchCreditBonusListFailure: bindActionCreators(fetchCreditBonusListFailure, dispatch),
        manageCreditBonus: bindActionCreators(manageCreditBonus, dispatch),
        loadCustomerData: bindActionCreators(loadCustomerData, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountQueueBonus);
