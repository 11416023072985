import React from 'react';
import S from 'StyledLoginForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'utilsHelper.js';
import CloseIcon from 'account_close_btn.png';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.LOGIN);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, submitFailed, dirty }
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" placeholder={label} dirty={dirty} />
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const LoginForm = ({loginOnSubmit, onLoginModalClose, toggleForgetPasswordModal, handleSubmit, pristine, submitting, error}) => {

    const onFormSubmit = async(values) => {
        try {
            const user = await loginOnSubmit(values);
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.LoginForm method="post" onSubmit={handleSubmit(onFormSubmit)} noValidate>

            <S.Header>
                <S.Title>
                    {translation('loginModal_title')}
                </S.Title>
                <S.CloseButton src={CloseIcon} onClick={onLoginModalClose}/>
            </S.Header>

            <S.Body>
                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Field
                    name="login"
                    type="text"
                    component={renderField}
                    label={translation('loginModal_login')}
                />
                <Field
                    name="password"
                    type="password"
                    component={renderField}
                    label={translation('loginModal_password')}
                />

                <S.RememberMe>
                    <S.RememberMeChbox as={Field} component="input" type="checkbox" name="autologin"/>
                    <S.RememberMeFakeChbox></S.RememberMeFakeChbox>
                    <S.RememberMeText>{translation('loginModal_rememberMe')}</S.RememberMeText>
                </S.RememberMe>

                <S.ForgetPasswordLink onClick={toggleForgetPasswordModal}>
                    {translation('loginModal_forgotPassword')}
                </S.ForgetPasswordLink>

                <S.SubmitBtn type="submit" disabled={submitting}>
                    {translation('loginModal_logIn')}
                </S.SubmitBtn>

            </S.Body>

        </S.LoginForm>
    );
}

export default reduxForm({
    form: 'login',
    validate
})(LoginForm)