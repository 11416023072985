// action creators
import {getBtagCookie} from 'helpers/cookiesHelper.js';

export const LOAD_VIRTUAL_GAMES_LIST = 'LOAD_VIRTUAL_GAMES_LIST';
export const LOAD_VIRTUAL_GAME = 'LOAD_VIRTUAL_GAME';
export const SET_VIRTUAL_PROVIDER = 'SET_VIRTUAL_PROVIDER';
export const SET_VIRTUAL_PROVIDER_LIST = 'SET_VIRTUAL_PROVIDER_LIST';
export const LOAD_IWG_GAMES_LIST = 'LOAD_IWG_GAMES_LIST';
export const LOAD_IMG_GAME = 'LOAD_IMG_GAME';

const integrationIncomeAccessGetBtag = () => {
    return getBtagCookie();
};

const integrationIncomeAccessAcitveIframe = (customerId, ref) => {
    if (getBtagCookie()) {
        let src  = process.env.INTEGRATION_INCOME_ACCESS_PIXEL_SRC
        ref.current.src = src.replace("[playerid]", parseInt(customerId.value) ? parseInt(customerId.value) : parseInt(customerId));
    }
};

const addIncomeAccessData = (registerData) => {
    const incomeAccess = process.env.INTEGRATION_INCOME_ACCESS ? JSON.parse(process.env.INTEGRATION_INCOME_ACCESS) : undefined;
    const btag = integrationIncomeAccessGetBtag();
    
    if (incomeAccess && btag) {
        registerData.clickId = btag;
        registerData.affiliateId = btag;
    }

    return registerData;
};

const setIncomeAccessIframe = (customerId, incomeAccessIframeRef) => {
    const incomeAccess = process.env.INTEGRATION_INCOME_ACCESS ? JSON.parse(process.env.INTEGRATION_INCOME_ACCESS) : undefined;
    const btag = integrationIncomeAccessGetBtag();
    if (incomeAccess && btag) {
        integrationIncomeAccessAcitveIframe(customerId, incomeAccessIframeRef);
    }
};

const integrationBetgeniusCreateScript = (tag, pathParams) => {
    const betgeniusTagsPath = process.env.BETGENIUS_TAGS_PATH;
    const betgeniusTagsUrlId = process.env.BETGENIUS_TAGS_URL_ID;

    const s = document.createElement('script');
    const url = new URL(`${betgeniusTagsPath}/${betgeniusTagsUrlId}/${tag}`);
    if(pathParams != null){
        for(let key in pathParams){
            url.searchParams.set(key, pathParams[key]);
        }
    }
    s.id = `betgenius${betgeniusTagsUrlId}-${tag}`;
    s.src = url;
    s.async = true;
    s.defer = true;

    const tagAlreadyExists = document.querySelector(`#betgenius${betgeniusTagsUrlId}-${tag}`);
    if (tagAlreadyExists) {
        tagAlreadyExists.remove();
    }
    document.head.appendChild(s);
};

const activateSportRadarEventsTrack = (type, modificator, payload) => {
    if (typeof srtmCommands != 'undefined') {
        if(app.config.shouldSportRadarEventUnshift){
            srtmCommands.unshift({
                event: "track." + modificator + "." + type,
                payload: payload
            });
        }else{
            srtmCommands.push({
                event: "track." + modificator + "." + type,
                payload: payload
            });
        }
     
    }
   
}

const getVirutalGames = (provider, format) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        dispatch(loadVirtualGamesPending());

        try {
            const data = await IntegrationApi.getVirutalGames(provider, format);
            dispatch(loadVirtualGamesSuccess(data));
            return data;

        } catch (error) {
            dispatch(loadVirtualGamesFailure(error));
            throw error;
        }
    }
};

const getVirutalGame = (provider, gameId) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        dispatch(loadVirtualGamePending());

        try {
            const data = await IntegrationApi.getVirutalGame(provider, gameId);
            dispatch(loadVirtualGameSuccess(data));
            return data;

        } catch (error) {
            dispatch(loadVirtualGameFailure(error));
            throw error;
        }
    }
};

const getSimpleIframeGame = (provider, gameId = null) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        dispatch(loadSimpeIframeGamePending());

        try {
            const data = await IntegrationApi.getSimpleIframeGame(provider, gameId);
            dispatch(loadSimpeIframeGameSuccess(data.data));
            return data.data;

        } catch (error) {
            dispatch(loadSimpeIframeGameFailure(error));
            throw error;
        }
    }
};

const sendParagonexAuthorizationData = (postData) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        try {
            const {code, data} = await IntegrationApi.sendParagonexAuthorizationData(postData);
            if (code == 200) {
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            throw error;
        }
    }
};

const sendParagonexTransferData = (postData) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        try {
            const {code, data} = await IntegrationApi.sendParagonexTransferData(postData);
            if (code == 200) {
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            throw error;
        }
    }
};

const getIntegration = (config) => async (dispatch, _, { IntegrationApi }) => {
    try {
        const { data } = await IntegrationApi.getIntegration(config)

        return data
    } catch (error) {
        return error
    }
}

const getGoldenRaceGame = ({ device, demo, countryCode }) => async (_, __, { IntegrationApi }) => {
    try {
        const { data } = await IntegrationApi.getGoldenRaceGame({ device, demo, countryCode })
        const result = JSON.parse(data)

        return result
    } catch ({ message }) {
    
    }
}


const getBitvilleIframeData = (demo, device) => {
    return async(dispatch, getState, {IntegrationApi}) => {
        try {
            const {code, data} = await IntegrationApi.getBitvilleIframeData(device, demo)
            if (code == 200) {
                return data;
            }

            throw {message: `error_${code}`};
        } catch (error ) {
            return error;
        }
    }
}

const getIWGgamesList = () => {

    return async(dispatch, getState, {IntegrationApi}) => {
        try {
            dispatch(loadIWGgamesListPending());
            const {code, data} = await IntegrationApi.getIWGgamesList();
            
            if (code == 200){
                dispatch(loadIWGgamesListSuccess(data));
                return data;
            }

            dispatch(loadIWGgamesListFailure({message: `error_${code}`}));
            throw {message: `error_${code}`};
        } catch (error) {
            dispatch(loadIWGgamesListFailure(error));
            throw error;
        }
    }    
};

const getIWGgame = (gameId, realPlay) => {
    return async(dispatch, getState, {IntegrationApi}) => {
        
        try {
            dispatch(loadIMGgamePending());
            const {code, data} = await IntegrationApi.getIWGgame(gameId, realPlay);
            if (code == 200){
                dispatch(loadIMGgameSuccess(data));
                return data;
            }

            dispatch(loadIMGgameFailure({message: `error_$${code}`}))
            throw {message: `error_${code}`};
        } catch (error) {
            dispatch(loadIMGgameFailure(error));
            throw error;
        }
    }
};

const loadVirtualGamesFailure = (error) => {
    return {
        type: `${LOAD_VIRTUAL_GAMES_LIST}_FAILURE`,
        payload: {error}
    }
};

const loadVirtualGamesSuccess = (gamesList) => {
    return {
        type: `${LOAD_VIRTUAL_GAMES_LIST}_SUCCESS`,
        payload: {gamesList}
    }
};

const loadVirtualGamesPending = () => {
    return {
        type: `${LOAD_VIRTUAL_GAMES_LIST}_PENDING`
    }
};

const loadVirtualGameFailure = (error) => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_FAILURE`,
        payload: {error}
    }
};

const loadVirtualGamePending = () => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_PENDING`
    }
};

const loadVirtualGameSuccess = (gameUrl) => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_SUCCESS`,
        payload: {gameUrl}
    }
};

const loadSimpeIframeGameFailure = (error) => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_FAILURE`,
        payload: {error}
    }
};

const loadSimpeIframeGamePending = () => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_PENDING`
    }
};

const loadSimpeIframeGameSuccess = (gameUrl) => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_SUCCESS`,
        payload: {gameUrl}
    }
};

const loadIWGgamesListPending = () => {
    return {
        type: `${LOAD_IWG_GAMES_LIST}_PENDING`
    }
};

const loadIWGgamesListFailure = (error) => {
    return {
        type: `${LOAD_IWG_GAMES_LIST}_FAILURE`,
        payload: {error}
    }
};

const loadIWGgamesListSuccess = (data) => {
    return {
        type: `${LOAD_IWG_GAMES_LIST}_SUCCESS`,
        payload: {data}
    }
};

const loadIMGgamePending = () => {
    return {
        type: `${LOAD_IMG_GAME}_PENDING`
    }
};

const loadIMGgameFailure = (error) => {
    return {
        type: `${LOAD_IMG_GAME}_FAILURE`,
        payload: {error}
    }
}

const loadIMGgameSuccess = (data) => {
    return {
        type: `${LOAD_IMG_GAME}_SUCCESS`,
        payload: {data}
    }
}

/**
 * Smartico Frontend Integration - sets the smartico_user_id and language in the window for smartico library. If the customer is logged in, the customer login and language values should be provided, otherwise both should be null
 *  @param {customerData} - data of the logged in user
*/
const createAndInitSmarticoData = (customerData) => {
     if(!customerData){
        window._smartico_user_id = null
        window._smartico_language = null
        window._smartico_user_hash = null
        return {
            type: 'SMARTICO_NO_USER',
        }
    
    } else {
         window._smartico_user_id = customerData.userId
         window._smartico_language = customerData.language.trim().toLowerCase()
         window._smartico_user_hash = customerData.smarticoHash
         window._smartico.init(app.config.smarticoInitCode, { brand_key: app.config.smarticoBrandKey })
         return {
            type: 'SMARTICO_INIT'
         }
        }
 }

export {
    integrationIncomeAccessGetBtag,
    integrationIncomeAccessAcitveIframe,
    addIncomeAccessData,
    setIncomeAccessIframe,
    integrationBetgeniusCreateScript,
    getVirutalGames,
    getVirutalGame,
    getSimpleIframeGame,
    getIntegration,
	getGoldenRaceGame,
    sendParagonexAuthorizationData,
    sendParagonexTransferData,
    getBitvilleIframeData,
    activateSportRadarEventsTrack,
    createAndInitSmarticoData,
    getIWGgamesList,
    getIWGgame
};
