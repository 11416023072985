export default {
    EDIT_PROFILE: {
        href: '/edit-profile',
        translationKey: 'account_userPanel_editProfile'
    },
    WALLET: {
        href: '/wallet',
        translationKey: 'account_userPanel_wallet'
    },
    DEPOSIT: {
        href: '/wplata',
        translationKey: 'account_userPanel_deposit'
    },
    WITHDRAW: {
        href: '/withdraw',
        translationKey: 'account_userPanel_withdraw'
    },
    SLIP_HISTORY: {
        href: '/bet-history',
        translationKey: 'account_userPanel_betHistory'
    },
    FREEBETS: {
        href: '/freebets',
        translationKey: 'account_userPanel_coupon'
    },
    BONUS: {
        href: '/bonus',
        translationKey: 'account_userPanel_bonus'
    },
    RESPONSIBLE_GAME: {
        href: '/responsible-game',
        translationKey: 'account_userPanel_responsibleGame'
    },
};