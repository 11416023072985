import React, {useEffect} from 'react';
import _map from 'lodash/map';
import Loader from 'Loader.js';
import S from 'StyledPopularCategories.js';
import {redirect, translation, getUrlPreparedString} from 'utilsHelper.js';
import classNames from 'classnames';
import _find from "lodash/find";

const PopularCategories = (props) => {
    const {fetchPopularCategories, popularCategories, isPopularCategoriesPending} = props;

    useEffect(() => {
        fetchPopularCategories();
    }, []);

    const createEventParamsObject = (leagueId) => {
        const normalizeURL = (name) => getUrlPreparedString(name).toLowerCase();
        let {treatAsSport, parentName, categoryName, sportName, sportId} = _find(popularCategories, {categoryId: leagueId});

        parentName = normalizeURL(parentName);
        categoryName =  normalizeURL(categoryName);
        sportName = normalizeURL(sportName);

        const params = {
            selectedSports: sportName,
            filterSportId: sportId,
            categories: leagueId,
            selectedCountries: parentName,
            selectedLeagues: categoryName,
            treatAsSport: treatAsSport
        };
        return params
    }

    const redirectToEventList = (leagueId) => {
        const params = createEventParamsObject(leagueId);
        const isEsport = (params.treatAsSport == -100);
        if (isEsport) {
            redirect('/esport/:selectedSports/:selectedCountries/:selectedLeagues/:categories', params);
        } else {
            redirect('/zaklady-bukmacherskie/:selectedSports/:selectedCountries/:selectedLeagues/:categories', params);
        }
    };

    const createSeoHref = (leagueId) => {
        const params = createEventParamsObject(leagueId);
        const isEsport = (params.treatAsSport == -100);
        if (isEsport) {
            return `/esport/${params.selectedSports}/${params.selectedCountries}/${params.selectedLeagues}/${params.categories}`
        }else{
            return `/zaklady-bukmacherskie/${params.selectedSports}/${params.selectedCountries}/${params.selectedLeagues}/${params.categories}`
        }
    }

    return (
        <S.PopularCategories>

            <S.PopularCategoriesHeader>
                <S.PopularCategoriesHeaderText>
                    {translation('popularCategoriesWidget_title')}
                </S.PopularCategoriesHeaderText>
            </S.PopularCategoriesHeader>


            <S.PopularCategoriesList>

                {isPopularCategoriesPending ?
                    (<Loader/>)
                    :
                    (
                        <>
                            {_map(popularCategories, ({categoryName, categoryId, sportId}) => {
                                const sportIconClass = classNames('sport-icon', {[`sport-${sportId}`]: true});
                                return (
                                    <S.PopularCategoriesListItem key={categoryId}  onClick={redirectToEventList.bind(null, categoryId)}>
                                        <S.PopularCategoriesSeo href={createSeoHref(categoryId)}>
                                            <S.SportIcon className={sportIconClass}/>
                                                <S.PopularCategoriesItemName >
                                                    {categoryName}
                                                </S.PopularCategoriesItemName>
                                        </S.PopularCategoriesSeo>
                                    </S.PopularCategoriesListItem>
                                )
                            })}
                        </>
                    )
                }

            </S.PopularCategoriesList>

        </S.PopularCategories>
    );
};

export default PopularCategories;