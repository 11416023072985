import React, {useEffect} from 'react';
import S from 'StyledRegisterWizardFormStep1.js';
import {Field, reduxForm, formValues} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {isValueUnique} from 'authActions.js';
import classNames from 'classnames';
import Box from 'react-styled-box';
import ReactTooltip from 'react-tooltip';
import useToggle from 'UseToggle.js'
import Loader from 'Loader.js';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER_STEP1);
    return errors;
};

const asyncValidate = async(values, dispatch, props, field) => {
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];

        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            return result;
        } catch (err) {
            throw {[fieldName]: [translation("account_registerForm_" + err)]};
        }
    }
};


const PasswordToggler = () => {
    const [showPassword, togglePassword] = useToggle(false);

    useEffect(() => {
        const passwordFields = document.querySelectorAll('[name="password"], [name="confirmPassword"]');
        if (passwordFields.length) {
            const passwordFieldsDOM = [...passwordFields];
            if (showPassword) {
                passwordFieldsDOM.forEach(el => el.type = 'text');
            } else {
                passwordFieldsDOM.forEach(el => el.type = 'password');
            }
        }
    }, [showPassword]);

    return (
        <S.PasswordToggler onClick={togglePassword}>
            <S.PasswordTogglerIcon isActive={showPassword} className="fa fa-eye"></S.PasswordTogglerIcon>
        </S.PasswordToggler>
    );
};

let CardLMM = ({lmm_card}) => {
    return lmm_card ?
        (
            <Box flexDirection="row">
                <Box width="50%" padding="0 7px 0 0">
                    <Field
                        name="clientNumber"
                        type="text"
                        component={renderField}
                        maxlength="12"
                        label={translation('account_registerForm_clientNumber')}
                    />
                </Box>

                <Box width="50%" padding="0 0 0 7px">
                    <Field
                        name="pinCode"
                        type="text"
                        maxlength="4"
                        component={renderField}
                        label={translation('account_registerForm_pinCode')}
                    />
                </Box>
            </Box>
        ): null;
};

CardLMM = formValues('lmm_card')(CardLMM);

const renderField = ({
    input,
    label,
    type,
    disabled,
    meta: {touched, error, warning, valid, asyncValidating, submitFailed}
}) => {
    const isDisabled = (typeof disabled != 'undefined') ? disabled : false;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    return (
        <S.Row>
            <S.InputWrapper>

                {input.name == 'password' && <PasswordToggler/>}

                <S.Input {...input} type={type} required="required" className={inputClass} disabled={isDisabled}/>
                <S.Label dangerouslySetInnerHTML={{__html:label}}></S.Label>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const CheckboxWithLabelAndError = ({
    input,
    label,
    required,
    meta: {touched, error, warning, submitFailed}
}) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">
                <S.Input {...input} value={input.value} checked={input.value}
                         onChange={event => input.onChange(event.target.checked)} type="checkbox" required={required}/>
                <S.FakeChbox>
                    {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                </S.FakeChbox>
                <S.Label dangerouslySetInnerHTML={{__html:label}}></S.Label>

                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    );
};


const RegisterWizardFormStep1 = ({change, handleSubmit, submitting, error}) => {

    return (
        <S.RegisterWizardFormStep1 className="register-step1" onSubmit={handleSubmit} autocomplete="off" noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <S.Header>
                <S.Title>{translation('account_registerForm_step1')}</S.Title>
            </S.Header>

            <S.Body>

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Box flexDirection="column">

                    <Box>
                        <Field
                            name="email"
                            type="text"
                            component={renderField}
                            label={translation('account_registerForm_email')}
                            autocomplete="off"
                        />
                    </Box>

                    <Box>
                        <Field
                            name="password"
                            type="password"
                            component={renderField}
                            autocomplete="new-password"
                            label={translation('account_registerForm_password')}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="confirmPassword"
                            type="password"
                            component={renderField}
                            autocomplete="new-password"
                            label={translation('account_registerForm_confirmPassword')}
                        />
                    </Box>

                    <Box flexDirection="row">
                        <Box width="50%" padding="0 7px 0 0">
                            <Field
                                name="mobile"
                                type="text"
                                component={renderField}
                                maxlength="9"
                                label={translation('account_registerForm_phoneNumber')}
                            />
                        </Box>

                    </Box>

                    <CardLMM/>

                    <Box>
                        <Field
                            name="rodoClause"
                            component={CheckboxWithLabelAndError}
                            label={translation('account_registerForm_rodoClause')}
                            required={true}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="marketingConsent"
                            component={CheckboxWithLabelAndError}
                            label={translation('account_registerForm_marketing_receiveEmail')}
                            required={false}
                        />
                    </Box>

                    <S.SubmitBtn type="submit"
                                 disabled={submitting}>{translation('account_registerForm_next')}</S.SubmitBtn>

                </Box>

            </S.Body>

            <S.Footer>
                <S.HelpBtn className="online-help" onClick={(e)=>{if(LC_API)LC_API.open_chat_window();return false;}}>

                    <S.HelpIcon data-tip data-for={`help-tooltip`}>&#63;</S.HelpIcon>

                    <ReactTooltip id={`help-tooltip`} type="light">
                        <span>lorem ipsum...</span>
                    </ReactTooltip>

                    {translation('account_registerForm_onlineHelp')}

                </S.HelpBtn>
            </S.Footer>

        </S.RegisterWizardFormStep1>
    );
};


export default reduxForm({
    form: 'registerWizardForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['email', 'mobile'],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(RegisterWizardFormStep1);

