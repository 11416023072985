import React, {memo} from 'react';
import {translation} from 'utilsHelper.js';
import S from 'StyledBetSlipTypesTabs.js';
import _size from 'lodash/size';

const BetSlipTypesTabs = (props) => {
    const {changeSlipType, slipType, hasApprovalButtonsVisibled, outcomes} = props;

    const temporarySystemBetHidden = false;
 
    return (
        <S.BetSlipTypesTabs className="betslip-types-tabs">

            <S.BetSlipTypeTab className={`betslip-types-tab ${(temporarySystemBetHidden) ? 'full-row' : ''}`} 
                              isActive={slipType == 'ACCUMULATOR'}
                              onClick={changeSlipType.bind(null, 'ACCUMULATOR')}
                              disabled={hasApprovalButtonsVisibled}>
                {_size(outcomes) > 1 ? translation('betSlip_tab_accumulator'): translation('betSlip_tab_single')}
            </S.BetSlipTypeTab>

            {!temporarySystemBetHidden && 
            <S.BetSlipTypeTab className="betslip-types-btn"
                              isActive={slipType == 'SYSTEM'}
                              onClick={changeSlipType.bind(null, 'SYSTEM')}
                              disabled={hasApprovalButtonsVisibled}>
                {translation('betSlip_tab_system')}
            </S.BetSlipTypeTab>
            }


        </S.BetSlipTypesTabs>
    );
};

export default memo(BetSlipTypesTabs);
