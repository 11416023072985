import React, {useState, useRef, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import S from 'StyledCashoutForm.js';
import {Field, reduxForm, SubmissionError}  from 'redux-form';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import Box from 'react-styled-box';
import ModalOpacity from 'ModalOpacity.js';
import Loader from 'Loader.js';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import AccountCloseBtn from 'account_close_btn.png';
import IconCashout from 'icon_cashout.svg';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';


const validate = values => {
    if (values.maximumAmount && values.maximumAmount.amount) {
        values.maximumAmount = values.maximumAmount.amount;
    }
    const errors = validation(values, validators.CASHOUT);
    return errors;
};

const renderField = ({
                         input,
                         extraData,
                         type,
                         disabled,
                         customError,
                         meta: {touched, error, warning}
                     }) => {

    if (input.name == 'maximumAmount') {
        input.value = extraData.amount ? extraData.amount : extraData;
    }

    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} disabled={disabled} required="required"/>
                {touched ?
                    ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))
                    :
                    (customError && <S.Error>{customError}</S.Error>)
                }
            </S.InputWrapper>
        </S.Row>
    )
};

let CashoutForm = ({
                       toggleLiveCurtainForTxId,
                       curtainActive,
                       refreshBetHistoryList,
                       dispatch,
                       maxAmount,
                       prepareCashout,
                       cachoutOnSubmit,
                       toggleCashoutModal,
                       stake,
                       possibleReturn,
                       currencyCode,
                       isFullCashoutDisabled,
                       transactionId,
                       handleSubmit,
                       pristine,
                       submitting,
                       valid,
                       error,
                       transactionOdds
                   }) => {

    const [showInfoModal, toggleInfoModal] = useState(false);
    // const [showAmountChangeChbox, toggleAmountChangeChbox] =  useState(false);
    const [infoModalSuccessMsg, setInfoModalSuccessMsg] = useState('');
    const [infoModalAmountChange, toggleInfoModalAmountChange] = useState(false);
    // const [infoCashoutBelowStake, toggleInfoCashoutBelowStake] = useState(false);
    const abortController = useRef(new AbortController());

    useEffect(() => {
        const accountWrapperElem = document.querySelector('.account-wrapper');
        if (accountWrapperElem) {
            accountWrapperElem.classList.add('has-blur');
        }
        return () => {
            if (accountWrapperElem) {
                accountWrapperElem.classList.remove('has-blur');
            }
            abortController.current.abort();
            toggleLiveCurtainForTxId(transactionId, false);
        }
    }, []);


    const onFormSubmit = async(type, values) => {

        const config = {
            slipId: transactionId,
            requestedAmount: ((type == 'full') ? maxAmount.amount ? maxAmount.amount : maxAmount : _get(values, 'partialAmount')),
            type: `cashout${_capitalize(type)}Amount`
        };

        const customerAcceptedAmountChange = _get(values, 'customerAcceptedAmountChange');
        if (customerAcceptedAmountChange) {
            config['customerAcceptedAmountChange'] = customerAcceptedAmountChange;
        }

        try {
            const {currentCashoutAmount = null} = await cachoutOnSubmit(config, abortController.current.signal);
            if(currentCashoutAmount){
                config['requestedAmount'] = currentCashoutAmount;
            }
            const successMsg = `${translation(`account_cashout_cashoutSuccess_${type}`)} ${config.requestedAmount} ${currencyCode}`;

            setInfoModalSuccessMsg(successMsg);
            toggleInfoModal(true);

        } catch ({message, oddsHasChanged = null, newCashoutAmount = null}) {
                if(newCashoutAmount){
                    toggleInfoModalAmountChange(true);
                }
            toggleLiveCurtainForTxId(transactionId, false);
            throw new SubmissionError({_error: message});
        }

    };

    const closeAllModal = async() => {
        toggleCashoutModal();
        try{
            await dispatch(refreshBetHistoryList());
        }catch(error){}
    };

    const showCashoutTaxInfo = useMemo(()=>{
        const cashoutAmount = (maxAmount?.amount??maxAmount);
        const tax = process.env.CASHOUT_TAX;
        return (cashoutAmount>tax);
    }, [maxAmount]);

    return (
        <S.CashoutForm method="post">

            {curtainActive && (
                <S.Overlay>
                    <Loader size="5"/>
                </S.Overlay>
            )}

            <ModalOpacity isOpen={showInfoModal}
                          showHeaderIcon={false}
                          showHeaderTitle={false}
                          toggleOpen={closeAllModal}
                          padding="0"
                          showHeader={false}>

                <Box flexDirection="column" width="100%" padding="20px">
                    <Box flexDirection="column" justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{infoModalSuccessMsg}</S.ConclusionMsg>
                        {showCashoutTaxInfo && <S.ConclusionTaxInfo>{translation('account_cashout_conclusionTaxInfo')}</S.ConclusionTaxInfo>}
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={closeAllModal}>{translation('common_ok')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity>

            <ModalOpacity isOpen={infoModalAmountChange}
                          showHeaderIcon={false}
                          showHeaderTitle={false}
                          toggleOpen={toggleInfoModalAmountChange.bind(null, false)}
                          padding="0"
                          showHeader={false}>

                <Box flexDirection="column" width="100%" padding="20px">
                    <Box justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{translation('account_cashout_amountChangeInfo')}</S.ConclusionMsg>
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={closeAllModal}>{translation('common_ok')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity>
{/* 
            <ModalOpacity isOpen={infoCashoutBelowStake}
                          showHeaderIcon={false}
                          showHeaderTitle={false}
                          toggleOpen={closeAllModal}
                          padding="0"
                          showHeader={false}>

                <Box flexDirection="column" width="100%" padding="20px">
                    <Box justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{translation('account_cashout_cashoutBelowStake')}</S.ConclusionMsg>
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={closeAllModal}>{translation('common_close')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity> */}

            <Box flexDirection="column" padding="0">

                {error && <S.SubmissionError className="submission-error">{translation(`account_${error}`)}</S.SubmissionError>}

                <Box flexDirection="row" alignItems="center" padding="0">

                    <S.InfoContainer className="transactionInfoContainer">

                        <S.IconCashout dangerouslySetInnerHTML={{__html: IconCashout}}/>

                        <div>{translation('account_bettingHistory_cashout')}</div>

                        <S.AccountCloseBtn src={AccountCloseBtn} onClick={toggleCashoutModal}></S.AccountCloseBtn>

                    </S.InfoContainer>

                </Box>

                <Box flexDirection="column" alignItems="center" padding="48px 30px 58px 30px" height={487}>

                    <Box>
                        <S.Label>
                            <span>{translation('account_cashout_odds')}&nbsp;</span>
                        </S.Label>

                        <S.Odds>
                            <span>{transactionOdds}</span>
                        </S.Odds>
                    </Box>

                    <Box>
                        <S.Label>
                            <span>{translation('account_cashout_amountTitle')}&nbsp;</span>
                        </S.Label>

                        <S.Stake>
                            <span>{formatMoney(stake)}</span>&nbsp;
                            <span>{currencyCode}</span>
                        </S.Stake>
                    </Box>

                    <Box>
                        <S.Label>
                            <span>{translation('account_cashout_possibleWinTitle')}&nbsp;</span>
                        </S.Label>

                        <S.PossibleReturn>
                            <span>{formatMoney(possibleReturn)}</span>&nbsp;
                            <span>{currencyCode}</span>
                        </S.PossibleReturn>
                    </Box>

                    <Box margin="50px 0 0 0" flexDirection="column" alignItems="center">
                        <S.Label>
                            <span>{translation('account_cashout_fullTitle')}</span>
                        </S.Label>

                        <S.CashoutAmmount>
                            <span>{(maxAmount?.amount??maxAmount)}</span>&nbsp;
                            <span>{currencyCode}</span>
                        </S.CashoutAmmount>
                    </Box>

                    {/* <Box width="100%">
                        <Field
                            name="maximumAmount"
                            type="hidden"
                            component={renderField}
                            extraData={maxAmount}
                            disabled={true}
                            customError={isFullCashoutDisabled && translation('account_cashout_cashoutBelowStake')}
                        />
                    </Box> */}


                    {showCashoutTaxInfo && (
                        <Box margin="10px 0 0 0">
                            <S.TaxInfo>{translation('account_cashout_taxInfo')}</S.TaxInfo>
                        </Box>
                    )}

                        <Box flexDirection="row" justifyContent="flex-end" alignItems="center" margin="10px 0 0 0">
                            <Field component="input" type="checkbox"  name="customerAcceptedAmountChange" id="customerAcceptedAmountChange"/>&nbsp;
                            <S.Label htmlFor="customerAcceptedAmountChange">{translation('account_cashout_acceptAmountChange')}&nbsp;<span className="asterisk">*</span></S.Label>
                        </Box>

                    <Box margin="auto 0 0 0">
                        <S.SubmitBtn type="button"
                                     onClick={handleSubmit(onFormSubmit.bind(null, 'full'))}
                                     disabled={submitting}>
                            {translation('account_cashout_full')}
                        </S.SubmitBtn>
                    </Box>

                </Box>

            </Box>

        </S.CashoutForm>
    )
};

CashoutForm = reduxForm({
    form: 'cashoutForm',
    validate
})(CashoutForm);

const mapStateToProps = (state, {stake, maxAmount}) => {
    return {
        isFullCashoutDisabled: (stake > _get(maxAmount, ['amount'], maxAmount))
    }
};

export default connect(mapStateToProps)(CashoutForm);

