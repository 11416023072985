import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {List, Button} from 'global/styles/styles.js';
import {rem} from "polished";

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwMedium = ({theme: {$fwMedium}}) => `${$fwMedium}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const AccountWalletTitleColor = ({theme: {$accountWalletTitleColor}}) => `${$accountWalletTitleColor}`;
const AccountWalletTitleBorderColor = ({theme: {$accountWalletTitleBorderColor}}) => `${$accountWalletTitleBorderColor}`;
const AccountDateBorderColor = ({theme: {$accountDateBorderColor}}) => `${$accountDateBorderColor}`;
const AccountDateIconBackgroundColor = ({theme: {$accountDateIconBackgroundColor}}) => `${$accountDateIconBackgroundColor}`;
const AccountDateInputColor = ({theme: {$accountDateInputColor}}) => `${$accountDateInputColor}`;
const AccountDateLabelColor = ({theme: {$accountDateLabelColor}}) => `${$accountDateLabelColor}`;
const AccountWalletHeaderBackgroundColor = ({theme: {$accountWalletHeaderBackgroundColor}}) => `${$accountWalletHeaderBackgroundColor}`;
const AccountWalletFilterButtonBackgroundColor = ({theme: {$accountWalletFilterButtonBackgroundColor}}) => `${$accountWalletFilterButtonBackgroundColor}`;
const AccountWalletFilterButtonColor = ({theme: {$accountWalletFilterButtonColor}}) => `${$accountWalletFilterButtonColor}`;
const AccountWalletFilterButtonActiveBackgroundColor = ({theme: {$accountWalletFilterButtonActiveBackgroundColor}}) => `${$accountWalletFilterButtonActiveBackgroundColor}`;
const AccountWalletItemColor = ({theme: {$accountWalletItemColor}}) => `${$accountWalletItemColor}`;
const AccountWalletItemBackgroundColor = ({theme: {$accountWalletItemBackgroundColor}}) => `${$accountWalletItemBackgroundColor}`;
const AccountWalletItemBorderColor = ({theme: {$accountWalletItemBorderColor}}) => `${$accountWalletItemBorderColor}`;

const baseFont = `12px`;
const S = {};

S.AccountWallet = styled.div`
    width: 100%;
    padding: ${rem('30px', baseFont)};
`;
S.CancelTransactionStatusContainer = styled.div`
width:100%;
justify-content:center;
align-items:center;
padding:20px;
color:#fff;
&.visible{
    display: flex;
}
&.not-visible{
    display:none;
}
&.success{
    background-color:#02B253;
}
&.failure{
    background-color:#E4202C;
}
`
S.PayoutCancelContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
flex-wrap:wrap;
width:100%;
padding:35px;
font-weight:700;
    & .transaction-amount{
        margin:20px 0 ;
        font-size:30px;

    }
    & .transaction-date{
        font-weight:400;
    }
    & button{
            width:100px;
            margin-top:20px;
            padding: 8px 10px;
            font-family: ${FontFamily};
            font-weight: ${FwRegular};
            font-size:12px;
            color:#fff;
            border-radius:4px;
            border-color:transparent;
            overflow:hidden;
            background-color:#E4202C;
          }
`
S.WalletTransactionsTitle = styled.div`
  font-size: ${rem('20px', baseFont)};
  text-transform: uppercase;
  padding-bottom: ${rem('30px', baseFont)};
  margin-bottom: ${rem('20px', baseFont)};
  font-family: ${FontFamily};
  font-weight: ${FwMedium};
  border-bottom: 1px solid ${AccountWalletTitleBorderColor};
  color: ${AccountWalletTitleColor};
`;

S.WalletTransactionsList = styled.div`
    margin-top: 10px;
    background-color: #f3f3f3;
    border-bottom: 6px solid #09256D;
    border-radius: 0 0 5px 5px;
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.WalletTransactionsListItem = styled.div`
    display: flex;
    background-color: ${AccountWalletItemBackgroundColor};
    min-height: ${rem('60px', baseFont)};
    border-bottom: 1px solid ${AccountWalletItemBorderColor};
    
    & > div{
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size:  ${rem('13px', baseFont)};
        text-align: left;
        color: ${AccountWalletItemColor};
        align-items: center;
        padding-right: ${rem('10px', baseFont)};
        font-family: ${FontFamilyMulish};
        font-weight: 700;

       &.wallet-status{
                flex: 1 1 11%;
                border-right: none;
                padding-left: ${rem('20px', baseFont)};
                display: flex;
                
                         & .status-icon{
		    width: ${rem('22px', baseFont)}; 
            height: ${rem('22px', baseFont)}; 
            background-color: transparent;
            background-position: 0 0;
            background-size: 22px;
            background-repeat: no-repeat;
            margin-left: ${rem('8px', baseFont)}; 
		}
		
           
    	&.red{
		    & .status-icon{
		        background-image: url(/images/icon_lost.png);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(/images/icon_won.png);
		    }
		}
	    &.gray{
		    & .status-icon{
		      background-image: url(/images/icon_pending.png);
 
		    }
		}
       }
        
       &.wallet-type{
                flex: 1 1 23%;
                flex-wrap:wrap;
                & span{
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                & .wallet-type-date{
                    color: rgb(0, 26, 91, 0.5);
                    font-size: ${rem('11px', baseFont)};
                }
        }
        
       &.wallet-info{
             flex: 1 1 17%;
             text-transform: lowercase;
             
                             & span{
                             width: 100%;
                        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
                }
                
       }
            
       &.wallet-amount{
          flex: 1 1 16%;
        }
        
        &.wallet-menage{
          flex: 1 1 13%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          & button{
            flex: 0 0 100px;
            padding: 8px 10px;
            font-family: ${FontFamily};
            font-weight: ${FwRegular};
            font-size:12px;
            color:#fff;
            border-radius:4px;
            border-color:transparent;
            overflow:hidden;
            &.available{
                background-color:#E4202C;
            }
            &.not-available{
                background-color:#BAC1D0;
            }

          }
        }
       
        &.wallet-id{
          flex: 1 1 20%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;          
        }
    }
`;

S.WalletTransactions = styled.div`
`;

S.FakeTableHead = styled.div`
  background-color: ${AccountWalletHeaderBackgroundColor};
    display: flex;
    height: ${rem('30px', baseFont)};
    border-radius: 4px 4px 0 0;
    font-family: ${FontFamily};
    align-items: center;
    
    & > div{
        border: none;
        font-size:  ${rem('13px', baseFont)};
        color: #fff;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: ${rem('10px', baseFont)};

       &.wallet-status{
           flex: 1 1 11%;
           border-right: none;
           padding-left: ${rem('20px', baseFont)};
            
       }
        
        &.wallet-type{
                flex: 1 1 23%;
        }
        
        &.wallet-info{
              flex: 1 1 17%;
        }
        
       &.wallet-amount{
               flex: 1 1 16%;
       }
        
       &.wallet-menage{
                  flex: 1 1 13%;
       }
           
           &.wallet-id{
              flex: 1 1 20%;
           }
    }
    
`;

S.WalletFilters = styled.div`
    display: flex;
    justify-content: space-between;
        height: 2.5rem;
    margin-bottom: 1.6666666666666667rem;
`;

S.WalletFiltersDates = styled.div`
  display: flex;
    margin-left: auto;
    & > div{
        margin-right: ${rem('10px', baseFont)};
        &:first-of-type{}
        &:last-of-type{
             margin-right: 0;
         }
    }
`;

S.DateWrapper = styled.div`
    width: ${rem('90px', baseFont)};
    height: ${rem('30px', baseFont)};
    display: flex;
    position: relative;
    
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: 100%;
       overflow: hidden;
       border-right: 1px solid ${AccountDateBorderColor};
       border-top: 1px solid ${AccountDateBorderColor};
       border-bottom: 1px solid ${AccountDateBorderColor};
       border-radius: 0 4px 4px 0;
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: ${rem('11px', baseFont)}
        font-weight: ${FwRegular};
        width: 100%;
        color: ${AccountDateInputColor};
        padding-left: ${rem('9px', baseFont)};
       
    }
`;


S.DateLabel = styled.div`
    display: flex;
    font-weight: ${FwRegular};
    color: ${AccountDateLabelColor};
    width: ${rem('45px', baseFont)};
    justify-content: center;
    align-items: center;
    font-size: ${rem('12px', baseFont)};
    text-align: right;
    text-transform: uppercase;
`;

S.DateIcon = styled.img``;

S.DateIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${AccountDateIconBackgroundColor};
  width: ${rem('25px', baseFont)};
  border-radius: 4px 0  0 4px;
  flex-shrink: 0;
`;

S.WalletFiltersBtns = styled.div`
    display: flex;
    & > button{
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;

S.FilterBtn = styled(Button)`
     margin-top: 0;
    background-color: ${AccountWalletFilterButtonBackgroundColor};
    color: ${AccountWalletFilterButtonColor};
    font-weight: ${FwRegular};
    width: ${rem('100px', baseFont)};
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 ${rem('5px', baseFont)};
    
    &:hover,
    &.active{
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: ${AccountWalletFilterButtonActiveBackgroundColor};
    }
`;


export default S;