import React, {Component} from 'react';

const withErrorBoundary = (WrappedComponent, FallbackComponent = null) => {

    class WithErrorBoundary extends Component {

        static getDerivedStateFromError = (error) => {
            return {hasError: true};
        };

        state = {hasError: false};

        componentDidCatch = (error, info) => {
            // extra info
        };

        render() {
            const {hasError} = this.state;

            if (hasError) {
                if(FallbackComponent){
                    return <FallbackComponent/>
                }else{
                    return 'Unexpected error';
                }
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    return WithErrorBoundary;
};


export default withErrorBoundary;