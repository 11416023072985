import React, {Component, useEffect} from 'react';
import Select from 'react-select';
import S from 'StyledBetSlip.js';
import {connect} from 'react-redux';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _replace from 'lodash/replace';
import _reject from 'lodash/reject';
import _pick from 'lodash/pick';
import {translation, formatMoney, decimal, pushCustomEventToGoogleAnalytics} from 'utilsHelper.js';
import {bindActionCreators} from 'redux';
import withErrorBoundary from  'withErrorBoundary.js';
import Loader from 'Loader.js';
import BetSlipTypesTabs from 'BetSlipTypesTabs.js';
import FreebetList from 'FreebetList.js';
import BetSlipPromotions from 'BetSlipPromotions.js';
import BetSlipAccumulator from 'BetSlipAccumulator.js';
import BetSlipSystem from 'BetSlipSystem.js';
import BetSlipTabs from 'BetSlipTabs.js';
import TotalBonus from 'TotalBonus.js';
import Box from "react-styled-box";
import {Scrollbars} from 'react-custom-scrollbars';
import {CSSTransition} from 'react-transition-group';
import ClearBetSlipIcon from 'remove_icon.svg';
import LockBetSlipIcon from 'pin_icon.svg';
import {loadCustomerData} from 'customerActions.js';
import {
    changeActiveTab,
    changeSlipType,
    fetchOddsByOutcomesIds,
    removeOutcome,
    clearBetslip,
    changeSlipStake,
    changeSlipTotalStake,
    placeBet,
    placeBetSlipAgain,
    startIntervalFetchOddsByOutcomeId,
    stopIntervalFetchOddsByOutcomeId,
    toggleOutcomeToBlock,
    createBlock,
    toggleBankerOutcome,
    changeCombinationType,
    toggleAccumulator,
    toggleAcceptHigherOdds,
    toggleAcceptAnyOdds,
    removeOutcomeFromBlock,
    updateBlock,
    toggleAcceptDefaultOdds,
    setPredefiniedStake
} from 'betSlipActions.js';
import {getSystemBetMultiplier, findMinStakeBasedOnCombinations} from 'betslipHelper.js';
import {loadApprovalDataOnInit, acceptTraderChanges, rejectTraderChanges, hideApprovalMessage} from 'approvalActions.js';
import {fetchFreebetByStatus, toggleFreebet, checkFreetbetConditionValidity} from 'freebetActions.js';
import {getCustomerBetSlipPromotions, togglePromotion} from 'offersActions.js';

const EmptyBetSlip = () => {
    return (
        <S.EmptySlip>
            {translation('betSlip_empty')}
        </S.EmptySlip>
    )
};

const UnexpectedBetSlipError = () => {
    return (
        <S.UnexpectedError>
            <S.UnexpectedErrorIcon className="fa fa-exclamation-triangle"/>
            <S.UnexpectedErrorMessage>{translation('betSlip_unexpectedError')}</S.UnexpectedErrorMessage>
        </S.UnexpectedError>
    )
};

const ErrorContainer = (props) => {
    const {error} = props;
    return (
        <Scrollbars
            renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
            style={{width: '100%'}}
            autoHeight
            autoHide
            autoHeightMax={70}>
            <S.ErrorContainer>
                {_map(Array.from(new Set(error)), (err, idx) => {
                    return <S.Error key={idx}>{err}</S.Error>;
                })}
            </S.ErrorContainer>
        </Scrollbars>
    )
};

class BetSlip extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        const hasApprovalMessageChanged = Boolean(nextProps.approvalMessage);
        if (hasApprovalMessageChanged != prevState.hasApprovalMessageVisibled) {
            return {
                hasApprovalMessageVisibled: hasApprovalMessageChanged
            }
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            hasApprovalMessageVisibled: false,
            hidden: false,
            sticked: true,
            submitDisabled: false
        };

        this.betSlipWrapperRef = React.createRef();
        this.stakeRef = React.createRef();
        this.totalStakeRef = React.createRef();
        this.acceptHigherOddsRef = React.createRef();
        this.acceptAnyOddsRef = React.createRef();

        this.debouncedOnStakeChange = _debounce((newStake, unsetPredefiniedStake) => {
            const {stake, setPredefiniedStake} = this.props;
            if (decimal(stake) == decimal(newStake)) {
                this.stakeRef.current.value = formatMoney(newStake);
                props.changeSlipStake(newStake);
            } else {
                if(unsetPredefiniedStake){
                    setPredefiniedStake(null);
                }
                props.changeSlipStake(newStake);
            }

            this.setState({submitDisabled:false});
        }, 1500);

        this.debouncedOnTotalStakeChange = _debounce((newTotalStake, unsetPredefiniedStake) => {
            const {setPredefiniedStake, totalStake} = this.props;
            if (decimal(totalStake) == decimal(newTotalStake)) {
                this.totalStakeRef.current.value = formatMoney(newTotalStake);
                props.changeSlipTotalStake(newTotalStake);
            } else {
                if(unsetPredefiniedStake){
                    setPredefiniedStake(null);
                }
                props.changeSlipTotalStake(newTotalStake);
            }

            this.setState({submitDisabled:false});
        }, 1500);
    }

    onStakeChangeByControl = (type) => {
        const {stake, changeSlipStake, predefiniedStake, defaultStake} = this.props;
        if (!stake && type == 'dec') {
            return;
        }

        const minStake = process.env.MIN_STAKE;
        const maxStake = process.env.MAX_STAKE;
        const stakeControlValueInc = (parseFloat(stake) < parseFloat(defaultStake)) ? minStake : (predefiniedStake || process.env.STAKE_CONTROL_VALUE);
        const stakeControlValueDec = ((parseFloat(stake) <= parseFloat(defaultStake))) ? minStake : (predefiniedStake || process.env.STAKE_CONTROL_VALUE);
        const changedStake =
            type == 'inc'
                ? parseFloat(stake) + parseFloat(stakeControlValueInc)
                : parseFloat(stake) - parseFloat(stakeControlValueDec);

        const controlStake = predefiniedStake || minStake;
        if (changedStake < controlStake) {
            changeSlipStake(controlStake);
        } else if (changedStake >= maxStake) {
            changeSlipStake(maxStake);
        } else {
            changeSlipStake(changedStake);
        }
    };

    onTotalStakeChangeByControl = (type)=>{
        const { totalStake, changeSlipTotalStake, predefiniedStake, defaultStake, setPredefiniedStake} = this.props;
        if (!totalStake && type == 'dec') {
            return;
        }
        const minStake = process.env.MIN_STAKE;
        const maxStake = process.env.MAX_STAKE;
        const stakeControlValueInc = (parseFloat(totalStake) < parseFloat(defaultStake)) ? minStake : (predefiniedStake || process.env.STAKE_CONTROL_VALUE);
        const stakeControlValueDec = ((parseFloat(totalStake) <= parseFloat(defaultStake))) ? minStake : (predefiniedStake || process.env.STAKE_CONTROL_VALUE);
        const changedTotalStake =
            type == 'inc'
                ? parseFloat(totalStake) + parseFloat(stakeControlValueInc)
                : parseFloat(totalStake) - parseFloat(stakeControlValueDec);

        const controlStake = predefiniedStake || minStake;
        let newTotalStake = controlStake;
        if (changedTotalStake < controlStake) {
            changeSlipTotalStake(controlStake);
        } else if(changedTotalStake >= maxStake){
            changeSlipTotalStake(maxStake);
            newTotalStake = maxStake;
        }else {
            changeSlipTotalStake(changedTotalStake);
            newTotalStake = changedTotalStake;
        }

        const combinationsCount = this.getCombinationsCount();
        const stakePerCombination = decimal(newTotalStake / combinationsCount);
        if(predefiniedStake && (stakePerCombination<predefiniedStake)){
            setPredefiniedStake(null)
        }

    };

    getAcceptOddsList = () => {
        let acceptOptionList = ['default', 'higher', 'any'];
        acceptOptionList = _map(acceptOptionList, (optionVal) => {
            const option = {
                label: translation(`betslip_oddsAccept_${optionVal}`),
                value: optionVal
            };
            return option;
        });
        return acceptOptionList;
    };

    onAcceptOddsChange = (option) => {
        const {toggleAcceptHigherOdds, toggleAcceptAnyOdds, toggleAcceptDefaultOdds} = this.props;

        const acceptOddsValue = _get(option, ['value'], 'default');
        if (acceptOddsValue == 'any') {
            toggleAcceptAnyOdds();
        } else if (acceptOddsValue == 'higher') {
            toggleAcceptHigherOdds();
        } else if (acceptOddsValue == 'default') {
            toggleAcceptDefaultOdds();
        }
    };

    selectedOption = () => {
        const {acceptHigherOdds, acceptAnyOdds} = this.props;
        const currentAcceptOddsValue = acceptHigherOdds ? 'higher': (acceptAnyOdds ? 'any': 'default');
        const selectedValue = _find(this.getAcceptOddsList(), {value: currentAcceptOddsValue});
        return selectedValue??null;
    };

    betSlipMarkup = () => {
        const {sticked, submitDisabled} = this.state;
        const showCouponModal = (slipId)=>{
            app.system.render(app.modal.betslipShare, {customSlipId:slipId});
        }
        const {
            outcomes,
            error,
            odds,
            winning,
            removeOutcome,
            clearBetslip,
            placeBet,
            isLogged,
            locked,
            placed,
            slipType,
            changeSlipType,
            blocks,
            toggleOutcomeToBlock,
            createBlock,
            toggleBankerOutcome,
            stake,
            totalStake,
            types,
            type,
            changeCombinationType,
            toggleAccumulator,
            addAccumulator,
            fetchFreebetByStatus,
            toggleFreebet,
            validFreebets,
            freebet,
            currencyCode,
            bonus = 0,
            hasApprovalButtonsVisibled,
            tax,
            taxFromWinning,
            approvalMessage,
            acceptTraderChanges,
            rejectTraderChanges,
            removeOutcomeFromBlock,
            updateBlock,
            betSlips,
            changeActiveTab,
            activeTab,
            changeSlipStake,
            slipId,
            showLoginModal,
            hideApprovalMessage,
            isTotalBonusEnabled,
            totalBonus,
            predefiniedStake,
            defaultStake,
            minStake,
            checkFreetbetConditionValidity,
            approvalData,
            promotions,
            getCustomerBetSlipPromotions,
            togglePromotion,
            customSlipId,
            selectedPromotion,
            unitDivider,
        } = this.props;

        return (

            <S.BetSlipContainer>

                <S.BetSlipHeader>

                    <S.BetSlipHeaderCaption>
                        {translation('betSlip_header')}
                    </S.BetSlipHeaderCaption>

                    <BetSlipTabs betSlips={betSlips}
                                 activeTab={activeTab}
                                 changeActiveTab={changeActiveTab}
                                 locked={locked}
                                 hasApprovalButtonsVisibled={hasApprovalButtonsVisibled}
                    />

                    <S.LockBetSlip onClick={this.toggleSticked} isSticked={sticked} dangerouslySetInnerHTML={{__html: LockBetSlipIcon}}></S.LockBetSlip>

                    <S.RemoveBetSlip onClick={clearBetslip} dangerouslySetInnerHTML={{__html: ClearBetSlipIcon}} disabled={hasApprovalButtonsVisibled}/>

                </S.BetSlipHeader>

                {_isEmpty(outcomes)
                    ?
                    <EmptyBetSlip/>
                    :
                    (
                        <>

                            <div style={{position: 'relative'}} className="betslip-inner">

                                {locked && (
                                    <S.BetSlipOverlay className="betslip-overlay">
                                        <Loader size="5"/>
                                    </S.BetSlipOverlay>)
                                }

                                <BetSlipTypesTabs slipType={slipType}
                                                  changeSlipType={changeSlipType}
                                                  outcomes={outcomes}
                                                  hasApprovalButtonsVisibled={hasApprovalButtonsVisibled}
                                />


                                {(placed && slipId) &&  (
                                    <S.PlaceBetSuccess>
                                        <span>{translation('betSlip_placeBetSuccess', [slipId])}</span>
                                        <S.SeeCouponButton onClick={()=>showCouponModal(customSlipId)}>{translation('see_coupon')}</S.SeeCouponButton>
                                    </S.PlaceBetSuccess>
                                )}

                                {error && <ErrorContainer error={error}/>}

                                {slipType == 'ACCUMULATOR'
                                    ?
                                    <BetSlipAccumulator removeOutcome={removeOutcome}
                                                        outcomes={outcomes}
                                                        hasApprovalButtonsVisibled={hasApprovalButtonsVisibled}
                                                        onUpdate={this.onScrollbarUpdate}
                                    />
                                    :
                                    <BetSlipSystem removeOutcome={removeOutcome}
                                                   outcomes={outcomes}
                                                   blocks={blocks}
                                                   toggleOutcomeToBlock={toggleOutcomeToBlock}
                                                   createBlock={createBlock}
                                                   updateBlock={updateBlock}
                                                   toggleBankerOutcome={toggleBankerOutcome}
                                                   removeOutcomeFromBlock={removeOutcomeFromBlock}
                                                   types={types}
                                                   type={type}
                                                   changeCombinationType={changeCombinationType}
                                                   toggleAccumulator={toggleAccumulator}
                                                   addAccumulator={addAccumulator}
                                                   hasApprovalButtonsVisibled={hasApprovalButtonsVisibled}
                                                   onUpdate={this.onScrollbarUpdate}
                                    />
                                }

                                <S.FormGroupWrapper className="stake-wrapper">
                                    <S.Label htmlFor="betslip-stake">{translation('betSlip_stake')}</S.Label>
                                    <S.InputWrapper>

                                        <S.StakeControl disabled={locked || hasApprovalButtonsVisibled||(stake<=(predefiniedStake||minStake)) || _size(freebet) || _size(selectedPromotion)}
                                                        onClick={() => this.onStakeChangeByControl('dec')}>
                                            <span>-</span>
                                        </S.StakeControl>

                                        <S.Input id="betslip-stake"
                                                 defaultValue={formatMoney(stake)}
                                                 ref={this.stakeRef}
                                                 disabled={locked || hasApprovalButtonsVisibled || _size(freebet) || _size(selectedPromotion)}
                                                 onChange={this.onStakeChange}>
                                        </S.Input>

                                        <S.StakeControl disabled={locked || hasApprovalButtonsVisibled || _size(freebet) || _size(selectedPromotion) } onClick={() => this.onStakeChangeByControl('inc')}>
                                            <span>+</span>
                                        </S.StakeControl>

                                    </S.InputWrapper>
                                </S.FormGroupWrapper>

                                {slipType == 'SYSTEM' && (
                                    <S.FormGroupWrapper className="total-stake-wrapper">
                                        <S.Label
                                            htmlFor="betslip-totalStake">{translation('betSlip_totalStake')}</S.Label>
                                        <S.InputWrapper>

                                            <S.StakeControl disabled={locked || hasApprovalButtonsVisibled||(totalStake<=(predefiniedStake||minStake)) || _size(freebet) || _size(selectedPromotion)}
                                                            onClick={() => this.onTotalStakeChangeByControl('dec')}>
                                                <span>-</span>
                                            </S.StakeControl>

                                            <S.Input id="betslip-totalStake"
                                                     defaultValue={formatMoney(totalStake)}
                                                     ref={this.totalStakeRef}
                                                     disabled={locked || hasApprovalButtonsVisibled || _size(freebet) || _size(selectedPromotion)}
                                                     onChange={this.onTotalStakeChange}>
                                            </S.Input>

                                            <S.StakeControl disabled={locked || hasApprovalButtonsVisibled || _size(freebet) || _size(selectedPromotion)} onClick={() => this.onTotalStakeChangeByControl('inc')}>
                                                <span>+</span>
                                            </S.StakeControl>

                                        </S.InputWrapper>
                                    </S.FormGroupWrapper>
                                )}

                                <S.PredefiniedStakes>
                                    {_map([20, 50, 100, 200], (stk) => {
                                        return (
                                            <S.Stake disabled={hasApprovalButtonsVisibled}
                                                     key={stk}
                                                     isActive={(stk==predefiniedStake)}
                                                     onClick={(e)=> this.onPredefiniedStakeChange(stk)}>
                                                {stk} {currencyCode}
                                            </S.Stake>
                                        )
                                    })}
                                </S.PredefiniedStakes>

                                <Box flexDirection="column">
                                    {odds && (
                                        <S.FormGroup>
                                            <S.FormGroupText>{translation('betSlip_odds_fullname')}</S.FormGroupText>
                                            <S.FormGroupText>{odds}</S.FormGroupText>
                                        </S.FormGroup>
                                    )}

                                    {!isTotalBonusEnabled && (bonus > 0) && (
                                        <S.FormGroup>
                                            <S.FormGroupText>{translation('betSlip_bonus')}</S.FormGroupText>
                                            <S.FormGroupText>{bonus} {currencyCode}</S.FormGroupText>
                                        </S.FormGroup>
                                    )}

                                    {taxFromWinning && (
                                        <S.FormGroup>
                                            <S.FormGroupText>{translation('betSlip_taxFromWinning')}</S.FormGroupText>
                                            <S.FormGroupText>{taxFromWinning} {currencyCode}</S.FormGroupText>
                                        </S.FormGroup>
                                    )}

                                    <S.FormGroup className="possible-win">
                                        <S.FormGroupText>{translation('betSlip_possibleWin')}</S.FormGroupText>
                                        <S.FormGroupText>{winning} {currencyCode}</S.FormGroupText>
                                    </S.FormGroup>
                                </Box>

                                {isTotalBonusEnabled && totalBonus && (
                                    <TotalBonus
                                        winning={winning}
                                        totalBonus={totalBonus}
                                        bonus={bonus}
                                        currencyCode={currencyCode}
                                        outcomes={outcomes}
                                    />
                                )}

                                {unitDivider?.size > 1 && (
                                    <S.FormGroup className="multi-betSlip">
                                        <S.FormGroupText>{translation('betSlip_multiSlip_title')}</S.FormGroupText>
                                        <S.FormGroupText>{translation('betSlip_multiSlip_description')}</S.FormGroupText>
                                        <S.FormGroupText>{unitDivider.size}</S.FormGroupText>
                                    </S.FormGroup>
                                )}

                                {isLogged && (
                                    <BetSlipPromotions 
                                                hasApprovalButtonsVisibled={hasApprovalButtonsVisibled}
                                                getCustomerBetSlipPromotions={getCustomerBetSlipPromotions}
                                                togglePromotion={togglePromotion}
                                                promotions={promotions}
                                                selectedPromotion={selectedPromotion}
                                                slipType={slipType}
                                                currencyCode={currencyCode}
                                    />
                                )}

                                {isLogged && (
                                    <FreebetList hasApprovalButtonsVisibled={hasApprovalButtonsVisibled}
                                                 fetchFreebetByStatus={fetchFreebetByStatus}
                                                 toggleFreebet={toggleFreebet}
                                                 validFreebets={validFreebets}
                                                 freebet={freebet}
                                                 slipType={slipType}
                                                 currencyCode={currencyCode}
                                                 checkFreetbetConditionValidity={checkFreetbetConditionValidity}
                                    />
                                )}

                                {!hasApprovalButtonsVisibled && (
                                    <S.ButtonsWrapper className="place-bet">

                                        {isLogged && (
                                            <S.PlaceBet onClick={placeBet}
                                                        disabled={locked||submitDisabled}>
                                                {placed ? translation('betSlip_placeBetAgain') : translation('betSlip_placeBet')}
                                            </S.PlaceBet>
                                        )}

                                        {!isLogged && (<S.BetSlipLogin onClick={showLoginModal}>{translation('betSlip_login')}</S.BetSlipLogin>)}

                                    </S.ButtonsWrapper>
                                )}


                                {/*<S.RemoveBetSlipLink onClick={clearBetslip} disabled={hasApprovalButtonsVisibled}>*/}
                                {/*    {translation('betSlip_clear')}*/}
                                {/*</S.RemoveBetSlipLink>*/}

                                <S.AcceptOddsChangeSelect as={Select}
                                                          options={this.getAcceptOddsList()}
                                                          value={this.selectedOption()}
                                                          onChange={this.onAcceptOddsChange}
                                                          isSearchable={false}
                                                          classNamePrefix="react-select"
                                                          className="react-select-container"
                                                          isDisabled={hasApprovalButtonsVisibled}
                                />

                                <S.TaxFactor>{translation('betSlip_rate')}&nbsp;{decimal(tax)}</S.TaxFactor>

                            </div>

                            {hasApprovalButtonsVisibled && (
                                <S.ButtonsWrapper className="trader-changes">
                                    <S.AcceptTraderChanges
                                        onClick={acceptTraderChanges}>{translation('betSlip_accept')}</S.AcceptTraderChanges>
                                    <S.RejectTraderChanges
                                        onClick={rejectTraderChanges}>{translation('betSlip_cancel')}</S.RejectTraderChanges>
                                </S.ButtonsWrapper>
                            )}

                        </>
                    )
                }

                <CSSTransition in={this.state.hasApprovalMessageVisibled}
                               appear={true}
                               classNames={{
                                   enter: 'animated',
                                   enterActive: 'fadeIn',
                                   exit: 'animated',
                                   exitActive: 'fadeOut'
                               }}
                               unmountOnExit
                               timeout={200}>
                    <S.Info status={_get(approvalMessage, ['status'])}>
                        <S.InfoMessage>{_get(approvalMessage, ['msg'])}</S.InfoMessage>
                        {/*<S.InfoCloseIcon onClick={hideApprovalMessage}>&times;</S.InfoCloseIcon>*/}
                    </S.Info>
                </CSSTransition>

            </S.BetSlipContainer>
        );
    };

    getCombinationsCount = () => {
        const {activeTab, betSlips} = this.props;
        const outcomesOnTab = _get(betSlips, [activeTab, 'outcomes']);
        const blocksOnTab = _get(betSlips, [activeTab, 'blocks']);
        const hasAccumulator = _get(betSlips, [activeTab, 'addAccumulator']);
        const type = _get(betSlips, [activeTab, 'type', 'type']);
        const outcomesNotInBlocks = _reject(outcomesOnTab, {inBlock: true});
        const bankers = _filter(outcomesOnTab, {banker: true});
        const outcomesCount = _size(outcomesNotInBlocks) + _size(blocksOnTab) - _size(bankers);

        let combinationsCount = getSystemBetMultiplier(outcomesCount, type);
        if (type < 100 && hasAccumulator) {
            combinationsCount += 1;
        }

        return combinationsCount;
    };

    onTotalStakeChange = (e) => {
        const {predefiniedStake} = this.props;
        const totalStakeValue = String(e.target.value).replace(/\,/, '.').replace(/\s+/, '');
        const isNumber = !isNaN(totalStakeValue);
        const minStake = process.env.MIN_STAKE;
        const maxStake = process.env.MAX_STAKE;
        let newTotalStake = decimal(minStake);

        const bindParams = [];
        if (_size(totalStakeValue) && isNumber && (parseFloat(totalStakeValue) >= parseFloat(minStake))) {
            newTotalStake = decimal(totalStakeValue);
            bindParams.push(newTotalStake);
        }

        if (_size(totalStakeValue) && isNumber && (parseFloat(totalStakeValue) >= parseFloat(maxStake))) {
            newTotalStake = decimal(maxStake);
            bindParams.push(newTotalStake);
        }

        let unsetPrefediniedStake = false;
        if(!bindParams.length){
            unsetPrefediniedStake = true;
            bindParams.push(newTotalStake);
        }

        const combinationsCount = this.getCombinationsCount();
        const stakePerCombination = decimal(newTotalStake / combinationsCount);
        if(predefiniedStake && (unsetPrefediniedStake||(stakePerCombination!=predefiniedStake))) bindParams.push(true);

        this.setState({submitDisabled:true});
        this.debouncedOnTotalStakeChange.apply(this, bindParams);
    };


    onStakeChange = (e) => {
        const {predefiniedStake, slipType} = this.props;
        const stakeValue = String(e.target.value).replace(/\,/, '.').replace(/\s+/, '');
        const isNumber = !isNaN(stakeValue);
        const minStakePerCombination = process.env.MIN_STAKE_PER_COMBINATION && parseFloat(process.env.MIN_STAKE_PER_COMBINATION);
        const minStake = (minStakePerCombination && !isNaN(minStakePerCombination) && slipType=='SYSTEM') ? minStakePerCombination : process.env.MIN_STAKE;
        const maxStake = process.env.MAX_STAKE;
        let newStake = decimal(minStake);

        const bindParams = [];
        if (_size(stakeValue) && isNumber && (parseFloat(stakeValue) >= parseFloat(minStake))) {
            newStake = decimal(stakeValue);
            bindParams.push(newStake);
        }

        if (_size(stakeValue) && isNumber && (parseFloat(stakeValue) >= parseFloat(maxStake))) {
            newStake = decimal(maxStake);
            bindParams.push(newStake);
        }

        let unsetPrefediniedStake = false;
        if(!bindParams.length){
            unsetPrefediniedStake = true;
            bindParams.push(newStake);
        }

        if(predefiniedStake && (unsetPrefediniedStake||(newStake!=predefiniedStake))) bindParams.push(true);

        this.setState({submitDisabled:true});
        this.debouncedOnStakeChange.apply(this, bindParams);
    };

    onPredefiniedStakeChange = (stk) => {
        const {predefiniedStake, changeSlipStake, setPredefiniedStake} = this.props;
        const minStake = process.env.MIN_STAKE;
        if(predefiniedStake == stk){
            changeSlipStake(decimal(minStake));
            setPredefiniedStake(null);
        }else{
            changeSlipStake(decimal(stk));
            setPredefiniedStake(stk);
        }
    };

    liveOutcomesExistCheck = () => {
        const {outcomes} = this.props;
        const hasLiveOutcomes = _find(outcomes, {outcomeLive: 'true'});
        let outcomesIds = [];
        if (_size(hasLiveOutcomes)) {
            outcomesIds = _map(_filter(outcomes, (o) => {
                return o.outcomeLive == 'true';
            }), ({outcomeId}) => {
                return {outcomeId}
            });
        }
        return outcomesIds;
    };

    restartLiveIntervals = ()=>{
        const {startIntervalFetchOddsByOutcomeId, locked, hasApprovalButtonsVisibled} = this.props;

        const liveOutcomes = this.liveOutcomesExistCheck();
        if (_size(liveOutcomes) && !locked && !hasApprovalButtonsVisibled) {
            this.rerunActiveLiveIntervals();
            startIntervalFetchOddsByOutcomeId(liveOutcomes);
        }
    };

    rerunActiveLiveIntervals = () => {
        const Timer = app.service.Timers;
        const activeIntervals = Timer.liveEventsListHandlerActions;

        const outcomeButtonWithOddsChangeIndicator = [
            ...document.querySelectorAll('.outcomeButtonPartial.up'),
            ...document.querySelectorAll('.outcomeButtonPartial.down')
        ];
        _map(outcomeButtonWithOddsChangeIndicator, (dom) => dom.classList.remove('up', 'down'));

        Timer.clearLiveEventsListIntervals();

        for (let actionName in activeIntervals) {
            if (Timer.liveEventsListHandler) {
                clearInterval(Timer.liveEventsListHandler);
                Timer.liveEventsListHandler = null;
            }
            activeIntervals[actionName]();
            Timer.setLiveEventsInterval(actionName, activeIntervals[actionName]);
        }
    };

    subscribeToSpikeOnRouteChange = () => {
        const {stopIntervalFetchOddsByOutcomeId} = this.props;

        app.router.onRouteChange('REACT_LISTEN_SPIKE_ROUTE_CHANGE', () => {
            stopIntervalFetchOddsByOutcomeId();
            this.restartLiveIntervals();
        })
    };

    componentDidMount = () => {
        const {loadApprovalDataOnInit, locked} = this.props;

        this.subscribeToSpikeOnRouteChange();
        this.restartLiveIntervals();
        this.addScrollHandler();

        if (locked) {
            setTimeout(() => {
                loadApprovalDataOnInit();
            }, process.env.FETCH_APPROVED_SLIPS_TIMEOUT);
        }
    };

    addScrollHandler = () => {
        document.addEventListener('scroll', this.bindScrollEvent);
    };

    removeScrollHandler = () => {
        document.removeEventListener('scroll', this.bindScrollEvent);

        const betSlipWrapperSelector = this.betSlipWrapperRef.current;
        betSlipWrapperSelector.classList.remove('is-sticked');
        betSlipWrapperSelector.parentNode.style.paddingTop = `0px`;
    };

    bindScrollEvent = () => {
        const windowScrollTop = window.scrollY;
        const betSlipWrapperSelector = this.betSlipWrapperRef.current;

        betSlipWrapperSelector.classList.toggle('is-sticked', (windowScrollTop > 173));

        this.onScrollbarUpdate();
    };

    onScrollbarUpdate = () => {
        const betSlipWrapperSelector = this.betSlipWrapperRef.current;
        const betSlipPlaceholderSelector = betSlipWrapperSelector.parentNode;
        const rightColumnSelector = betSlipPlaceholderSelector.parentNode;
        const rightColumnComputedStyles = getComputedStyle(rightColumnSelector);
        const rightColumnWidth = parseFloat(rightColumnSelector.offsetWidth - parseFloat(rightColumnComputedStyles.paddingLeft) - parseFloat(rightColumnComputedStyles.paddingRight));

        const isSticked = betSlipWrapperSelector.classList.contains('is-sticked');
        if (isSticked) {
            const bestSlipOffsetHeight = betSlipWrapperSelector.offsetHeight;
            betSlipPlaceholderSelector.style.paddingTop = `${bestSlipOffsetHeight}px`;
            betSlipWrapperSelector.style.width = `${rightColumnWidth}px`;
        } else {
            betSlipWrapperSelector.parentNode.style.paddingTop = `0px`;
            betSlipWrapperSelector.style.width = `auto`;
        }

    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.stakeRef.current && (prevProps.stake !== this.props.stake)) {
            this.stakeRef.current.value = formatMoney(this.props.stake);
        }

        if (this.totalStakeRef.current && (prevProps.totalStake !== this.props.totalStake)) {
            this.totalStakeRef.current.value = formatMoney(this.props.totalStake);

            if(this.props.slipType == 'SYSTEM') {
                const minStake = process.env.MIN_STAKE && parseFloat(process.env.MIN_STAKE);
                if(this.props.totalStake < minStake) {
                    const combinationsCount = this.getCombinationsCount();
                    const correctStake = findMinStakeBasedOnCombinations(minStake, combinationsCount);
                    this.props.changeSlipStake(correctStake);
                }
            } 
        }

        
        if ((prevProps.locked != this.props.locked)) {
            if(this.props.locked){
                const {stopIntervalFetchOddsByOutcomeId} = this.props;
                stopIntervalFetchOddsByOutcomeId();
            }else if(!this.props.locked && !this.props.approvalData){
                this.restartLiveIntervals();
            }

        }

        if ((_size(prevProps.outcomes) != _size(this.props.outcomes)) ||
            ((_size(prevProps.outcomes) == _size(this.props.outcomes)) && (prevProps.placed != this.props.placed) && this.props.placed )) {
            const {stopIntervalFetchOddsByOutcomeId} = this.props;
            stopIntervalFetchOddsByOutcomeId();

            this.restartLiveIntervals();
        }
        if(prevProps.placed !== this.props.placed && this.props.placed ==true){
            // #112384 - GTM trigger whenever bet is placed
            app.service.CustomerApi.hashSHA256(app.service.Auth.customerData.userId).then(response=>{
                const outcomesList = this.props.outcomes.map(({outcomeId,sportId,categoryId,outcomeName,countryId,isLive,outcomeLive})=>{
                    return (
                    {
                        'item_id': outcomeId,
                        'item_name':outcomeName,
                        'item_category': sportId,
                        'item_category2': countryId, 
                        'item_category3': categoryId, 
                        'item_list_id': isLive || outcomeLive == 'true' ?'live' : 'main'
                    }
                )})
                pushCustomEventToGoogleAnalytics({
                'event': 'purchase',
                'currency': 'PLN', 
                'transaction_id': this.props.slipId,
                'user_id': response,
                'value': this.props.totalStake, 
                'items': outcomesList
                })
            })
        }
        if(this.props.placed && this.props.slipId && prevProps.slipId !== this.props.slipId){
            app.service.Integration.metaPixelSendEventObject('Purchase',{
                currency:"PLN",
                value: this.props.totalStake,
                num_items:_size(this.props.outcomes)
            })
        }
        if (prevState.sticked != this.state.sticked) {
            if (this.state.sticked) {
                this.addScrollHandler();
            } else {
                this.removeScrollHandler();
            }
        }
    };

    toggleVisibility = () => {
        this.setState(prevState => {
            return {...prevState, hidden: !prevState.hidden}
        });
    };

    toggleSticked = () => {
        this.setState(prevState => {
            return {...prevState, sticked: !prevState.sticked}
        });
    };

    componentWillUnmount = () => {
        const {stopIntervalFetchOddsByOutcomeId, locked} = this.props;
        if (!locked) {
            stopIntervalFetchOddsByOutcomeId();
        }
    };

    render() {
        return (
            <S.BetSlipWrapper ref={this.betSlipWrapperRef}>
                    {this.betSlipMarkup()}
            </S.BetSlipWrapper>
        )
    }

}

const mapStateToProps = ({BetSlip:{activeTab, betSlips, locked, approvalData, validFreebets, acceptHigherOdds, acceptAnyOdds, showApprovalButtons: hasApprovalButtonsVisibled, promotions}, Auth:{isLogged}}, props) => {

    const currencyCode = process.env.MAIN_CURRENCY_CODE;
    const activeSlip = _get(betSlips, [activeTab]);

    const oddsPrecision = _get(activeSlip, ['oddsPrecision']);
    const amountPrecision = _get(activeSlip, ['amountPrecision']);

    const keysToPick = [
        'tax',
        'taxFromWinning',
        'winningWithoutTax',
        'approvalMessage',
        'outcomes',
        'totalStake',
        'stake',
        'totalOdds',
        'totalOddsWithoutSalesTaxFactor',
        'winning',
        'error',
        'placed',
        'betInShop',
        'blocks',
        'slipType',
        'types',
        'type',
        'addAccumulator',
        'freebet',
        'bonus',
        'validFreebets',
        'minPossibleWinning',
        'maxStake',
        'totalBonus',
        'slipId',
        'customSlipId',
        'predefiniedStake',
        'promotions',
        'selectedPromotion',
        'unitDivider',
    ];

    const keysFromActiveSlip = _pick(activeSlip, keysToPick);

    const keysWithOddsPrecision = _pick(keysFromActiveSlip, ['totalOdds', 'totalOddsWithoutSalesTaxFactor']);
    _each(keysWithOddsPrecision, (value, key) => {
        keysFromActiveSlip[key] = parseFloat(value).toFixed(oddsPrecision);
        keysFromActiveSlip[key] = (keysFromActiveSlip[key] && keysFromActiveSlip[key]!='0.00')?keysFromActiveSlip[key]:null;
    });

    const keysWithAmountPrecision = _pick(keysFromActiveSlip, ['bonus', 'winning', 'winningWithoutTax', 'minPossibleWinning', 'winningForDefaultTaxFactor', 'totalStake', 'maxStake', 'taxFromWinning']);
    _each(keysWithAmountPrecision, (value, key) => {
        keysFromActiveSlip[key] = parseFloat(value).toFixed(amountPrecision);
        keysFromActiveSlip[key] = (keysFromActiveSlip[key] && keysFromActiveSlip[key]!='0.00')?keysFromActiveSlip[key]:'0.00';
    });

    const taxFromWinning = _get(keysFromActiveSlip, 'taxFromWinning');
    keysFromActiveSlip['taxFromWinning'] = taxFromWinning?taxFromWinning:null;
    keysFromActiveSlip['winning'] = _get(keysFromActiveSlip, 'taxFromWinning') ? _get(keysFromActiveSlip, 'winningWithoutTax'): _get(keysFromActiveSlip, 'winning');

    let hasOddsWithSalesTaxFactor = process.env.ODDS_WITH_SALES_TAX_FACTOR;
    hasOddsWithSalesTaxFactor = hasOddsWithSalesTaxFactor && JSON.parse(hasOddsWithSalesTaxFactor);
    const odds = hasOddsWithSalesTaxFactor? _get(keysFromActiveSlip, 'totalOdds') : _get(keysFromActiveSlip, 'totalOddsWithoutSalesTaxFactor');

    const winning = parseFloat(keysFromActiveSlip['winning']);
    const bonus = keysFromActiveSlip['bonus'] && parseFloat(keysFromActiveSlip['bonus']);
    const finalWinningAmount = bonus ? (winning+bonus): winning;
    keysFromActiveSlip['winning'] = (finalWinningAmount).toFixed(amountPrecision);

    // Total bonus
    const isTotalBonusEnabled = process.env.TOTAL_BONUS && activeSlip.totalBonus?.isTotalbonusAvailable;

    const minStake = process.env.MIN_STAKE;
    const defaultStake = process.env.DEFAULT_STAKE;

    return {
        hasApprovalButtonsVisibled,
        acceptHigherOdds,
        minStake,
        defaultStake,
        toggleAcceptDefaultOdds,
        acceptAnyOdds,
        currencyCode,
        validFreebets,
        activeTab,
        isLogged,
        betSlips,
        odds,
        locked,
        approvalData,
        promotions,
        ...keysFromActiveSlip,
        isTotalBonusEnabled,
    }
};


const mapDispatchToProps = (dispatch, props) => {

    const boundActionCreators = bindActionCreators(
        {
            hideApprovalMessage,
            changeActiveTab,
            changeSlipStake,
            changeSlipTotalStake,
            fetchOddsByOutcomesIds,
            removeOutcome,
            startIntervalFetchOddsByOutcomeId,
            stopIntervalFetchOddsByOutcomeId,
            toggleOutcomeToBlock,
            loadApprovalDataOnInit,
            changeCombinationType,
            fetchFreebetByStatus,
            toggleFreebet,
            removeOutcomeFromBlock,
            updateBlock,
            toggleAcceptHigherOdds,
            toggleAcceptAnyOdds,
            toggleAcceptDefaultOdds,
            setPredefiniedStake,
            checkFreetbetConditionValidity,
            getCustomerBetSlipPromotions,
            togglePromotion
        },
        dispatch
    );

    return {
        ...boundActionCreators,
        placeBetInShop: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(placeBet(true));
        },
        placeBet: async (e) => {
            e.nativeEvent.stopImmediatePropagation();
            await dispatch(placeBet());
            dispatch(loadCustomerData({updateBalance: true}));
        },
        clearBetslip: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(clearBetslip());
        },
        placeBetSlipAgain: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(placeBetSlipAgain());
            dispatch(loadCustomerData({updateBalance: true}));
        },
        changeSlipType: (slipType, e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(changeSlipType(slipType));
        },
        toggleAccumulator: (e) => {
            if (e && ('nativeEvent' in e)) {
                e.nativeEvent.stopImmediatePropagation();
            }
            dispatch(toggleAccumulator());
        },
        acceptTraderChanges: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(acceptTraderChanges());
        },
        rejectTraderChanges: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(rejectTraderChanges());
        },
        toggleBankerOutcome: (outcomeId, e) => {
            if (e && ('nativeEvent' in e)) {
                e.nativeEvent.stopImmediatePropagation();
            }
            dispatch(toggleBankerOutcome(outcomeId));
        },
        createBlock: (e) => {
            if (e && ('nativeEvent' in e)) {
                e.nativeEvent.stopImmediatePropagation();
            }
            dispatch(createBlock());
        },
        showLoginModal: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            const showLoginModalParams = {
                type: 'TOGGLE_BY_KEY',
                payload: {
                    key: 'TOGGLE_OPEN_LOGIN_MODAL',
                    isOpen: true
                }
            };
            dispatch(showLoginModalParams);
        },
        dispatch
    }
};


export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(BetSlip), UnexpectedBetSlipError);

