import React, {memo, useMemo} from 'react';
import S from 'StyledSubmenuList.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import subMenuItems from 'subMenuItems.enum.js';
import {translation, redirect} from 'helpers/utilsHelper';
import classNames from 'classnames';

const SubmenuList = (props) => {

    const {activeItem, changeSubmenuActiveItem, submenuItem, changeSubmenuDropdownItem, submenuDropdownItem} = props;

    const submenuItems = useMemo(() => {
        return (activeItem in subMenuItems) && subMenuItems[activeItem];
    }, [activeItem]);

    const onSubmenuLinkClick = (e, href, itemName) => {
        e.preventDefault();
        changeSubmenuActiveItem(itemName);
        redirect(href);
    };

    const onSubmenuDropdownLinkClick = (e, href, submenuItemName) => {
        e.preventDefault();
        changeSubmenuDropdownItem(submenuItemName);
        redirect(href);
    };

    const onOpenInNewTabLinkClick = (e, href) => {
        if (href === subMenuItems.HOME.BLOG.href) {
            app.service.Integration.metaPixelSendEvent(
            'ViewContent',
            {
                content_name: 'blog'
            })
        }
    }

    return (
        <S.SubmenuList className="submenu-list">
            {_map(submenuItems, ({translationKey, href, submenu, openInNewTab, ...rest}, itemName) => {
                const itemClass = classNames({'is-active': (submenuItem == itemName), 'submenu-menu-item': true});
                const hasSubmenu = (submenu && _size(submenu) > 0);
                const title = translation(translationKey);

                return (
                    <S.SubmenuListItem key={itemName} isActive={submenuItem == itemName} className={itemClass}>
                    {!openInNewTab
                        ?
                        <S.SubmenuLink {...rest}
                                       href={href}
                                       className="top-menu-link"
                                       title={title}
                                       onClick={(e) => {
                                           onSubmenuLinkClick(e, href, itemName)
                                       }}>

                            {title}

                            {hasSubmenu &&
                            (
                                <S.SubList className="submenu-list">
                                    {_map(submenu, ({translationKey, href}, submenuItemName) => {
                                        const title = translation(translationKey);
                                        return (
                                            <S.SubListItem key={submenuItemName} isActive={submenuDropdownItem == submenuItemName} className="submenu-list-item">

                                                <S.SubListLink {...rest}
                                                               className="sub-menu-link"
                                                               title={title}
                                                               onClick={(e) => {
                                                                   onSubmenuDropdownLinkClick(e, href, submenuItemName);
                                                               }}>

                                                    <span>{title}</span>

                                                </S.SubListLink>

                                            </S.SubListItem>
                                        )
                                    })}
                                </S.SubList>
                            )
                            }

                        </S.SubmenuLink>
                        :
                        <S.SubmenuLink {...rest} 
                            className="top-menu-link" 
                            title={title} 
                            target="_blank"
                            href={href}
                            onClick={(e) => {
                                onOpenInNewTabLinkClick(e, href)
                            }}>
                            {title}
                        </S.SubmenuLink>}
                    </S.SubmenuListItem>
                )
            })}
        </S.SubmenuList>
    );
};

export default memo(SubmenuList);
