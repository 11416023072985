import React from 'react'
import * as styled from '../StyledVirtual'

const Stats = ({ statsUrl }) => {
  const onClick = () => {
    window.open(
      `https://s5.sir.sportradar.com/bukmacherskavirtuals/pl/1${statsUrl}`,
      'betradarStats',
      'resizable, scrollbars'
    )
  }

  return (
    <styled.Stats onClick={onClick}>
      <styled.StatsIconWrap>
        <styled.StatsIcon />
      </styled.StatsIconWrap>
    </styled.Stats>
  )
}

export default Stats
