import React, { useState, useEffect } from 'react';
import {translation} from 'helpers/utilsHelper';
import S from 'StyledBetSlip.js';

const TotalBonus = ({
    winning,
    totalBonus: {
        currentBonusValue = 0,
        futureBonusValue = 0,
        isGameWithoutTax,
        isTotalbonusAvailable,
        minOutcomeOdds = 0,
        outcomesLeftToPlayWithoutTax,
        bonusStepFrom = 0
    },
    winningForDefaultTaxFactor = 0,
    bonus = 0,
    currencyCode,
    outcomes

}) => {
    const [outcomesLeftTilBonus, setOutcomesLeftTilBonus] = useState(bonusStepFrom);

    const outcomeCount = outcomes.filter(({ outcomeOdds }) => parseFloat(outcomeOdds) >= minOutcomeOdds).length;

    useEffect(() => {
        if (!bonusStepFrom || bonusStepFrom - outcomeCount === 0) setOutcomesLeftTilBonus(1);
        else return setOutcomesLeftTilBonus(bonusStepFrom - outcomeCount);
    }, [outcomeCount, bonusStepFrom]);

    return (
        <S.TotalBonusWrapper className="total-bonus-wrapper">
            {bonus > 0 && (
                <S.FormGroup className="total-bonus">
                    <S.FormGroupText data-translation={'betSlip_customBonusTitle'}>{translation('betSlip_customBonusTitle')}</S.FormGroupText>
                    <S.FormGroupText>{bonus} {currencyCode}</S.FormGroupText>
                </S.FormGroup>
            )}
            {minOutcomeOdds > 0 && futureBonusValue > 0 && (
                <S.TotalBonusAdditionalInfoWrapper>
                    {(bonus < 250_000) && (
                        <S.TotalBonusAdditionalInfoText data-translation={'betSlip_customBonusAdditionalInfo'} dangerouslySetInnerHTML={{ __html: translation(outcomesLeftTilBonus === 1 ? 'betSlip_customBonusAdditionalInfoOne' : 'betSlip_customBonusAdditionalInfo', [futureBonusValue, minOutcomeOdds, outcomesLeftTilBonus]) }} />
                    )}
                    {(bonus >= 250_000) && (
                        <S.TotalBonusAdditionalInfoText data-translation={'betSlip_customBonusInfoMax'} dangerouslySetInnerHTML={{ __html: translation('betSlip_customBonusInfoMax') }} />
                    )}
                    <S.TotalBonusAdditionalInfoIcon />
                </S.TotalBonusAdditionalInfoWrapper>
            )}
        </S.TotalBonusWrapper>
    );
};

export default TotalBonus;
