import styled from "styled-components";
// import InputRange from "react-input-range";
import {rem} from 'polished';

const baseFont = `12px`;

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FwMedium = ({theme: {$fwMedium}}) => `${$fwMedium}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const S = {};

S.GetLucky = styled.form`
margin-top: ${rem('15px', baseFont)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    border-bottom: ${rem('6px', baseFont)} solid  ${({theme:{$getLuckyBorderColor}}) => $getLuckyBorderColor};
    background-color: ${({theme:{$getLuckyBackgroundColor}}) => $getLuckyBackgroundColor};
`;

S.GetLuckyHeader = styled.div`
    width: 100%;
    height: ${rem('50px', baseFont)};
    line-height: ${rem('50px', baseFont)};
    padding-left: ${rem('20px', baseFont)};
    font-size: 1.25rem;
    color: ${({theme:{$getLuckyHeaderColor}}) => $getLuckyHeaderColor};
    background-color: ${({theme:{$getLuckyHeaderBackground}}) => $getLuckyHeaderBackground};
    text-transform: uppercase;
    font-weight: ${FwRegular};
`;

S.GetLuckyHeaderTitle = styled.span``;

S.ArrowIcon = styled.i`
    margin-left: auto;
    margin-right: 0.5rem;
`;

S.GetLuckyBody = styled.div`
    position: relative;
    max-height: 1000px;
    transition-property: max-height;
    transition-duration: .5s;
    ${({isHidden}) => {
        return isHidden == true && 'max-height: 0';
    }}
`;

S.GetLuckyBodyContent = styled.div`
    background: ${({theme:{$getLuckyBodyContentBackground}}) => $getLuckyBodyContentBackground};
    color: ${({theme:{$getLuckyBodyContentColor}}) => $getLuckyBodyContentColor};
`;

S.GetLuckyBodyPanel = styled.div`
    padding-left: ${rem('20px', baseFont)};
    padding-right: ${rem('20px', baseFont)};
    &.border-bottom {
        border-bottom: 1px solid ${({theme:{$getLuckyBodyBorderColor}}) => $getLuckyBodyBorderColor};
    }
`;

S.CheckboxWrapperLabel = styled.div`
    margin-top: 1rem;
    font-size: 11px;
    font-family: ${FontFamilyMulish};
    text-align: left;
    font-weight: 700;
`;

S.FormGroupWrapper = styled.div`
    margin-top: 1rem;
`;

S.Label = styled.label`
    margin-top: 0.25rem;
    font-size: 14px;
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    margin-bottom: 0;
    width: 50%;
    font-weight: ${FwMedium};
    text-transform: uppercase;
`;

S.AmountWrapper = styled.div`
    background-color: ${({theme:{$getLuckyInputBackground}}) => $getLuckyInputBackground};
    border: 1px solid ${({theme:{$getLuckyInputBorderColor}}) => $getLuckyInputBorderColor};
    border-radius: 4px;
    height: ${rem('28px', baseFont)};
    padding: 0 0.5rem 0 0;
    width: 50%;
    color: ${({theme:{$getLuckyInputColor}}) => $getLuckyInputColor};
`

S.Input = styled.input`
    font-size: 13px;
    text-align: right;
    background-color: transparent;
    border: none;
    padding-right: 0.5rem;
    width: 100%;
    font-weight: ${FwMedium};
    color: ${({theme:{$getLuckyInputColor}}) => $getLuckyInputColor};
`;

S.Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 13px;
    color: ${({theme:{$getLuckyButtonColor}}) => $getLuckyButtonColor};;
    background-color: ${({theme:{$getLuckyButtonBackground}}) => $getLuckyButtonBackground};;
    height: ${rem('40px', baseFont)};
    line-height: ${rem('40px', baseFont)};
    border: none;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    font-family: ${FontFamilyMulish};
    font-weight: 700;
`;

S.Currency = styled.div`
    font-size: 13px;
`;

S.CheckboxWrapper = styled.div`
    margin: 1rem 0 0.5rem 0rem;
`;

S.CheckboxGroup = styled.div`
    flex: 0 0 33.3333333333%;
`;

S.LabelCheckbox = styled.label`
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    font-size: 11px;
`;

S.FakeCheckbox = styled.div`
    background-color: ${({theme:{$getLuckyFakeChboxBackground}}) => $getLuckyFakeChboxBackground};
    margin-right: 0.5rem;
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid ${({theme:{$getLuckyInputBorderColor}}) => $getLuckyInputBorderColor};;
    &:before {
        content: "\\002714";
        position: absolute;
        display: none;
        left: 0.30rem;
        top: 0.05rem;
    }
`;

S.InputCheckbox = styled.input`
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;

    &:checked + ${S.FakeCheckbox} {
        &:before {
            display: block;
        }
    }
`;

S.CheckboxText = styled.span`
    font-size: 11px;
    font-family: ${FontFamilyMulish}; 
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    font-weight: 400;
`;

S.SliderWrapper = styled.div`
    margin: 0.8rem 0 0.8rem 0;

    .input-range {
        margin-top: 1rem;
        width: 67%!important;
        margin-left: 13%!important;
        margin-right: 20%!important;
        &__slider {
            height: ${rem('25px', baseFont)};
            width: ${rem('25px', baseFont)};
            margin-top: -1.9rem;
            background: ${({theme:{$getLuckyInputRangeTrackSlider}}) => $getLuckyInputRangeTrackSlider};
            border: 1px solid ${({theme:{$getLuckyInputRangeTrackSliderBorderColor}}) => $getLuckyInputRangeTrackSliderBorderColor};
            transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
            z-index: 10000;
            &-container {
                transition: left 0.1s ease-out;
            }
            &:active {
                transform: scale(1.0)!important;
            }
        }
        &__track {
            background: ${({theme:{$getLuckyInputRangeTrackBackground}}) => $getLuckyInputRangeTrackBackground};
            transition: left 0.1s ease-out, width 0.1s ease-out;
            border-radius: 1rem!important;
            height: ${rem('20px', baseFont)};
            &--active {
                border-radius: 0px!important;
                background: ${({theme:{$getLuckyInputRangeTrackActiveBackground}}) => $getLuckyInputRangeTrackActiveBackground};
            }
        }
        &__label {
            display: none;
        }
    }
`;

S.InputRangeValuesWrapper = styled.div`
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    font-size: 13px;
    position: relative;
    height: 1.5rem;
`

S.InputRangeValue = styled.div`
    position: absolute;
    top: -0.6rem;
    font-weight: ${FwMedium};
    &.left {
        left: 0px;
    }
    &.right {
        right: 0px;
    }
`

S.SliderLabel = styled.span`
    font-size: 11px;
    font-family: ${FontFamilyMulish};
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    font-weight: 700;
`;

S.GetLuckyFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${rem('10px', baseFont)} ${rem('20px', baseFont)} ${rem('10px', baseFont)} ${rem('20px', baseFont)};
    flex-wrap: wrap;
`;

S.Error = styled.div`
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-size: 1.5rem;
    color: #262626;
    background-color: red;
    margin-top: 0.5rem;
    font-weight: normal;
    height: 3.5rem;
    line-height: 3.5rem;
`

export default S;
