import React, {useEffect, useState} from 'react';
import Loader from 'Loader.js';
import {useDispatch} from 'react-redux';
import {translation} from 'utilsHelper.js';
import {customerActivateAccount} from 'customerActions.js';
import S from 'StyledAccountActivate.js';

const AccountActivate = () => {

    const dispatch = useDispatch();
    const [activationObj, setActivationObj] = useState({isPending: true, error: null});
    const {isPending, error} = activationObj;

    useEffect(() => {
        requestActivateAccount();
    }, []);

    const requestActivateAccount = async() => {
        try {
            const {userId, code} = app.router.getPathParams();
            const params = {};
            params['customerId'] = userId;
            params['activationType'] = 'account-and-email';
            params['activationCode'] = code;

            await dispatch(customerActivateAccount(params));
            setActivationObj({isPending: false});
        } catch (error) {
            setActivationObj({isPending: false, error})
        }
    };

    if (isPending) {
        return <Loader color="#F05A22"/>;
    }
    if (error) {
        return <S.Error>{error}</S.Error>
    }

    return (
        <S.AccountActivate>
            {translation('accountActivate_success')}
        </S.AccountActivate>
    );
};

export default AccountActivate;
