import React, {useState, memo, useEffect, useMemo} from 'react';
import Switch from 'react-switch';
import S from 'StyledBetSlipPromotions.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import { Scrollbars } from 'react-custom-scrollbars';
import useMediaQuery from 'react-use-media-query-hook';
import {translation} from 'utilsHelper';

const BetSlipPromotions = ({togglePromotion, selectedPromotion, slipType, hasApprovalButtonsVisibled, getCustomerBetSlipPromotions, promotions}) => {

    const OFFER_TYPE_TAX_FACTOR_PROMOTION = "TAX_FACTOR_PROMOTION";

    const [betSlipPromotions, setBetSlipPromotions] = useState(null);

    const isSmallDesktop = useMediaQuery('(max-width: 1440px)');

    useEffect(() => {
        const getBetSlipPromo = async() => {
            await getCustomerBetSlipPromotions();
        }
        getBetSlipPromo();
    }, []);

    useEffect(() => {
        const customerPromotions = _filter(promotions, (promo) => promo.offerType === OFFER_TYPE_TAX_FACTOR_PROMOTION);
        setBetSlipPromotions(customerPromotions);
    }, [promotions]);

    const toggleSelectedPromotion = (offerId) => {
        const item = _find(betSlipPromotions, {offerId});
        togglePromotion(item);
    };

    const isVisible = useMemo(() => {
        return (slipType == 'ACCUMULATOR' && _size(betSlipPromotions) > 0);
    }, [slipType, betSlipPromotions]);

    return (
        <S.BetSlipPromotionsWrapper isVisible={isVisible}>

            <S.BetSlipPromotions isSmallDesktop={isSmallDesktop}>

                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                    style={{width: '100%'}}
                    autoHeight
                    autoHeightMax={(isSmallDesktop ? 50 : 100)}>

                    {_map(betSlipPromotions, ({offerName, offerId, offerNameKey}) => {
                        const selectedPromotionId = _get(selectedPromotion, ['offerId']);
                        return (
                            <S.BetSlipPromotionsItem key={offerId} isActive={offerId == selectedPromotionId}>
                                <span>
                                    <S.PromotionName data-translation={offerNameKey}>{translation(offerNameKey)}</S.PromotionName>
                                </span>&nbsp;&nbsp;
                                <Switch height={20}
                                        width={40}
                                        disabled={hasApprovalButtonsVisibled}
                                        onChange={() => toggleSelectedPromotion(offerId)}
                                        checked={offerId == selectedPromotionId}/>
                            </S.BetSlipPromotionsItem>
                        )
                    })}

                </Scrollbars>

            </S.BetSlipPromotions>

        </S.BetSlipPromotionsWrapper>
    );
};

export default memo(BetSlipPromotions);
