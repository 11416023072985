import React from 'react'
import { Vflm } from 'sb-virtuals'
import * as styled from '../StyledVirtual'
import Outcome from '../utils/Outcome'
import Stats from '../utils/Stats'
import { AnimatePresence } from 'framer-motion'

const { Event, Game } = Vflm

const variants = {
  open: { height: 'auto' },
  collapsed: { height: 0 },
}

const Layout = ({ iframeUrl, iframeHeight, tabsList, events, groups, styles, ...rest }) => (
  <styled.Wrapper>
    <styled.Iframe src={iframeUrl} height={iframeHeight} width={800} />

    {tabsList ? (
      <styled.GamesList>
        {tabsList.map((item) => (
          <styled.GamesItem key={item.name}>
            <styled.GamesItemButton {...item}>
              {item.name}
            </styled.GamesItemButton>
          </styled.GamesItem>
        ))}
      </styled.GamesList>
    ) : null}

    <div {...styles.wrapper}>
      <styled.EventsList {...styles.events} hasOneChild={events.length === 1}>
        {events.map(event => (
          <Event {...event} {...rest} key={event.eventId}>
            {({ isSingle, isOpen, toggleIsOpen, games, otherGames, otherGamesCount, statsUrl }) => (
              isSingle ? (
              <styled.EventsItem>
                <styled.EventsRest>
                  {games.map(game => (
                    <styled.EventsRestItem key={game.gameId}>
                      <styled.GameDataInfo>
                        <styled.EventsRestHeading>{game.gameName}</styled.EventsRestHeading>
                      </styled.GameDataInfo>
                      <styled.EventsRestSublist>
                        <Game {...game} key={game.gameId}>
                          {({ outcomes }) => (
                            outcomes.map(outcome => (
                              <styled.EventsRestSubitem key={outcome.outcomeId}>
                                <Outcome isWide {...outcome} />
                              </styled.EventsRestSubitem>
                            ))
                          )}
                        </Game>
                      </styled.EventsRestSublist>
                    </styled.EventsRestItem>
                  ))}
                </styled.EventsRest>
              </styled.EventsItem>
              ) : (
              <styled.EventsItem>
                <styled.EventsDataInfo>
                  <styled.EventIcon>
                    <use href={`#sport-${event.sportId}`}></use>
                  </styled.EventIcon>
                  <styled.EventsName>{event.eventName}</styled.EventsName>
                </styled.EventsDataInfo>
                {games.map((game, index) => (
                  <Game {...game} key={game.gameId}>
                    {({ outcomes }) => (
                      <styled.Group {...styles.outcomes}>
                        <styled.GroupHeading>
                          {groups?.[index]?.header ? (
                            <div {...styles.heading}>{groups[index].header}</div>
                          ) : null}
                          {groups?.[index]?.outcomes ? (
                            <div {...styles.headers}>
                              <div {...styles.scope}>
                                {groups[index].outcomes.map((outcome, key) => (
                                  <div {...styles.outcome} key={key}>{outcome}</div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </styled.GroupHeading>
                        <styled.OutcomesList {...styles.outcomes}>
                          <styled.OutcomesItem {...styles.row}>
                            <styled.OutcomesSublist {...styles.columns}>
                              {game.argument ? (
                                <styled.OutcomesSubitem {...styles.argument}>
                                  <styled.OutcomesArgument>
                                    {game.argument}
                                  </styled.OutcomesArgument>
                                </styled.OutcomesSubitem>
                              ) : null}
                              {outcomes.map(outcome => (
                                <styled.OutcomesSubitem {...styles.outcome} key={outcome.outcomeId}>
                                  <Outcome {...outcome} />
                                </styled.OutcomesSubitem>
                              ))}
                            </styled.OutcomesSublist>
                          </styled.OutcomesItem>
                        </styled.OutcomesList>
                      </styled.Group>
                    )}
                  </Game>
                ))}
                {otherGamesCount ? (
                  <styled.Toggler onClick={toggleIsOpen}>
                    {`+${otherGamesCount}`}
                  </styled.Toggler>
                ) : null}
                {statsUrl ? (
                  <Stats statsUrl={statsUrl} />
                ) : null}
                <AnimatePresence>
                  {isOpen ? (
                    <styled.EventsRest
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={variants}
                    >
                      {otherGames?.map(game => (
                        <styled.EventsRestItem key={game.gameId}>
                          <styled.EventsRestHeadingNonFull>{game.gameName}</styled.EventsRestHeadingNonFull>
                          <styled.EventsRestSublist>
                            <Game {...game} key={game.gameId}>
                              {({ outcomes }) => (
                                outcomes.map(outcome => (
                                  <styled.EventsRestSubitem key={outcome.outcomeId}>
                                    <Outcome isWide {...outcome} />
                                  </styled.EventsRestSubitem>
                                ))
                              )}
                            </Game>
                          </styled.EventsRestSublist>
                        </styled.EventsRestItem>
                      ))}
                    </styled.EventsRest>
                  ) : null}
                </AnimatePresence>
              </styled.EventsItem>
              )
            )}
          </Event>
        ))}
      </styled.EventsList>
    </div>
  </styled.Wrapper>
)
export default Layout
