import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FwBold = ({theme:{$FwBold}}) => `${$FwBold}`;
const FontFamily = ({theme:{$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme:{$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;

const S = {};

S.ResponsibleGamingForm = styled(Form)`
    width: ${rem('800px', baseFont)} !important;
    font-family: ${FontFamilyMulish};
    padding: 0 0 ${rem('20px', baseFont)};
    margin: 0 !important;
    font-weight: 600;
`;

S.CloseModalButton = styled(Button)`
    position: absolute;
    right: -40px;
    top: -40px;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;
    transform: none;
    line-height: 1;
    margin-right: -3rem;
    margin-top: -3rem;
    border-radius: 50%;
    bottom: 0;
    margin: .83rem 0;
    background: #fff url(/images/svg/header_close_black.svg) no-repeat 50% 50%;
    background-size: 1.47rem 1.47rem;
    cursor: pointer;
`;

S.LoaderWrapper = styled.div`
    width: ${rem('584px', baseFont)};
`;

S.Row = styled.div`
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;


S.Label = styled.label`
    font-size: ${rem('12px', baseFont)};
    text-overflow: ellipsis;
    overflow: hidden;
`;

S.Input = styled(Input)`
    font-family: ${FontFamilyMulish};
    font-size:  ${rem('15px', baseFont)};
    color: #001A5B;
    border: none;
    display: block;
    text-align: left;
    height: ${rem('42px', baseFont)};
    font-weight: 700;

    &:focus {
        border-bottom-color: #0E62C4;
    }
     
    &[type="number"]{
      -moz-appearance: textfield;
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
   
`;

S.SubmitBtn = styled(Button)`
    overflow: hidden;
    border-radius: ${rem('7px', baseFont)};
    height: ${rem('40px', baseFont)};
    width: ${rem('240px', baseFont)};
    padding: 0 ${rem('10px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameFormSubmitBtn}})=> $accountResponsibleGameFormSubmitBtn};
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    text-align: center;
    text-overflow: ellipsis;
    font-weight: 700;
    color: #fff;
`;

S.ClearFormBtn = styled(Button)`
    overflow: hidden;
    margin-right: ${rem('30px', baseFont)};
    border-radius: ${rem('7px', baseFont)};
    height: ${rem('40px', baseFont)};
    width: ${rem('240px', baseFont)};
    padding: 0 ${rem('10px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameFormClearBtn}})=> $accountResponsibleGameFormClearBtn};
    font-size: ${rem('13px', baseFont)};
    font-family: ${FontFamilyMulish};
    text-align: center;
    text-overflow: ellipsis;
    font-weight: 700;
    color: #fff;
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    padding: 2px 0;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.LimitUpdateResponse = styled(S.SubmissionError)`
    background-color: transparent;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
`;

S.Currency = styled.div`
    font-family: ${FontFamilyMulish};
    font-size:  ${rem('15px', baseFont)};
    font-weight: 700;
`;

S.Hour = styled(S.Currency)`
    text-transform: lowercase;
`;

S.InputWrapper = styled.div`
    height: 85px;
    display: flex;
    flex-direction: column;
   
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
                flex: 1;
                font-size: 1.17rem;
                font-weight: 400;
                padding-top: 0;
                color: #787d83;
                display: flex;
                position: relative;
                left: 0;
                cursor: pointer;
                top: 0;
                
                & a{
                    color: #CE0006;
                }
            }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 1px;
            height: 14px;
            width: auto;
         }
    }
`;

S.ValueWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 2px solid #BAC1D0;

    &:focus-within{
        border-bottom: 2px solid #0E62C4;
    }
    &.has-error{
        border-bottom: 2px solid red;
    }
    &.is-valid{
        border-bottom: 2px solid #BAC1D0;
    }

`;

S.Body = styled.div``;

S.BodyInner = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    > div {
        flex-basis: 35%;
        margin-right: 100px;
    }
`;

S.Footer = styled.div`
    display: flex;
`;

S.FormTitle = styled.h2`
  margin: 0;
  padding-bottom: 20px;
  color: ${({theme:{$accountResponsibleGameTitleColor}})=> $accountResponsibleGameTitleColor};
  font-family: ${FontFamily};
  font-size: ${rem('16px', baseFont)};
  font-weight: ${FwSemibold};
`;

S.Limit = styled.div`
  color: ${({status})=>(status?'green':'red')};
  margin-bottom: ${rem('10px', baseFont)};
  &:last-of-type{
    margin-bottom: 0;
  }
`;

S.LimitName = styled.div`
  font-weight: ${FwSemibold};
`;

S.LimitText = styled.div`
color: ${({theme:{$accountResponsibleGameFormLabel}})=> $accountResponsibleGameFormLabel};
line-height: 1.4;
`;

S.DialogBtnOk = styled(Button)`
    margin-bottom: ${rem('10px', baseFont)}; 
    border-radius: ${rem('10px', baseFont)};
    width: 100%;
    padding: ${rem('18px', baseFont)};
    background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelBgColor}})=> $accountResponsibleGameSelfExclusionBtnCancelBgColor};
    font-size: ${rem('14px', baseFont)};
    font-family: ${FontFamily};
    font-weight: ${FwBold};
    color: ${({theme:{$accountResponsibleGameSelfExclusionBtnCancelColor}})=> $accountResponsibleGameSelfExclusionBtnCancelColor};
    
    &:focus {
            outline: 0;
            outline-color: transparent;
            outline-style: none;
            border-bottom-color: #0E62C4;
        }
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
    background-color: ${({theme:{$accountResponsibleGameSelfExclusionBtnBgColor}})=> $accountResponsibleGameSelfExclusionBtnBgColor};
    font-weight: ${FwBold};
    color: ${({theme:{$accountResponsibleGameSelfExclusionBtnColor}})=> $accountResponsibleGameSelfExclusionBtnColor};
`;

S.DialogText =styled.div`
    font-size: ${rem('17px', baseFont)};
    font-weight:  ${FwSemibold};
    color: ${({theme:{$accountResponsibleGameSelfExclusionDialogText}})=> $accountResponsibleGameSelfExclusionDialogText};;
    text-align: center;
`;

export default S;