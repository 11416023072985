import styled from 'styled-components';
import {rem} from 'polished';
import {Button} from 'global/styles/styles.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;
const FwMedium = ({theme: {$fwMedium}}) => `${$fwMedium}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const AccountBetHistoryTransactionItemColor = ({theme: {$accountBetHistoryTransactionItemColor}}) => `${$accountBetHistoryTransactionItemColor}`;
const AccountBetHistoryTransactionItemBackgroundColor = ({theme: {$accountBetHistoryTransactionItemBackgroundColor}}) => `${$accountBetHistoryTransactionItemBackgroundColor}`;
const AccountBetHistoryTransactionItemBorderColor = ({theme: {$accountBetHistoryTransactionItemBorderColor}}) => `${$accountBetHistoryTransactionItemBorderColor}`;
const AccountBetHistoryTransactionCashoutButtonBackgroundColor = ({theme: {$accountBetHistoryTransactionCashoutButtonBackgroundColor}}) => `${$accountBetHistoryTransactionCashoutButtonBackgroundColor}`;


const baseFont = `12px`;
const S = {};

S.BetHistoryItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;


S.FreebetIcon = styled.div`
 width:  ${rem('20px', baseFont)};
     height:  ${rem('20px', baseFont)};
     margin-left: ${rem('10px', baseFont)};
`;

S.BetHistoryItem = styled.div`
    display: flex;
    background-color: ${AccountBetHistoryTransactionItemBackgroundColor};
    min-height: ${rem('60px', baseFont)};
    border-bottom: 1px solid ${AccountBetHistoryTransactionItemBorderColor};
    cursor: pointer;
    
    & > div{
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size:  ${rem('13px', baseFont)};
        text-align: left;
        color: ${AccountBetHistoryTransactionItemColor};
        align-items: center;
        padding-right: ${rem('10px', baseFont)};
        font-family: ${FontFamilyMulish};
        font-weight: 700;
        
             &.transaction-status{
                flex: 1 1 10%;
                border-right: none;
                padding-left: ${rem('20px', baseFont)};
                display: flex;
                
              &.default {
              }
    
              &.pending_acceptation {
                   background: transparent url(/images/icon_pending.png) no-repeat 28px 50%;
              }
    
              &.won {
              background: transparent url(/images/icon_won.png) no-repeat 28px 50%;
              }
    
              &.cashout {
                    background: transparent url(/images/icon_cashout.png) no-repeat 28px 50%;
              }
    
              &.cancel {
              background: transparent url(/images/icon_cancel.png) no-repeat 28px 50%;
              }
    
              &.cashback {
                    background: transparent url(/images/icon_cashback.png) no-repeat 28px 50%;
              }
    
              &.lost {
               background: transparent url(/images/icon_lost.png) no-repeat 28px 50%;
              }
    
              &.placed {
               background: transparent url(/images/icon_pending.png) no-repeat 28px 50%;
              }
    
              &.safebet {
              }
            
        }
        
       &.transaction-odd{
                flex: 1 1 7%;
        }
        
        &.transaction-amount-cashout{
             flex: 1 1 15%;
             display: flex;
             align-items: center;
        }
        &.transaction-amount{
             flex: 1 1 15%;
        }
            
        &.transaction-date-cashout,
        &.transaction-date{
          display: flex;
          flex-direction: column;
          flex: 1 1 15%;
          justify-content: center;
          align-items: flex-start;
          color: rgb(0, 26, 91, 0.5);
          font-size: ${rem('11px', baseFont)};
          position: relative;
        }
        
        &.transaction-stake-cashout,
        &.transaction-stake{
             flex: 1 1 15%;
        }
        &.transaction-result{
          flex: 1 1 15%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
                 
        }
        &.transaction-type{
           flex: 1 1 20%;
        }
        &.transaction-cashout{
            padding-right: 0;
            flex: 1 1 18%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            & > button{
                font-family: ${FontFamily};
                background-color: ${AccountBetHistoryTransactionCashoutButtonBackgroundColor};
                width: ${rem('100px', baseFont)};
                height: ${rem('32px', baseFont)};
                text-transform: uppercase;
                border-radius: 4px;
                border: 0;
                color: #fff;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;
                font-weight: ${FwRegular};
                font-size: ${rem('12px', baseFont)};
            }
        }
        
    }
`;

S.ErrorModal = styled.div`
     width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.SlipDetailsArrow = styled.img`
  position: absolute;
  right: ${rem('20px', baseFont)};
  top: 0;
  bottom: 0;
  margin: auto 0;
  width:  ${rem('8px', baseFont)};
  height:  ${rem('12px', baseFont)};
  cursor: pointer;
`;

S.TransactionTitle = styled.div`
  color: ${AccountBetHistoryTransactionItemColor};
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('11px', baseFont)};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
`;


S.TransactionAmmount = styled.div`
  color: ${({isWon, isCombinationWon})=>(isWon || isCombinationWon? `#098E09`: `${AccountBetHistoryTransactionItemColor}`)};
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  width: 100%;
   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
`;

export default S;