import React from 'react'
import { Fields, Tabs } from 'sb-virtuals'
import * as styled from 'StyledVirtual'
import Outcome from '../utils/Outcome'
import Switcher from '../utils/Switcher'

const { SwitchProvider, Switch, Tab } = Tabs

const { EventsProvider } = Fields

const MatchGroup = ({ isInsideTab, gameName, gameOutcomes, outcomes, groups, tab, styles, arguments: args, index }) => (
  <styled.Group {...styles.outcomes} key={index}>
    {!isInsideTab ? (
      gameName ? <styled.MatchGroupHeading {...styles.heading}>{gameName}</styled.MatchGroupHeading> : null
    ) : null}

    {gameOutcomes ? (
      <div {...styles.headers}>
        {outcomes ? (
            outcomes[index] ? (
                outcomes[index].map((_, key) => (
                  <div {...styles.scope} key={key}>
                    <>
                      {gameOutcomes.map((gameOutcome, key) => (
                        <div {...styles.outcome} key={key}>
                          {typeof gameOutcome === 'string' ? gameOutcome : (
                            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                              <div style={{ textAlign: 'center' }}>{gameOutcome.main}</div>
                              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {gameOutcome.sub.map((sub, index) => (
                                  <div key={index}>
                                    {sub}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  </div>
                ))
            ) : null
        ) : null}
      </div>
    ) : null}

    {outcomes ? (
      <styled.OutcomesList {...styles.outcomes}>
        {outcomes.map((columns, columnsIndex) => (
          <styled.OutcomesItem {...styles.row} key={columnsIndex}>
            {columns.map((outcomes, outcomesIndex) => (
              <styled.OutcomesSublist {...styles.columns} key={outcomesIndex}>
                {args ? (
                  args[columnsIndex] ? (
                    args[columnsIndex][outcomesIndex] ? (
                      <styled.OutcomesSubitem {...styles.argument}>
                        <styled.OutcomesArgument>
                          {args[columnsIndex][outcomesIndex]}
                        </styled.OutcomesArgument>
                      </styled.OutcomesSubitem>
                    ) : null
                  ) : null
                ) : null}
                {outcomes.map((outcome) => (
                  <styled.OutcomesSubitem {...styles.outcome} key={outcome.outcomeId}>
                    <Outcome {...outcome} />
                  </styled.OutcomesSubitem>
                ))}
              </styled.OutcomesSublist>
            ))}
          </styled.OutcomesItem>
        ))}
      </styled.OutcomesList>
    ) : null}

    {tab ? (
      <Tab tab={tab} index={index}>
        <div {...styles.wrapper}>
          {groups?.map((group, key) => (
            <MatchGroup isInsideTab {...group} index={key} styles={styles} key={key} />
          ))}
        </div>
      </Tab>
    ) : null}
  </styled.Group>
)

const Layout = ({ iframeUrl, iframeHeight, tabsList, events, data, styles }) => (
  <styled.Wrapper>
    <styled.Iframe src={iframeUrl} height={iframeHeight} width={800} />

    {tabsList ? (
      <styled.GamesList>
        {tabsList.map((item) => (
          <styled.GamesItem key={item.name}>
            <styled.GamesItemButton {...item}>
              {item.name}
            </styled.GamesItemButton>
          </styled.GamesItem>
        ))}
      </styled.GamesList>
    ) : null}

    <styled.EventsWrap {...styles.wrapper}>
      <EventsProvider events={events}>
        <SwitchProvider>
          <styled.Switch>
            <Switch element={Switcher}/>
          </styled.Switch>
          <styled.EventNamesList {...styles.events}>
            {events?.map(({ eventName, sportId }, eventNameIndex) => (
              <styled.EventNamesItem key={eventNameIndex}>
                <styled.EventsDataInfo>
                  <styled.EventIcon>
                    <use href={`#sport-${sportId}`}></use>
                  </styled.EventIcon>
                  <styled.EventNamesName>{eventName}</styled.EventNamesName>
                </styled.EventsDataInfo>  
              </styled.EventNamesItem>
            ))}
          </styled.EventNamesList>
          {data?.map((group, index) => (
            <MatchGroup {...group} index={index} key={index} styles={styles} />
          ))}
        </SwitchProvider>
      </EventsProvider>
    </styled.EventsWrap>
  </styled.Wrapper>
)

export default Layout