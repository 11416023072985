import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import S from 'StyledModal.js';
import classNames from 'classnames';
import {modalUserIconSVG} from 'global/svgIcons/svgIcons.js';
import CloseIcon from 'account_close_btn.png';

const Modal = ({showHeader, showHeaderIcon, preventCloseOnOverlay, showCloseIcon, showHeaderTitle, onClose, title = '', children, className, flexDirectionBody, backgroundBody, padding, width, height, maxHeight, backgroundWrapper, margin, borderRadius, alignItemsBody}) => {

    useEffect(()=>{
        window.addEventListener('scroll', onScrollHandler);
        return ()=> window.removeEventListener('scroll', onScrollHandler);
    }, []);

    const elementMouseDownRef = useRef(null);

    const onScrollHandler = (e) => {
        window.scrollTo(0, 0);
    };

    const onMouseUpHandler = (e) => {
        if (elementMouseDownRef.current == e.target && e.target.matches('.modal-background')) {
            if (!preventCloseOnOverlay && onClose && typeof onClose == 'function') {
                onClose();
                reactStopPropagation(e);
            }
        } else {
            reactStopPropagation(e);
        }
    };

    const onMouseDownHandler = (e)=>{
        reactStopPropagation(e);
        elementMouseDownRef.current = e.target;
    };

    const reactStopPropagation = (e) => {
        e.persist(); 
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
    }

    const closeIcon = (showCloseIcon && <S.CloseIconContainer src={CloseIcon} onClick={onClose}></S.CloseIconContainer>);

    const headerIcon = (showHeaderIcon && <S.UserIconContainer dangerouslySetInnerHTML={modalUserIconSVG()}></S.UserIconContainer>);

    const headerTitle = (showHeaderTitle && <S.ModalTitle>{title}</S.ModalTitle>);

    const modalMarkup = (
        <S.ModalBackground className={classNames(className, 'modal-background')}
                           onMouseDown={onMouseDownHandler}
                           onMouseUp={onMouseUpHandler}
                           onClick={reactStopPropagation}
                           >
                           

            <S.ModalWrapper className="modal-wrapper" padding={padding} width={width} height={height} maxHeight={maxHeight} margin={margin} borderRadius={borderRadius} backgroundWrapper={backgroundWrapper}>
                {showHeader &&
                (
                    <S.ModalHeader>
                        {closeIcon}
                        {headerIcon}
                        {headerTitle}
                    </S.ModalHeader>
                )}
                <S.ModalBody flexDirectionBody={flexDirectionBody} alignItemsBody={alignItemsBody} backgroundBody={backgroundBody}>
                    {children}
                </S.ModalBody>
            </S.ModalWrapper>

        </S.ModalBackground>
    );

    return ReactDOM.createPortal(modalMarkup, document.body);
};

export default Modal;
