import React, { useEffect, useMemo } from 'react';
import {useDispatch} from 'react-redux';
import S from 'StyledUnloggedPanel.js';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'
import LoginForm from 'LoginForm.js';
import RegisterWizardForm from 'RegisterWizardForm.js';
import ForgotPasswordForm from 'ForgotPasswordForm.js';
import ResetPasswordForm from 'ResetPasswordForm.js';
import CmsPage from 'CmsPage.js';
import AccountActivate from 'AccountActivate.js';

const UnloggedPanel = ({loginOnSubmit, registerOnSubmit, preregisterUser, verificationCode, forgotPasswordOnSubmit, resetPasswordOnSubmit, destroyWizardFormOnModalClose, isResetPasswordModalOpen, isRegisterModalOpen, isLoginModalOpen, isActivateAccountModalOpen}) => {

    const dispatch = useDispatch();
    const [showLoginModal, toggleLoginModal] = useToggle(false);
    const [showRegisterModal, toggleRegisterModal] = useToggle(false);
    const [showForgetPasswordModal, toggleForgetPasswordModal] = useToggle(false);
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);
    const [showResetPasswordModal, toggleResetPasswordModal] = useToggle(isResetPasswordModalOpen);
    const [showActivateAccountModal, toggleActivateAccountModal] = useToggle(isActivateAccountModalOpen);

    useEffect(() => {
        if (showResetPasswordModal && !isResetPasswordModalOpen) {
            toggleResetPasswordModal();
        } else if (!showResetPasswordModal && isResetPasswordModalOpen) {
            toggleResetPasswordModal();
        }
    }, [isResetPasswordModalOpen]);

    useEffect(() => {
        if (showRegisterModal && !isRegisterModalOpen) {
            toggleRegisterModal();
        } else if (!showRegisterModal && isRegisterModalOpen) {
            toggleRegisterModal();
        }
    }, [isRegisterModalOpen]);

    useEffect(() => {
        if (showLoginModal && !isLoginModalOpen) {
            toggleLoginModal();
        } else if (!showLoginModal && isLoginModalOpen) {
            toggleLoginModal();
        }
    }, [isLoginModalOpen]);

    useEffect(() => {
        if (showActivateAccountModal && !isActivateAccountModalOpen) {
            toggleActivateAccountModal();
        } else if (!showActivateAccountModal && isActivateAccountModalOpen) {
            toggleActivateAccountModal();
        }
    }, [isActivateAccountModalOpen]);

    const onLoginModalClose = () => {
        toggleLoginModal();
        dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: {key: 'TOGGLE_OPEN_LOGIN_MODAL', isOpen: false}
        });
    };

    const onActivationModalClose = () => {
        toggleActivateAccountModal();
        dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: {key: 'TOGGLE_OPEN_ACTIVATE_ACCOUNT_MODAL', isOpen: false}
        });
    };

    const onResetPasswordModalClose = () => {
        toggleResetPasswordModal();
        dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: { key: 'TOGGLE_OPEN_RESET_PASSWORD_MODAL', isOpen: false}
        });
    };

    const getBtag = (name = 'btag') => {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=')

            return parts[0] === name ? decodeURIComponent(parts[1]) : r
        }, '')
    }

    const onRegisterClick = () => {
        const btag = getBtag();
        const registrationUrl = process.env.REGISTRATION_URL;
        app.service.Integration.metaPixelSendEvent('InitRegistration', {})
        window.location = `${registrationUrl}/${btag ? `?btag=${btag}` : ''}`
    }

    return (
        <S.UnloggedPanel className="unlogged-panel-box">

            <S.ButtonsWrapper className="buttons-wrapper">
                <S.Button className="login-btn"
                          onClick={toggleLoginModal}
                          as="a">
                    {translation('account_unloggedPanel_header_login')}
                </S.Button>
                <S.Button className="register-btn" onClick={onRegisterClick}>
                    {translation('account_unloggedPanel_header_register')}
                </S.Button>
            </S.ButtonsWrapper>

            {/* ACCOUNT ACTIVATION MODAL */}
            <ModalOpacity isOpen={showActivateAccountModal}
                          showHeader={false}
                          toggleOpen={onActivationModalClose}>

                <AccountActivate/>

            </ModalOpacity>

            {/* LOGIN MODAL */}
            <ModalOpacity isOpen={showLoginModal}
                          toggleOpen={onLoginModalClose}
                          title={translation('loginModal_title')}
                          showHeader={false}
                          padding={0}>

                <LoginForm loginOnSubmit={loginOnSubmit} onLoginModalClose={onLoginModalClose} toggleForgetPasswordModal={toggleForgetPasswordModal}/>

            </ModalOpacity>

            {/* REGISTER WIZARD MODAL */}
            <ModalOpacity isOpen={showRegisterModal}
                          toggleOpen={toggleRegisterModal}
                          title="Register"
                          showHeader={false}
                          padding={0}>

                <S.CloseButton onClick={toggleRegisterModal}></S.CloseButton>

                <RegisterWizardForm registerOnSubmit={registerOnSubmit} preregisterUser={preregisterUser}
                                    loginOnSubmit={loginOnSubmit}
                                    destroyWizardFormOnModalClose={destroyWizardFormOnModalClose}
                                    verificationCode={verificationCode} toggleRegisterModal={toggleRegisterModal}
                                    toggleConfirmationModal={toggleConfirmationModal}/>

            </ModalOpacity>

            {/* REGISTER CONFIRMATION MODAL */}
            <ModalOpacity isOpen={showConfirmationModal}
                          toggleOpen={toggleConfirmationModal}
                          showHeader={false}
                          padding={0}>

                <S.ConfirmationModal>
                    <S.ConfirmationMsg>{translation('account_unloggedPanel_confirmationModal')}</S.ConfirmationMsg>
                    <S.ConfirmationCloseBtn
                        onClick={toggleConfirmationModal}>{translation('common_ok')}</S.ConfirmationCloseBtn>
                </S.ConfirmationModal>

            </ModalOpacity>

            {/* PASSWORD RECOVERY MODAL */}
            <ModalOpacity isOpen={showForgetPasswordModal}
                          toggleOpen={toggleForgetPasswordModal}
                          title={translation('forgotPasswordForm_title')}
                          showHeader={false}
                          padding={0}>

                <ForgotPasswordForm forgotPasswordOnSubmit={forgotPasswordOnSubmit} toggleOpen={toggleForgetPasswordModal}/>

            </ModalOpacity>

            {/* RESET PASSWORD MODAL */}
            <ModalOpacity isOpen={showResetPasswordModal}
                          toggleOpen={onResetPasswordModalClose}
                          showHeader={false}
                          padding={0}
                          title={translation('account_unloggedPanel_header_newPassword')}>

                <ResetPasswordForm resetPasswordOnSubmit={resetPasswordOnSubmit} onResetPasswordModalClose={onResetPasswordModalClose}/>

            </ModalOpacity>


        </S.UnloggedPanel>
    );
};

export default UnloggedPanel;
