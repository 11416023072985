const theme = {

    mediumRWD: `@media (max-width: 1359px)`,
    normalRWD: `@media (min-width: 1360px) and (max-width: 1439px)`,
    bigRWD: `@media (min-width: 1440px) and (max-width: 1599px)`,
    largeRWD: `@media (min-width: 1600px) and (max-width: 1919px)`,

    boxShadow: ( hOffset = 0, vOffset = 0, blur= 3, spread = 0, color = 'rgba(99,94,99,0.2)') => {
        return `box-shadow: ${hOffset}px ${vOffset}px ${blur}px ${spread}px ${color}`
    },

    lineClamp: (lineNumber)=>{
        return `
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${lineNumber};
            overflow: hidden;
        `;
    },

    // Main colors
    $color_1: `#E3F4FF`,
    $color_2: `#2D9EF5`,
    $color_3: `#3EE2EF`,
    $color_4: `#2999BF`,
    $color_5: `#FFF200`,
    $color_6: `#70BF44`,
    $color_7: `#51A517`,
    $color_8: '#626973',
    $color_9: `#EFEFEF`,
    $color_10: `#3F3F3F`,
    $color_11: `#E8E8E8`,
    $color_12: `#3C3C3C`,
    $color_13: `#F97447`,
    $color_14: `#F9C023`,
    $color_15: `#F82B00`,
    $color_16: `#E1E1E1`,

    // Additional colors
    $color_17: `#F75703`,
    $color_18: `#BC4405`,
    $color_19: `#F8A072`,
    $color_20: `#404040`,
    $color_21: `#222222`,
    $color_22: `#383838`,
    $color_23: `#333333`,
    $color_24: `#191919`,
    $color_25: `#ffffff`,
    $color_26: `#A1A1A1`,
    $color_27: `#000000`,
    $color_28: `#00A651`,
    $color_29: `#078143`,
    $color_30: `#004D26`,
    $color_31: `#007639`,
    $color_32: `#85C9A6`,
    $color_33: `#CACACA`,
    $color_34: `#242c2f`,
    $color_35: `#282828`,
    $color_36: `#A3A3A3`,
    $color_37: `#F3F3F3`,
    $color_38: `#EAEAEA`,
    $color_39: `#9CAEDA`,
    $color_40: `#CBD0D6`,
    $color_41: `#787878`,
    $color_42: `#2d9ef5`,
    $color_43: `#2d9ef5`,
    $color_44: `#777D85`,
    $color_45: `#5E0E0E`,
    $color_46: `#E9D3D4`,
    $color_47: `#4D555E`,
    $color_48: `#CE0006`,
    $color_49: `#001A5B`,
    $color_50: `#262626`,
    $color_51: `#FCFCFC`,
    $color_52: `rgba(0,0,0,0)`,
    $color_53: `#F2f2f2`,
    $color_54: `#FAFAFA`,
    $color_55: `#F2F2F2`,
	$color_56: `#494F55`,
    $color_57: `transparent`,
    $color_58: `#EAEAEA`,
    $color_59: `#fba59f`,
    $color_60: `#808080`,
    $color_61: `#54C341`,
    $color_62: `#F4F7FC`,
    $color_63: `#09256D`,
    $color_64: `#0E62C4`,
    $color_65: `#E4202C`,
    $color_66: `#262C70`,
    $color_67: `#E8ECF4`,
    $color_68: `#F3F6FA`,
    $color_69: `#BAC1D0`,
    $color_70: `#0D61C3`,
    $color_71: `rgba(0, 26, 91, 0.5)`,
    $color_72: `#111A5B`,
    $color_73: `#C6D9F1`,
    $color_74: `#F5F7FC`,
    $color_75: `#A0A8B5`,
    $color_76: `#112469`,
    $color_77: `#E4202C80`,
    $color_78: `#eff3ff`,
    $fontFamily: `"Oswald", sans-serif`,
    $fontFamilyMulish: `"Mulish", sans-serif`,
    $fwRegular: 400,
    $fwMedium: 500,
    $fwSemibold: 600,
    $fwBold: 700
};

theme['$fontFamily'] = theme['$fontFamily'];
theme['$fontFamilyMulish'] = theme['$fontFamilyMulish'];

theme['$fwRegular'] = theme['$fwRegular'];
theme['$fwMedium'] = theme['$fwMedium'];
theme['$fwSemibold'] = theme['$fwSemibold'];
theme['$fwBold'] = theme['$fwBold'];

theme['$loaderColor'] = theme['$color_44'];

// top menu
theme['$topMenuBackgroundColor'] = theme['$color_49'];
theme['$topMenuTopBarColor'] = theme['$color_49'];
theme['$topMenuListItemActiveColor'] = theme['$color_48'];
theme['$topMenuLinkColor'] = theme['$color_39'];
theme['$topMenuActiveLinkColor'] = theme['$color_25'];
theme['$topMenuActiveLinkBgColor'] =  theme['$color_63'];
theme['$topMenuUnloggedBtnColor'] = theme['$color_25'];
theme['$topMenuUnloggedLoginBtnBgColor'] = theme['$color_64'];
theme['$topMenuUnloggedRegisterBtnBgColor'] = theme['$color_65'];
theme['$topMenuLoggedOutBtnColor'] = theme['$color_5'];
theme['$topMenuLoggedOutBtnBgColor'] = theme['$color_52'];
theme['$topMenuLoggedUserLoginColor'] = theme['$color_25'];
theme['$topMenuLoggedDepositBtnBgColor'] = theme['$color_65'];
theme['$topMenuLoggedAccountBtnBgColor'] = theme['$color_64'];
theme['$topMenuSubmenuBarColor'] = theme['$color_62'];
theme['$topMenuSubmenuLinkColor'] = theme['$color_66'];

// categories
theme['$categoriesDropdownItemBgColor'] = theme['$color_67'];
theme['$categoriesDropdownItemColor'] = theme['$color_49'];
theme['$categoriesDropdownItemActiveColor'] = theme['$color_49'];
theme['$categoriesDropdownItemBgColorHover'] = theme['$color_68'];
theme['$categoriesDropdownItemBorderBottom'] = theme['$color_69'];
theme['$categoriesActiveEventsCountBgColor'] = theme['$color_8'];
theme['$categoriesActiveEventsCountColor'] = theme['$color_40'];

theme['$categoriesDropdownItemLvl2ArrowColor'] = theme['$color_40'];
theme['$categoriesDropdownItemLvl2BorderBottom'] = theme['$color_44'];
theme['$categoriesDropdownItemLvl2BgColor'] = theme['$color_68'];
theme['$categoriesDropdownItemLvl2BgColorHover'] = theme['$color_25'];
theme['$categoriesDropdownItemLvl2ActiveBgColor'] = theme['$color_25'];
theme['$categoriesDropdownItemLvl2ActiveTitleColor'] = theme['$color_49'];
theme['$categoriesDropdownItemLvl2ActiveArrowColor'] = theme['$color_49'];
theme['$categoriesDropdownItemLvl3BgColor'] = theme['$color_25'];
theme['$categoriesEventsCount'] = theme['$color_8'];
theme['$categoriesLvl3CheckboxBgColor'] = 'transparent';
theme['$categoriesLvl3CheckboxActiveBgColor'] = 'transparent';
theme['$categoriesLvl3CheckboxBorderColor'] = theme['$color_69'];
theme['$categoriesLvl3CheckboxActiveBorderColor'] = theme['$color_69'];

// popular categories/top leagues
theme['$popularCategoriesHeaderBgColor'] = theme['$color_49'];
theme['$popularCategoriesItemBgColor'] = theme['$color_67'];
theme['$popularCategoriesItemTextColor'] = theme['$color_49'];
theme['$popularCategoriesSportIconColor'] = theme['$color_49'];
theme['$popularCategoriesEventsCountColor'] = theme['$color_62'];

// login form
theme['$loginFormSubmitBtnColor'] = theme['$color_25'];
theme['$loginFormSubmitBtnBgColor'] = theme['$color_65'];

// register form
theme['$registerFormSubmitBtnColor'] = theme['$color_50'];
theme['$registerFormSubmitBtnBgColor'] = theme['$color_25'];

// account
theme['$accountTopHeaderBackgroundColor'] = theme['$color_25'];
theme['$accountTopHeaderBorderColor'] = theme['$color_11'];
theme['$accountTopLeftSideBgColor'] = theme['$color_25'];
theme['$accountTopLeftStatusColor'] = theme['$color_48'];
theme['$accountTopLeftSideHeaderColor'] = theme['$color_49'];
theme['$accountTopRightSideLabelColor'] = theme['$color_44'];
theme['$accountTopRightSideColor'] = theme['$color_50'];
theme['$accountTopRightSideSelectWrapperBackground'] = theme['$color_51'];
theme['$accountMenuBorderColor'] = theme['$color_69'];
theme['$accountMenuBackgroundColor'] = theme['$color_67'];
theme['$accountMenuColor'] = theme['$color_49'];
theme['$accountMenuActiveColor'] = theme['$color_49'];
theme['$accountMenuActiveBackgroundColor'] = theme['$color_25'];
theme['$accountMenuFreebetsCountColor'] = theme['$color_48'];
theme['$accountTopLeftSideBgColor'] = theme['$color_62'];
theme['$accountSidebarBackgroundColor'] = theme['$color_62'];
theme['$accountSidebarBorderColor'] = theme['$color_69'];
theme['$accountResponsibleGameTabBgColor'] = theme['$color_49'];
theme['$accountResponsibleGameTabActiveBgColor'] = theme['$color_64'];
theme['$accountResponsibleGameTabTextColor'] = theme['$color_25'];
theme['$accountResponsibleGameInfoColor'] = theme['$color_49'];
theme['$accountResponsibleGameTableBgColor'] = theme['$color_62'];
theme['$accountResponsibleGameTableColor'] = theme['$color_49'];
theme['$accountResponsibleGameTableHelpBgColor'] = theme['$color_49'];
theme['$accountResponsibleGameTooltipContentColor'] = theme['$color_49'];
theme['$accountResponsibleGameTitleColor'] = theme['$color_49'];
theme['$accountResponsibleGameSelfExclusionBtnBgColor'] = theme['$color_78'];
theme['$accountResponsibleGameSelfExclusionBtnColor'] = theme['$color_63'];
theme['$accountResponsibleGameSelfExclusionBtnOkColor'] = theme['$color_27'];
theme['$accountResponsibleGameSelfExclusionBtnCancelColor'] = theme['$color_25'];
theme['$accountResponsibleGameSelfExclusionBtnCancelBgColor'] = theme['$color_64'];
theme['$accountResponsibleGameSelfExclusionBtnDisabled'] = theme['$color_73'];
theme['$accountResponsibleGameSelfExclusionDialogText'] = theme['$color_27'];
theme['$accountResponsibleGameFormSubmitBtn'] = theme['$color_65'];
theme['$accountResponsibleGameFormClearBtn'] = theme['$color_49'];
theme['$accountResponsibleGameFormLabel'] = theme['$color_76'];


// account wallet
theme['$accountWalletTitleColor'] = theme['$color_49'];
theme['$accountWalletHeaderBackgroundColor'] = theme['$color_49'];
theme['$accountWalletTitleBorderColor'] = theme['$color_69'];
theme['$accountWalletFilterButtonBackgroundColor'] = theme['$color_49'];
theme['$accountWalletFilterButtonActiveBackgroundColor'] = theme['$color_64'];
theme['$accountWalletFilterButtonColor'] = theme['$color_25'];
theme['$accountWalletItemColor'] = theme['$color_49'];
theme['$accountWalletItemBackgroundColor'] = theme['$color_62'];
theme['$accountWalletItemBorderColor'] = theme['$color_69'];
// bet history
theme['$accountBetHistoryTitleColor'] = theme['$color_49'];
theme['$accountBetHistoryTabBackgroundColor'] = theme['$color_62'];
theme['$accountBetHistoryTabColor'] = theme['$color_49'];
theme['$accountBetHistoryTabBackgroundColorActive'] = theme['$color_49'];
theme['$accountBetHistoryTabColorActive'] = theme['$color_62'];
theme['$accountBetHistoryHeaderBackgroundColor'] = theme['$color_49'];
theme['$accountBetHistoryTitleBorderColor'] = theme['$color_69'];
theme['$accountBetHistoryFilterButtonBackgroundColor'] = theme['$color_49'];
theme['$accountBetHistoryFilterButtonActiveBackgroundColor'] = theme['$color_64'];
theme['$accountBetHistoryFilterButtonColor'] = theme['$color_25'];
theme['$accountDateBorderColor'] = theme['$color_49'];
theme['$accountDateIconBackgroundColor'] = theme['$color_49'];
theme['$accountDateInputColor'] = theme['$color_49'];
theme['$accountDateLabelColor'] = theme['$color_49'];
theme['$accountBetHistoryTransactionItemId'] = theme['$color_71'];
theme['$accountProfileBetHistoryTransactionDetailsBackButtonBgColor'] = theme['$color_49'];
theme['$accountBetHistoryTransactionItemColor'] = theme['$color_49'];
theme['$accountBetHistoryTransactionItemBackgroundColor'] = theme['$color_62'];
theme['$accountBetHistoryTransactionItemBorderColor'] = theme['$color_69'];
theme['$accountBetHistoryTransactionCashoutButtonBackgroundColor'] = theme['$color_70'];
theme['$accountBetHistoryTransactionItemHeaderBackgroundColor'] = theme['$color_49'];
theme['$accountProfileBetHistoryTransactionDetailsHeaderBlockBg'] = theme['$color_49'];
theme['$accountProfileBetHistoryTransactionDetailsHeaderBlockTxt'] = theme['$color_25'];
theme['$accountProfileBetHistoryTransactionDetailsBgColor'] = theme['$color_62'];
theme['$accountProfileBetHistoryTransactionDetailsBorderColor'] = theme['$color_69'];
theme['$accountProfileBetHistoryTransactionDetailsIcon'] = theme['$color_49'];
theme['$accountProfileBetHistoryTransactionDetailsDateColor'] = theme['$color_71'];
theme['$accountProfileBetHistoryTransactionDetailsTypeColor'] = theme['$color_49'];
theme['$accountProfileBetHistoryTransactionDetailsOddColor'] = theme['$color_49'];
theme['$accountProfileBetHistoryTransactionDetailsFooterBg'] = theme['$color_63'];
theme['$accountProfileBetHistoryTransactionDetailsFooterInfoBg'] = theme['$color_49'];
theme['$accountProfileBetHistoryTransactionDetailsFooterCashoutButtongBg'] = theme['$color_64'];
theme['$accountProfileBetHistoryCashoutHeaderColor'] = theme['$color_49'];
theme['$accountProfileBetHistoryCashoutHeaderBgColor'] = theme['$color_62'];
theme['$accountProfileBetHistoryCashoutLabelColor'] = theme['$color_49'];
theme['$accountBetHistoryItemDetailsBlockCombinationsCaption'] = theme['$color_72'];
theme['$accountBetHistoryItemDetailsBlockCombinationsHeaderBgColor'] = theme['$color_73'];
theme['$accountBetHistoryItemDetailsBlockCombinationsItemBgColor'] = theme['$color_74'];
theme['$accountBetHistoryItemDetailsBlockCombinationsItemColor'] = theme['$color_72'];
theme['$accountBetHistoryItemDetailsBlockCombinationsItemBorderColor'] = theme['$color_69'];
// profile
theme['$accountProfileStepIndicator'] = theme['$color_61'];
theme['$accountProfileInputLabel'] = theme['$color_44'];
theme['$accountProfileInput'] = theme['$color_44'];
theme['$accountProfileInputBackground'] = theme['$color_57'];
theme['$accountProfileHasErrorsColor'] = theme['$color_48'];
theme['$accountProfileHasErrorsBackground'] = theme['$color_51'];
theme['$accountProfileValidData'] = theme['$color_61'];
theme['$accountProfileAproveedDocs'] = theme['$color_61'];
theme['$accountProfileRejectedDocs'] = theme['$color_48'];
theme['$accountProfileValidUpload'] = theme['$color_61'];
theme['$accountProfileSaveIconBackground'] = theme['$color_57'];
theme['$accountProfileSubmitButtonBackground'] = theme['$color_25'];
theme['$accountProfileSubmitButtonColor'] = theme['$color_50'];
theme['$accountProfileSubmitBorderColor'] = theme['$color_48'];
theme['$accountProfileSubmitDisabledBackground'] = theme['$color_58'];
theme['$accountProfileSubmitDisabledColor'] = theme['$color_27'];
theme['$accountProfileSubmitDisabledColorHover'] = theme['$color_25'];
theme['$accountProfileSubmitDisabledBackgroundHover'] = theme['$color_48']; 
theme['$accountProfileChangePassColor'] = theme['$color_50']; 
theme['$accountProfileChangePassDisabledColorHover'] = theme['$color_25'];
theme['$accountProfileChangePassDisabledBackgroundHover'] = theme['$color_48']; 
theme['$accountProfileModalSuccessTitle'] = theme['$color_42'];
theme['$accountProfileModalSuccessMessageColor'] = theme['$color_27'];
theme['$accountProfileFieldLabel'] = theme['$color_44'];
theme['$accountProfileFieldIsEditableBorder'] = theme['$color_11'];
theme['$accountProfileFieldIsEditableBackground'] = theme['$color_51'];
theme['$accountProfileSelectBackground'] = theme['$color_57'];
theme['$accountProfileHelpButtonColor'] = theme['$color_44'];
theme['$accountProfileHelpIconBackground'] = theme['$color_40'];
theme['$accountProfileHelpIconColor'] = theme['$color_25'];
theme['$accountProfileHelpBeforeBackground'] = theme['$color_57'];
theme['$accountProfileSubmissionErrorBackground'] = theme['$color_59'];
theme['$accountProfileDisableSelectBackground'] = theme['$color_25']; 
theme['$accountProfileDisableSelectSpecialStateBorder'] = theme['$color_27']; 
theme['$accountProfileDisableSelectIndicator'] = theme['$color_60']; 
theme['$accountProfileSelectSingleValueColor'] = theme['$color_44'];
theme['$accountProfileUpdateModalMessage'] = theme['$color_44']; 

//get lucky
theme['$getLuckyBackgroundColor'] = theme['$color_62'];
theme['$getLuckyBorderColor'] = theme['$color_49'];
theme['$getLuckyHeaderBackground'] = theme['$color_49'];
theme['$getLuckyHeaderColor'] = theme['$color_25'];
theme['$getLuckyBodyContentBackground'] = theme['$color_62'];
theme['$getLuckyBodyContentColor'] = theme['$color_49'];
theme['$getLuckyBodyBorderColor'] = theme['$color_69'];
theme['$getLuckyInputBackground'] = theme['$color_25'];
theme['$getLuckyInputBorderColor'] = theme['$color_69'];
theme['$getLuckyInputColor'] = theme['$color_49'];
theme['$getLuckyLabelColor'] = theme['$color_49'];
theme['$getLuckyFakeChboxBackground'] = theme['$color_25'];
theme['$getLuckyInputRangeTrackBackground'] = theme['$color_75'];
theme['$getLuckyInputRangeTrackActiveBackground'] = theme['$color_64'];
theme['$getLuckyInputRangeTrackSlider'] = theme['$color_49'];
theme['$getLuckyInputRangeTrackSliderBorderColor'] = theme['$color_49'];
theme['$getLuckyButtonBackground'] = theme['$color_64'];
theme['$getLuckyButtonColor'] = theme['$color_25'];


theme['$getBestsellersOutcomesButtonColor'] = theme['$color_25'];
theme['$getBestsellersOutcomesBackground'] = theme['$color_49'];

//betslip check
theme['$betSlipCheckBackgroundColor'] = theme['$color_53'];
theme['$betSlipCheckHeaderBackground'] = theme['$color_44'];
theme['$betSlipCheckHeaderColor'] = theme['$color_40'];
theme['$betSlipCheckBodyContentBackground'] = theme['$color_25'];
theme['$betSlipCheckInputBackground'] = theme['$color_51'];
theme['$betSlipCheckInputBorderColor'] = theme['$color_11'];
theme['$betSlipCheckInputColor'] = theme['$color_44'];
theme['$betSlipCheckLabelColor'] = theme['$color_44'];
theme['$betSlipCheckButtonBackground'] = theme['$color_64'];
theme['$betSlipCheckButtonColor'] = theme['$color_25'];
theme['$betSlipCheckButtonTextFont'] = theme['$textRegular'];

//betslip details
theme['$betSlipDetailsDataBackground'] = theme['$color_44'];
theme['$betSlipDetailsDataTitleColor'] = theme['$color_12'];
theme['$betSlipDetailsDataColor'] = theme['$color_25'];

theme['$betSlipDetailsListHeaderBackground'] = theme['$color_11'];
theme['$betSlipDetailsListHeaderColor'] = theme['$color_12'];
theme['$betSlipDetailsListEventDataBackground'] = theme['$color_51'];
theme['$betSlipDetailsListEventDataColor'] = theme['$color_56'];
theme['$betSlipDetailsListEventDataColorDate'] = theme['$color_44'];

theme['$betSlipDetailsListStatusPending'] = theme['$color_49'];
theme['$betSlipDetailsListStatusPlaced'] = theme['$color_49'];
theme['$betSlipDetailsListStatusWon'] = '#54C341';
theme['$betSlipDetailsListStatusLost'] = theme['$color_40'];
theme['$betSlipDetailsListStatusCancel'] = '#CE0006';
theme['$betSlipDetailsListStatusCashout'] = '#54C341';
theme['$betSlipDetailsListStatusDefault'] = theme['$color_49'];

theme['$betSlipTotalBonusBackground'] = '#113286';
theme['$betSlipTotalBonusBorder'] = '#2982EA';

// sport/time/game filters
theme['$sportFilterContainerBackground'] = theme['$color_8'];
theme['$sportFilterBackground'] = theme['$color_44'];
theme['$sportFilterText'] = theme['$color_40'];
theme['$sportFilterHeaderBorder'] = theme['$color_44'];
theme['$sportFilterButtonBackground'] = theme['$color_40'];
theme['$sportFilterButtonColor'] = theme['$color_44'];
theme['$sportFilterDropdownBackground'] = theme['$color_49'];
theme['$sportFilterDropdownText'] = theme['$color_40'];
theme['$sportFilterDropdownBackgroundHover'] = theme['$color_47'];
theme['$sportFilterDropdownTextHover'] = theme['$color_25'];

// betSlipTabs
theme['$betSlipHeaderBackground'] = theme['$color_64'];
theme['$betSlipTabsBackground'] = theme['$color_49'];
theme['$betSlipTabsActiveBackground'] = theme['$color_65'];
theme['$betSlipTabsTabNumberColor'] =  theme['$color_44'];
theme['$betSlipTabsTabColor'] = theme['$color_25'];
theme['$betSlipTabsBorderColor'] = theme['$color_44'];
theme['$betSlipTabsActiveBorderColor'] = theme['$color_48'];

// betSlipTypesTabs
theme['$betSlipTypesTabsBackground'] = theme['$color_49'];
theme['$betSlipTypesTabsTabColor'] = theme['$color_44'];
theme['$betSlipTypesTabsTabActiveColor'] = theme['$color_50'];
theme['$betSlipTypesTabsTabActiveBgColor'] = theme['$color_63'];
theme['$betSlipTypesTabsAnchor'] = theme['$color_25'];
theme['$betSlipTypesTabsClearSlip'] = theme['$color_50'];
theme['$betSlipTypesTabsBorderColor'] = theme['$color_11'];

// bestSlip outcome
theme['$betSlipOutcomeOddsColor'] = theme['$color_25'];
theme['$betSlipOutcomeOddsBgColor'] = theme['$color_63'];
theme['$betSlipOutcomeEventNameColor'] = theme['$color_63'];
theme['$betSlipOutcomeRemoveIconColor'] = theme['$color_40'];
theme['$betSlipOutcomeGameNameColor'] = theme['$color_63'];
theme['$betSlipOutcomeNameColor'] = theme['$color_63'];
theme['$betSlipOutcomeBorderColor'] = theme['$color_69'];
theme['$betSlipOutcomeBgColor'] = theme['$color_62'];

// betslip buttons
theme['$betSlipButtonBorderColor'] = theme['$color_48'];
theme['$betSlipButtonHoverColor'] = theme['$color_48'];
theme['$betSlipPlaceBetButtonBgColor'] = theme['$color_65'];
theme['$betSlipPlaceBetButtonColor'] = theme['$color_25'];

// betslip stake/total stake
theme['$betSlipFormStakeWrapperBackground'] = theme['$color_63'];
theme['$betSlipFormInputBackground'] = theme['$color_51'];
theme['$betSlipFormInputBorderColor'] = theme['$color_11'];
theme['$betSlipFormInputColor'] = theme['$color_49'];
theme['$betSlipFormLabelColor'] = theme['$color_25'];
theme['$betSlipFormGroupBgColor'] = theme['$color_49'];


// betslip success message
theme['$betSlipPlaceBetSuccessBackground'] = theme['$color_48'];
theme['$betSlipPlaceBetSuccessColor'] = theme['$color_25'];

// betslip combination types
theme['$betSlipCombinationTypesSelectBackground'] = theme['$color_40'];
theme['$betSlipCombinationTypesSelectArrowColor'] = theme['$color_50'];
theme['$betSlipCombinationTypesSelectTextColor'] = theme['$color_49'];

// betslip select
theme['$betSlipSelectBackground'] = theme['$color_11'];
theme['$betSlipSelectArrowColor'] = theme['$color_50'];
theme['$betSlipSelectTextColor'] = theme['$color_49'];

// betslip select in block
theme['$betSlipBlockSelectBackground'] = theme['$color_25'];
theme['$betSlipBlockSelectArrowColor'] = theme['$color_50'];
theme['$betSlipBlockSelectTextColor'] = theme['$color_49'];
theme['$betSlipBlockSelectBorderColor'] = theme['$color_11'];

// betslip system block header, block border
theme['$betSlipBlockHeaderTextColor'] = theme['$color_44'];
theme['$betSlipBlockHeaderBackground'] = theme['$color_51'];
theme['$betSlipBlockBodyBorderColor'] = theme['$color_11'];

// betslip button wrapper
theme['$betSlipButtonsWrapperBackground'] = theme['$color_49'];

// betslip checkboxs
theme['$betSlipCheckboxWrapperBackground'] = theme['$color_55'];
theme['$betSlipFakeChboxBackground'] = theme['$color_51'];
theme['$betSlipChboxInputBorderColor'] = theme['$color_11'];
theme['$betSlipChboxLabelColor'] = theme['$color_44'];

// bestlip form group
theme['$betSlipFormGroupColor'] = theme['$color_44'];

// betslip freebet
theme['$betSlipFreebetWrapperBackground'] = theme['$color_63'];

export default theme;