import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import {translation, redirect} from 'helpers/utilsHelper';
import classNames from 'classnames';
import S from 'StyledTopMenuList.js';
import subMenuItems from 'subMenuItems.enum.js';
import topMenuItems from 'topMenuItems.enum.js';

const TopMenuList = ({activeItem, submenuItem, changeActiveItem, changeSubmenuActiveItem,isLogged}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const submenuLinks = _get(subMenuItems, [activeItem], {});
        const firstSubmenuLinksKey = _get(Object.keys(submenuLinks), [0]);
        if (firstSubmenuLinksKey && !(submenuItem in submenuLinks)) {
            changeSubmenuActiveItem(firstSubmenuLinksKey);
        }
    }, []);

    const onMenuLinkClick = (e, href, topItemName) => {
        e.preventDefault();
        redirect(href);
    };

    const onSubmenuLinkClick = (e, url, topItemName, submenuItemName) => {
        e.preventDefault();
        e.stopPropagation();
        redirect(url);
    };


/**
 * Function to handle smartico widget. If the user is logged in, it should return a smartico widget, otherwise it should open the login module.
 */
    const handleSmarticoIframe = () => {
        if(isLogged && app.config.smarticoIsEnabled){
            window._smartico.dp('dp:gf');
        } else {
            dispatch({ type: 'TOGGLE_BY_KEY', payload: { key: 'TOGGLE_OPEN_LOGIN_MODAL', isOpen: true, fromTab: 'missions' } })
        }
    }

    return (
        <S.MenuList>
            {_map(topMenuItems, ({ translationKey, href, openInNewTab, submenu, dataTest = '', isNew, isHidden, isSmarticoButton, ...rest}, topItemName) => {
                const url = typeof href === 'function' ? href() : href;
                const itemClass = classNames({'is-active': (activeItem == topItemName), 'top-menu-item': true});
                const hasSubmenu = (submenu && _size(submenu) > 0);
                if(!isHidden) {
                    if(topItemName === 'MISSIONS' && !app.config.smarticoIsEnabled || topItemName === 'MISSIONS' && app.config.smarticoOnlyInit) {
                        return null
                    } else {
                    return (
                        <S.MenuListItem key={topItemName} className={itemClass}>
    
                        {isNew && (
                            <S.MenuNewSign>{translation('topMenu_new')}</S.MenuNewSign>
                        )}
                      
                            {!openInNewTab
                                ?
                                isSmarticoButton ? 
                                <S.MenuButton onClick={() => handleSmarticoIframe()}>
                                    {translation(translationKey)}
                                </S.MenuButton> 
                                    :
                                <S.MenuLink {...rest}
                                            href={href}
                                            className="top-menu-link"
                                            onClick={(e) => {
                                                onMenuLinkClick(e, url, topItemName);
                                            }}
                                            data-test={dataTest}>
    
                                    {translation(translationKey)}
    
                                    {hasSubmenu &&
                                    (
                                        <S.SubMenuList className="submenu-list">
                                            {_map(submenu, ({ translationKey, href, dataTest = ''}, submenuItemName) => {
                                                const url = typeof href === 'function' ? href() : href;
                                                return (
                                                    <S.SubMenuListItem key={submenuItemName} isActive={submenuItem == submenuItemName} className="submenu-list-item">
    
                                                        <S.SubMenuLink {...rest}
                                                                       className="sub-menu-link"
                                                                       onClick={(e) => {
                                                                           onSubmenuLinkClick(e, url, topItemName, submenuItemName);
                                                                       }}
                                                                       data-test={dataTest}
                                                                       target="_blank" 
                                                                       href={url}>
    
                                                            <span>{translation(translationKey)}</span>
    
                                                        </S.SubMenuLink>
    
                                                    </S.SubMenuListItem>
                                                )
                                            })}
                                        </S.SubMenuList>
                                    )
                                    }
                                </S.MenuLink>
                                :
                                <S.MenuLink {...rest} className="top-menu-link" target="_blank" href={url}>
                                    {translation(translationKey)}
                                </S.MenuLink>
                            }
                        </S.MenuListItem>
                    )
                }}

            })}
        </S.MenuList>
    );
};

export default TopMenuList;

