import React from 'react';
import S from 'StyledRegisterConfirmation.js';
import {translation} from 'helpers/utilsHelper.js';
import Box from 'react-styled-box';
import ReactTooltip from 'react-tooltip';
import {withRouter} from 'react-router-dom';

const RegisterConfirmation = ({toggleOpen, toggleAccountModal, history}) => {

    const onStartGameClick = ()=>{
        toggleOpen();
        toggleAccountModal();
        history.push('/deposit');
    };

    return (
        <S.RegisterConfirmation className="register-step3">

            <S.Header>
                <S.Title>{translation('registerForm_step3')}</S.Title>
            </S.Header>

            <S.Body>
                <Box flexDirection="column">

                    <Box>{translation('account_registerForm_finalDescription')}</Box>

                    <S.StartGameBtn onClick={onStartGameClick}>{translation('account_registerForm_startGame')}</S.StartGameBtn>

                </Box>
            </S.Body>

            <S.Footer>
                <S.HelpBtn className="online-help">

                    <S.HelpIcon data-tip data-for={`help-tooltip`}>&#63;</S.HelpIcon>

                    <ReactTooltip id={`help-tooltip`} type="light">
                        <span>lorem ipsum...</span>
                    </ReactTooltip>

                    {translation('online_help')}

                </S.HelpBtn>
            </S.Footer>

        </S.RegisterConfirmation>
    );
};

export default withRouter(React.memo(RegisterConfirmation));

