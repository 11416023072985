import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$registerFormSubmitBtnBgColor}}) => `${$registerFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$registerFormSubmitBtnColor}}) => `${$registerFormSubmitBtnColor}`;

const baseFont = `12px`;

const S = {};

S.RegisterWizardFormStep1 = styled(Form)` 
    width: ${rem('467px', baseFont)};
    font-family: "Roboto Condensed", sans-serif;
    padding: 0 0 ${rem('20px', baseFont)};
`;

S.Row = styled.div`
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
`;

S.PasswordToggler = styled.div`
    position: absolute;
    right: ${rem('10px', baseFont)};
    top: ${rem('15px', baseFont)};
    font-size: ${rem('20px', baseFont)};
`;

S.PasswordTogglerIcon = styled.i`
    ${({isActive})=>{
    
         if(isActive){
             return `
                &:before{
                    color: grey;
                }
            `;
         }
     
    }}
`;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: 1.4rem;
    left: 1.8rem;
    color: #787d83!important;
    font-size: 1.33rem;
    font-weight: 400;
`;

S.FakeChbox = styled.div`
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    margin-right: .83rem;
    cursor: pointer;
    margin-top: 2px;
    flex-shrink: 0;
    pointer-events: none;
    z-index: 3;
    background-color: #FCFCFC;
    position: relative;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    font-weight: bold;
    color: #787d83;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: #262626;
    font-size:  ${rem('14px', baseFont)};
    padding: 2.1rem 1.75rem;
    background-color: #FCFCFC;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    display: block;
    width: 100%;
    height: ${rem('54px', baseFont)};
    &:valid{
       & + ${S.Label}{
         margin-top: ${rem('-14px', baseFont)};
         font-size:  ${rem('12px', baseFont)};
       }
    }
    &:-webkit-autofill {
                background-color: #FCFCFC;
                -webkit-box-shadow: 0 0 0rem 83.33rem #FCFCFC inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #FCFCFC inset;
                }
     }
     
    &.has-error{
        border: .08rem solid red;
    }
    &.is-valid{
        border: .08rem solid #63dd7d;
    }
`;

S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('44px', baseFont)};
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: ${rem('125px', baseFont)};
    margin-bottom: 2.18rem;
    margin-top: .69rem;
    color: ${SubmitBtnColor};
    cursor: pointer;
    border: 2px solid #CE0006;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: ${rem('14px', baseFont)};
    &:hover{
        color: #fff;
        background-color: #CE0006;
    }
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
    margin-left: ${rem('15px', baseFont)};
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #bebebe;
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 2rem;
    cursor: pointer;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
    
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
            flex: 1;
            font-size: 1.17rem;
            font-weight: 400;
            padding-top: 0;
            color: #787d83;
            display: flex;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
            align-items: flex-end;
            
            & > span,
            & a{
              color: #CE0006;
            }
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 1px;
            height: 14px;
            width: auto;
         }
    }
`;

S.Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${rem('35px', baseFont)};
  height:  ${rem('80px', baseFont)};
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 ${rem('9px', baseFont)} ${rem('87px', baseFont)};
`;

S.Title = styled.h2`
   margin: 0;
   color: #262626;
    font-size: ${rem('24px', baseFont)}
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;

S.Body = styled.div`
 padding: 0 ${rem('66px', baseFont)};
`;

S.HelpBtn = styled.div`
    color: #777D85;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${rem('20px', baseFont)};
`;

S.HelpIcon = styled.div`
    cursor: pointer;
    width: ${rem('19px', baseFont)};
    height: ${rem('19px', baseFont)};
    background-color: #CBD0D6;
    border-radius: 50%;
    display: flex;
    color: #fff;
    margin-right: ${rem('10px', baseFont)};
    justify-content: center;
    align-items: center;
    
    &:before{
     background-color: transparent;
    }
`;

S.Footer = styled.div``;

export default S;
