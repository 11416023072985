import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.AccountCurrentBonus = styled.div`
    width: 100%;
`;

S.BonusListWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: ${rem('20px', baseFont)};
`;

S.BonusImageWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${p => `calc(100% / ${p.ratio})`};
    background: #001A5B;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
`;

S.BonusImage = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
`;

S.BonusList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem('-25px', baseFont)};
`;

S.BonusItem = styled.div`
   width: calc(50% - ${rem('25px', baseFont)});
   margin-right: ${rem('25px', baseFont)};
   margin-bottom: ${rem('25px', baseFont)};
   position: relative;
`;

S.BonusHeader = styled.div`
  padding-bottom: ${rem('15px', baseFont)};
`;

S.BonusTitle = styled.div`
  font-size:  ${rem('13px', baseFont)};
  font-family: ${FontFamily};
  font-weight: 400;
`;

S.BonusBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${rem('122px', baseFont)};
  background-color: #F4F7FC;
  padding-bottom: ${rem('20px', baseFont)};
  border-radius: 0 0 10px 10px;
`;

S.BonusBox =  styled.div`
  width: calc(100% / 3);
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

S.BonusBoxTitle =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('11px', baseFont)};
  color: #001A5B;
  width: 100%;
  padding: 0 ${rem('5px', baseFont)};
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
 text-align: center;
  font-weight: 400;
`;

S.BonusBoxValue =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  color: #001A5B;
    width: 100%;
      padding: 0 ${rem('5px', baseFont)};
       white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
  text-align: center;
  font-weight: 700;
`;

S.BonusFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;

S.BonusFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;

S.BonusFiltersBtns = styled.div`
    display: flex;
    margin-left: 10px;
    & > a{
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FilterBtn = styled(Button)`
    margin-top: 0;
    background-color: #eee;
    color: #bcbcbc;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    padding-top: 15px;
    text-decoration: none;
   
    &.active{
            color: #676767;
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
           
           &:after{
            content: '';
            position: absolute;
            background-color: #676767;
            height: .25rem;
            bottom: 6px;
            left: 1rem;
            right: 1rem;
           } 
    }
`;

S.BonusListHeader= styled.div`
    color: #262626;
    padding: 1rem 1rem 1rem 0;
    border-bottom: .1rem solid #fff;
    font-size: 1.166rem;
    text-transform: uppercase;
    font-weight: bold;
`;

S.Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 0;
`;

S.Thead = styled.thead`
`;

S.Tbody = styled.tbody``;

S.Tr = styled.tr`
    border-top: 0;
    
    &:before,
    &:after{
     display: none !important;
    }
    
    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
        background-color: #f3f3f3;
        color: #000;
        border-right: .08rem solid #fff;
      
        & > td{
            text-align: center;
        }
    }
    
`;

S.Td = styled.td`
    padding: 0 1.25rem;
    line-height: 2rem!important;
    font-size: 1rem;
    background-color: #f3f3f3;
    color: #000;
    border-right: .08rem solid #fff;
    border-top: .08rem solid #fff;
    vertical-align: middle;
    text-align: center;

    & .ion-help-circled {
        color: #CBD0D6;
        font-size: 1.3rem;
    }

    &.bonus-actions {
        padding: 0;
        border: 0;
        margin: 0;
    }

    &.pagination{
        background-color: transparent;
    }
        &.wageredPercent{
        width: 15%;
    }
`;

S.Th = styled.th`
    padding: .4rem 1.25rem;
    font-weight: 400;
    border-right: .2rem solid #fff;
    line-height: 1;
    vertical-align: bottom;
    border-top: 0;
    background-color: #777D85;
    color: #fff;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    text-align: center;

    &:last-child {
        border-right: 0;
    }

    &:first-child {
        border-left: 0;
    }
`;



S.NoResult = styled.div`
    height: 40px;
    text-align: center;
`;

S.Button = styled(Button)`
    display: block;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    height: 100%;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    margin: 0;

    &.reject, &.accept {
        border-radius: 0;
    }

    &.reject{
        background-color: #CBD0D6;
    }
    &.accept{
        background-color: #54C341;
    }
`;

S.ActivateModal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: -30px;

    & button {
        border-radius:3px;
        text-transform: uppercase;
        padding: 8px 25px;
        font-weight: bold;

        &:first-child {
          background-color: #CE0006;
          color: #fff;
        }

        &:last-child {
          background-color: #fff;
          color: #262626;
          border: 2px solid #CE0006;
        }
    }
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
`;

S.ButtonCancel = styled(Button)`
    color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    padding: 6px 12px;
    background: #00a651;
    margin-right: 1rem;
    margin-top: 1rem;
`;
S.ButtonAccept = styled(S.ButtonCancel)``;
S.Title = styled.div`
    font-size: 1.9rem;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
`;
S.Description = styled.div`
    color: #777D85;
`;

S.ProgressCircularWrapper = styled.div`
    width: 50%;
    margin: 0 auto;
    padding: 5px;
`;


S.ModalInfo = styled.div`
    width: 100%;
    margin: 44px 54px;
`;

S.InfoTitle = styled.h4`
    color: #54C341;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    float: none!important;
    text-align: center;
`;

S.InfoDescription = styled.div`
    text-align: center;
    color: #000;
`;

S.WageredPercent = styled.div`
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 44px;
    background-color: #CFE0F3;
    height: 3px;
    border-radius: 2px;
    &:after{
      content: '';
      width: ${({wageredPercent})=>wageredPercent};
      background-color: #E4202C;
      display: block;
      border-radius: 2px;
      height: 100%;
    }
`;

S.BonusFooter = styled.div`
  text-align: center;
  padding-top: ${rem('12px', baseFont)};
`;

S.BonusRegulationLink = styled.a`
  font-size: ${rem('11px', baseFont)};
  color: #BAC1D0;
  font-family: ${FontFamilyMulish};
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
`;


export default S;