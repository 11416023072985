import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';

const BetSlipTabsBackground = ({theme:{$betSlipTabsBackground}}) => `${$betSlipTabsBackground}`;
const BetSlipTabsActiveBackground = ({theme:{$betSlipTabsActiveBackground}}) => `${$betSlipTabsActiveBackground}`;
const BetSlipTabsTabNumberColor = ({theme:{$betSlipTabsTabNumberColor}}) => `${$betSlipTabsTabNumberColor}`;
const BetSlipTabsTabColor = ({theme:{$betSlipTabsTabColor}}) => `${$betSlipTabsTabColor}`;
const BetSlipTabsBorderColor = ({theme:{$betSlipTabsBorderColor}}) => `${$betSlipTabsBorderColor}`;
const BetSlipTabsActiveBorderColor = ({theme:{$betSlipTabsActiveBorderColor}}) => `${$betSlipTabsActiveBorderColor}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

S.BetSlipTabs = styled.div`
    margin-left: auto;
    display: flex;
    height: ${rem('20px', baseFont)};
    font-family: ${FontFamily};
    font-weight: ${FwRegular};
`;

S.BetSlipTabsTab = styled(Button)`
    margin-right: ${rem('5px', baseFont)};
    border-radius: 2px;
    cursor: pointer;
    width: ${rem('20px', baseFont)};
    height: 100%;
    font-size: ${rem('12px', baseFont)};
    background-color: ${BetSlipTabsBackground};
    color: ${BetSlipTabsTabColor};
    line-height: 1;
    padding: 0;
    &:last-of-type{
     margin-right: 0;
    }
    ${({isActive}) => {
         if(isActive == true){
            return css`
               background-color: ${BetSlipTabsActiveBackground};
            `; 
         }else{
             return css`
                background-color: ${BetSlipTabsBackground};
            `;
         }
    }}};
`;

S.BetSlipTabsTabInner = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${rem('3px', baseFont)};
`;

S.OutcomesCount = styled.span`
`;

S.TabNumber = styled.span`
    display: none;
    color: ${BetSlipTabsTabNumberColor};
    font-size: ${rem('10px', baseFont)};
`;

export default S;