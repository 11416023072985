import React, {useRef, useEffect, memo} from 'react';
import S from 'StyledBetSlipAccumulator.js';
import _map from 'lodash/map';
import classNames from 'classnames';
import {translation, decimal} from 'utilsHelper.js';
import RemoveIcon from 'remove_outcome_icon.svg';
import UnavailableIcon from 'remove_unavailable.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import useMediaQuery from 'react-use-media-query-hook';
import usePrevious from 'UsePrevious.js';

const BetSlipAccumulator = (props) => {

    const {outcomes, removeOutcome, hasApprovalButtonsVisibled, onUpdate} = props;
    const accumulatorContainerRef = useRef(null);
    const scrollBarRef = useRef(null);
    const previousOutcomes = usePrevious(outcomes);
    const isSmallDesktop = useMediaQuery('(max-width: 1440px)');

    useEffect(() => {
        // only if adding outcome (or previous is undefined - eg. refresh site with a lot of outcomes)
        if((previousOutcomes && previousOutcomes.length < outcomes.length) || (!previousOutcomes && outcomes.length > 3)) {
            scrollBarRef.current.scrollToBottom();
        }
    }, [outcomes]);

    const onScrollbarUpdate = (props) => {
        const {clientHeight, scrollHeight} = props;
        if (scrollHeight > clientHeight) {
            accumulatorContainerRef.current.classList.add('has-scrollbar');
        } else {
            accumulatorContainerRef.current.classList.remove('has-scrollbar');
        }
        onUpdate(props);
    };

    return (
        <S.BetSlipAccumulator ref={accumulatorContainerRef} isSmallDesktop={isSmallDesktop}>

            <Scrollbars
                renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                ref={scrollBarRef}
                style={{ width: '100%' }}
                autoHeight
                autoHeightMax={(isSmallDesktop?200:250)}
                onUpdate={onScrollbarUpdate}>

                {_map(outcomes, ({eventName, gameName, outcomeName, outcomeLive, outcomeId, outcomeOdds, prevOdds, active = true, unavailable = false, oddsChanged = false}) => {
                    const isLive = outcomeLive && JSON.parse(outcomeLive);
                    const outcomeChangedUp = (prevOdds && (outcomeOdds > prevOdds));
                    const outcomeChangedDown = (prevOdds && (outcomeOdds < prevOdds));
                    const outcomeOddsChangedClass = classNames({
                        'up': outcomeChangedUp,
                        'down': outcomeChangedDown,
                        'outcome': true,
                        'no-active': !active
                    });
                    const outcomeClass = classNames({
                        'unavailable': unavailable,
                        'oddsChanged': oddsChanged,
                        'up': outcomeChangedUp,
                        'down': outcomeChangedDown,
                        'no-active': !active
                    });

                    return (
                        <S.Outcome key={outcomeId} className={outcomeClass}>

                            <S.OutcomeBody>

                                {unavailable && (
                                    <S.UnavailableOutcome>
                                        <S.RemoveUnavailableOutcome onClick={removeOutcome.bind(null, outcomeId)}
                                                                    dangerouslySetInnerHTML={{__html: UnavailableIcon}}/>
                                    </S.UnavailableOutcome>
                                )}

                                <S.OutcomeLeftSide>

                                    <S.EventName title={eventName}>
                                        <span>{eventName}</span>
                                        {isLive && <S.LiveIndicator>{translation('betSlip_live')}</S.LiveIndicator>}
                                    </S.EventName>

                                    <S.OutcomeName>{outcomeName}</S.OutcomeName>

                                    <S.GameName>{gameName}</S.GameName>

                                </S.OutcomeLeftSide>

                                <S.OutcomeRightSide>

                                    <S.RemoveOutcome onClick={removeOutcome.bind(null, outcomeId)}
                                                     disabled={hasApprovalButtonsVisibled}
                                                     dangerouslySetInnerHTML={{__html: RemoveIcon}}>
                                    </S.RemoveOutcome>

                                    {prevOdds && (prevOdds != outcomeOdds) && (
                                        <S.PrevOdds className={outcomeOddsChangedClass}>{decimal(prevOdds)}</S.PrevOdds>
                                    )}

                                    <S.OutcomeOdds>{decimal(outcomeOdds)}</S.OutcomeOdds>

                                </S.OutcomeRightSide>

                            </S.OutcomeBody>

                        </S.Outcome>
                    )
                })}

            </Scrollbars>

        </S.BetSlipAccumulator>
    );
};

export default memo(BetSlipAccumulator);
