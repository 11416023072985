import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Modal from 'Modal.js';

const duration = 300;
const ModalWithOpacityStyles = styled(Modal)`
  &.modal-opacity-enter {
    opacity: 0.01;
  }
  &.modal-opacity-enter-active {
    transition: opacity ${duration}ms;
    opacity: 1;
  }
  &.modal-opacity-exit {
    opacity: 1;
  }
  &.modal-opacity-exit-active {
    opacity: 0.01;
    transition: opacity ${duration}ms ease-in;
  }
`;

const ModalOpacity = ({
  isOpen,
  toggleOpen,
  children,
  withCms = false,
  preventCloseOnOverlay = false,
  showHeader = true,
  showHeaderIcon = true,
  showHeaderTitle = true,
  showCloseIcon = true,
  margin = null,
  positionByMargin = null,
  borderRadius = null,
  padding = null,
  width = null,
  height = null,
  maxHeight = null,
  flexDirectionBody = null,
  justifyContentBody = null,
  alignItemsBody = null,
  minWidthBody = null,
  minHeightBody = null,
  paddingBody = null,
  backgroundBody = null,
  headerPadding = null,
  title = '',
  titleIcon = null,
  titleIconWidth,
  titleIconHeight,
  titleColor = '',
  titleFontSize,
  closeIconImg = null,
  closeIconColor = null,
  backgroundHeader = null,
  backgroundWrapper = null,
  backgroundCurtain = null,
}) => {
  return (
    <>
      <CSSTransition in={isOpen} className="modal-opacity" classNames="modal-opacity" unmountOnExit timeout={duration}>
        <ModalWithOpacityStyles
          {...{
            open: isOpen,
            onClose: toggleOpen,
            withCms,
            preventCloseOnOverlay,
            showHeader,
            showHeaderIcon,
            showHeaderTitle,
            showCloseIcon,
            margin,
            positionByMargin,
            borderRadius,
            padding,
            width,
            height,
            maxHeight,
            flexDirectionBody,
            justifyContentBody,
            alignItemsBody,
            minWidthBody,
            minHeightBody,
            paddingBody,
            backgroundBody, 
            headerPadding,
            title,
            titleIcon,
            titleIconWidth,
            titleIconHeight,
            titleFontSize,
            titleColor,
            closeIconImg,
            closeIconColor,
            backgroundHeader,
            backgroundWrapper,
            backgroundCurtain,
          }}
        >
          {children}
        </ModalWithOpacityStyles>
      </CSSTransition>
    </>
  );
};

export default ModalOpacity;
