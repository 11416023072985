import styled from 'styled-components';
import {rem} from 'polished';
import {Button} from 'global/styles/styles.js';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledPagination from 'StyledPagination.js';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FontFamilyMulish  = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const AccountDateBorderColor = ({theme: {$accountDateBorderColor}}) => `${$accountDateBorderColor}`;
const AccountDateIconBackgroundColor = ({theme: {$accountDateIconBackgroundColor}}) => `${$accountDateIconBackgroundColor}`;
const AccountDateInputColor = ({theme: {$accountDateInputColor}}) => `${$accountDateInputColor}`;
const AccountDateLabelColor = ({theme: {$accountDateLabelColor}}) => `${$accountDateLabelColor}`;

const baseFont = `12px`;
const S = {};

S.AccountFreebet = styled.div`
    width: 100%;
    padding: 3.3rem 2.5rem 0;
    padding-left: 30.7692px;
    padding-right: 30.7692px;
    
      ${StyledPagination.Pagination}{
      margin-bottom: ${rem('20px', baseFont)}; 
    }
`;

S.FreebetImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${p => `calc(100% / ${p.ratio})`};
  background: #001A5B;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

S.FreebetImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
`;

S.AccountFreebetTitle = styled.div`
  font-size: ${rem('20px', baseFont)};
  font-family: ${FontFamily};
  color: #001A5B;
  font-weight: 500;
  margin-bottom: ${rem('20px', baseFont)};
  padding-bottom: ${rem('24px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
`;


S.FreebetFilters = styled.div`
  display: flex;
  margin: 0 0 ${rem('20px', baseFont)}
`;

S.FiltersDates = styled.div`
    display: flex;
    margin-left: auto;
    & > div{
        margin-right: ${rem('10px', baseFont)};
        &:first-of-type{}
        &:last-of-type{
             margin-right: 0;
         }
    }
`;

S.DateWrapper = styled.div`
    width: ${rem('90px', baseFont)};
    height: ${rem('30px', baseFont)};
    display: flex;
    position: relative;
    
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: 100%;
       overflow: hidden;
       border-right: 1px solid ${AccountDateBorderColor};
       border-top: 1px solid ${AccountDateBorderColor};
       border-bottom: 1px solid ${AccountDateBorderColor};
       border-radius: 0 4px 4px 0;
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        border: none;
        font-size: ${rem('11px', baseFont)};
        font-weight: ${FwRegular};
        width: 100%;
        color: ${AccountDateInputColor};
        padding-left: ${rem('9px', baseFont)};
       
    }
`;


S.DateLabel = styled.div`
    display: flex;
    font-weight: ${FwRegular};
    color: ${AccountDateLabelColor};
    width: ${rem('45px', baseFont)};
    justify-content: center;
    align-items: flex-start;
    font-size: ${rem('12px', baseFont)};
    text-align: right;
    text-transform: uppercase;
`;

S.DateIcon = styled.img``;

S.DateIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${AccountDateIconBackgroundColor};
  width: ${rem('25px', baseFont)};
  border-radius: 4px 0  0 4px;
  flex-shrink: 0;
`;

S.FreebetList = styled.div``;
S.FreebetActiveList= styled.div`
   margin-top: 2.5rem;
`;
S.FreebetHistoryList= styled(S.FreebetActiveList)``;
S.FreebetListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;

S.FreebetFakeTable = styled.div``;

S.Th = styled.div`
    color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-size: ${rem('12px', baseFont)};
        font-weight: 400;
        line-height: 1;
        border-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    
    & > span{
        overflow: hidden;
    text-overflow: ellipsis;
    }
    
    &.freebet_id{
        width: 10%;
    }
    &.freebet_ammount{
      width: 15%;
    }
    &.freebet_name{
        width: 55%;
    }
    &.freebet_expirationDate{
       width: 20%;
    }
`;

S.Tr = styled.div`
  display: flex;
 width: 100%;
`;
S.Td = styled(S.Th)`

border: none;
font-size: ${rem('14px', baseFont)}; 
color: rgba(0, 0, 0, 0.87);
font-weight: ${FwRegular};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
display: flex;
justify-content: center;
align-items: center;


    &.freebet-id{
        width: 10%;
    }
    &.freebet-ammount{
      width: 15%;
    }
    &.freebet-name{
        width: 55%;
    }
    &.freebet-expirationDate{
       width: 20%;
    }
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.Thead = styled.div`
     display: flex;
    border-bottom: 2px solid #D32F2F;
    padding-bottom: ${rem('5px', baseFont)};
    margin-bottom: ${rem('14px', baseFont)};
    
    ${S.Tr}{
       & > ${S.Th}{}
    }
`;
S.Tbody = styled.div`
    ${S.Tr}{
    height: ${rem('52px', baseFont)};
       &:nth-child(odd){
    background-color: #fff;
  }
  &:nth-child(even){
  background-color: rgba(255, 255, 255, 0.6);
  }
    
       & > ${S.Td}{}
    }
`;


S.FreebetFiltersBtns = styled.div`
    display: flex;
    height:  ${rem('30px', baseFont)};

    & > button{
        margin-right:  ${rem('10px', baseFont)};

        &:link {
            text-decoration: none;
        }
        &:last-of-type{
            margin-right: 0;
        }
    }
`;

S.FilterBtn = styled(Button)`
    color: #fff;
    font-weight: 400;
    width: ${rem('100px', baseFont)};
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;;
    white-space: nowrap;
    text-decoration: none;
    background-color: ${({isActive})=>((isActive==true)?'#0E62C4':'#001A5B')};
    text-transform: uppercase;
    font-family: ${FontFamily};
      font-size: ${rem('12px', baseFont)};
      display: flex;
      justify-content: center;
      align-items: center;
      
      
   
   &:hover,
    &.active{
  background-color: #0E62C4;
    }
`;

S.FreebetListWrapper = styled.div``;

S.FreebetList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem('-25px', baseFont)};
`;

S.FreebetItem = styled.div`
   width: calc(50% - ${rem('25px', baseFont)});
   margin-right: ${rem('25px', baseFont)};
   margin-bottom: ${rem('25px', baseFont)};
   position: relative;
`;

S.FreebetHeader = styled.div`
  padding-bottom: ${rem('15px', baseFont)};
`;

S.FreebetTitle = styled.div`
  font-size:  ${rem('13px', baseFont)};
  font-family: ${FontFamily};
  text-transform: uppercase;
  font-weight: 400;
`;

S.FreebetBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${rem('122px', baseFont)};
  background-color: #F4F7FC;
  padding-bottom:  0;
`;

S.FreebetBox =  styled.div`
  width: calc(100% / 3);
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

S.FreebetBoxTitle =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('11px', baseFont)};
  color: #001A5B;
  width: 100%;
  padding: 0 ${rem('5px', baseFont)};
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
 text-align: center;
 font-weight: 400;
  
`;

S.FreebetBoxValue =  styled.div`
  font-family: ${FontFamilyMulish};
  font-size:  ${rem('13px', baseFont)};
  color: #001A5B;
    width: 100%;
      padding: 0 ${rem('5px', baseFont)};
       white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
  text-align: center;
  font-weight: 700;
`;


S.FreebetFooter = styled.div`
  text-align: center;
  padding-top: ${rem('12px', baseFont)};
`;

S.FreebetRegulationLink = styled.a`
  font-size: ${rem('11px', baseFont)};
  color: #BAC1D0;
  font-family: ${FontFamilyMulish};
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
`;


export default S;