import styled from 'styled-components';
import {rem} from 'polished';

const FontFamilyMulish = ({theme: {$fontFamilyMulish}}) => `${$fontFamilyMulish}`;

const baseFont = `12px`;
const S = {};

S.AccountWrapper = styled.div`
    height: 100%;
    display: block;
    flex-direction: column;
    flex:1;
    width: 100%;
      &.has-blur{
      filter: blur(4px);
    }
`;

S.AccountSidebar = styled.div`
    flex-basis: ${rem('295px', baseFont)};
    background-color: ${({theme:{$accountSidebarBackgroundColor}})=> $accountSidebarBackgroundColor};
    border-right: 1px solid  ${({theme:{$accountSidebarBorderColor}})=> $accountSidebarBorderColor};
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

S.AccountBody = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
`;
S.AccountTop = styled.div`
    height: 8.15rem;
    flex-basis: 10.83rem;
    background-color: ${({theme:{$accountTopHeaderBackgroundColor}})=> $accountTopHeaderBackgroundColor};
    padding: 0;
    display: flex;
    position: relative;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
    border-bottom: 2px solid ${({theme:{$accountTopHeaderBorderColor}})=> $accountTopHeaderBorderColor};
`;
S.AccountBottom = styled.div`
    flex: 1;
    padding: 0;
    display: flex;
    min-height: 100%;
    height: 100%;
`;
S.AccountTopLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    width: 16.6666666667%;
    flex-basis: 17.75rem;
    border-top-left-radius: .42rem;
    color: #fff;
    
`;

S.AccountStatusInfo = styled.div`
    display: flex;
    padding-left: 1.6rem;
`;

S.AccountTopRightSide = styled.div`
    position: relative;
    min-height: 1px;
    width: 83.3333333333%;
    flex: 1;
    padding: 0 6.67rem 0 .8333rem;
    display: flex;
    align-items: center;
    border-top-right-radius: .42rem;
`;

S.UserMainBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin-right: 3.33rem;
    border-radius: 5px;
    padding: 0 1.0833333333rem;
    width: 25%;
    height: 3.333rem;
`;

S.UserFreebets = styled(S.UserMainBalance)``;

S.UserMainBalanceLabel = styled.div`
    width: 100%;
    font-size: 1rem;
    color: ${({theme:{$accountTopRightSideLabelColor}}) => $accountTopRightSideLabelColor};
`;

S.UserMainBalanceValue = styled.div`
    font-size: 1.2rem;
    color: ${({theme:{$accountTopRightSideColor}}) => $accountTopRightSideColor};
    align-self: baseline;
    line-height: 1;
`;

S.FreebetsCount = styled(S.UserMainBalanceValue)``;

S.UserMainBalanceCurrency = styled.div`
    display: inline-block;
    color: ${({theme:{$accountTopRightSideColor}}) => $accountTopRightSideColor};
    font-size: 1rem;
    text-transform: uppercase;
    align-self: baseline;
    margin-left: 2px;
    line-height: 1;
    font-size: 1.2rem;
`;

S.UserBonusBalanceWrapper = styled.div`
    padding: 0.5rem 1rem;
    width: 34%;
    margin-right: 3.33rem;
    border-radius: 5px;
    background-color: ${({theme:{$accountTopRightSideSelectWrapperBackground}}) => $accountTopRightSideSelectWrapperBackground};
    border: 2px solid ${({theme:{$accountTopHeaderBorderColor}}) => $accountTopHeaderBorderColor};
`;

S.UserBonusBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.0833333333rem;
    height: 3.333rem;
    padding-right: 0;
`;

S.UserBonusBalanceLabel =  styled(S.UserMainBalanceLabel)``;

S.FreebetLabel = styled(S.UserMainBalanceLabel)``;

S.UserName = styled.div`
  font-size: ${rem('15px', baseFont)};
  font-family: ${FontFamilyMulish};
  font-weight: 700;
`;
S.UserInfo = styled.div`
  background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: ${rem('36px', baseFont)} ${rem('29px', baseFont)}; 
    color: ${({theme:{$accountTopLeftSideHeaderColor}})=> $accountTopLeftSideHeaderColor};

`;
S.AccountStatusInfoDetails = styled.div`
    font-size: 1rem;
    color: ${({theme:{$accountTopLeftStatusColor}})=> $accountTopLeftStatusColor};
`;

S.BalanceSelect = styled.div`
    min-width: 100%;
    cursor: pointer;
    align-items: stretch;
    top: -3px;
    left: -10px;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        border: none;
        background: inherit;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 font-size: 1.2rem;
                 font-family: Roboto,sans-serif;
            }
        }
    
    }
`;


S.UserEmail = styled.div`
   font-size: ${rem('13px', baseFont)};
   font-family: ${FontFamilyMulish};
   font-weight: 400;
`;


S.AccountCloseBtn = styled.img`
  position: absolute;
  width: ${rem('35px', baseFont)};
  height: ${rem('35px', baseFont)};
  cursor: pointer;
  right: -18px;
  top: -18px;
  z-index: 1;
`;

export default S;