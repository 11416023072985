import React, {useState, useMemo, useEffect} from 'react';
import S from 'StyledRegisterWizardFormStep2.js';
import {Field, reduxForm, formValues} from 'redux-form';
import {translation} from 'utilsHelper.js';
import {isValueUnique, smsCodeVerify} from 'authActions.js';
import classNames from 'classnames';
import Box from 'react-styled-box';
import Select from 'react-select';
import _find from 'lodash/find';
import _map from 'lodash/map';
import Datepicker from 'Datepicker.js';
import {formatDate, substractYears} from 'datesHelper.js'
import ReactTooltip from 'react-tooltip';
import MaskedInput from 'react-maskedinput';
import Loader from 'Loader.js';
import {connect} from 'react-redux';
import {fetchRegistrationOffers} from 'accountBonusActions.js';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER_STEP2);
    return errors;
};

const asyncValidate = async(values, dispatch, props, field) => {
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];

        let requestAction = null;
        switch (fieldName) {
            case 'verificationCode':
                requestAction = smsCodeVerify;
                break;
            default:
                requestAction = isValueUnique;
                break;
        }
        try {
            const result = await dispatch(requestAction.call(null, fieldName, fieldValue));
            return result;
        } catch (err) {
            throw {[fieldName]: [translation("account_registerForm_" + err)]};
        }
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning, valid, asyncValidating, submitFailed}
}) => {
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" className={inputClass}/>
                <S.Label dangerouslySetInnerHTML={{__html:label}}></S.Label>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const CheckboxWithLabelAndError = ({
    input,
    label,
    required,
    meta: {touched, error, warning, submitFailed}
}) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">

                <S.Input {...input} value={input.value} checked={input.value}
                         onChange={event => input.onChange(event.target.checked)} type="checkbox" required={required}/>
                <S.FakeChbox>
                    {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                </S.FakeChbox>
                <S.Label dangerouslySetInnerHTML={{__html:label}}></S.Label>

                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    );
};

const renderDatepicker = ({
    input,
    label,
    touch,
    meta: {touched, error, warning, valid, submitFailed}
}) => {
    const customInput = <MaskedInput mask="11-11-1111" placeholder="dd-mm-yyyy"/>;

    return (
        <S.Row>
            <S.InputWrapper className="with-datepicker">
                <S.Label>{label}</S.Label>
                <S.Datepicker
                    as={Datepicker}
                    selectsStart
                    selected={input.value}
                    startDate={input.value}
                    endDate={input.value}
                    customInput={customInput}
                    dateFormat="dd-MM-yyyy"
                    filterDate={(date) => {
                        return ((date >= new Date(1900, 0, 1)) && (date < substractYears(new Date(), 18)));
                    }}
                    cb={(value) => {
                        input.onChange(value);
                        touch('dateOfBirth');
                    }}
                />

                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const renderSelect = ({
    input,
    label,
    options,
    meta: {touched, error, warning, valid, submitFailed},
    isMulti,
    placeholder,
    noOptionsMessage,
    hideSelectedOptions = true,
    components
}) => {

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'react-select-container': true
    });



    return (
        <S.Row>
            <S.InputWrapper className="with-select">
                <S.Label>{label}</S.Label>

                <S.Select as={Select}
                          options={options}
                          value={_find(options, {value: input.value})}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          className={inputClass}
                          onChange={(props) => {
                              if(isMulti){
                                  input.onChange(props);
                              }else{
                                  input.onChange(props.value);
                              }
                          }}
                          isMulti={isMulti}
                          placeholder={placeholder}
                          noOptionsMessage={noOptionsMessage}
                          hideSelectedOptions={hideSelectedOptions}
                          components={components}
                />

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

let NationalityToggler = ({nationality, touch}) => {
    return (nationality == 'PL'?
            (
                <Box width="50%" padding="0 0 0 7px">
                    <Field
                        name="personalIdentifier"
                        type="text"
                        component={renderField}
                        label={translation('account_registerForm_personalIdentifier')}
                    />
                </Box>
            )
            :
            (
                <Box width="50%" padding="0 0 0 7px">
                    <Field
                        name="dateOfBirth"
                        type="text"
                        component={renderDatepicker}
                        label={translation('account_registerForm_dateOfBirth')}
                        touch={touch}
                    />
                </Box>
            )
    )
};

NationalityToggler = formValues('nationality')(NationalityToggler);

let RegisterWizardFormStep2 = ({handleSubmit, goToPrevPage, submitting, error, touch, fetchRegistrationOffers}) => {

    const countries = useMemo(() => {
        const countries = app.service.Language.cachedDict.countries;
        const options = _map(countries, (countryName, countryCode) => {
            const option = {};
            option['label'] = countryName;
            option['value'] = countryCode;
            return option;
        });

        options.sort((a, b) => a['label'].localeCompare(b['label']));
        return options;
    }, []);
    const [registrationOffersCodes, setRegistrationOffersCodes] = useState([]);

    useEffect(() => {
        fetchRegistrationOffers()
            .then(data => {
                let options = _map(data, ({offerName, registrationCode}) => ({
                    label: offerName,
                    value: registrationCode,
                }));
                setRegistrationOffersCodes(options);
            });

    }, []);

    return (
        <S.RegisterWizardFormStep2 className="register-step2" onSubmit={handleSubmit} noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <S.Header>
                <S.Title>{translation('account_registerForm_step2')}</S.Title>
            </S.Header>

            <S.Body>

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Box flexDirection="column">
                    <Box>
                        <Box width="50%" padding="0 7px 0 0">
                            <Field
                                name="firstName"
                                type="text"
                                component={renderField}
                                label={translation('account_registerForm_firstName')}
                            />
                        </Box>

                        <Box width="50%" padding="0 0 0 7px">
                            <Field
                                name="lastName"
                                type="text"
                                component={renderField}
                                label={translation('account_registerForm_lastName')}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Box width="50%" padding="0 7px 0 0">
                            <Field
                                name="nationality"
                                component={renderSelect}
                                options={countries}
                                label={translation('account_registerForm_nationality')}
                            />
                        </Box>

                        <NationalityToggler touch={touch}/>

                    </Box>
                    <Box>

                        <Box width="50%" padding="0 7px 0 0">
                            <Field
                                name="registrationOffers"
                                component={renderSelect}
                                options={registrationOffersCodes}
                                label={translation('account_registerForm_offersCodes')}
                                isMulti={true}
                                placeholder={''}
                                noOptionsMessage={() => translation('account_registerForm_offersCodes_notAvailable')}
                                hideSelectedOptions={false}
                                components={{
                                    MultiValueContainer: ({ selectProps, data }) => {
                                        const label = data.label;
                                        const allSelected = selectProps.value;
                                        const index = allSelected.findIndex(selected => selected.label === label);
                                        const isLastSelected = index === allSelected.length - 1;
                                        const labelSuffix = isLastSelected ? "" : ", ";
                                        const val = `${label}${labelSuffix}`;
                                        return val;
                                    }
                                }}
                            />
                        </Box>

                    </Box>

                    <Box>
                        <Field
                            name="bonus"
                            type="text"
                            component={CheckboxWithLabelAndError}
                            label={translation('account_registerForm_bonus')}
                            required={false}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="freebet"
                            component={CheckboxWithLabelAndError}
                            label={translation('account_registerForm_freebet')}
                            required={false}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="giifClause"
                            component={CheckboxWithLabelAndError}
                            label={translation('account_registerForm_giifClause')}
                        />
                    </Box>

                    <S.SubmitBtn type="submit" disabled={submitting}>
                        {translation('account_registerForm_signup')}
                    </S.SubmitBtn>

                </Box>

            </S.Body>

            <S.Footer>
                <Box justifyContent="space-between" alignItems="center">
                    <Box>
                        <S.PrevStepBtn onClick={goToPrevPage}>
                            <S.PrevIcon>&#60;</S.PrevIcon>
                            {translation('account_registerForm_prevStep')}
                        </S.PrevStepBtn>
                    </Box>

                    <Box>
                        <S.HelpBtn className="online-help" onClick={(e)=>{if(LC_API)LC_API.open_chat_window();return false;}}>

                            <S.HelpIcon data-tip data-for={`help-tooltip`}>&#63;</S.HelpIcon>

                            <ReactTooltip id={`help-tooltip`} type="light">
                                <span>lorem ipsum...</span>
                            </ReactTooltip>

                            {translation('account_registerForm_onlineHelp')}

                        </S.HelpBtn>
                    </Box>
                </Box>
            </S.Footer>

        </S.RegisterWizardFormStep2>
    );
};

const mapDispatchToProps = {
    fetchRegistrationOffers,
};


export default  connect(null, mapDispatchToProps)(reduxForm({
    form: 'registerWizardForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['personalIdentifier'/*, 'verificationCode'*/],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(RegisterWizardFormStep2));
