const encode = encodeURIComponent;

export default (requests, axios) => {
    return {
        logInUser: (user) => {
            return requests.post('/customer/session/login', user);
        },
        logoutUser: (sessionCookie) => {
            const config = {};
            if (sessionCookie) {
                config['headers'] = {'X-ODDS-SESSION': sessionCookie};
            }
            return requests.delete('/customer/session/logout', config);
        },
        registerUser: (user) => {
            return requests.post('/customer/account/register', user);
        },
        forgotPassword: (email) => {
            return requests.post('/customer/account/reset-password', email);
        },
        meFromCookie: () => {
            requests.get('/meFromCookie');
        },
        isValueUnique: (fieldName, fieldValue) => {
            if (process.env.USE_VALIDATORS_UNIQUE){
                return requests.get(`/customer/validators/unique/${fieldName}/${fieldValue}`);
            } else {
                return requests.get(`/customer/account/is-value-unique/${fieldName}/${fieldValue}`);
            }
        },
        changePassword: (data) => {
            return requests.post('/customer/account/change-password', data);
        },
        resetPassword: (data) => {
            return requests.post('/customer/account/reset-password-verify', data);
        },
        preregisterUser: (data) => {
            return requests.post('/customer/account/preregister', data);
        },
        verificationCode: (data)=>{
            return requests.post('/customer/account/verification-code', data);
        },
        validateSMSCode: (fieldName, fieldValue, mobileNumber, withAttempts = false) => {
            return requests.get(`/customer/customerInput/validation/preregistrationVerificationCode/${fieldValue}?additionalValue=${mobileNumber}${(withAttempts ? '&withAttempts=true' : '')}`);
        },
        registerUserByMyId: (data) => {
            return requests.post('/customer/identity-resolver/my-id/register', data);
        },
        fetchNewCustomerDataByMyId: (id) => {
            return requests.get(`/customer/identity-resolver/my-id/data/${id}`);
        },
        getBrandData: () => {
            return requests.get(`/configuration/brands`);
        },
        requestToSendSmsCodeToExtendSession: () => {
            return requests.post(`/customer/account/session/send-code`);
        },
        verifySmsCodeToExtendSession: (data) => {
            return requests.post(`/customer/account/session/verify-code`, data);
        }
    }
};