import { createSelector } from 'reselect'

// return selected transaction from transactionsList, based on path parametr
export const getPathTransaction =(transactionList, {transactionId}) => {
    let transaction;

    if(transactionList && transactionList.length === 1) {
        return transactionList[0];
    } else {
        transaction = transactionList.filter((transaction) => {
            return transaction['transactionId'] === parseInt(transactionId);
        });
        return transaction[0];
    }
}