import React, {memo} from 'react';
import _map from 'lodash/map';
import _size from 'lodash/size';
import {translation} from 'utilsHelper.js';
import S from 'StyledBetSlipTabs.js';

const BetSlipTabs = (props) => {
    const {changeActiveTab, activeTab, betSlips, hasApprovalButtonsVisibled, locked} = props;

    return (
        <S.BetSlipTabs className="betslip-tabs">
            {_map(betSlips, ({outcomes}, tabNumber) => {
                const outcomesCount = _size(outcomes);
                return (
                    <S.BetSlipTabsTab key={tabNumber} className="betslip-tabes-tab"
                                      isActive={activeTab==tabNumber}
                                      onClick={changeActiveTab.bind(null, tabNumber)}
                                      disabled={locked || hasApprovalButtonsVisibled}>

                        <S.BetSlipTabsTabInner>
                            <S.TabNumber>{tabNumber}</S.TabNumber>
                            <S.OutcomesCount>{outcomesCount}</S.OutcomesCount>
                        </S.BetSlipTabsTabInner>

                    </S.BetSlipTabsTab>
                )
            })}
        </S.BetSlipTabs>
    );
};

export default memo(BetSlipTabs);