import React, {useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {withRouter, NavLink} from 'react-router-dom';
import S from 'StyledLoggedPanel.js';
import {translation, formatMoney, parseBalance} from 'utilsHelper.js';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _size from 'lodash/size';
import useToggle from 'UseToggle.js';
import AccountModal from 'AccountModal.js';
import Account from 'Account.js';
import ModalOpacity from 'ModalOpacity.js';
import ResponsibleGamingForm from 'ResponsibleGamingForm.js';
import RegisterConfirmation from 'RegisterConfirmation.js';
import { calculateWinning } from 'betSlipActions.js'
import { SBPayments } from 'sb-payments-etoto'
import { bindActionCreators } from 'redux';
import {getCustomerBettingStatus} from 'customerActions.js';
const { PaymentsModal: { Modal, useModal }, useSession, useLang } = SBPayments

const LoggedPanel = ({user, customerRegulations, logOut, balanceId, toggleBalance, toggleUserAccountModal, isAccountOpen, history, calculateWinning, dispatch}) => {
    const { open } = useModal()

    const session = useSession()

    const lang = useLang(app, false)

    const {login, firstName, lastName, userId, currencyCode, balance, creditBonusBalance, status, dateOfBirth} = user;
    const [showAccountModal, toggleAccountModal] = useState(isAccountOpen);
    const [showResponsibleGamingModal, toggleResponsibleGamingModal] = useToggle(false);
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);

    const onBalanceChange = (option) => {
        const balanceId = _get(option,['value'], 0);

        toggleBalance(false, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };

    const selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || balanceId;
        const selectedValue = _find(getBalanceList, {value: Number(currentBalanceId)});
        return selectedValue??null;
    };

    const getBalanceList = useMemo(() => {
        const overllBalance = parseBalance(parseFloat(balance) + parseFloat(creditBonusBalance?.amount || 0));

        const options = [
          {
            value: 0,
            label: `${translation('topMenu_overallBalance')}: ${overllBalance} ${currencyCode}`
          },
          {
            value: 1,
            label: `${translation('topMenu_mainBalance')}: ${parseBalance(balance || 0)} ${currencyCode}`
          },
          {
            value: 2,
            label: `${translation('topMenu_creditBalance')}: ${parseBalance(creditBonusBalance?.amount || 0)} ${currencyCode}`
          }
        ];

        return options;
    },[creditBonusBalance]);

    const openAccount = () => {
        toggleUserAccountModal(!showAccountModal);
        toggleAccountModal();
        history.push('/edit-profile');
    };

    const Option = (props) => {
        const {data, innerRef, innerProps} = props;
        const {label} = data;
        const bonusPart = label.split(':').map((part)=>part.trim());
        const bonusName = bonusPart[0];
        const bonusAmount = bonusPart[1];
        return (
            <S.Option ref={innerRef} {...innerProps}>
                <S.OptionLabel>{bonusName}</S.OptionLabel>
                <S.OptionAmount>{bonusAmount}</S.OptionAmount>
            </S.Option>
        )
    };

    const SingleValue = (props) => {
        const {data, innerRef, innerProps} = props;
        const {label} = data;
        const bonusPart = label.split(':').map((part)=>part.trim());
        const bonusName = bonusPart[0];
        const bonusAmount = bonusPart[1];
        return (
            <S.SingleValue ref={innerRef} {...innerProps}>
                <S.SingleValueAmount>{bonusAmount}</S.SingleValueAmount>
                <S.SingleValueLabel>{bonusName}</S.SingleValueLabel>
            </S.SingleValue>
        )
    };

    const getBtag = (name = 'btag') => {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=')

            return parts[0] === name ? decodeURIComponent(parts[1]) : r
        }, '')
    };

    const bettingAllowed = useMemo(() => {
        const bettingAllowed = _get(customerRegulations, ['bettingAllowed']);
        const bettingDisabledReasons = _get(customerRegulations, ['bettingDisabledReasons']);
        const regulationsNotAccepted = !bettingAllowed && bettingDisabledReasons && (bettingDisabledReasons.indexOf('REGULATIONS_NOT_ACCEPTED') != -1);
        return !regulationsNotAccepted;
    }, [customerRegulations])

    useEffect(() => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || balanceId;

        toggleBalance(false, currentBalanceId);

        // Logout user - need calculateWinning call
        return () => {
           toggleBalance(false, 0);
           calculateWinning();
        }
    }, []);

    useEffect(() => {
        app.service.BetSlip.betSlip.placeFromBonusBalance = Boolean(balanceId);
        calculateWinning();
    }, [balanceId]);

    useEffect(() => {
        if (showAccountModal && !isAccountOpen) {
            toggleAccountModal();
        } else if (!showAccountModal && isAccountOpen) {
            toggleAccountModal();
        }

    }, [isAccountOpen]);

    useEffect(() => {

        const checkUser = () => {
            if (!customerRegulations) {
                dispatch(getCustomerBettingStatus());
                return;
            }

            if(status=='INACTIVE'){
                if(dateOfBirth){
                    if(!bettingAllowed){
                        toggleResponsibleGamingModal();
                    }else{
                        openAccount();
                    }
                }else{
                    const btag = getBtag();
                    const registrationUrl = process.env.REGISTRATION_URL;
                    window.location.href = `${registrationUrl}/${btag ? `?btag=${btag}` : ''}`
                }
            }else{
                if(!bettingAllowed){
                    toggleResponsibleGamingModal();
                }
            }
        };
        checkUser();

    }, [customerRegulations]);

    return (
        <S.LoggedPanel className="logged-panel-box">

            <ModalOpacity isOpen={showResponsibleGamingModal}  title="Odpowiedzialana gra" showHeader={true} showCloseIcon={false} padding={0}>
                <S.ResponsibleGamingWrapper>
                    <ResponsibleGamingForm toggleOpen={toggleResponsibleGamingModal} toggleConfirmationModal={toggleConfirmationModal}></ResponsibleGamingForm>
                </S.ResponsibleGamingWrapper>
            </ModalOpacity>

            <AccountModal isOpen={showAccountModal} toggleOpen={toggleAccountModal}>
                <Account user={user} toggleBalance={toggleBalance} toggleAccountModal={toggleAccountModal} balanceId={balanceId}/>
            </AccountModal>

            {_size(getBalanceList) && (
                <S.BalanceSelect as={Select}
                                 options={getBalanceList}
                                 components={{ Option, SingleValue }}
                                 value={selectedOption()}
                                 isSearchable={false}
                                 classNamePrefix="react-select"
                                 className="react-select-container"
                                 placeholder="wybierz saldo"
                />
            )}

            <S.DepositButton onClick={()=>app.router.redirect('/wplata')}>
                {translation('topMenu_deposit')}
            </S.DepositButton>
            <Modal session={session} lang={lang} />

            <S.UserAccountButton>

                <div>{translation('topMenu_myAccount')}</div>

                <S.QuickMenuWrapper>

                    <S.QuickMenu>

                        <S.QuickMenuItem hasUserName>
                            <S.UserName>
                                <S.UserNameFirstName>{firstName}</S.UserNameFirstName>
                                <S.UserNameLastName>{lastName}</S.UserNameLastName>
                            </S.UserName>
                            <S.LogOut onClick={() => logOut(true)}>
                                {translation('common_logout')}
                            </S.LogOut>
                        </S.QuickMenuItem>

                        <S.QuickMenuItem>
                            <S.QuickMenuLink as={NavLink}
                                             activeClassName="active"
                                             to="/edit-profile"
                                             onClick={toggleAccountModal.bind(null, true)}>
                                {translation('topMenu_editProfile')}
                            </S.QuickMenuLink>
                        </S.QuickMenuItem>

                        <S.QuickMenuItem>
                            <S.QuickMenuLink as={NavLink}
                                             activeClassName="active"
                                             to="/bet-history"
                                             onClick={toggleAccountModal.bind(null, true)}>
                                {translation('topMenu_betHistory')}
                            </S.QuickMenuLink>
                        </S.QuickMenuItem>

                        <S.QuickMenuItem>
                            <S.QuickMenuLink as={NavLink}
                                             activeClassName="active"
                                             to="/bonus"
                                             onClick={toggleAccountModal.bind(null, true)}>
                                {translation('topMenu_bonus')}
                            </S.QuickMenuLink>
                        </S.QuickMenuItem>

                        <S.QuickMenuItem>
                            <S.QuickMenuLink as={NavLink}
                                             activeClassName="active"
                                             to="/edit-profile"
                                             onClick={toggleAccountModal.bind(null, true)}>
                                {translation('topMenu_more')}
                            </S.QuickMenuLink>
                        </S.QuickMenuItem>

                    </S.QuickMenu>

                </S.QuickMenuWrapper>

            </S.UserAccountButton>

        </S.LoggedPanel>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        calculateWinning: bindActionCreators(calculateWinning, dispatch),
        dispatch
    }
};
export default withRouter(connect(null, mapDispatchToProps)(React.memo(LoggedPanel)));
