import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$registerFormSubmitBtnBgColor}}) => `${$registerFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$registerFormSubmitBtnColor}}) => `${$registerFormSubmitBtnColor}`;

const baseFont = `12px`;

const S = {};

S.RegisterConfirmation = styled.div` 
    width: ${rem('467px', baseFont)};
    font-family: "Roboto Condensed", sans-serif;
    padding: 0 0 ${rem('20px', baseFont)};
`;

S.StartGameBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('44px', baseFont)};
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: ${rem('125px', baseFont)};
    margin-top: ${rem('35px', baseFont)};
    color: ${SubmitBtnColor};
    cursor: pointer;
    border: 2px solid #CE0006;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: ${rem('14px', baseFont)};
    &:hover{
        color: #fff;
        background-color: #CE0006;
    }
`;


S.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${rem('35px', baseFont)};
  height:  ${rem('80px', baseFont)};
  border-bottom: 1px solid #E8E8E8;
`;

S.Title = styled.h2`
   margin: 0;
   color: #262626;
    font-size: ${rem('24px', baseFont)}
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;

S.Body = styled.div`
     padding: 0 ${rem('66px', baseFont)};
     font-size: ${rem('14px', baseFont)};
     color: #777D85;
`;

S.HelpBtn = styled.div`
    color: #777D85;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${rem('20px', baseFont)};
`;

S.HelpIcon = styled.div`
    cursor: pointer;
    width: ${rem('19px', baseFont)};
    height: ${rem('19px', baseFont)};
    background-color: #CBD0D6;
    border-radius: 50%;
    display: flex;
    color: #fff;
    margin-right: ${rem('10px', baseFont)};
    justify-content: center;
    align-items: center;
    
    &:before{
     background-color: transparent;
    }
`;

S.Footer = styled.div``;

export default S;
