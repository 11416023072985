import styled from 'styled-components';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const baseFont = `12px`;

const S = {};

S.ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FontFamily};
`;

S.ModalWrapper = styled.div`
    z-index: 99;
    margin: 0 auto 0 auto;
    background-color: #fff;
    position: relative;
    border-radius: 5px;
    overflow: visible;
    width: ${({ width }) => (width != null) ? width : 'auto' }};
    padding: ${({padding})=> (padding!=null) ? padding : '40px 93px' }};
`;

S.ModalHeader = styled.div`
  display: flex;
  margin: 0 ;
  height:  ${rem('80px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
  padding: 0 ;
  justify-content: center;
  align-items: center;
  background-color: #F4F7FC;
  border-radius: 5px 5px 0 0;
`;

S.ModalTitle = styled.h2`
   margin: 0;
   color: #001A5B;
    font-size: ${rem('24px', baseFont)}
    padding: 0;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
    font-family: ${FontFamily};
`;


S.UserIconContainer = styled.div`
    width: 3rem;
    height: 3rem;
`;

S.CloseIconContainer = styled.img`
     position: absolute;
    width: 2.9166666666666665rem;
    height: 2.9166666666666665rem;
    cursor: pointer;
    right: -18px;
    top: -18px;
    z-index: 1;
`;

S.ModalBody = styled.div`
  display: flex;
`;

export default S;