import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { motion } from 'framer-motion'
import { NavLink as _NavLink } from 'react-router-dom'

const oddBackground = `white`

const evenBackground = `${rgba('white', 0.6)}`

const ResetList = styled.ul`
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
`

const ResetListItem = styled.li`
  list-style: none;
`

const ResetButton = styled.button`
  background: transparent;
  display: flex;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
`

export const Wrapper = styled.div`
  background-color: #EFF2F3;
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
`

export const Iframe = styled.iframe.attrs(({
  scrolling: 'no'
}))`
  border: 0;
  display: block;
  margin: .5rem auto;
  overflow: hidden;
`

export const Switch = styled.div`
  border-bottom: 2px solid #C62828;
  display: flex;
  flex-basis: 100%;
  justify-content: center;

  :empty {
    display: none;
  }
`

export const Switcher = styled(ResetButton)`
  border-bottom: 2px solid ${({ isDisabled }) => !isDisabled ? 'transparent' : '#C62828'};
  color: ${({ isDisabled }) => !isDisabled ? '#1F2020' : '#C62828'};
  padding: .5rem;
  text-transform: uppercase;

  :empty {
    display: none;
  }
`

export const Nav = styled.nav`
  position: relative;
  z-index: 10;
`

export const NavList = styled(ResetList)`
  left: 0;
  padding: 0 0 0 24px;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
`

export const NavItem = styled(ResetListItem)`
  align-items: center;
  display: flex;
  height: 50px;

  & + & {
    margin-left: 2rem;
  }
`

export const NavLink = styled(_NavLink)`
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  :hover,
  :focus {
    text-decoration: none;
  }

  :active {
    color: white;
  }

  &[aria-current="page"] {
    font-weight: 600;
  }
`

// SPORTS
export const SportsList = styled(ResetList)``

export const SportsItem = styled(ResetListItem)``

export const SportsItemButton = styled(ResetButton)``

// GAMES
export const GamesList = styled(ResetList)`
  background-color: white;
  margin: .5rem;
  padding: 1rem;
`

export const GamesItem = styled(ResetListItem)`
  & + & {
    margin-left: 3.25rem;
  }
`

export const GamesItemButton = styled(ResetButton)`
  color: ${({ isActive }) => isActive ? '#C62828' : '#1F2020'}
`

// GROUPS
export const MatchGroupHeading = styled.div`
  margin-bottom: 2px;
  position: relative;
`

export const Group = styled.div`
  position: relative;
  z-index: 1;

  &:not(:empty) + & {
    margin: 0 0 0 5rem;
  }

  :last-child {

    ${MatchGroupHeading} {
      ::after {
        background-color: #C62828;
        bottom: 0;
        content: '';
        height: 2px;
        position: absolute;
        right: 0;
        width: 100vw;
      }
    }
  }
`

export const GroupHeading = styled.div`
  display: none;
`

export const Toggler = styled(ResetButton)`
  align-items: center;
  background-color: transparent;
  color: #D32F2F;
  height: 3.9rem;
  justify-content: center;
  margin: 0 4px;
  position: relative;
  width: 3rem;
  z-index: 1;
  font-size: 1.08rem;
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: transparent;
  }
`

export const Stats = styled(Toggler)`
  margin-right: 0;
  background-color: #eff2f3;
	padding-top: 10px;
	height: 3.9rem;
`

export const StatsIconWrap = styled.div`
  align-items: center;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  width: 2.25rem;
`

export const StatsIcon = styled.span`
  background-color: #D32F2F;
  display: block;
  height: 50%;
  position: relative;
  width: 3px;

  ::after,
  ::before {
    background-color: #D32F2F;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    width: 3px;
  }

  ::after {
    height: 65%;
    transform: translateX(-5px);
  }

  ::before {
    height: 125%;
    transform: translateX(5px);
  }
`

// EVENTS
export const EventsList = styled(ResetList)`
  position: relative;
  margin: 0 .5rem .5rem;

  ::after {
    background-color: #D32F2F;
    content: '';
    display: ${({ hasOneChild }) => hasOneChild ? 'none' : 'block'};
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 21px;
    z-index: 1;
  }
`

export const EventsDataInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  background-color: #ffffff;
`

export const GameDataInfo = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  background-color: #ffffff;
`

export const EventIcon = styled.svg`
  display: flex;
  align-items: center;
  margin: 0 1.17rem 0 1.33rem;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 1;
`

export const EventsName = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 3.9rem;
  position: relative;
  z-index: 1;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.67rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EventsItem = styled(ResetListItem)`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  position: relative;

  :first-child {
    ${GroupHeading} {
      display: block;
    }

    ::before {
      background-color: #EFF2F3;
      content: '';
      height: 44px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  :only-child {
    ::after,
    ::before {
      display: none;
    }
  }

	:last-child {
		margin-bottom: 5%;
	}

  & + & {
    margin-top: 4px;
  }

  ::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  :nth-child(odd) {
    ::after {
      background-color: ${oddBackground};
    }
  }

  :nth-child(even) {
    ::after {
      background-color: ${evenBackground};
    }
  }
`

export const EventsWrap = styled.div`
  margin: 0 .5rem .5rem;
  overflow: hidden;
`

export const EventsRest = styled(motion.ul)`
  background-color: #EFF2F3;
  flex-basis: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
`

export const EventsRestItem = styled(ResetListItem)`
  margin-top: 0.25rem;
  display: flex;
  padding-right: 3.25rem;

  :first-child {
    padding-top: 1rem;
  }

  :last-child {
	margin-bottom: 5%;
  }
`

export const EventsRestHeading = styled.div`
  background-color: #FCFCFC;
  border-bottom: 0;
  color: #D32F2F;
  padding: 1.2rem 0.75rem 1.3rem 4.1rem;
  min-width: 30%;
  font-size: 1.1rem;
  width: 100%;
  height: 100%;
`

export const EventsRestHeadingNonFull = styled(EventsRestHeading)`
  width: auto;
  height: auto;
`

export const EventsRestSublist = styled(ResetList)`
  flex-wrap: wrap;
  padding: 0 1px;
  margin: 0 -2px;
  background-color: #FFFFFF;
`

export const EventsRestSubitem = styled(ResetListItem)`
  flex-basis: 33.333%;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 1px 2px 1px;
  display: flex;
  background-color: #FFFFFF;
  align-items: center;

  :nth-child(n + 3) {
    flex-grow: 0;
		padding-top: 0.4rem;
  }
`

// OUTCOMES
export const OutcomesList = styled(ResetList)`
	padding: 1px;
`

export const OutcomesSublist = styled(ResetList)`
  height: calc(3.9rem - 2px);

  & + & {
    border-left: 2px solid #EFF2F3;
  }
`

export const OutcomesItem = styled(ResetListItem)`
  padding: 0 2px;

  & + & {
    margin-top: 4px;
  }
`

export const OutcomesSubitem = styled(ResetListItem)`
  padding: 0 1px;
`

export const OutcomesOutcomeNameContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-end;
  font-size: 1.1rem;
  font-weight: 600;
`

export const OutcomesOutcomeContainer = styled.div`
	display: flex;
`

export const OutcomesOutcome = styled(ResetButton).attrs(({ isAvailable }) => ({
  disabled: !isAvailable
}))`
  align-items: center;
  background-color: ${({ isActive }) => isActive ? '#c62828' : '#EFF2F3'};
  border-radius: 2px;
  color: ${({ isActive }) => isActive && '#FFFFFF'};
  display: flex;
  height: calc(calc(3rem + (2 * 0.17rem)) - (2 * 0.17rem));
  justify-content: center;
  opacity: ${({ isAvailable }) => !isAvailable ? '0.5' : '1'};
  position: relative;
  width: calc(calc(6rem + (2 * 0.17rem)) - (2 * 0.17rem));
  font-size: 1.08rem;
  font-weight: 600;

  :disabled {
    color: currentColor;
		background-color: #EFF2F3
  }

  ${({ isLost }) => isLost && css`
    background-color: ${rgba('black', .4)};
    opacity: 1;
  `}

  ${({ isWon }) => isWon && css`
    background-color: ${rgba('green', .3)};
    opacity: 1;
  `}
`

export const OutcomesCaret = styled(motion.div)`
  border-style: solid;
  border-width: .5rem;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: green;
  border-top-color: green;
  height: .5rem;
  position: absolute;
  right: 0;
  width: .5rem;
`

export const OutcomesHigh = styled(OutcomesCaret)`
  top: 0;
`

export const OutcomesLow = styled(OutcomesCaret)`
  border-bottom-color: red;
  border-left-color: transparent;
  border-right-color: red;
  border-top-color: transparent;
  bottom: 0;
`

export const OutcomesOutcomeName = styled.div`
  font-weight: 400;
  margin-right: 1rem;
  white-space: nowrap;
  font-weight: 600;
`

export const OutcomesArgument = styled(OutcomesOutcome).attrs(({ isAvailable }) => ({
  disabled: isAvailable
}))`
  color: #D32F2F;
  min-width: 3.5rem;
  opacity: ${({ isAvailable }) => isAvailable ? '0.5' : '1'};
  padding: 0 .25rem;
`

// EVENT NAMES
export const EventNamesList = styled(ResetList)``

export const EventNamesItem = styled(ResetListItem)`
  align-items: center;
  height: 3.9rem;
  position: relative;

  ::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  :nth-child(odd) {
    ::after {
      background-color: ${oddBackground};
    }
  }

  :nth-child(even) {
    ::after {
      background-color: ${evenBackground};
    }
  }

  & + & {
    margin-top: 2px;
  }
`

export const EventNamesName = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 3.9rem;
  position: relative;
  z-index: 1;
	font-size: 1.1rem;
`

// OUTCOMES VDK VHK
export const OutcomesColumn = styled.div`
  pointer-events: none;
  z-index: 3;

  & + & {
    z-index: 2;
  }

  & + & + & {
    z-index: 1;
  }
`

export const OutcomesCheckboxLabel = styled.label`
  background-color: white;
  border: 2px solid #D9D9D9;
  border-radius: 2px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;

  ::after {
    border: 3px solid white;
    border-top: none;
    border-right: none;
    content: '';
    height: 10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translate(-50%, -50%) translateY(-2px) rotate(-45deg);
    width: 20px;
  }

  ::before {
    background: linear-gradient(45deg, transparent 0%, transparent 45%, #fff 47%, #fff 53%, transparent 55%, transparent 100%),
    linear-gradient(135deg, transparent 0%, transparent 45%, #fff 47%, #fff 53%, transparent 55%, transparent 100%);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const OutcomesCheckboxInput = styled.input.attrs(({ isActive, isDisabled, onChange }) => ({
  checked: isActive,
  disabled: isDisabled,
  onChange: onChange,
  type: 'checkbox'
}))`
  height: 100%;
  margin: 0 !important;
  opacity: 0;
  padding: 0;
  pointer-events: all;
  position: relative;
  width: 100%;
  z-index: 1;

  :disabled {
    pointer-events: none;
    + ${OutcomesCheckboxLabel} {
      background-color: #D9D9D9;
      pointer-events: none;
      ::before {
        opacity: 1;
      }
    }
  }

  :checked {
    pointer-events: all;
    + ${OutcomesCheckboxLabel} {
      background-color: #D32F2F;
      border-color: #D32F2F;
      ::after {
        opacity: 1;
      }
    }
  }
`

export const OutcomesCheckbox = styled.div`
  height: 2rem;
  margin: 0 .5rem;
  padding: 0;
  position: relative;
  width: 2rem;
`

export const OutcomesFieldset = styled.div`
  ${({ 'data-multiselect': isMultiselect }) => `
    ${isMultiselect && css`
      position: relative;
    `}

    ${OutcomesColumn} {
      :not(:last-child) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        ${OutcomesCheckboxInput} {
          :disabled {
            + ${OutcomesCheckboxLabel} {
              opacity: 0;
            }
            opacity: 0;
          }
        }

        ${OutcomesItem} {
          background: none;
        }
      }
    }
  `}
`

export const GroupTi = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & + & {
    margin-top: 2px;
  }

  :nth-child(odd) {
    background-color: ${oddBackground};
  }

  :nth-child(even) {
    background-color: ${evenBackground};
  }
`

export const GroupNameTi = styled.div`
  min-width: 256px;
  font-size: 1.1rem;
`

export const OutcomesListTi = styled(OutcomesList)`
  flex-wrap: wrap;
`

export const OutcomesItemTi = styled(ResetListItem)`
  flex-basis: 25%;
  flex-grow: 1;
  flex-shrink: 0;
	display: flex;
	align-items: center;
`
