import styled from 'styled-components';
import StyledResponsibleGamingForm from 'StyledResponsibleGamingForm.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `12px`;

const S = {};

S.AccountResponsibleGame = styled.div`
  margin-left: 40px;
  width: 90%;
  font-family: ${FontFamily};
  ${StyledResponsibleGamingForm.ResponsibleGamingForm}{
    width: ${rem('600px', baseFont)};
    margin: ${rem('50px', baseFont)} auto 0;
  }
  
  ${StyledResponsibleGamingForm.Body}{
      padding: 0;
  }
  
    ${StyledResponsibleGamingForm.ButtonsWrapper}{
    padding-left: 0;
    padding-right: 0;
    }
    
    ${StyledResponsibleGamingForm.SubmitBtn}{
      width: ${rem('240px', baseFont)}
    }

`;

S.Header = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: ${rem('24px', baseFont)};
  border-bottom: 1px solid #BAC1D0;
  height: ${rem('80px', baseFont)};
`;

S.Title = styled.h2`
  margin: 0;
  padding-bottom: 20px;
  color: ${({theme:{$accountResponsibleGameTitleColor}})=> $accountResponsibleGameTitleColor};
  font-size: ${rem('20px', baseFont)};
  font-weight: ${FwSemibold};
`;

S.AccountResponsibleGameTabs = styled.div``;
S.TabsWrapper = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;
`;
S.Tab = styled.li`
  margin-right: 15px;
  padding: 0 5px;
  width: 150px;
  height: 30px;
  background: ${({theme:{$accountResponsibleGameTabBgColor}})=> $accountResponsibleGameTabBgColor};
  color: ${({theme:{$accountResponsibleGameTabTextColor}})=> $accountResponsibleGameTabTextColor};
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  &.active-tab {
    background: ${({theme:{$accountResponsibleGameTabActiveBgColor}})=> $accountResponsibleGameTabActiveBgColor};
  }
`;

S.CmsContainer = styled.div`
  width: 90%;
`;

export default S;