import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountFreebet.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import {translation, formatMoney} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {
    changeDateByType,
    fetchFreebetsByFilter,
    setCurrentPage,
    fetchAllFreebets,
    changeActiveTab,
    resetToInitialState
} from 'accountFreebetActions.js';
import CalendarIcon from 'calendar_icon.png';

class AccountFreebet extends Component {

    constructor(props) {
        super(props);
        this.dateStartRef = React.createRef();
        this.dateEndRef = React.createRef();
    }

    componentDidMount = async () => {
        const accountBonusSelector = document.querySelector('.account-freebets');
        if (accountBonusSelector) {
            accountBonusSelector.addEventListener('click', this.handleOutsideClick, false);
        }

        try {
            this.fetchAllFreebets({limit:5});
        } catch (error) {
            console.log('fetchFreebetByFilter:' + error);
        }
    };

    componentWillUnmount = () => {
        const accountBonusSelector = document.querySelector('.account-freebets');
        if (accountBonusSelector) {
            accountBonusSelector.removeEventListener('click', this.handleOutsideClick, false);
        }

        const {resetToInitialState} = this.props;
        resetToInitialState();
    };

    handleOutsideClick = (e) => {
        const datePickerPopperSelector = document.querySelector('.react-datepicker-popper');
        if (datePickerPopperSelector && !datePickerPopperSelector.contains(e.target)) {
            this.dateEndRef?.current?.setOpen(false);
            this.dateStartRef?.current?.setOpen(false);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, fetchFreebetsByFilter} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate
        };
        params['status'] = 'HISTORY';
        params['limit'] = 5;

        changeDateByType('startDate', startDate);
        fetchFreebetsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, fetchFreebetsByFilter} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate
        };
        params['status'] = 'HISTORY';
        params['limit'] = 5;

        changeDateByType('endDate', endDate);
        fetchFreebetsByFilter(params);
    };

    fetchAllFreebets = async (params = {}) => {
        const {fetchAllFreebets} = this.props;
        try {
            const result = await fetchAllFreebets(params);
            return result;
        } catch (error) {
            throw error;
        }
    };

    goPage = async (status, currentPage) => {
        const {fetchFreebetsByFilter, startDate, endDate, setCurrentPage} = this.props;
        const limit = 5;
        const offset = currentPage * limit;
        const params = {
            endDate,
            startDate,
            status,
            currentPage: offset,
            limit
        };

        try {
            await fetchFreebetsByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchFreebetsByFilter, startDate, endDate, limit} = this.props;
        const params = {
            endDate,
            startDate
        };

        params['status'] = activeTab.toUpperCase();
        params['limit'] = 5;

        changeActiveTab(activeTab);
        fetchFreebetsByFilter(params);
    };

    render() {

        const {isPending, activeTab, startDate, endDate, freebets, limit, currencyCode} = this.props;
        const activeFreebets = _get(freebets, 'active');
        const historyFreebets = _get(freebets, 'history');
        const activeFreebetsData = _get(activeFreebets, 'data');
        const historyFreebetsData = _get(historyFreebets, 'data');

        return (
            <S.AccountFreebet className="account-freebets">

                <S.AccountFreebetTitle>
                    {translation('account_freebet_title')}
                </S.AccountFreebetTitle>

                <S.FreebetFilters className="freebet-filters">

                    <S.FreebetFiltersBtns>

                        <S.FilterBtn isActive={(activeTab == 'active')}
                                     onClick={this.onChangeFilter.bind(this, 'active')}>
                            {translation('account_bonus_active')}
                        </S.FilterBtn>
                        <S.FilterBtn isActive={(activeTab == 'history')}
                                     onClick={this.onChangeFilter.bind(this, 'history')}>
                            {translation('account_freebets_historyFreebets')}
                        </S.FilterBtn>
                    </S.FreebetFiltersBtns>


                    {(activeTab == 'history') && (<S.FiltersDates className="bet-history-filters-dates">

                        <S.DateLabel dangerouslySetInnerHTML={{__html: translation('account_betHistory_dateRange')}}/>

                        <S.DateWrapper className="date-wrapper">

                            <S.DateIconWrapper>
                                <S.DateIcon src={CalendarIcon}/>
                            </S.DateIconWrapper>

                            <Datepicker
                                disabled={(activeTab == 'active')}
                                ref={this.dateStartRef}
                                selected={startDate}
                                selectsStart
                                dateFormat="dd-MM-yyyy"
                                cb={this.handleChangeStart}
                                popperPlacement="bottom-end"
                            />
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">

                            <S.DateIconWrapper>
                                <S.DateIcon src={CalendarIcon}/>
                            </S.DateIconWrapper>

                            <Datepicker
                                disabled={(activeTab == 'active')}
                                ref={this.dateEndRef}
                                minDate={startDate}
                                selected={endDate}
                                selectsEnd
                                dateFormat="dd-MM-yyyy"
                                cb={this.handleChangeEnd}
                                popperPlacement="bottom-end"
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </S.DateWrapper>

                    </S.FiltersDates>)}

                </S.FreebetFilters>

                <S.FreebetListWrapper>

                    {isPending ?
                        <Loader/>
                        : (
                            <>
                                {activeTab == 'active' && (
                                    <>
                                        {_size(activeFreebetsData) ?
                                            (
                                                <>
                                                <S.FreebetList>
                                                    {_map(activeFreebetsData, (freebet) => {
                                                        let {validTo, nameKey, betSlipId, offerId, freebetStake, offerPlayerInfo, offerName} = freebet;
                                                        offerPlayerInfo = offerPlayerInfo??{};
                                                        const {imageUrl = null, rulesPageUrl= null} = offerPlayerInfo;
                                                        const freebetItem = (

                                                            <S.FreebetItem key={offerId}>

                                                                <S.FreebetHeader>
                                                                    <S.FreebetTitle data-translation={nameKey}>
                                                                        {translation(nameKey)}
                                                                    </S.FreebetTitle>
                                                                </S.FreebetHeader>

                                                                <S.FreebetImageWrapper ratio={2}>
                                                                    {imageUrl && <S.FreebetImage src={imageUrl}/>}
                                                                </S.FreebetImageWrapper>

                                                                <S.FreebetBody>

                                                                    <S.FreebetBox>
                                                                        <S.FreebetBoxTitle>
                                                                            {translation('account_freebets_offerId')}
                                                                        </S.FreebetBoxTitle>
                                                                        <S.FreebetBoxValue>
                                                                            {offerId}
                                                                        </S.FreebetBoxValue>
                                                                    </S.FreebetBox>

                                                                    <S.FreebetBox>
                                                                        <S.FreebetBoxTitle>
                                                                            {translation('account_freebets_freebetAmount')}
                                                                        </S.FreebetBoxTitle>
                                                                        <S.FreebetBoxValue>
                                                                            {formatMoney(freebetStake)}&nbsp;{currencyCode}
                                                                        </S.FreebetBoxValue>
                                                                    </S.FreebetBox>

                                                                    <S.FreebetBox>
                                                                        <S.FreebetBoxTitle>
                                                                            {translation('account_freebets_freebetExpirationDate')}
                                                                        </S.FreebetBoxTitle>
                                                                        <S.FreebetBoxValue>
                                                                            {formatDate(validTo, 'yyyy.MM.dd HH:mm')}
                                                                        </S.FreebetBoxValue>
                                                                    </S.FreebetBox>

                                                                </S.FreebetBody>

                                                                <S.FreebetFooter>
                                                                    <S.FreebetRegulationLink
                                                                        href={(rulesPageUrl ? rulesPageUrl : '/')}
                                                                        target="_blank">
                                                                        {translation('account_bonus_regulationLink')}
                                                                    </S.FreebetRegulationLink>
                                                                </S.FreebetFooter>

                                                            </S.FreebetItem>
                                                        );
                                                        return freebetItem;
                                                    })}
                                                </S.FreebetList>
                                                {_size(activeFreebetsData) > 0 && (
                                                    <Pagination
                                                        currentPage={_get(activeFreebets, 'currentPage')}
                                                        limit={5} data={activeFreebetsData}
                                                        cb={this.goPage.bind(this, 'active')}/>
                                                )}
                                                </>
                                            )
                                            :
                                            <S.NoResult>
                                                {translation('account_freebets_noActiveFreebets')}
                                            </S.NoResult>
                                        }

                                    </>
                                )}

                                {activeTab == 'history' && (
                                    <>
                                        {_size(historyFreebetsData) ?
                                            (
                                                <>

                                                <S.FreebetList>
                                                    {_map(historyFreebetsData, (freebet) => {
                                                        let {validTo, nameKey, betSlipId, freebetStake, offerId, offerPlayerInfo, offerName} = freebet;
                                                        offerPlayerInfo = offerPlayerInfo??{};
                                                        const {imageUrl = null, rulesPageUrl = null} = offerPlayerInfo;
                                                        const freebetItem = (

                                                            <S.FreebetItem key={offerId}>

                                                                <S.FreebetHeader>
                                                                    <S.FreebetTitle data-translation={nameKey}>
                                                                        {translation(nameKey)}
                                                                    </S.FreebetTitle>
                                                                </S.FreebetHeader>

                                                                <S.FreebetImageWrapper ratio={2}>
                                                                    {imageUrl && <S.FreebetImage src={imageUrl}/>}
                                                                </S.FreebetImageWrapper>

                                                                <S.FreebetBody>

                                                                    <S.FreebetBox>
                                                                        <S.FreebetBoxTitle>
                                                                            {translation('account_freebets_offerId')}
                                                                        </S.FreebetBoxTitle>
                                                                        <S.FreebetBoxValue>
                                                                            {offerId}
                                                                        </S.FreebetBoxValue>
                                                                    </S.FreebetBox>

                                                                    <S.FreebetBox>
                                                                        <S.FreebetBoxTitle>
                                                                            {translation('account_freebets_freebetAmount')}
                                                                        </S.FreebetBoxTitle>
                                                                        <S.FreebetBoxValue>
                                                                            {formatMoney(freebetStake)}&nbsp;{currencyCode}
                                                                        </S.FreebetBoxValue>
                                                                    </S.FreebetBox>

                                                                    <S.FreebetBox>
                                                                        <S.FreebetBoxTitle>
                                                                            {translation('account_freebets_freebetExpirationDate')}
                                                                        </S.FreebetBoxTitle>
                                                                        <S.FreebetBoxValue>
                                                                            {formatDate(validTo, 'yyyy.MM.dd HH:mm')}
                                                                        </S.FreebetBoxValue>
                                                                    </S.FreebetBox>

                                                                </S.FreebetBody>

                                                                <S.FreebetFooter>
                                                                    <S.FreebetRegulationLink
                                                                        href={(rulesPageUrl ? rulesPageUrl : '/')}
                                                                        target="_blank">
                                                                        {translation('account_bonus_regulationLink')}
                                                                    </S.FreebetRegulationLink>
                                                                </S.FreebetFooter>

                                                            </S.FreebetItem>
                                                        );
                                                        return freebetItem;
                                                    })}
                                                </S.FreebetList>

                                            {_size(historyFreebetsData) > 0 && (
                                                <Pagination
                                                    currentPage={_get(historyFreebets, 'currentPage')}
                                                    limit={5} data={historyFreebetsData}
                                                    cb={this.goPage.bind(this, 'history')}/>
                                            )}

                                           </>
                                            )
                                            :
                                            <S.NoResult>
                                                {translation('account_freebets_noHistoryFreebets')}
                                            </S.NoResult>
                                        }

                                    </>
                                )}


                            </>
                        )
                    }

                </S.FreebetListWrapper>

            </S.AccountFreebet>
        );
    }
}

const mapStateToProps = ({AccountFreebet: {isPending, error, startDate, endDate, freebets, limit, activeTab}, Auth: {user: {currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        freebets,
        limit,
        currencyCode,
        activeTab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchFreebetsByFilter: bindActionCreators(fetchFreebetsByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchAllFreebets: bindActionCreators(fetchAllFreebets, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        resetToInitialState: bindActionCreators(resetToInitialState, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFreebet);
