import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledResponsibleLimitsTable.js';
import {translation} from 'helpers/utilsHelper.js';
import {formatDateFromUnix, getPolishTimePlurality} from 'datesHelper';
import {loadCustomerLimits} from 'customerActions.js';
import ReactTooltip from 'react-tooltip';
import Loader from 'Loader.js';


const ResponsibleLimitsTable = ({customerLimits, loadCustomerLimits}) => {
    
    useEffect(() => {
        loadCustomerLimits();
    }, []);

    let customerLimitsFiltered;

    if (customerLimits) {
        customerLimitsFiltered = customerLimits.filter(item => item.limitType == 1 ||item.limitType == 3 || item.limitType == 13 || item.limitType == 15);
    }

    const currency = translation('account_responsibleGame_currency');
    const hoursName = (number) =>  translation(`account_responsibleGame_hours_${getPolishTimePlurality(number)}`);
    const minutesName = (number) =>  translation(`account_responsibleGame_minutes_${getPolishTimePlurality(number)}`);
    
    const renderCell = (usage, type) => usage == undefined ? null 
        : type == 1 || type == 3 ? `${usage} ${currency}` 
        : usage < 60 ? `${formatDateFromUnix(usage * 60, 'm')} ${minutesName(usage)}` 
        : `${Math.floor(usage/60)} ${hoursName(Math.floor(usage/60))}`;
    
    return (
        !customerLimits ?

        <Loader/>

        :
        
        <S.LimitsTable>
            <S.FlexTable>
                <S.Header className="first">{translation('account_responsibleGame_headerLimits')}</S.Header>
                {/* <S.Header>{translation('account_responsibleGame_headerUsed')}</S.Header> */}
                <S.Header>{translation('account_responsibleGame_headerUsageLeft')}</S.Header>
                <S.Header>{translation('account_responsibleGame_headerCurrent')}</S.Header>
                <S.Header>{translation('account_responsibleGame_headerPlanned')}</S.Header>
                <S.Header>{translation('account_responsibleGame_headerValidFrom')}</S.Header>
            </S.FlexTable>
            <S.FlexTable>

            {customerLimitsFiltered.map(({ limitType, limitAmount, limitUsage, requestedLimitAmount, requestedLimitValidFrom }, index) => {
                return (
                    <S.FlexRow key={index}>
                        <S.Cell className="first">
                            <span className="text">{translation(`account_responsibleGame_${limitType}`)}</span>
                            <S.HelpIcon data-tip data-for={`help-tooltip-${limitType}`}>&#161;</S.HelpIcon>
                            <ReactTooltip 
                                className="tooltip-content" 
                                id={`help-tooltip-${limitType}`} 
                                type="light" 
                                effect="solid" 
                                opacity={1}
                                border={true}
                                place="bottom">
                                <span>{translation(`account_responsibleGame_tooltip_${limitType}`)}</span>
                            </ReactTooltip>
                        </S.Cell>
                        {/* <S.Cell>{renderLimitUsage(limitUsage, limitType)}</S.Cell> */}
                        <S.Cell>{renderCell(limitAmount-limitUsage, limitType)}</S.Cell>
                        <S.Cell>{renderCell(limitAmount, limitType)}</S.Cell>
                        <S.Cell>{renderCell(requestedLimitAmount, limitType)}</S.Cell>
                        <S.Cell>{requestedLimitValidFrom == undefined ? null : formatDateFromUnix(requestedLimitValidFrom/1000, 'dd/MM/yyyy HH:mm')}</S.Cell>
                    </S.FlexRow>
                );
            })}

            </S.FlexTable>
        </S.LimitsTable>

    );
};

const mapStateToProps = (state, props) => {
    const {Customer:{customerLimits}} = state;

    return {
        customerLimits,
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionToBind = {loadCustomerLimits};
    return {
        ...bindActionCreators(actionToBind, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleLimitsTable);
