import React from 'react'
import { connect } from 'react-redux'
import * as styled from 'StyledTvBet'

const platform = location.hostname.split('.')[0];
const RGS_URL = (platform == "rc" && CLIENT == 'etoto') ? process.env.RGS_URL.replace('rgs', `rgs-rc`) : process.env.RGS_URL;

const TvBet = ({ isLogged }) => {
  const src = `${RGS_URL}?provider=TVBET`

  return (
    <styled.Iframe id="tvbet-iframe" key={isLogged} src={src} allowFullScreen={true} allow={'autoplay'} />
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => ({ isLogged })

export default connect(mapStateToProps)(TvBet)
