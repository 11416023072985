import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation} from 'utilsHelper.js';
import Datepicker from 'Datepicker.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import classNames from 'classnames';
import S from 'StyledAccountBetHistory.js';
import {
    changeDateByType,
    changeDateByTypeOnVirtualTab,
    changeActiveTab,
    changeActiveTabOnVirtualTab,
    setCurrentPage,
    fetchTransactionsByFilter,
    fetchCashoutsMaximumAmount,
    resetBetHistoryList,
    refreshBetHistoryList,
    processCashoutAmount,
    processRebet,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    fetchExternalBetHistory,
    filterExternalBetHistory
} from 'accountBetHistoryActions.js';
import BetHistoryTransactionItem from 'BetHistoryTransactionItem.js';
import VirtualBetHistoryItem from 'VirtualBetHistoryItem.js';
import { toggleAccountModal} from 'accountActions.js';
import CalendarIcon from 'calendar_icon.png';

const tabs = [
    { label: 'accountBetHistory_bettingHistoryTab', value: 1 },
    { label: 'accountBetHistory_virtualsHistoryTab', value: 2 },
]

class AccountBetHistory extends Component {

    constructor(props) {
        super(props);

        this.dateStartRef = React.createRef();
        this.dateEndRef = React.createRef();
        this.virtualDateStartRef = React.createRef();
        this.virtualDateEndRef = React.createRef();
        this.state = {
            isCountDownRequired: false,
            bettingActiveTab: 1
        }
    }

    handleClickOnTab = async(tabId) => {

        const {virtualStartDate, virtualEndDate, virtualLimit, virtualActiveTab, fetchExternalBetHistory} = this.props;
        const virtualBetHistoryParams =  {
            virtualStartDate,
            virtualEndDate,
            virtualLimit,
            virtualActiveTab
        }
        try {
            if(tabId === 2) {
                await fetchExternalBetHistory(virtualBetHistoryParams);
            }
        } catch (error) {
            console.log('fetchExternalBetHistory:' + error);
        }

        this.setState({
            bettingActiveTab: tabId,
        });
    }

    componentWillUnmount = () => {
        const accountBetHistorySelector = document.querySelector('.account-bet-history');
        if(accountBetHistorySelector) {
            accountBetHistorySelector.removeEventListener('click', this.handleOutsideClick, false);
        }
    };

    componentDidMount = async() => {
        const accountBetHistorySelector = document.querySelector('.account-bet-history');
        if(accountBetHistorySelector) {
            accountBetHistorySelector.addEventListener('click', this.handleOutsideClick, false);
        }

        const {fetchTransactionsByFilter, fetchExternalBetHistory, startDate, endDate, limit, activeTab, currentPage, virtualBetHistory, location: {state}} = this.props;
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        if(state && ('fromTransactionDetails' in state)){
            params['currentPage'] = currentPage*limit;
        }

        if(state && ('fromTransactionVirtualDetails' in state)){
            this.setState(() => {
                return {bettingActiveTab: state.bettingActiveTab}
            })
        }

        try {
            await fetchTransactionsByFilter(params);
        } catch (error) {
            console.log('fetchTransactionsByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        changeDateByType('startDate', startDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        changeDateByType('endDate', endDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeVirtualStartDate = (date) => {
        const {changeDateByTypeOnVirtualTab, virtualEndDate, virtualLimit, virtualActiveTab, fetchExternalBetHistory} = this.props;
        const virtualStartDate = new Date(date);
        const params = {
            virtualEndDate,
            virtualStartDate,
            virtualLimit,
            virtualActiveTab
        };

        changeDateByTypeOnVirtualTab('virtualStartDate', virtualStartDate);
        fetchExternalBetHistory(params);
    };

    handleChangeVirtualEndDate = (date) => {
        const {changeDateByTypeOnVirtualTab, virtualStartDate, virtualLimit, virtualActiveTab, fetchExternalBetHistory, virtualBetHistory} = this.props;
        const virtualEndDate = new Date(date);
        const params = {
            virtualEndDate,
            virtualStartDate,
            virtualLimit,
            virtualActiveTab
        };

        changeDateByTypeOnVirtualTab('virtualEndDate', virtualEndDate);
        fetchExternalBetHistory(params);
    };

    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchTransactionsByFilter, startDate, endDate, limit} = this.props;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit
        };

        changeActiveTab(activeTab);
        fetchTransactionsByFilter(params);
    };

    onChangeVirtualTabFilter = (virtualActiveTab) => {
        const {changeActiveTabOnVirtualTab, virtualBetHistory, filterExternalBetHistory} = this.props;

        changeActiveTabOnVirtualTab(virtualActiveTab);
        filterExternalBetHistory(virtualBetHistory, virtualActiveTab);
    };

    prepareCashout = async(slipId) => {
        const {fetchCashoutsMaximumAmount} = this.props;
        try {
            const maxAmount = await fetchCashoutsMaximumAmount(slipId);
            if (maxAmount.countDownRequired != undefined) {
                this.setState({isCountDownRequired: maxAmount.countDownRequired});
            }
            return maxAmount;
        } catch (error) {
            throw error;
        }
    };

    processCashout = async(config, abortControllerSignal) => {
        const curtainDelay = async(delayMS) => {
            const p = new Promise((resolve, reject) => {
                delayMS = Number(delayMS);
                const timerId = setTimeout(resolve, delayMS);
                abortControllerSignal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject({message: translation('account_cashoutForm_aborted')});
                });
            });
            return p;
        };

        const {processCashoutAmount, processLiveCurtainForTxId} = this.props;
        try {
            let slipId = _get(config, ['slipId']);
            slipId = Number(slipId);

            const hasCashoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);
            if (hasCashoutLiveCurtain && this.state.isCountDownRequired) {
                const curtainData = _omit({...config}, ['slipId', 'type']);
                const delayMS = await processLiveCurtainForTxId(slipId, curtainData);
                await curtainDelay(delayMS);
                const result = await processCashoutAmount(config);
                return result;
            }else{
                const result = await processCashoutAmount(config);
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    processRebet = async(eventId) => {
        const {processRebet} = this.props;
        try {
            const result = await processRebet(eventId);
            return result;
        } catch (error) {
            throw error;
        }
    };

    goPage = async(currentPage) => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit,
            currentPage: offset
        };
        try {
            await fetchTransactionsByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    handleOutsideClick = (e) => {

        const datePickerPopperSelector = document.querySelector('.react-datepicker-popper');
        if (datePickerPopperSelector && !datePickerPopperSelector.contains(e.target)) {
            this.dateEndRef?.current?.setOpen(false);
            this.dateStartRef?.current?.setOpen(false);
            this.virtualDateEndRef?.current?.setOpen(false);
            this.virtualDateStartRef?.current?.setOpen(false);
        }
    };

    render() {

        const { isPending, isPendingVirtualHistory, error, activeTab, startDate, endDate, limit, transactionsList, currentPage, cashouts, historyFreebets, currencyCode, resetBetHistoryList, refreshBetHistoryList, processRebet, toggleAccountModal, toggleLiveCurtainForTxId, virtualBetHistory, virtualActiveTab, virtualEndDate, virtualStartDate, virtualTabCurrentPage, virtualLimit, filteredVirtualBetHistory } = this.props;

        const { bettingActiveTab } = this.state;

        return (
            <S.AccountBetHistory className="account-bet-history">

                <S.AccountBetHistoryTitle>{translation('account_betHistory_title')}</S.AccountBetHistoryTitle>

                <S.TabsWrapper>
                    {tabs.map(({ label, value }, index) => {
                        return (
                            <S.Tab key={index} className={`tab ${value === bettingActiveTab ? 'active-tab' : ''}`} onClick={() => {this.handleClickOnTab(value)}}>{translation(label)}</S.Tab>
                        )
                    })}
                </S.TabsWrapper>

                { 
                bettingActiveTab == 1 ?

                    <>
                        <S.BetHistoryFilters className="bet-history-filters">

                            <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">
                                <S.FilterBtn
                                    onClick={this.onChangeFilter.bind(this, 'all')}
                                    className={classNames({active: (activeTab == 'all')})}>{translation('account_betHistory_types_all')}</S.FilterBtn>
                                <S.FilterBtn
                                    onClick={this.onChangeFilter.bind(this, 'undecided')}
                                    className={classNames({active: (activeTab == 'undecided')})}>{translation('account_betHistory_types_undecided')}</S.FilterBtn>
                                <S.FilterBtn
                                    onClick={this.onChangeFilter.bind(this, 'won')}
                                    className={classNames({active: (activeTab == 'won')})}>{translation('account_betHistory_types_won')}</S.FilterBtn>
                                <S.FilterBtn
                                    onClick={this.onChangeFilter.bind(this, 'cashout')}
                                    className={classNames({active: (activeTab == 'cashout')})}>{translation('account_betHistory_types_cashout')}</S.FilterBtn>
                                <S.FilterBtn
                                    onClick={this.onChangeFilter.bind(this, 'lost')}
                                    className={classNames({active: (activeTab == 'lost')})}>{translation('account_betHistory_types_lost')}</S.FilterBtn>

                            </S.BetHistoryFiltersBtns>

                            <S.BetHistoryFiltersDates className="bet-history-filters-dates">

                                <S.DateLabel dangerouslySetInnerHTML={{__html: translation('account_betHistory_dateRange')}}/>

                                <S.DateWrapper className="date-wrapper">

                                    <S.DateIconWrapper>
                                        <S.DateIcon src={CalendarIcon}/>
                                    </S.DateIconWrapper>

                                    <Datepicker
                                        ref={this.dateStartRef}
                                        selected={startDate}
                                        selectsStart
                                        dateFormat="dd-MM-yyyy"
                                        cb={this.handleChangeStart}
                                        popperPlacement="bottom-end"
                                    />
                                </S.DateWrapper>

                                <S.DateWrapper className="date-wrapper">

                                    <S.DateIconWrapper>
                                        <S.DateIcon src={CalendarIcon}/>
                                    </S.DateIconWrapper>

                                    <Datepicker
                                        ref={this.dateEndRef}
                                        minDate={startDate}
                                        selected={endDate}
                                        selectsEnd
                                        dateFormat="dd-MM-yyyy"
                                        cb={this.handleChangeEnd}
                                        popperPlacement="bottom-end"
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </S.DateWrapper>

                            </S.BetHistoryFiltersDates>

                        </S.BetHistoryFilters>

                        <S.BetHistoryTransactions className="bet-history-transaction-list">
                        {isPending ?
                            <Loader/>
                            :
                            (
                                <>
                                <S.BetHistoryTransactionsList className="transaction-list">

                                    <S.FakeTableHead>

                                        <div className="transaction-status">{translation('account_betHistory_status')}</div>

                                        <div className="transaction-typ">{translation('account_betHistory_type')}</div>

                                        <div className={`transaction-stake${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                                            {translation('account_betHistory_stake')}
                                        </div>

                                        <div className="transaction-odd">{translation('account_betHistory_odd')}</div>

                                        <div className="transaction-result">
                                            {translation('account_betHistory_result')}
                                        </div>

                                        {process.env.CASHOUT && (
                                            <div className="transaction-cashout">
                                                {translation('account_betHistory_cashout')}
                                            </div>)
                                        }

                                        <div className={`transaction-date${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                                            {translation('account_betHistory_date')}
                                        </div>

                                    </S.FakeTableHead>

                                    {_map(transactionsList, (transaction, index) => {
                                        const transactionItemProps = {
                                            index,
                                            currencyCode,
                                            transaction,
                                            transactionsList,
                                            cashouts,
                                            historyFreebets,
                                            refreshBetHistoryList,
                                            toggleLiveCurtainForTxId
                                        };
                                        return (
                                            <BetHistoryTransactionItem key={transaction.transactionId}
                                                                    className="transaction-list-item" {...transactionItemProps}
                                                                    prepareCashout={this.prepareCashout}
                                                                    processCashout={this.processCashout}
                                                                    processRebet={this.processRebet}
                                                                    toggleAccountModal={toggleAccountModal}
                                                    >
                                            </BetHistoryTransactionItem>
                                        )
                                    })}

                                    {!_size(transactionsList) && (
                                        <S.NoResult className="no-result">{translation('account_betHistory_transfersNotFound')}</S.NoResult>)}

                                </S.BetHistoryTransactionsList>

                                {(_size(transactionsList) > 0 || (_size(transactionsList) == 0 && currentPage != 0)) && (
                                    <Pagination currentPage={currentPage} limit={limit} data={transactionsList}
                                                cb={this.goPage}/>)}

                                </>
                            )
                        }
                        </S.BetHistoryTransactions>
                    </>

                    :

                bettingActiveTab == 2 ?

                <>
                    <S.BetHistoryFilters className="bet-history-filters">

                        <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">
                            <S.FilterBtn
                                onClick={this.onChangeVirtualTabFilter.bind(this, 'all')}
                                className={classNames({active: (virtualActiveTab == 'all')})}>{translation('account_betHistory_types_all')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeVirtualTabFilter.bind(this, 'undecided')}
                                className={classNames({active: (virtualActiveTab == 'undecided')})}>{translation('account_betHistory_types_undecided')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeVirtualTabFilter.bind(this, 'won')}
                                className={classNames({active: (virtualActiveTab == 'won')})}>{translation('account_betHistory_types_won')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeVirtualTabFilter.bind(this, 'lost')}
                                className={classNames({active: (virtualActiveTab == 'lost')})}>{translation('account_betHistory_types_lost')}</S.FilterBtn>

                        </S.BetHistoryFiltersBtns>

                        <S.BetHistoryFiltersDates className="bet-history-filters-dates">

                            <S.DateLabel dangerouslySetInnerHTML={{__html: translation('account_betHistory_dateRange')}}/>

                            <S.DateWrapper className="date-wrapper">

                                <S.DateIconWrapper>
                                    <S.DateIcon src={CalendarIcon}/>
                                </S.DateIconWrapper>

                                <Datepicker
                                    ref={this.virtualDateStartRef}
                                    selected={virtualStartDate}
                                    selectsStart
                                    dateFormat="dd-MM-yyyy"
                                    cb={this.handleChangeVirtualStartDate}
                                    popperPlacement="bottom-end"
                                />
                            </S.DateWrapper>

                            <S.DateWrapper className="date-wrapper">

                                <S.DateIconWrapper>
                                    <S.DateIcon src={CalendarIcon}/>
                                </S.DateIconWrapper>

                                <Datepicker
                                    ref={this.virtualDateEndRef}
                                    minDate={virtualStartDate}
                                    selected={virtualEndDate}
                                    selectsEnd
                                    dateFormat="dd-MM-yyyy"
                                    cb={this.handleChangeVirtualEndDate}
                                    popperPlacement="bottom-end"
                                    virtualStartDate={virtualStartDate}
                                    virtualEndDate={virtualEndDate}
                                />
                            </S.DateWrapper>

                        </S.BetHistoryFiltersDates>

                    </S.BetHistoryFilters>

                    <S.BetHistoryTransactionsList className="transaction-list">

                    { isPendingVirtualHistory ? 

                        <Loader/>
                        
                        :

                        (
                            <>
                                <S.FakeTableHead>
                                    <div className="transaction-status">{translation('account_betHistory_status')}</div>

                                    <div className="transaction-typ">{translation('account_betHistory_type')}</div>

                                    <div className={`transaction-stake`}>
                                        {translation('account_betHistory_stake')}
                                    </div>

                                    <div className="transaction-odd">{translation('account_betHistory_odd')}</div>

                                    <div className="transaction-result">
                                        {translation('account_betHistory_result')}
                                    </div>

                                    <div className={`transaction-date`}>
                                        {translation('account_betHistory_date')}
                                    </div>
                                </S.FakeTableHead>

                                {_map(filteredVirtualBetHistory, (item, index) => {
                                const transactionItemProps = {
                                    index,
                                    item
                                };
                                return (
                                    <VirtualBetHistoryItem key={item.transactionId}
                                        className="transaction-list-item" {...transactionItemProps}
                                        toggleAccountModal={toggleAccountModal}
                                        >
                                    </VirtualBetHistoryItem>
                                )
                                })} 
                            </>
                        )

                    }

                    {!isPendingVirtualHistory && !_size(filteredVirtualBetHistory) && (
                        <S.NoResult className="no-result">{translation('account_betHistory_transfersNotFound')}</S.NoResult>
                    )}

                    </S.BetHistoryTransactionsList>

                </>

                    : null
                
                }

            </S.AccountBetHistory>
        );
    }
}

const mapStateToProps = ({AccountBetHistory:{isPending, isPendingVirtualHistory, error, startDate, endDate, limit, transactionsList, currentPage, activeTab, virtualActiveTab, cashouts, virtualBetHistory, virtualStartDate, virtualEndDate, virtualLimit, virtualTabCurrentPage, filteredVirtualBetHistory }, AccountFreebet:{freebets:{history:{data:historyFreebets}}}, Auth:{user:{currencyCode}}}, props) => {

    return {
        error,
        isPending,
        isPendingVirtualHistory,
        startDate,
        endDate,
        limit,
        currencyCode,
        activeTab,
        transactionsList,
        cashouts,
        historyFreebets,
        currentPage,
        virtualBetHistory,
        virtualActiveTab,
        virtualStartDate,
        virtualEndDate,
        virtualLimit,
        virtualTabCurrentPage,
        filteredVirtualBetHistory
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeActiveTabOnVirtualTab: bindActionCreators(changeActiveTabOnVirtualTab, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        changeDateByTypeOnVirtualTab: bindActionCreators(changeDateByTypeOnVirtualTab, dispatch),
        fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
        fetchCashoutsMaximumAmount: bindActionCreators(fetchCashoutsMaximumAmount, dispatch),
        resetBetHistoryList: bindActionCreators(resetBetHistoryList, dispatch),
        refreshBetHistoryList: bindActionCreators(refreshBetHistoryList, dispatch),
        processCashoutAmount: bindActionCreators(processCashoutAmount, dispatch),
        processRebet: bindActionCreators(processRebet, dispatch),
        toggleAccountModal: bindActionCreators(toggleAccountModal, dispatch),
        processLiveCurtainForTxId: bindActionCreators(processLiveCurtainForTxId, dispatch),
        fetchExternalBetHistory: bindActionCreators(fetchExternalBetHistory, dispatch),
        toggleLiveCurtainForTxId: bindActionCreators(toggleLiveCurtainForTxId, dispatch),
        filterExternalBetHistory: bindActionCreators(filterExternalBetHistory, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBetHistory);
