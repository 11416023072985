import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountCashback.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import classNames from 'classnames';
import {translation, formatMoney} from 'utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import {changeDateByType, changeActiveTab, fetchCashbackByFilter, setCurrentPage} from 'accountCashbackActions.js';
import ReactTooltip from 'react-tooltip';
import CalendarIcon from 'calendar_icon.png';
import _get from "lodash/get";

class AccountCashback extends Component {

    constructor(props) {
        super(props);
        this.dateStartRef = React.createRef();
        this.dateEndRef = React.createRef();
    };

    componentDidMount = async() => {

        const accountBonusSelector = document.querySelector('.account-cashbacks');
        if (accountBonusSelector) {
            accountBonusSelector.addEventListener('click', this.handleOutsideClick, false);
        }

        const {fetchCashbackByFilter} = this.props;
        try {
            const data = await fetchCashbackByFilter({limit:5});
        } catch (error) {
            console.log('fetchCashbackByFilter:' + error);
        }
    };

    componentWillUnmount = () => {
        const accountBonusSelector = document.querySelector('.account-cashbacks');
        if (accountBonusSelector) {
            accountBonusSelector.removeEventListener('click', this.handleOutsideClick, false);
        }
    };

    handleOutsideClick = (e) => {
        const datePickerPopperSelector = document.querySelector('.react-datepicker-popper');
        if (datePickerPopperSelector && !datePickerPopperSelector.contains(e.target)) {
            this.dateEndRef?.current?.setOpen(false);
            this.dateStartRef?.current?.setOpen(false);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, fetchCashbackByFilter, endDate, status, currentPage, limit} = this.props;
        const startDate = new Date(date);
        const params = {
            status,
            endDate,
            startDate,
            currentPage
        };

        params['limit'] = 5;

        changeDateByType('startDate', startDate);
        fetchCashbackByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, fetchCashbackByFilter, startDate, status, currentPage, limit} = this.props;
        const endDate = new Date(date);
        const params = {
            status,
            endDate,
            startDate,
            currentPage
        };

        params['limit'] = 5;

        changeDateByType('endDate', endDate);
        fetchCashbackByFilter(params);
    };

    onChangeFilter = (status) => {
        const {changeActiveTab, fetchCashbackByFilter, startDate, endDate, currentPage, limit} = this.props;
        const params = {
            status,
            endDate,
            startDate,
            currentPage
        };

        params['limit'] = 5;

        changeActiveTab(status);
        fetchCashbackByFilter(params);
    };

    goPage = async(currentPage) => {
        const {fetchCashbackByFilter, startDate, endDate, limit, status, setCurrentPage} = this.props;
        const params = {
            status,
            endDate,
            startDate,
            currentPage
        };

        params['limit'] = 5;

        try {
            await fetchCashbackByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:'+error);
        }
    };

    render() {
        const {isPending, error, startDate, endDate, status, data, currentPage, limit, currencyCode} = this.props;

        return (
            <S.AccountCashback className="account-cashbacks">

                <S.AccountCashbackTitle>
                    {translation('account_cashback_title')}
                </S.AccountCashbackTitle>

                <S.CashbackFilters>

                    <S.CashbackFiltersBtns>

                        <S.FilterBtn isActive={(status == 'paid')}
                                     onClick={this.onChangeFilter.bind(this, 'paid')}>
                            {translation('account_cashback_paid')}
                        </S.FilterBtn>
                        <S.FilterBtn isActive={(status == 'awaiting')}
                                     onClick={this.onChangeFilter.bind(this, 'awaiting')}>
                            {translation('account_cashback_awaiting')}
                        </S.FilterBtn>
                    </S.CashbackFiltersBtns>

                    <S.FiltersDates className="bet-history-filters-dates">

                        <S.DateLabel dangerouslySetInnerHTML={{__html: translation('account_betHistory_dateRange')}}/>

                        <S.DateWrapper className="date-wrapper">

                            <S.DateIconWrapper>
                                <S.DateIcon src={CalendarIcon}/>
                            </S.DateIconWrapper>

                            <Datepicker
                                disabled={(status == 'active')}
                                ref={this.dateStartRef}
                                selected={startDate}
                                selectsStart
                                dateFormat="dd-MM-yyyy"
                                cb={this.handleChangeStart}
                                popperPlacement="bottom"
                            />
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">

                            <S.DateIconWrapper>
                                <S.DateIcon src={CalendarIcon}/>
                            </S.DateIconWrapper>

                            <Datepicker
                                disabled={(status == 'active')}
                                ref={this.dateEndRef}
                                minDate={startDate}
                                selected={endDate}
                                selectsEnd
                                dateFormat="dd-MM-yyyy"
                                cb={this.handleChangeEnd}
                                popperPlacement="bottom"
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </S.DateWrapper>

                    </S.FiltersDates>

                </S.CashbackFilters>

                <S.CashbackListWrapper>
                    {isPending ?
                        <Loader/>
                        : (
                            <>
                                {_size(data[status]) ?
                                    (
                                        <>
                                            <S.CashbackList>

                                                {_map(data[status], (cashback) => {
                                                    const {betSlipId, createTime, offerId, descriptionKey, paidValue = 0, nameKey, offerName} = cashback;

                                                    const cashbackItem = (
                                                        <S.CashbackItem key={offerId}>

                                                            <S.CashbackHeader>
                                                                <S.CashbackTitle>
                                                                    {translation(offerName)}
                                                                </S.CashbackTitle>
                                                            </S.CashbackHeader>

                                                            <S.CashbackBody>

                                                                <S.CashbackBox>
                                                                    <S.CashbackBoxTitle>
                                                                        {translation('account_cashback_betSlipId')}
                                                                    </S.CashbackBoxTitle>
                                                                    <S.CashbackBoxValue>
                                                                        {betSlipId}
                                                                    </S.CashbackBoxValue>
                                                                </S.CashbackBox>

                                                                <S.CashbackBox>
                                                                    <S.CashbackBoxTitle>
                                                                        {translation('account_cashback_createTime')}
                                                                    </S.CashbackBoxTitle>
                                                                    <S.CashbackBoxValue>
                                                                        {formatDate(createTime, 'yyyy-MM-dd HH:mm')}
                                                                    </S.CashbackBoxValue>
                                                                </S.CashbackBox>

                                                                <S.CashbackBox>
                                                                    <S.CashbackBoxTitle>
                                                                        {translation('account_cashback_offerName')}
                                                                    </S.CashbackBoxTitle>
                                                                    <S.CashbackBoxValue>
                                                                        {translation(nameKey)}
                                                                    </S.CashbackBoxValue>
                                                                </S.CashbackBox>

                                                                <S.CashbackBox>
                                                                    <S.CashbackBoxTitle>
                                                                        {translation('account_cashback_paidValue')}
                                                                    </S.CashbackBoxTitle>
                                                                    <S.CashbackBoxValue>
                                                                        {formatMoney(paidValue)} {currencyCode}
                                                                    </S.CashbackBoxValue>
                                                                </S.CashbackBox>

                                                            </S.CashbackBody>

                                                        </S.CashbackItem>
                                                    );

                                                    return cashbackItem;
                                                })}

                                            </S.CashbackList>


                                            {_size(data[status]) > 0 && (
                                                <Pagination
                                                    currentPage={currentPage}
                                                    limit={5}
                                                    data={data[status]}
                                                    cb={this.goPage}/>
                                            )}

                                        </>
                                    )
                                    :
                                    <S.NoResult>
                                        {translation('account_cashbacks_noResult')}
                                    </S.NoResult>
                                }
                            </>

                        )}

                </S.CashbackListWrapper>

            </S.AccountCashback>
        );
    }
}

const mapStateToProps = ({AccountCashback:{isPending, error, startDate, endDate, status, data, currentPage, limit}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        status,
        data,
        currentPage,
        limit,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchCashbackByFilter: bindActionCreators(fetchCashbackByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountCashback);
