import React, {useState, useEffect, useRef, useMemo} from 'react';
import S from 'StyledRegisterWizardForm.js';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import {translation} from 'helpers/utilsHelper.js';
import RegisterWizardFormStep1 from 'RegisterWizardFormStep1.js';
import RegisterWizardFormStep2 from 'RegisterWizardFormStep2.js';
import {SubmissionError, change} from 'redux-form';
import store from 'store';
import usePrevious from 'UsePrevious.js';

const RegisterWizardForm = (props) => {

    const {toggleRegisterModal, toggleConfirmationModal, registerOnSubmit, preregisterUser, loginOnSubmit, destroyWizardFormOnModalClose, verificationCode} = props;
    const [step, setNextStep]  = useState(1);
    // const prevStep = usePrevious(step);

    useEffect(() => {
        return destroyWizardFormOnModalClose;
    }, []);

    useEffect(() => {
        if (step == 1 /* && step!=prevStep*/) {
            const {dispatch} = store;
            setTimeout(() => {
                dispatch(change('registerWizardForm', 'password', ''));
                dispatch(change('registerWizardForm', 'confirmPassword', ''));
            }, 500);
        }
    }, [step]);

    const goToNextPage = () => {
        setNextStep((prevStep) => prevStep + 1);
    };

    const goToPrevPage = () => {
        setNextStep((prevStep) => prevStep - 1);
    };

    const registerFirstStep = async(values) => {
        try {
            const pickedValues = ['email', 'marketingConsent', 'mobile'];
            const registerData = _pick(values, pickedValues);
            if (_get(registerData, ['marketingConsent']) == true) {
                await preregisterUser(registerData)
            }
            goToNextPage();

        } catch (error) {
            throw new SubmissionError({_error: error});
        }
    };

    const registerSecondStep = async(values) => {
        try {

            let pickedValues = [
                'firstName',
                'lastName',
                'nationality',
                'password',
                'bonus',
                'freebet',
                'email',
                'mobile',
                'marketingConsent',
                'registrationOffers',
                'dateOfBirth'
            ];

            const nationalityPL = _get(values, ['nationality']);
            if (nationalityPL == 'PL') {
                pickedValues = [...pickedValues, 'personalIdentifier'];
            } else {
                pickedValues = [...pickedValues, 'dateOfBirth'];
            }

            let registerData = _pick(values, pickedValues);
            if('personalIdentifier' in registerData){
                registerData['personalIdentificationNumber'] = registerData['personalIdentifier'];
                delete registerData['personalIdentifier'];
            }
            if(('registrationOffers' in registerData) && _size(registerData['registrationOffers'])){
                registerData['registrationOffers']= _map([].concat(registerData['registrationOffers']), ({value})=> value);
            }
            registerData.countryCode = process.env.MAIN_COUNTRY_CODE;
            registerData.currencyCode = process.env.MAIN_CURRENCY_CODE;
            await registerOnSubmit(registerData);

            toggleRegisterModal();
            toggleConfirmationModal();

        } catch (error) {
            throw new SubmissionError({_error: error});
        }
    };

    const initialValues = useMemo(() => {
        return {
            nationality: process.env.MAIN_COUNTRY_CODE,
            dateOfBirth: new Date(1990, 0, 1)
        };
    }, []);

    return (
        <S.RegisterWizardForm>
            {step == 1 && (<RegisterWizardFormStep1 onSubmit={registerFirstStep}/>)}
            {step == 2 && (
                <RegisterWizardFormStep2 goToPrevPage={goToPrevPage} initialValues={initialValues}
                                         onSubmit={registerSecondStep}/>)}
        </S.RegisterWizardForm>
    );
};

export default RegisterWizardForm;
