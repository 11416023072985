export default {
    HOME: {
        href: '/',
        className: 'sport',
        translationKey: 'topMenu_navbar_sports'
    },

    LIVES: {
        href: '/zaklady-live',
        className: 'live',
        translationKey: 'topMenu_navbar_inPlay'
    },
    VIRTUALS: {
        href: '/virtuals',
        className: 'virtual',
        translationKey: 'topMenu_navbar_virtuals'
    },
    TVBET: {
        href: '/gra-w-karty',
        className: 'tvbet',
        translationKey: 'topMenu_navbar_tvbet',
        isNew: true,
    },
    MISSIONS: {
        className: 'missions',
        translationKey: 'topMenu_navbar_missions',
        isSmarticoButton: true
    }
};